import { toast } from "react-toastify"
import { call, put, takeEvery } from "redux-saga/effects"
import { postSitedata, updateSiteData } from "../../helpers/api_helper"
import { addSiteFail, updateSiteFail } from "./action"
// Calender Redux States
import { ADD_NEW_SITE, UPDATE_SITE } from "./actionTypes"

//async
const asyncPostSiteData = async (data, authtoken) => {
  try {
    const response = await postSitedata(data, authtoken)
    return response.data
  } catch (error) {
    return error.response.data
  }
}

function* onAddSite({ payload: { data, history, authtoken } }) {
  try {
    const response = yield call(asyncPostSiteData, data, authtoken)
    if (response.status == "success") {
      toast("🦄 Site added successfully!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
      history.push("sites")
    } else {
      toast.error(response.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
  } catch (error) {
    console.log("error", error)
    yield put(addSiteFail(error.message))
    toast.error(error.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    })
  }
}

function* onUpdateSite({ payload: { id, data, history, token } }) {
  try {
    const response = yield call(updateSiteData, id, data, token)
    toast("🦄 Site updated successfully!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    })
    if (response.status === 200) {
      history.push("/sites")
    }
  } catch (error) {
    console.log(error.response)
    yield put(updateSiteFail(error))
  }
}

function* SiteSaga() {
  yield takeEvery(ADD_NEW_SITE, onAddSite)
  yield takeEvery(UPDATE_SITE, onUpdateSite)
}

export default SiteSaga
