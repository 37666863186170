import AddCard from "components/Atoms/AddCard"
import CustomCard from "components/Atoms/CustomCard"
import SearchInput from "components/Atoms/SearchInput"
import Apaexlinecolumn from "components/Common/apaexlinecolumn"
import ApexRevenue from "components/Common/ApexRevenue"
import CardComponent from "components/Layout/CardComponent"
import InnerLayer from "components/Layout/InnerLayer"
import AddOffer from "components/Product/AddOffer"
import ProductChart from "components/Product/ProductChart"
import PropTypes from "prop-types"
import React from "react"
import { Col, Row } from "reactstrap"
const breadcrumbItems = [
  {
    title: "Dashboard",
    link: "/dashboard",
  },
  {
    title: "Product List",
    link: "/products",
  },
  {
    title: "Pepsodent 400ml Details",
    link: "#",
  },
]

const ProductDetails = () => {
  return (
    <React.Fragment>
      <InnerLayer
        isMultiple
        title={"Pepsodent 400ml Details"}
        breadcrumbItems={breadcrumbItems}
      >
        <Row>
          <Col md="3">
            <CustomCard title="Sell TK & Unit">
              <div className="d-flex justify-content-center align-content-center">
                <p>10,00,000tk</p> <span className="mx-1">|</span>{" "}
                <p>30000Pieces</p>
              </div>
            </CustomCard>
          </Col>
          <Col md="3">
            <CustomCard title="Top Selling Area">
              <div className="d-flex justify-content-center align-content-center">
                <h6>Area Name</h6>
              </div>
            </CustomCard>
          </Col>
          <Col md="3">
            <CustomCard title="Top Selling Store">
              <div className="d-flex justify-content-center align-content-center">
                <h6>Store Name</h6>
              </div>
            </CustomCard>
          </Col>
          <Col md="3">
            <AddCard
              title="Sell-off your Product"
              buttonText="Sell Off"
              onAddHandler={() => {}}
            />
          </Col>
        </Row>

        <Row>
          <Col md="8">
            <ProductChart title="Total amount">
              <ApexRevenue />
            </ProductChart>
            <ProductChart title="Total Units">
              <ApexRevenue />
            </ProductChart>

            <ProductChart title="Store Wise Selling Chart">
              <Apaexlinecolumn />
            </ProductChart>
          </Col>
          <Col md="4">
            <AddOffer />

            {/*  */}
            <CardComponent>
              <div className="d-flex align-items-center justify-content-between">
                <h6 className="font-size-13">See Map Area</h6>
                <div className="search-box me-2 mb-2">
                  <SearchInput
                    className="userSearchBar form-control"
                    onChange={() => {}}
                  />
                </div>
              </div>
              <div style={{ minHeight: "200px" }}></div>
            </CardComponent>
          </Col>
        </Row>
      </InnerLayer>
    </React.Fragment>
  )
}

ProductDetails.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.any,
}

export default ProductDetails
