import { toast } from "react-toastify"
import { call, put, takeEvery } from "redux-saga/effects"
import {
  axiosGetCategory,
  axiosGetSubCategory,
  axiosGetSubCategoryByCategory,
  postCategoryData,
  postSubCategoryData,
  updateCategoryData,
  updateSubCategoryData,
} from "../../helpers/api_helper"
import {
  addCategoryFail,
  addSubCategoryFail,
  getCategoryValueSuccess,
  getSubCategoryByCategoryValueSuccess,
  getSubCategoryValueSuccess,
  updateCategoryFail,
} from "./action"
// Calender Redux States
import {
  ADD_NEW_CATEGORY,
  ADD_NEW_SUB_CATEGORY,
  GET_CATEGORY_VALUE,
  GET_SUB_CATEGORY_BY_CATEGORY_VALUE,
  GET_SUB_CATEGORY_VALUE,
  UPDATE_CATEGORY,
  UPDATE_SUB_CATEGORY,
} from "./actionTypes"

//Category
const asyncGetCategory = async (authtoken, dataRange, currentPage) => {
  try {
    const response = await axiosGetCategory(authtoken, dataRange, currentPage)
    return response.data
  } catch (error) {
    return error.response.data
  }
}
function* workerGetCategory({
  payload: { authtoken, currentPage, dataRange },
}) {
  try {
    const response = yield call(
      asyncGetCategory,
      authtoken,
      dataRange,
      currentPage
    )
    yield put(getCategoryValueSuccess(response))
  } catch (error) {}
}

//async
const asyncPostCategoryData = async (data, authtoken) => {
  try {
    const response = await postCategoryData(data, authtoken)
    return response.data
  } catch (error) {
    return error.response.data
  }
}

function* onAddCategory({ payload: { data, history, authtoken } }) {
  try {
    const response = yield call(asyncPostCategoryData, data, authtoken)
    console.log("response here", response)
    if (response.status == "success") {
      toast("🦄 Category added successfully!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
      history.push("categories")
    } else {
      toast.error(response.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
  } catch (error) {
    yield put(addCategoryFail(error.message))
    toast.error(error.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    })
  }
}

function* onUpdateCategory({ payload: { id, data, token, history } }) {
  try {
    const response = yield call(updateCategoryData, id, data, token)
    // yield put(updateTerritorySuccess(response))
    toast("🦄 Category updated successfully!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    })
    if (response.status === 200) {
      history.push("/categories")
    }
  } catch (error) {
    console.log(error.response)
    yield put(updateCategoryFail(error))
  }
}

//Sub Category

const asyncGetSubCategory = async (
  authtoken,
  categoryId,
  dataRange,
  currentPage
) => {
  try {
    const response = await axiosGetSubCategory(
      authtoken,
      categoryId,
      dataRange,
      currentPage
    )
    return response.data
  } catch (error) {
    return error.response.data
  }
}
function* workerGetSubCategory({
  payload: { authtoken, categoryId, dataRange, currentPage },
}) {
  try {
    const response = yield call(
      asyncGetSubCategory,
      authtoken,
      categoryId,
      dataRange,
      currentPage
    )
    yield put(getSubCategoryValueSuccess(response))
  } catch (error) {}
}

const asyncGetSubCategoryByCategory = async (
  authtoken,
  categoryId,
  dataRange,
  currentPage
) => {
  try {
    const response = await axiosGetSubCategoryByCategory(
      authtoken,
      categoryId,
      dataRange,
      currentPage
    )
    return response.data
  } catch (error) {
    return error.response.data
  }
}
function* workerGetSubCategoryByCategory({
  payload: { authtoken, categoryId, dataRange, currentPage },
}) {
  try {
    console.log("testtest", categoryId)
    const response = yield call(
      asyncGetSubCategoryByCategory,
      authtoken,
      categoryId,
      dataRange,
      currentPage
    )
    yield put(getSubCategoryByCategoryValueSuccess(response))
  } catch (error) {}
}

//async
const asyncPostSubCategoryData = async (data, authtoken) => {
  try {
    const response = await postSubCategoryData(data, authtoken)
    return response.data
  } catch (error) {
    return error.response.data
  }
}

function* onAddSubCategory({ payload: { data, history, authtoken } }) {
  try {
    const response = yield call(asyncPostSubCategoryData, data, authtoken)
    if (response.status == "success") {
      toast("🦄 Sub Category added successfully!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
      history.push("sub-categories")
    } else {
      toast.error(response.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
  } catch (error) {
    yield put(addSubCategoryFail(error.message))
    toast.error(error.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    })
  }
}

function* onUpdateSubCategory({ payload: { id, data, token, history } }) {
  try {
    const response = yield call(updateSubCategoryData, id, data, token)
    console.log("response", response)
    console.log("history", history)
    // yield put(updateTerritorySuccess(response))
    toast("🦄 Sub Category updated successfully!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    })
    if (response.status === 200) {
      history.push("/sub-categories")
    }
  } catch (error) {
    console.log(error.response)
    yield put(updateTerritoryFail(error))
  }
}

function* GeoInformationSaga() {
  yield takeEvery(GET_CATEGORY_VALUE, workerGetCategory)
  yield takeEvery(ADD_NEW_CATEGORY, onAddCategory)
  yield takeEvery(UPDATE_CATEGORY, onUpdateCategory)

  yield takeEvery(GET_SUB_CATEGORY_VALUE, workerGetSubCategory)
  yield takeEvery(
    GET_SUB_CATEGORY_BY_CATEGORY_VALUE,
    workerGetSubCategoryByCategory
  )
  yield takeEvery(ADD_NEW_SUB_CATEGORY, onAddSubCategory)
  yield takeEvery(UPDATE_SUB_CATEGORY, onUpdateSubCategory)
}

export default GeoInformationSaga
