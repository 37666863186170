import { get, post } from "./api_helper"

//Get
export const getData = (url, authtoken) =>
  get(url, { headers: { Authorization: `Bearer ${authtoken}` } })
export const postData = (url, data, authtoken) =>
  post(url, data, { headers: { Authorization: `Bearer ${authtoken}` } })

export const getSiteExcelData = (url, data, authtoken) =>
  post(url, data, { headers: { Authorization: `Bearer ${authtoken}` } })
