import {
  ADD_TARGET_FAIL,
  ADD_TARGET_SUCCESS,
  GET_TARGET_VALUE,
  GET_TARGET_VALUE_SUCCESS,
  UPDATE_TARGET_FAIL,
  UPDATE_TARGET_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
  isLoading: false,
  targetData: [],
  editTargetInfo: [],
}

const TargetReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_TARGET_VALUE:
      return {
        ...state,
        isLoading: true,
      }
      break

    case GET_TARGET_VALUE_SUCCESS:
      return {
        ...state,
        targetData: action.payload.data,
        isLoading: false,
      }

    case ADD_TARGET_SUCCESS:
      return {
        ...state,
        target: [...state.target, action.payload],
        isLoading: false,
      }

    case ADD_TARGET_FAIL:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      }
    case UPDATE_TARGET_SUCCESS:
      return {
        ...state,
        target: [...state.target, action.payload],
        isLoading: false,
      }

    case UPDATE_TARGET_FAIL:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      }

    default:
      return state
  }
}

export default TargetReducer
