import AddCard from "components/Atoms/AddCard"
import TableHeader from "components/Common/TableHeader"
import CardComponent from "components/Layout/CardComponent"
import CustomTable from "components/Layout/CustomTable"
import InnerLayer from "components/Layout/InnerLayer"
import NoTableData from "components/Layout/NoTableData"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { withRouter } from "react-router-dom"
import { Col, Row } from "reactstrap"
import {
  getRouteValue,
  storeRouteData,
} from "../../../store/GeoInformation/action"

const tableHead = [
  "No.",
  "Region Name",
  "Area Name",
  "Territory Name",
  "Route Name",
  "Action",
]

const Route = ({ history }) => {
  const [currentPage, setCurrentPage] = useState(1)
  const [dataRange, setDataRange] = useState(10)
  let dispatch = useDispatch()
  const { routeData, loading, authtoken } = useSelector(state => ({
    loading: state.GeoInformationReducer.isLoading,
    routeData: state.GeoInformationReducer.routeData,
    authtoken: state.Login.token,
  }))
  useEffect(() => {
    dispatch(getRouteValue(authtoken, dataRange, currentPage))
  }, [dataRange, currentPage])
  let totalPageNumber = Math.ceil(routeData?.length / dataRange)

  const handleEditRoute = data => {
    dispatch(storeRouteData("singleRoute", data))
    history.push("/edit-route/" + data._id)
  }
  return (
    <React.Fragment>
      <InnerLayer
        title="Route List"
        wrapperClass="py-3 users"
        isBreadCrumb={true}
        link={"#"}
        mainTitle={"Geo Information"}
        subTitle={"Route List"}
      >
        <Row>
          <Col md="3" className="">
            <AddCard
              title="Create New Route"
              buttonText="Create Route"
              onAddHandler={() => history.push("/add-route")}
            />
          </Col>
        </Row>

        {/* table */}

        <Row>
          <CardComponent className="user_table_card">
            <TableHeader
              handleSearchFilter={() => {}}
              handleSelectFilter={() => {}}
              data={[10, 20, 50, 100]}
              handleSelectFilter2={() => {}}
              data2={[]}
              selectValue={dataRange}
              title="Route List"
              headerSearchOption="yes"
            />

            {/* table */}
            <Row>
              <CustomTable
                paginationClass="paginationContainer text-right"
                data={routeData.route}
                tableHead={tableHead}
                setCurrentPage={setCurrentPage}
                isPagination
                pageNo={totalPageNumber}
              >
                {routeData?.length ? (
                  routeData?.route.map((data, idx) => (
                    <tr key={idx}>
                      <th scope="row">{idx + 1}</th>
                      <td>{data.territory.area.region.name}</td>
                      <td>{data.territory.area.name}</td>
                      <td>{data.territory.name}</td>
                      <td>{data.name}</td>
                      <td>
                        <div className="d-flex gap-3">
                          <span
                            className="text-success pointer_event"
                            onClick={() => handleEditRoute(data)}
                          >
                            <i
                              className="mdi mdi-pencil font-size-18"
                              id="edittooltip"
                            ></i>
                          </span>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <NoTableData
                    colSpan=""
                    className="d-flex justify-content-center align-items-center"
                    style={{ minHeight: "300px", width: `100%` }}
                  >
                    <span>
                      No data here
                      {/* <Link
                        to="#"
                        style={{
                          color: "blue",
                          textDecoration: "underline",
                        }}
                      >
                        Add Product
                      </Link> */}
                    </span>
                  </NoTableData>
                )}
              </CustomTable>
              {/* table end */}
            </Row>
          </CardComponent>
        </Row>
      </InnerLayer>
    </React.Fragment>
  )
}

Route.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
}

export default withRouter(Route)
