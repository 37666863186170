import HelpCard from "components/Atoms/HelpCard"
import CustomInput from "components/Common/CustomInput"
import CardComponent from "components/Layout/CardComponent"
import InnerLayer from "components/Layout/InnerLayer"
import { addRegionSchema } from "components/Schemas/AddRegion.schema"
import { useFormik } from "formik"
import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { Col, Form, Row } from "reactstrap"
import { addNewRegion } from "../../../store/GeoInformation/action"

const breadcrumbItems = [
  {
    title: "Geo Information",
    link: "#",
  },
  {
    title: "Region",
    link: "geo/region",
  },
  {
    title: "Add Region",
    link: "#",
  },
]
const initialValues = {
  name: "",
}

const AddRegion = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { authtoken } = useSelector(state => ({
    authtoken: state.Login.token,
  }))
  const onSubmit = values => {
    console.log("values", values)
    // event.preventDefault()
    let obj = {}
    obj.name = values.name
    console.log(obj)
    dispatch(addNewRegion(obj, history, authtoken))
    console.log("run from here")
    console.log("values data here", values)
  }

  const addRegion = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: addRegionSchema,
    onSubmit,
  })
  return (
    <React.Fragment>
      <InnerLayer
        isMultiple
        title={"Add new Region"}
        breadcrumbItems={breadcrumbItems}
      >
        <div style={{ minHeight: "100vh" }} className="position-relative">
          <Row className="mt-4">
            <Col md="6" className="mx-auto">
              {/* add User */}
              <CardComponent className="">
                <Form
                  className="needs-validation"
                  onSubmit={addRegion.handleSubmit}
                >
                  <CustomInput
                    name={"name"}
                    type={"text"}
                    label={"Type region"}
                    placeholder={"Region name"}
                    validationType={addRegion}
                  />
                  <div className="d-flex justify-content-start">
                    <input
                      type="submit"
                      value={"Add Region"}
                      className="btn button "
                    />
                  </div>
                </Form>
              </CardComponent>
            </Col>
          </Row>

          <div className="position-absolute bottom-0 w-100">
            <HelpCard link="#" />
          </div>
        </div>
      </InnerLayer>
    </React.Fragment>
  )
}

export default AddRegion
