import PropTypes from "prop-types"
import React from "react"
import { Link } from "react-router-dom"
import { Card, CardBody, Col } from "reactstrap"

const CardContact = props => {
  const { user } = props
  return (
    <React.Fragment>
      <Col xl="3" sm="6">
        <Card className="text-center">
          <CardBody>
            {user.profile === "" ? (
              <div className="avatar-sm mx-auto mb-4">
                <span
                  className={
                    "avatar-title rounded-circle bg-soft bg-" +
                    " text-" +
                    " font-size-16"
                  }
                >
                  {user?.name?.charAt(0)}
                </span>
              </div>
            ) : (
              <div className="mb-4">
                <img
                  className="rounded-circle avatar-sm"
                  src={require(`../../assets/images/users/${user.profile}`)}
                  alt="image"
                />
              </div>
            )}
            <h5 className="font-size-15 mb-1">
              <Link to="#" className="text-dark">
                {user.name}
              </Link>
            </h5>
            <p className="text-muted">{user?.role}</p>
            <div>
              <button
                className="btn button"
                onClick={() => props.viewAttendance(user)}
                // style={{ backgroundColor: "#573B81" }}
              >
                Attendance
              </button>
            </div>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  )
}

CardContact.propTypes = {
  user: PropTypes.object,
  image: PropTypes.any,
  viewAttendance: PropTypes.func,
}

export default CardContact
