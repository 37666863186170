import axios from "axios"
import accessToken from "./jwt-token-access/accessToken"

//pass new generated access token here
const token = accessToken

//apply base url for axios
const API_URL = "https://staging-api.xelerator.org/api/v1"
//const API_URL = "http://localhost:5000/api/v1"

const axiosApi = axios.create({
  baseURL: API_URL,
})

// axiosApi.defaults.headers.common["Authorization"] = ""

axiosApi.interceptors.response.use(
  response => response,
  error => Promise.reject(error)
)

export async function get(url, config = {}) {
  return await axiosApi.get(url, { ...config }).then(response => response.data)
}

export async function post(url, data, config = {}) {
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then(response => response.data)
}
export async function patch(url, data, authtoken) {
  return axiosApi
    .patch(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data)
}
//REGION
export const axiosGetRegion = async (authtoken, currentPage, pageRange) =>
  await axios.get(`${API_URL}/region/${pageRange}/${currentPage}`, {
    headers: { Authorization: `Bearer ${authtoken}` },
  })
export const postRegiondata = async (data, authtoken) =>
  await axios.post(`${API_URL}/region`, data, {
    headers: { Authorization: `Bearer ${authtoken}` },
  })
export const updateRegionData = async (id, data, authtoken) =>
  await axios.patch(`${API_URL}/region/${id}`, data, {
    headers: { Authorization: `Bearer ${authtoken}` },
  })
//AREA
export const axiosGetArea = async (authtoken, dataRange, currentPage) =>
  await axios.get(`${API_URL}/area/${dataRange}/${currentPage}`, {
    headers: { Authorization: `Bearer ${authtoken}` },
  })
export const axiosGetAreaByRegion = async (
  authtoken,
  regionId,
  dataRange,
  currentPage
) =>
  await axios.get(
    `${API_URL}/area/getAreaByRegion/${regionId}/${dataRange}/${currentPage}`,
    { headers: { Authorization: `Bearer ${authtoken}` } }
  )
export const postAreaData = async (data, authtoken) =>
  await axios.post(`${API_URL}/area`, data, {
    headers: { Authorization: `Bearer ${authtoken}` },
  })
export const updateAreaData = async (id, data, authtoken) =>
  await axios.patch(`${API_URL}/area/${id}`, data, {
    headers: { Authorization: `Bearer ${authtoken}` },
  })
//TERRITORY
export const axiosGetTerritory = async (authtoken, dataRange, currentPage) =>
  await axios.get(`${API_URL}/territory/${dataRange}/${currentPage}`, {
    headers: { Authorization: `Bearer ${authtoken}` },
  })
export const axiosGetTerritoryByArea = async (
  authtoken,
  areaId,
  dataRange,
  currentPage
) =>
  await axios.get(
    `${API_URL}/territory/getTerritoryByArea/${areaId}/${dataRange}/${currentPage}`,
    { headers: { Authorization: `Bearer ${authtoken}` } }
  )
export const postTerritoryData = async (data, authtoken) =>
  await axios.post(`${API_URL}/territory`, data, {
    headers: { Authorization: `Bearer ${authtoken}` },
  })
export const updateTerritoryData = async (id, data, authtoken) =>
  await axios.patch(`${API_URL}/territory/${id}`, data, {
    headers: { Authorization: `Bearer ${authtoken}` },
  })
//ROUTE
export const axiosGetRoute = async (authtoken, dataRange, currentPage) =>
  await axios.get(`${API_URL}/route/${dataRange}/${currentPage}`, {
    headers: { Authorization: `Bearer ${authtoken}` },
  })
export const postRouteData = async (data, authtoken) =>
  await axios.post(`${API_URL}/route`, data, {
    headers: { Authorization: `Bearer ${authtoken}` },
  })
export const updateRouteData = async (id, data, authtoken) =>
  await axios.patch(`${API_URL}/route/${id}`, data, {
    headers: { Authorization: `Bearer ${authtoken}` },
  })
//CATEGORY
export const axiosGetCategory = async (authtoken, dataRange, currentPage) =>
  await axios.get(`${API_URL}/category/paging/${dataRange}/${currentPage}`, {
    headers: { Authorization: `Bearer ${authtoken}` },
  })
export const postCategoryData = async (data, authtoken) =>
  await axios.post(`${API_URL}/category`, data, {
    headers: { Authorization: `Bearer ${authtoken}` },
  })
export const updateCategoryData = async (id, data, authtoken) =>
  await axios.patch(`${API_URL}/category/${id}`, data, {
    headers: { Authorization: `Bearer ${authtoken}` },
  })

//SUB CATEGORY
export const axiosGetSubCategory = async (
  authtoken,
  categoryId,
  dataRange,
  currentPage
) =>
  await axios.get(
    `${API_URL}/subCategory/${categoryId}/${dataRange}/${currentPage}`,
    {
      headers: { Authorization: `Bearer ${authtoken}` },
    }
  )
export const axiosGetSubCategoryByCategory = async (
  authtoken,
  categoryId,
  dataRange,
  currentPage
) =>
  await axios.get(
    `${API_URL}/subCategory/getSubCategoryByCategory/${categoryId}/${dataRange}/${currentPage}`,
    {
      headers: { Authorization: `Bearer ${authtoken}` },
    }
  )
export const postSubCategoryData = async (data, authtoken) =>
  await axios.post(`${API_URL}/subCategory`, data, {
    headers: { Authorization: `Bearer ${authtoken}` },
  })
export const updateSubCategoryData = async (id, data, authtoken) =>
  await axios.patch(`${API_URL}/subCategory/${id}`, data, {
    headers: { Authorization: `Bearer ${authtoken}` },
  })
//USER
export const axiosGetUser = async (
  authtoken,
  userRole,
  dataRange,
  currentPage
) =>
  await axios.get(`${API_URL}/users/${userRole}/${dataRange}/${currentPage}`, {
    headers: { Authorization: `Bearer ${authtoken}` },
  })
export const postUserData = async (data, authtoken) =>
  await axios.post(`${API_URL}/users`, data, {
    headers: { Authorization: `Bearer ${authtoken}` },
  })
export const updateUserData = async (id, data, authtoken) =>
  await axios.patch(`${API_URL}/users/${id}`, data, {
    headers: { Authorization: `Bearer ${authtoken}` },
  })
//sites view
export const axiosGetSitesView = async (authtoken, siteId) =>
  await axios.get(`${API_URL}/site/${siteId}`, {
    headers: { Authorization: `Bearer ${authtoken}` },
  })
//delete org site
export const deleteOrgSite = async (authtoken, siteId) =>
  await axios.delete(`${API_URL}/organizationSite/${siteId}`, {
    headers: { Authorization: `Bearer ${authtoken}` },
  })
//Add Site
export const postSitedata = async (data, authtoken) =>
  await axios.post(`${API_URL}/site`, data, {
    headers: { Authorization: `Bearer ${authtoken}` },
  })
//Update Site
export const updateSiteData = async (id, data, authtoken) =>
  await axios.patch(`${API_URL}/site/${id}`, data, {
    headers: { Authorization: `Bearer ${authtoken}` },
  })

// Customer Segments
export const axiosGetSegment = async (authtoken, pageRange, currentPage) =>
  await axios.get(
    `${API_URL}/customerSegment/paging/${pageRange}/${currentPage}`,
    {
      headers: { Authorization: `Bearer ${authtoken}` },
    }
  )
export const postSegmentData = async (data, authtoken) =>
  await axios.post(`${API_URL}/customerSegment`, data, {
    headers: { Authorization: `Bearer ${authtoken}` },
  })
export const updateSegmentData = async (id, data, authtoken) =>
  await axios.patch(`${API_URL}/customerSegment/${id}`, data, {
    headers: { Authorization: `Bearer ${authtoken}` },
  })

// Route plan
export const axiosGetRoutePlan = async (
  authtoken,
  laUserId,
  fromDate,
  toDate
) =>
  await axios.get(
    `${API_URL}/routePlan/paging/getRoutePlanByLA/${laUserId}/${fromDate}/${toDate}`,
    {
      headers: { Authorization: `Bearer ${authtoken}` },
    }
  )
export const postRoutePlanData = async (data, authtoken) =>
  await axios.post(`${API_URL}/routePlan`, data, {
    headers: { Authorization: `Bearer ${authtoken}` },
  })
export const updateRoutePlanData = async (data, authtoken) =>
  await axios.patch(`${API_URL}/routePlan/updatemanyrouteplan`, data, {
    headers: { Authorization: `Bearer ${authtoken}` },
  })

// TARGET
export const axiosGetTarget = async (authtoken, laUserId, fromDate, toDate) =>
  await axios.get(
    `${API_URL}/target/getTargetByUser/${laUserId}/${fromDate}/${toDate}`,
    {
      headers: { Authorization: `Bearer ${authtoken}` },
    }
  )
export const postTargetData = async (data, authtoken) =>
  await axios.post(`${API_URL}/target`, data, {
    headers: { Authorization: `Bearer ${authtoken}` },
  })
export const updateTargetData = async (data, authtoken) =>
  await axios.patch(`${API_URL}/target/updateUserTarget`, data, {
    headers: { Authorization: `Bearer ${authtoken}` },
  })
// UPDATE STATUS IN ORGANIZATION PANEL
export const updateOrgStatusData = async (data, authtoken) =>
  await axios.post(`${API_URL}/org/organizationSite/orgFeedbackToSite`, data, {
    headers: { Authorization: `Bearer ${authtoken}` },
  })
//LOGIN
export const axiosLoginUser = async (email, password) =>
  await axios.post(`${API_URL}/users/login`, { email, password })
