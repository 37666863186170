import CustomInput from "components/Common/CustomInput"
import CardComponent from "components/Layout/CardComponent"
import InnerLayer from "components/Layout/InnerLayer"
import { editUserSchema } from "components/Schemas/EditUser.schemal"
import { useFormik } from "formik"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory, useParams } from "react-router-dom"
import { Col, Form, Row } from "reactstrap"
import { updateUser } from "store/actions"

const breadcrumbItems = [
  {
    title: "User",
    link: "/users",
  },
  {
    title: "Edit User",
    link: "#",
  },
]
const role = [
  {
    title: "Admin",
    value: "ADMIN",
  },
  {
    title: "Lead Agent",
    value: "LA",
  },
  {
    title: "Organization",
    value: "ORG",
  },
]
const initialValues = {
  name: "",
  email: "",
  mobileNo: "",
  role: "",
}

const EditUser = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [user, setUser] = useState(null)
  const { id: userId } = useParams()
  const onSubmit = values => {
    dispatch(updateUser(userId, values, authtoken, history))
  }
  const { editUserInfo, loading, authtoken } = useSelector(state => ({
    loading: state.UserReducer.isLoading,
    editUserInfo: state.UserReducer.editUserInfo,
    authtoken: state.Login.token,
  }))
  console.log("editUserInfo", editUserInfo)
  useEffect(() => {
    const oldUser = { ...user }
    oldUser.name = editUserInfo?.data?.name
    oldUser.email = editUserInfo?.data?.email
    oldUser.mobileNo = editUserInfo?.data?.mobileNo
    oldUser.role = editUserInfo?.data?.role
    setUser(oldUser)
  }, [editUserInfo])

  const userEdit = useFormik({
    enableReinitialize: true,
    initialValues: user || initialValues,
    validationSchema: editUserSchema,
    onSubmit,
  })
  return (
    <React.Fragment>
      <InnerLayer
        isMultiple
        title={"Edit User"}
        breadcrumbItems={breadcrumbItems}
      >
        <div style={{ minHeight: "100vh" }} className="position-relative">
          {/* add User */}
          <CardComponent className="mt-4">
            <Form className="needs-validation" onSubmit={userEdit.handleSubmit}>
              <Row>
                <Col>
                  <CustomInput
                    name={"name"}
                    type={"text"}
                    label={"User Name"}
                    placeholder={"Type user name"}
                    validationType={userEdit}
                  />
                </Col>
                <Col>
                  <CustomInput
                    name={"mobileNo"}
                    type={"text"}
                    label={"Phone name"}
                    placeholder={"Type phone number"}
                    validationType={userEdit}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <CustomInput
                    name={"email"}
                    type={"email"}
                    label={"Email"}
                    placeholder={"Type email address"}
                    validationType={userEdit}
                  />
                </Col>
                <Col>
                  <CustomInput
                    name={"role"}
                    type={"select"}
                    label={"Select role"}
                    validationType={userEdit}
                  >
                    <option defaultValue>Select role...</option>
                    {role?.map((data, idx) => (
                      <option key={idx} value={data.value}>
                        {data.title}
                      </option>
                    ))}
                  </CustomInput>
                </Col>
              </Row>
              <Row>
                <Col className="col-6 mt-3">
                  <CustomInput
                    name={"check"}
                    type={"checkbox"}
                    label={"All"}
                    validationType={userEdit}
                    // onChange={handleSelectAll}
                  />
                </Col>
              </Row>
              <Row>
                <Col className="col-6">
                  <CustomInput
                    name={"paint"}
                    type={"checkbox"}
                    label={"Paint"}
                    validationType={userEdit}
                  />
                </Col>

                <Col className="col-6">
                  <CustomInput
                    name={"cement"}
                    type={"checkbox"}
                    label={"Cement"}
                    validationType={userEdit}
                  />
                </Col>
                <Col className="col-6">
                  <CustomInput
                    name={"sand"}
                    type={"checkbox"}
                    label={"Sand"}
                    validationType={userEdit}
                  />
                </Col>

                <Col className="col-6">
                  <CustomInput
                    name={"steel"}
                    type={"checkbox"}
                    label={"Steel"}
                    validationType={userEdit}
                  />
                </Col>
                <Col className="col-6">
                  <CustomInput
                    name={"aggregates"}
                    type={"checkbox"}
                    label={"Aggregates"}
                    validationType={userEdit}
                  />
                </Col>

                <Col className="col-6">
                  <CustomInput
                    name={"brick"}
                    type={"checkbox"}
                    label={"Brick"}
                    validationType={userEdit}
                  />
                </Col>
                <Col className="col-6">
                  <CustomInput
                    name={"windows"}
                    type={"checkbox"}
                    label={"Windows"}
                    validationType={userEdit}
                  />
                </Col>

                <Col className="col-6">
                  <CustomInput
                    name={"doors"}
                    type={"checkbox"}
                    label={"Doors"}
                    validationType={userEdit}
                  />
                </Col>
                <Col className="col-6">
                  <CustomInput
                    name={"plumbing"}
                    type={"checkbox"}
                    label={"Plumbing"}
                    validationType={userEdit}
                  />
                </Col>

                <Col className="col-6">
                  <CustomInput
                    name={"electrical"}
                    type={"checkbox"}
                    label={"Electrical"}
                    validationType={userEdit}
                  />
                </Col>
                <Col className="col-6">
                  <CustomInput
                    name={"sanitary"}
                    type={"checkbox"}
                    label={"Sanitary"}
                    validationType={userEdit}
                  />
                </Col>
                <Col className="col-6">
                  <CustomInput
                    name={"tiles"}
                    type={"checkbox"}
                    label={"Tiles"}
                    validationType={userEdit}
                  />
                </Col>
              </Row>
              <div className="d-flex justify-content-start">
                <input
                  type="submit"
                  value={"Update User"}
                  className="btn button"
                />
              </div>
            </Form>
          </CardComponent>
        </div>
      </InnerLayer>
    </React.Fragment>
  )
}

export default EditUser
