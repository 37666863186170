import { toast } from "react-toastify"
import { call, put, takeEvery } from "redux-saga/effects"
import {
  axiosGetSegment,
  postSegmentData,
  updateSegmentData,
} from "../../helpers/api_helper"
import {
  addSegmentFail,
  getSegmentValueSuccess,
  updateSegmentFail,
} from "./action"
// Calender Redux States
import {
  ADD_NEW_SEGMENT,
  GET_SEGMENT_VALUE,
  UPDATE_SEGMENT,
} from "./actionTypes"

//Segment
const asyncGetSegment = async (authtoken, pageRange, currentPage) => {
  try {
    const response = await axiosGetSegment(authtoken, pageRange, currentPage)
    return response.data
  } catch (error) {
    return error.response.data
  }
}
function* workerGetSegment({ payload: { authtoken, pageRange, currentPage } }) {
  try {
    const response = yield call(
      asyncGetSegment,
      authtoken,
      pageRange,
      currentPage
    )
    yield put(getSegmentValueSuccess(response))
  } catch (error) {}
}

//async
const asyncPostSegmentData = async (data, authtoken) => {
  try {
    const response = await postSegmentData(data, authtoken)
    return response.data
  } catch (error) {
    return error.response.data
  }
}

function* onAddSegment({ payload: { data, history, authtoken } }) {
  try {
    const response = yield call(asyncPostSegmentData, data, authtoken)
    if (response.status == "success") {
      toast("🦄 Customer segment added successfully!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
      history.push("customer-segments")
    } else {
      toast.error(response.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
  } catch (error) {
    yield put(addSegmentFail(error.message))
    toast.error(error.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    })
  }
}

function* onUpdateSegment({ payload: { id, data, token, history } }) {
  try {
    const response = yield call(updateSegmentData, id, data, token)
    // yield put(updateTerritorySuccess(response))
    toast("🦄 Customer segment updated successfully!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    })
    if (response.status === 200) {
      history.push("/customer-segments")
    }
  } catch (error) {
    console.log(error.response)
    yield put(updateSegmentFail(error))
  }
}

function* SegmentSaga() {
  yield takeEvery(GET_SEGMENT_VALUE, workerGetSegment)
  yield takeEvery(ADD_NEW_SEGMENT, onAddSegment)
  yield takeEvery(UPDATE_SEGMENT, onUpdateSegment)
}

export default SegmentSaga
