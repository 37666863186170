import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import Select from "react-select"
import {
  getCategory,
  getSubCategory,
  onMainFilterChange,
  onSubCategoryChange,
  onSubFilterChange,
  onTotalValueChange
} from "../../store/OrgDashboard/actions"
const data = [
  {
    name: "Private",
    value: "private",
    id: "",
  },
  {
    name: "Govt",
    value: "govt",
    id: "",
  },
]

const mainFilter = [{ name: "All", value: "all", id: "" }]

const moneyData = [
  {
    _id: 1,
    name: "Total",
    value: "all",
  },
  {
    _id: 2,
    name: "Less than 7 lakh",
    value: "lt",
  },
  {
    _id: 3,
    name: "Greater than 7 lakh",
    value: "gt",
  },
]
const typeData = [
  {
    name: "All",
    value: "All",
  },
  {
    name: "Paint",
    value: "Paint",
  },
  {
    name: "Cement",
    value: "Cement",
  },
  {
    name: "Sand",
    value: "Sand",
  },
  {
    name: "Steel",
    value: "Steel",
  },
  {
    name: "Aggregates",
    value: "Aggregates",
  },
  {
    name: "Brick",
    value: "Brick",
  },
  {
    name: "Windows",
    value: "Windows",
  },
  {
    name: "Doors",
    value: "Doors",
  },
  {
    name: "Plumbing",
    value: "Plumbing",
  },
  {
    name: "Electrical",
    value: "Electrical",
  },
  {
    name: "Sanitary",
    value: "Sanitary",
  },
  {
    name: "Tiles",
    value: "Tiles",
  },
 
]
const BangladeshSection = () => {
  const dispatch = useDispatch()
  const [status, setStatus] = useState("all")
  const [total, setTotal] = useState("all")
  // console.log(total)
  const [loadSingleCategory, setLoadSingleCategory] = useState(false)
  const stateDashboard = useSelector(state => state.DashboardReducer)
  const { subFilters, subSelectedFilters, subCategory, selectedSubCategory } =
    stateDashboard
  // console.log('subSelectedFilters',subSelectedFilters);
  // console.log({data?.filter(data => data?._id === subSelectedFilters?._id) || [{ name: "", value: "",_id:"" }]});
  useEffect(() => {
    const data = { name: "All", value: "all", id: "" }
    dispatch(onMainFilterChange(data))
  }, [])

  useEffect(() => {
    dispatch(getCategory(""))
  }, [mainFilter])

  useEffect(() => {
    if (Object?.keys(subSelectedFilters)?.length) {
      dispatch(getSubCategory(subSelectedFilters?._id))
    }
  }, [loadSingleCategory])

  // const dispatch = useDispatch()
  const handleMainFilter = data => {
    if (data) {
      setStatus(data?.value)
      dispatch(onMainFilterChange(data))
    }
  }
  const handleSubFilter = data => {
    setLoadSingleCategory(!loadSingleCategory)
    dispatch(onSubFilterChange(data))
    // dispatch(storeDashboardReducerData("subSelectedFilters", data))
    // dispatch(toggleDashboardData("isSubFilterChanged"))
  }

  const handleSubCategory = data => {
    dispatch(onSubCategoryChange(data))
  }
  const handleTotalValue = data => {
    console.log("totalValueData", data)
    // setTotal(data?.value)
    dispatch(onTotalValueChange(data))
  }
  // console.log(
  //   subCategory.filter(data => data?._id === selectedSubCategory?._id)
  // )
  return (
    <div className="d-flex justify-content-between flex-wrap">
      <div className="col-md-3 d-flex align-items-center">
      <div style={{ minWidth: "190px" }} className="me-3 custom_select">
          <Select
            name="subFilter"
            classNamePrefix="select2-selection"
            style={{ minWidth: "100px" }}
            getOptionLabel={e => e.name}
            getOptionValue={e => e._id}
            defaultValue={moneyData?.filter(data => data?.value === total)}
            options={moneyData}
            onChange={handleTotalValue}
          />
        </div>
        {/* <div style={{ minWidth: "190px" }} className="me-3 custom_select">
          <Select
            name="subFilter"
            classNamePrefix="select2-selection"
            style={{ minWidth: "100px" }}
            getOptionLabel={e => e.name}
            getOptionValue={e => e.value}
            // defaultValue={typeData?.filter(data => data?.value === "All")}
            options={typeData}
            // onChange={handleTotalValue}
          />
        </div> */}
      </div>
      <div className="d-flex ">
        {Object.keys(subSelectedFilters)?.length && subCategory?.length ? (
          <div style={{ minWidth: "150px" }} className="me-3">
            <Select
              name="subFilter"
              classNamePrefix="select2-selection"
              style={{ minWidth: "100px" }}
              getOptionLabel={e => e.name}
              getOptionValue={e => e._id}
              value={
                subCategory.filter(
                  data => data?._id === selectedSubCategory?._id
                ) || [{ name: "", value: "" }]
              }
              options={subCategory}
              onChange={handleSubCategory}
            />
          </div>
        ) : null}
        <div style={{ minWidth: "150px" }} className="me-3">
          <Select
            name="mainFilter"
            classNamePrefix="select2-selection"
            // style={{ minWidth: "100px" }}
            cacheOptions
            getOptionLabel={e => e.name}
            getOptionValue={e => e._id}
            value={
              subFilters?.filter(
                data => data?._id === subSelectedFilters?._id
              ) || [{ name: "", value: "", _id: "" }]
            }
            options={subFilters}
            onChange={handleSubFilter}
          />
        </div>
        <div style={{ minWidth: "150px" }}>
          <Select
            name="mainFilter"
            classNamePrefix="select2-selection"
            // style={{ minWidth: "100px" }}
            cacheOptions
            getOptionLabel={e => e.name}
            getOptionValue={e => e.value}
            defaultValue={mainFilter?.filter(data => data.value === status)}
            options={mainFilter}
            onChange={handleMainFilter}
            // isClearable
            // isSearchable
          />
        </div>
      </div>
    </div>
  )
}

export default BangladeshSection
