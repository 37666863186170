import AddCard from "components/Atoms/AddCard"
import TableHeader from "components/Common/TableHeader"
import CardComponent from "components/Layout/CardComponent"
import CustomTable from "components/Layout/CustomTable"
import InnerLayer from "components/Layout/InnerLayer"
import NoTableData from "components/Layout/NoTableData"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { withRouter } from "react-router-dom"
import { Col, Row } from "reactstrap"
import { getAreaValue } from "../../../store/GeoInformation/action"

const tableHead = ["No.", "Region Name", "Area Name", "Action"]

const Area = ({ history }) => {
  const [currentPage, setCurrentPage] = useState(1)
  const [dataRange, setDataRange] = useState(10)
  let dispatch = useDispatch()
  const { regionData, areaData, loading, authtoken } = useSelector(state => ({
    loading: state.GeoInformationReducer.isLoading,
    areaData: state.GeoInformationReducer.areaData,
    authtoken: state.Login.token,
  }))
  useEffect(() => {
    dispatch(getAreaValue(authtoken, dataRange, currentPage))
  }, [dataRange, currentPage])
  let totalPageNumber = Math.ceil(areaData?.length / dataRange)

  return (
    <React.Fragment>
      <InnerLayer
        title="Area List"
        wrapperClass="py-3 users"
        isBreadCrumb={true}
        link={"#"}
        mainTitle={"Geo Information"}
        subTitle={"Area List"}
      >
        <Row>
          <Col md="3" className="">
            <AddCard
              title="Create New Area"
              buttonText="Create Area"
              onAddHandler={() => history.push("/add-area")}
            />
          </Col>
        </Row>

        {/* table */}

        <Row>
          <CardComponent className="user_table_card">
            <TableHeader
              handleSearchFilter={() => {}}
              handleSelectFilter={e => {
                setDataRange(e.target.value)
              }}
              data={[10, 20, 50, 100]}
              handleSelectFilter2={() => {}}
              data2={[]}
              selectValue={dataRange}
              title="Area List"
              headerSearchOption="yes"
            />

            {/* table */}
            <Row>
              <CustomTable
                paginationClass="paginationContainer text-right"
                data={areaData?.area}
                tableHead={tableHead}
                setCurrentPage={setCurrentPage}
                reInitialize={dataRange}
                isPagination
                pageNo={totalPageNumber}
              >
                {areaData?.length > 0 ? (
                  areaData?.area.map((data, idx) => (
                    <tr key={idx}>
                      <th scope="row">{idx + 1}</th>
                      <td>{data.region.name}</td>
                      <td>{data.name}</td>
                      <td>
                        <div className="d-flex gap-3">
                          <span
                            className="text-success pointer_event"
                            onClick={() => handleEditArea(data)}
                          >
                            <i
                              className="mdi mdi-pencil font-size-18"
                              id="edittooltip"
                            ></i>
                          </span>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <NoTableData
                    colSpan=""
                    className="d-flex justify-content-center align-items-center"
                    style={{ minHeight: "300px", width: `100%` }}
                  >
                    <span>No data here</span>
                  </NoTableData>
                )}
              </CustomTable>
              {/* table end */}
            </Row>
          </CardComponent>
        </Row>
      </InnerLayer>
    </React.Fragment>
  )
}

Area.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
}

export default withRouter(Area)
