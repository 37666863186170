import CustomInput from "components/Common/CustomInput"
import CardComponent from "components/Layout/CardComponent"
import InnerLayer from "components/Layout/InnerLayer"
import { addSegmentSchema } from "components/Schemas/AddSegment.schema"
import { useFormik } from "formik"
import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { Col, Form, Row } from "reactstrap"
import { addNewSegment } from "../../store/Segment/action"

const breadcrumbItems = [
  {
    title: "Customer Segment",
    link: "/customer-segments",
  },
  {
    title: "Add Customer Segment",
    link: "#",
  },
]
const initialValues = {
  name: "",
}

const AddSegment = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { authtoken } = useSelector(state => ({
    authtoken: state.Login.token,
  }))
  const onSubmit = values => {
    console.log("values", values)
    // event.preventDefault()
    let obj = {}
    obj.name = values.name
    dispatch(addNewSegment(obj, history, authtoken))
  }

  const addSegment = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: addSegmentSchema,
    onSubmit,
  })
  return (
    <React.Fragment>
      <InnerLayer
        isMultiple
        title={"Add New Customer segment"}
        breadcrumbItems={breadcrumbItems}
      >
        <div style={{ minHeight: "100vh" }} className="position-relative">
          <Row className="mt-4">
            <Col md="6" className="mx-auto">
              {/* add User */}
              <CardComponent className="">
                <Form
                  className="needs-validation"
                  onSubmit={addSegment.handleSubmit}
                >
                  <CustomInput
                    name={"name"}
                    type={"text"}
                    label={"Type Customer Segment"}
                    placeholder={"Customer Segment Name"}
                    validationType={addSegment}
                  />
                  <div className="d-flex justify-content-start">
                    <input
                      type="submit"
                      value={"Add Customer Segment"}
                      className="btn button "
                    />
                  </div>
                </Form>
              </CardComponent>
            </Col>
          </Row>
        </div>
      </InnerLayer>
    </React.Fragment>
  )
}

export default AddSegment
