import * as Yup from "yup"

export const addProductSchema = Yup.object().shape({
  productName: Yup.string().required("This value is required"),
  code: Yup.number().required("This value is required"),
  model: Yup.string().required("This value is required"),
  category: Yup.string().required("This value is required"),
  unit: Yup.number().required("This value is required"),
  unitPrice: Yup.number().required("This value is required"),
  brandName: Yup.string().required("This value is required"),
  discount: Yup.string().required("This value is required"),
  file: Yup.mixed().required("A file is required"),
})
