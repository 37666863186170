import CardComponent from "components/Layout/CardComponent"
import React, { useEffect, useState } from "react"
import ReactApexChart from "react-apexcharts"
import { Col, Row } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { Spinner } from "reactstrap"
import { getPaintArea } from "store/actions"
import Exterior from "../../assets/images/xelerator/icon/dashboard/exterior.svg"
import Interior from "../../assets/images/xelerator/icon/dashboard/interior.svg"
import WetArea from "../../assets/images/xelerator/icon/dashboard/wet-area.svg"
import { makeQuery } from "../../helpers/Custom/makeQuery"

const Paint = () => {
  const dispatch = useDispatch()
  // useEffect(() => {
  //  dispatch(getPotValue)
  // });
  const [chartValue, setChartValue] = useState("monthly")
  const [year, setYear] = useState("all")
  // const [index, setIndex] = useState('value');
  const {
    mainFilter,
    subSelectedFilters,
    isSubFilterChanged,
    selectedSubCategory,
    currentSelection,
    totalValue,
    paintArea,
    paintAreaLoading,
    authToken,
    role
  } = useSelector(state => ({
    mainFilter: state.DashboardReducer.mainFilter,
    subSelectedFilters: state.DashboardReducer.subSelectedFilters,
    totalValue: state.DashboardReducer.totalValue,
    isSubFilterChanged: state.DashboardReducer.isSubFilterChanged,
    selectedSubCategory: state.DashboardReducer.selectedSubCategory,
    currentSelection: state.DashboardReducer.currentSelection,
    paintArea: state?.AdminReducer?.paintArea,
    potValueLoading: state?.AdminReducer?.potValueLoading,
    paintAreaLoading: state?.AdminReducer?.paintAreaLoading,
    authToken : state.Login.token,
    role: state.Login.data?.role,
  }))
  // const query = makeQuery(
  //   mainFilter,
  //   subSelectedFilters,
  //   selectedSubCategory,
  //   currentSelection
  // )
  // console.log("mainfilterChanged??????", paintArea)
  useEffect(() => {
    const chartQuery = makeQuery(
      mainFilter,
      subSelectedFilters,
      selectedSubCategory,
      currentSelection,
      totalValue,
      chartValue,
      year
    )
    // console.log(
    //   `file: Paint.js ~ line 111 ~ useEffect ~ chartQuery`,
    //   chartQuery
    // )
    dispatch(getPaintArea(authToken, chartQuery, 'ADMIN'))
  }, [isSubFilterChanged, chartValue, year])
  const handleChat = e => {
    setChartValue(e.target.value)
  }
  const handleYear = e => {
    setYear(e.target.value)
  }

  let exteriorAreaData, interiorData, wetSurfaceAreaData, monthData
  if (!paintAreaLoading) {
    exteriorAreaData = paintArea?.data?.ExteriorArea
    interiorData = paintArea?.data?.InteriorArea
    wetSurfaceAreaData = paintArea?.data?.wetSurfaceArea
    monthData = paintArea?.data?.month
  }
  //   let ExteriorArray =[]
  //   let interiorArray =[]
  //   let wetSurfaceArray =[]
  // const exterior = exteriorAreaData?.map(exteriorFunction)
  // const interior = interiorData?.map(interiorFunction)
  // const wetSurface = wetSurfaceAreaData?.map(wetSurfaceFunction)

  // function exteriorFunction(num) {
  //   const convertToCr =( num/10000000).toFixed(2)
  //   const addCrsign = convertToCr + 'cr'
  //   ExteriorArray.push(addCrsign)
  // }
  // function interiorFunction(num) {
  //   const convertToCr =( num/10000000).toFixed(2)
  //   const addCrsign = convertToCr + 'cr'
  //   interiorArray.push(addCrsign)
  // }
  // function wetSurfaceFunction(num) {
  //   const convertToCr =( num/10000000).toFixed(2)
  //   const addCrsign = convertToCr + 'cr'
  //   wetSurfaceArray.push(addCrsign)
  // }
  // const series = [
  //   {
  //     name: "Wet Area",
  //     data: wetSurfaceAreaData || [],
  //   },
  //   {
  //     name: "Interior",
  //     data: interiorData || [],
  //   },
  //   {
  //     name: "Exterior",
  //     data: exteriorAreaData || [],
  //   },
  // ]

  // const options = {
  //   chart: {
  //     height: 350,
  //     type: "area",
  //     toolbar: {
  //       show: false,
  //     },
  //   },
  //   colors: ["#00BCD5", "#AD1457", "#2879FE"],
  //   dataLabels: {
  //     enabled: false,
  //   },
  //   stroke: {
  //     curve: "smooth",
  //     width: 2,
  //   },
  //   fill: {
  //     type: "gradient",
  //     gradient: {
  //       shadeIntensity: 1,
  //       inverseColors: false,
  //       opacityFrom: 0.45,
  //       opacityTo: 0.05,
  //       stops: [20, 100, 100, 100],
  //     },
  //   },
  //   xaxis: {
  //     categories: monthData || []
  //   },

  //   markers: {
  //     size: 3,
  //     strokeWidth: 3,

  //     hover: {
  //       size: 4,
  //       sizeOffset: 2,
  //     },
  //   },
  //   legend: {
  //     position: "bottom",
  //     horizontalAlign: "center",
  //   },
  // }

  // var options = {
  //   series: [{
  //   name: 'Exterior Area',
  //   type: 'column',
  //   data:  exteriorAreaData || []
  // }, {
  //   name: 'Interior Area',
  //   type: 'column',
  //   data:  interiorData || []
  // }, {
  //   name: 'Wet Area',
  //   type: 'line',
  //   data:  wetSurfaceAreaData  || []
  // }],
  //   chart: {
  //   height: 350,
  //   width: '100%',
  //   type: 'line',
  //   stacked: false
  // },
  // plotOptions: {
  //   bar: {
  //     columnWidth: "15%",
  //   },
  // },
  // dataLabels: {
  //   enabled: false
  // },
  // fill: {
  //   colors: undefined,
  //   opacity: 1,
  //   type: "solid",
  // },
  // stroke: {
  //   width: [4, 4, 4]
  // },

  // xaxis: {
  //   categories: monthData || [] ,
  // },
  // yaxis: [
  //   {
  //     axisTicks: {
  //       // show: true,
  //     },
  //     axisBorder: {
  //       show: true,
  //       color: '#008FFB',
  //       offsetX: -10,
  //     },
  //     labels: {
  //       style: {
  //         colors: '#008FFB',
  //       }
  //     },
  //     title: {
  //       text: "Exterior Area (sqft)",
  //       style: {
  //         color: '#008FFB',
  //       }
  //     },
  //     tooltip: {
  //       enabled: true
  //     }
  //   },
  //   {
  //     seriesName: 'Exterior Area',
  //     opposite: true,
  //     axisTicks: {
  //       // show: true,
  //     },
  //     axisBorder: {
  //       show: true,
  //       color: '#00E396',
  //       offsetX: -10,
  //     },
  //     labels: {
  //       style: {
  //         colors: '#00E396',

  //       }
  //     },
  //     title: {
  //       text: "Interior Area (sqft)",
  //       style: {
  //         color: '#00E396',
  //       }
  //     },
  //   },
  //   {
  //     seriesName: 'Wet Area',
  //     opposite: true,
  //     axisTicks: {
  //       show: true,
  //     },
  //     axisBorder: {
  //       show: true,
  //       color: '#FEB019',
  //       offsetX: 10,
  //       // padding:10
  //     },
  //     labels: {
  //       style: {
  //         colors: '#FEB019',
  //       },
  //     },
  //     title: {
  //       text: "Wet Area (sqft)",
  //       style: {
  //         color: '#FEB019',
  //       }
  //     }
  //   },
  // ],
  // tooltip: {
  //   fixed: {
  //     enabled: true,
  //     position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
  //     offsetY: 30,
  //     offsetX: 60
  //   },
  // },
  // legend: {
  //   horizontalAlign: 'center',
  //   offsetX: 40
  // }
  // };
  var singleExteriorAreaData = Math.ceil(
    paintArea?.data?.ExteriorAreas / 1000000
  ).toLocaleString()
  var singleInteriorAreaData = Math.ceil(
    paintArea?.data?.InteriorAreas / 1000000
  ).toLocaleString()
  var SingleWetSurfaceAreaData = Math.ceil(
    paintArea?.data?.wetSurfaceAreas / 1000000
  ).toLocaleString()
  var series = [
    parseFloat(singleExteriorAreaData),
    parseFloat(singleInteriorAreaData),
    parseFloat(SingleWetSurfaceAreaData),
  ]
  // console.log('newPaintArray',newPaintArray);
  const options = {
    labels: ["Exterior Area", "Interior Area", "Wet Area"],
    colors: ["#556ee6", "#34c38f", "#f46a6a"],
    legend: {
      position: "bottom",
      horizontalAlign: "center",
      offsetX: 40,
    },
    plotOptions: {
      pie: {
        donut: {
          size: "70%",
        },
      },
    },
  }
  return (
    <CardComponent>
      {paintAreaLoading ? (
        <div className="text-center mt-5 pt-5">
          <Spinner />
        </div>
      ) : (
        <div className="paint-section">
          <div className="d-flex flex-wrap justify-content-between align-items-center">
            <h6 className="card-title me-2">Paint Area</h6>

            <div className="d-flex">
              {/* <div className="select-area me-2">
            <Form.Select aria-label="Default select" onChange={handleChat}>
              <option value="monthly">Monthly</option>
              <option value="weekly">Weekly</option>
              <option value="daily">Daily</option>
            </Form.Select>
          </div> */}
              {/* <div className="select-area">
                <Form.Select aria-label="Default select" onChange={handleYear}>
                  <option value="all">All Year</option>
                  <option value="2021">2021</option>
                  <option value="2022">2022</option>
                </Form.Select>
              </div> */}
            </div>

            {/* <div className="large-select-area">
            <Form.Select aria-label="Default select" onChange={handleChat}>
            <option value="monthly">Monthly</option>
              <option value="weekly">Weekly</option>
              <option value="daily">Daily</option>
            </Form.Select>
          </div> */}
          </div>

          <Row className="mt-4">
            <Col lg={4}>
              <div className="card">
                <span className="exterior-border-left-area"></span>
                <div className="card-body">
                  <div className="d-flex justify-content-center">
                    <div className="me-2" style={{ marginTop: "-7px" }}>
                      <img src={Exterior} alt="Icon" />
                    </div>
                    <p className="">Exterior Area</p>
                  </div>
                  <h5 className="text-center ms-4">
                    {singleExteriorAreaData}
                    <small>m sqft</small>
                  </h5>
                </div>
              </div>
            </Col>

            <Col lg={4}>
              <div className="card interior">
                <span className="interior-border-left-area"></span>
                <div className="card-body">
                  <div className="d-flex justify-content-center">
                    <div className="me-2" style={{ marginTop: "-4px" }}>
                      <img src={Interior} alt="Icon" />
                    </div>
                    <p className="">Interior Area</p>
                  </div>
                  <h5 className="text-center ms-4">
                    {singleInteriorAreaData}
                    <small>m sqft</small>
                  </h5>
                </div>
              </div>
            </Col>

            <Col lg={4}>
              <div className="card wet">
                <span className="wet-border-left-area"></span>
                <div className="card-body">
                  <div className="d-flex justify-content-center">
                    <div className="me-2">
                      <img src={WetArea} alt="Icon" />
                    </div>
                    <p className="">Wet Area</p>
                  </div>
                  <h5 className="text-center ms-4">
                    {SingleWetSurfaceAreaData}
                    <small>m sqft</small>
                  </h5>
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <CardComponent>
                <h6 className="ms-3 font-size-16">Paint Area</h6>
                <div id="donut-chart">
                  <ReactApexChart
                    options={options}
                    series={series || []}
                    type="donut"
                    height={398}
                    className="apex-charts"
                  />
                </div>
              </CardComponent>
            </Col>
          </Row>
        </div>
      )}
    </CardComponent>
  )
}

export default Paint
