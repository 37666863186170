import CustomInput from "components/Common/CustomInput"
import CardComponent from "components/Layout/CardComponent"
import InnerLayer from "components/Layout/InnerLayer"
import { addCategorySchema } from "components/Schemas/AddCategory.schema"
import { useFormik } from "formik"
import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { Col, Form, Row } from "reactstrap"
import { addNewCategory } from "../../store/Category/action"

const breadcrumbItems = [
  {
    title: "Category",
    link: "/categories",
  },
  {
    title: "Add Category",
    link: "#",
  },
]
const initialValues = {
  name: "",
}

const AddCategory = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { authtoken } = useSelector(state => ({
    authtoken: state.Login.token,
  }))
  const onSubmit = values => {
    let obj = {}
    obj.name = values.name
    dispatch(addNewCategory(obj, history, authtoken))
  }

  const addCategory = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: addCategorySchema,
    onSubmit,
  })
  return (
    <React.Fragment>
      <InnerLayer
        isMultiple
        title={"Add new Category"}
        breadcrumbItems={breadcrumbItems}
      >
        <div style={{ minHeight: "100vh" }} className="position-relative">
          <Row className="mt-4">
            <Col md="6" className="mx-auto">
              {/* add User */}
              <CardComponent className="">
                <Form
                  className="needs-validation"
                  onSubmit={addCategory.handleSubmit}
                >
                  <CustomInput
                    name={"name"}
                    type={"text"}
                    label={"Type Category"}
                    placeholder={"Category Name"}
                    validationType={addCategory}
                  />
                  <div className="d-flex justify-content-start">
                    <input
                      type="submit"
                      value={"Add Category"}
                      className="btn button "
                    />
                  </div>
                </Form>
              </CardComponent>
            </Col>
          </Row>
        </div>
      </InnerLayer>
    </React.Fragment>
  )
}

export default AddCategory
