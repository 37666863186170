import AddCard from "components/Atoms/AddCard"
import Button from "components/Common/Button"
import TableHeader from "components/Common/TableHeader"
import UserCard from "components/Common/UserCard"
import CardComponent from "components/Layout/CardComponent"
import CustomTable from "components/Layout/CustomTable"
import InnerLayer from "components/Layout/InnerLayer"
import NoTableData from "components/Layout/NoTableData"
import { toCapitalize } from "helpers/Custom/Capitalize"
import PropTypes from "prop-types"
import React, { useState } from "react"
import { Link, withRouter } from "react-router-dom"
import { Badge, Col, Row } from "reactstrap"
import Product1 from "../../assets/images/xelerator/product/product1.png"
import Product2 from "../../assets/images/xelerator/product/product2.png"
import Product3 from "../../assets/images/xelerator/product/product3.png"

const tableHead = [
  "No.",
  "Image",
  "Product Name",
  "Product Code",
  "Category",
  "Unit",
  "Status",
  "Price",
  "View Details",
]

export const reports = [
  {
    title: "You have total",
    iconClass: "bx-copy-alt",
    value: `1000 Products`,
  },
  { title: "Top user as SR", iconClass: "bx-archive-in", value: "SR name" },
]

export const products = [
  {
    id: "1",
    name: "Asian Tractor Emulsion Paint",
    code: 1234,
    category: "Exterior",
    unit: "LTR",
    price: "43k",
    status: "active",
    image: Product1,
    toggle: false,
  },
  {
    id: "2",
    name: "Jane Cooper",
    code: 1234,
    category: "WPCC",
    unit: "LTR",
    price: "43k",
    status: "active",
    image: Product2,
    toggle: false,
  },
  {
    id: "3",
    name: "Marvin McKinney",
    code: 1234,
    category: "Exterior",
    unit: "LTR",
    price: "43k",
    status: "inactive",
    image: Product3,
    toggle: false,
  },
]

const Products = ({ history }) => {
  const [listUser, setListUser] = useState(products)
  const [currentPage, setCurrentPage] = useState(1)
  return (
    <React.Fragment>
      <InnerLayer
        title="Product List"
        wrapperClass="py-3 users"
        isBreadCrumb={true}
        link={"/dashboard"}
        mainTitle={"Dashboard"}
      >
        <Row>
          {reports.map((data, idx) => (
            <Col md="3" key={"_col_" + idx}>
              <UserCard report={data} value={data.value} />
            </Col>
          ))}
          <Col md="3">
            <AddCard
              title="Product Add Analytic"
              buttonText="View Analytic"
              iconClass="bx bx-right-arrow-alt"
              onAddHandler={() => {}}
            />
          </Col>
          <Col md="3">
            <AddCard
              title="Add Products"
              buttonText="Add Product"
              onAddHandler={() => history.push("/add-product")}
            />
          </Col>
        </Row>

        {/* table */}

        <Row>
          <CardComponent className="user_table_card">
            <TableHeader
              handleSearchFilter={() => {}}
              handleSelectFilter={() => {}}
              data={[]}
              selectValue=""
            />

            {/* table */}
            <Row>
              <CustomTable
                paginationClass="paginationContainer text-right"
                data={listUser}
                tableHead={tableHead}
                setCurrentPage={setCurrentPage}
                isPagination
              >
                {listUser.length ? (
                  listUser.map((data, idx) => (
                    <tr key={data.id}>
                      <th scope="row">{data.id}</th>
                      <th>
                        <img width={"50px"} src={data.image} alt="image" />
                      </th>
                      <td>{data.name}</td>
                      <td>{data.code}</td>
                      <td>{data.category}</td>
                      <td>{data.unit}</td>
                      <td>
                        <Badge
                          className={"font-size-12"}
                          color={
                            data.status === "active" ? "success" : "danger"
                          }
                          pill
                        >
                          {toCapitalize(data.status)}
                        </Badge>
                      </td>
                      <td>{data.price}</td>
                      <td>
                        <Button
                          className="btn button btn-sm"
                          style={{ borderRadius: "20px" }}
                          onClick={() => history.push("/product-details")}
                        >
                          View Details
                        </Button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <NoTableData
                    colSpan=""
                    className="d-flex justify-content-center align-items-center"
                    style={{ minHeight: "300px", width: `100%` }}
                  >
                    <span>
                      Currently you have no Product.{" "}
                      <Link
                        to="#"
                        style={{
                          color: "blue",
                          textDecoration: "underline",
                        }}
                      >
                        Add Product
                      </Link>
                    </span>
                  </NoTableData>
                )}
              </CustomTable>
              {/* table end */}
            </Row>
          </CardComponent>
        </Row>
        {/* component end */}
      </InnerLayer>
    </React.Fragment>
  )
}

Products.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
}

export default withRouter(Products)
