export const attendance = [
  {
    name: "Minnie Walter",
    role: "Executive",
    profile: "avatar-1.jpg",
  },
  {
    name: "Minnie Walter1",
    role: "Executive",
    profile: "avatar-2.jpg",
  },
  {
    name: "Minnie Walter2",
    role: "Executive",
    profile: "avatar-3.jpg",
  },
  {
    name: "Minnie Walter3",
    role: "Executive",
    profile: "avatar-4.jpg",
  },
  {
    name: "Minnie Walter4",
    role: "Executive",
    profile: "avatar-5.jpg",
  },
  {
    name: "Minnie Walter5",
    role: "Executive",
    profile: "avatar-6.jpg",
  },
  {
    name: "Minnie Walter6",
    role: "Executive",
    profile: "avatar-7.jpg",
  },
]
