import CustomInput from "components/Common/CustomInput"
import CardComponent from "components/Layout/CardComponent"
import InnerLayer from "components/Layout/InnerLayer"
import { addAreaSchema } from "components/Schemas/AddArea.schema"
import { useFormik } from "formik"
import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { Col, Form, Row } from "reactstrap"
import {
  addNewArea,
  getRegionValue,
} from "../../../store/GeoInformation/action"
const breadcrumbItems = [
  {
    title: "Geo Information",
    link: "#",
  },
  {
    title: "Area",
    link: "geo/area",
  },
  {
    title: "Add Area",
    link: "#",
  },
]
const initialValues = {
  region: "",
  name: "",
}

const AddArea = () => {
  const history = useHistory()
  let dispatch = useDispatch()
  //get region
  const { regionData, authtoken } = useSelector(state => ({
    authtoken: state.Login.token,
    regionData: state.GeoInformationReducer.regionData,
  }))
  useEffect(() => {
    dispatch(getRegionValue(authtoken))
  }, [])
  //get region

  const onSubmit = values => {
    // event.preventDefault()
    let obj = {}
    obj.name = values.name
    obj.region = values.region
    dispatch(addNewArea(obj, history, authtoken))
  }

  const AddArea = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: addAreaSchema,
    onSubmit,
  })
  return (
    <React.Fragment>
      <InnerLayer
        isMultiple
        title={"Add new Area"}
        breadcrumbItems={breadcrumbItems}
      >
        <div style={{ minHeight: "100vh" }} className="position-relative">
          <Row className="mt-4">
            <Col md="6" className="mx-auto">
              {/* add User */}
              <CardComponent className="">
                <Form
                  className="needs-validation"
                  onSubmit={AddArea.handleSubmit}
                >
                  <CustomInput
                    name={"region"}
                    type={"select"}
                    label={"Select region"}
                    validationType={AddArea}
                  >
                    <option defaultValue>Select region...</option>
                    {regionData?.length > 0 ? (
                      regionData?.region.map((data, idx) => (
                        <option value={data._id} key={idx}>
                          {data.name}
                        </option>
                      ))
                    ) : (
                      <span></span>
                    )}
                  </CustomInput>

                  <CustomInput
                    name={"name"}
                    type={"text"}
                    label={"Type area"}
                    placeholder={"Area name"}
                    validationType={AddArea}
                  />
                  <div className="d-flex justify-content-start">
                    <input
                      type="submit"
                      value={"Add Area"}
                      className="btn button "
                    />
                  </div>
                </Form>
              </CardComponent>
            </Col>
          </Row>
        </div>
      </InnerLayer>
    </React.Fragment>
  )
}

export default AddArea
