import SearchInput from "components/Atoms/SearchInput"
import PropTypes from "prop-types"
import React from "react"
import { Input, Row } from "reactstrap"

const TableHeader = ({
  handleSearchFilter,
  handleSelectFilter,
  selectValue,
  data,
  isMultiple,
  isMultiple2,
  isMultiple3,
  handleSelectFilter2,
  handleSelectFilter3,
  selectValue2,
  selectValue3,
  data2,
  data3,
  title,
  headerSearchOption,
  headerButtonOption,
  handleExcel,
  isLoading,
}) => {
  return (
    <React.Fragment>
      <Row className="mb-3">
        <div
          className={`d-flex ${
            title ? "justify-content-between " : "justify-content-end"
          }`}
        >
          {title ? <h6>{title}</h6> : null}
          {headerSearchOption ? (
            <>
              <div className="d-flex align-items-center">
                {headerButtonOption ? (
                  <div className="me-4 justify-content-end">
                    <button
                      className="btn btn-success btn-lg"
                      disabled={isLoading}
                      onClick={handleExcel}
                    >
                      {isLoading ? "Downloading..." : "Download"}
                    </button>
                  </div>
                ) : null}
                <div className="search-box me-4 d-flex justify-content-end">
                  <SearchInput
                    className="userSearchBar form-control"
                    style={{ minWidth: "140px" }}
                    onChange={handleSearchFilter}
                  />
                </div>

                {isMultiple ? (
                  <Input
                    type="select"
                    className="me-4"
                    onChange={handleSelectFilter2}
                    value={selectValue2}
                  >
                    {/* <option defaultValue>Select...</option> */}
                    {data2.map((data, idx) => (
                      <option key={idx} value={data}>
                        {data}
                      </option>
                    ))}
                  </Input>
                ) : null}
                {isMultiple3 ? (
                  <Input
                    type="select"
                    className="me-2"
                    onChange={handleSelectFilter3}
                    value={selectValue3}
                  >
                    <option defaultValue>Select...</option>
                    <option value="All" key="All">
                      All
                    </option>
                    {data3.map((data, idx) => (
                      <option key={idx} value={data._id}>
                        {data.name}
                      </option>
                    ))}
                  </Input>
                ) : null}

                <Input
                  type="select"
                  onChange={handleSelectFilter}
                  value={selectValue}
                >
                  {/* <option defaultValue>Select...</option> */}
                  {data.map((data, idx) => (
                    <option key={idx} value={data}>
                      {data}
                    </option>
                  ))}
                </Input>
              </div>
            </>
          ) : null}
        </div>
      </Row>
    </React.Fragment>
  )
}
TableHeader.propTypes = {
  title: PropTypes.string,
  headerSearchOption: PropTypes.string,
  headerButtonOption: PropTypes.string,
  selectValue: PropTypes.number,
  handleSearchFilter: PropTypes.func,
  handleSelectFilter: PropTypes.func,
  data: PropTypes.array,
  selectValue2: PropTypes.string,
  selectValue3: PropTypes.string,
  handleSelectFilter2: PropTypes.func,
  handleSelectFilter3: PropTypes.func,
  handleExcel: PropTypes.func,
  data2: PropTypes.array,
  data3: PropTypes.array,
  isMultiple: PropTypes.bool,
  isMultiple2: PropTypes.bool,
  isMultiple3: PropTypes.bool,
  isLoading: PropTypes.bool,
}

export default TableHeader
