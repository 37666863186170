import CustomInput from "components/Common/CustomInput"
import CardComponent from "components/Layout/CardComponent"
import InnerLayer from "components/Layout/InnerLayer"
import { addCategorySchema } from "components/Schemas/AddCategory.schema"
import { useFormik } from "formik"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory, useParams } from "react-router-dom"
import { Col, Form, Row } from "reactstrap"
import { updateCategory } from "../../store/Category/action"

const breadcrumbItems = [
  {
    title: "Category",
    link: "/categories",
  },
  {
    title: "Edit Category",
    link: "#",
  },
]
const initialValues = {
  name: "",
}

const EditCategory = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [category, setCategory] = useState(null)
  const { id: categoryId } = useParams()
  const onSubmit = values => {
    dispatch(updateCategory(categoryId, values, authtoken, history))
  }
  const { editCategoryInfo, loading, authtoken } = useSelector(state => ({
    loading: state.CategoryReducer.isLoading,
    editCategoryInfo: state.CategoryReducer.editCategoryInfo,
    authtoken: state.Login.token,
  }))

  useEffect(() => {
    const oldCategory = { ...category }
    oldCategory.name = editCategoryInfo?.data?.name
    setCategory(oldCategory)
  }, [editCategoryInfo])

  const categoryEdit = useFormik({
    enableReinitialize: true,
    initialValues: category || initialValues,
    validationSchema: addCategorySchema,
    onSubmit,
  })
  return (
    <React.Fragment>
      <InnerLayer
        isMultiple
        title={"Edit Category"}
        breadcrumbItems={breadcrumbItems}
      >
        <div style={{ minHeight: "100vh" }} className="position-relative">
          <Row className="mt-4">
            <Col md="6" className="mx-auto">
              {/* add User */}
              <CardComponent className="">
                <Form
                  className="needs-validation"
                  onSubmit={categoryEdit.handleSubmit}
                >
                  <CustomInput
                    name={"name"}
                    type={"text"}
                    label={"Type Category"}
                    placeholder={"Category Name"}
                    validationType={categoryEdit}
                  />

                  <div className="d-flex justify-content-start">
                    <input
                      type="submit"
                      value={"Update Category"}
                      className="btn button"
                    />
                  </div>
                </Form>
              </CardComponent>
            </Col>
          </Row>
        </div>
      </InnerLayer>
    </React.Fragment>
  )
}

export default EditCategory
