import {
  AREA_FILTER_CHANGED,
  ASSIGNING_SITE,
  ASSIGNING_SITE_SUCCESS,
  CATEGORY_FILTER_CHANGED,
  CLEAR_FILTERS,
  DELETE_ORG_SITE_FAIL,
  DELETE_ORG_SITE_SUCCESS,
  GET_MANAGING_ORG_SITES_LIST,
  GET_MANAGING_ORG_SITES_LIST_SUCCESS,
  GET_ORG_SITE,
  GET_ORG_SITES_LIST,
  GET_ORG_SITES_LIST_SUCCESS,
  GET_ORG_SITE_SUCCESS,
  GET_SITES_LIST,
  GET_SITES_LIST_SUCCESS,
  GET_SITE_EXCEL_FAIL,
  GET_SITE_EXCEL_SUCCESS,
  GET_SITE_VIEW,
  GET_SITE_VIEW_SUCCESS,
  MAIN_FILTER_CHANGED,
  REGION_FILTER_CHANGED,
  ROUTE_FILTER_CHANGED,
  STORE_DASHBOARD_DATA,
  SUB_CATEGORY_CHANGED,
  SUB_CATEGORY_FILTER_CHANGED,
  SUB_FILTER_CHANGED,
  TERRITORY_FILTER_CHANGED,
  TOGGLE_DASHBOARD_DATA,
  TOTAL_VALUE_CHANGED,
  UPDATE_ORG_STATUS_FAIL,
  UPDATE_ORG_STATUS_SUCCESS,
} from "./actionTypes"
const INIT_STATE = {
  mainFilter: {},
  loadFilter: false,
  subFilters: [],
  subCategory: [],
  totalValue: {},
  subSelectedFilters: {},
  selectedSubCategory: {},
  isSubFilterChanged: false,
  areaList: [],
  territoryList: [],
  routesList: [],
  categoryList: [],
  selectedCategory: {},
  selectedSubCategory: {},
  subCategoryList: [],
  selectedRoutes: {},
  selectedRegion: {},
  selectedArea: {},
  selectedTerritory: {},
  currentSelection: "",
  laUser: [],
  sitesData: {},
  managingOrgSitesData: [],
  orgSiteData: [],
  sitesViewData: {},
  siteExcel: [],
  siteExcelLoading: true,
  sitesLoading: false,
  subLoading: false,
  regionSubLoading: false,
  areaSubLoading: false,
  territorySubLoading: false,
  assignLoading: false,
  isLoading: false,
}

const DashboardReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_SITES_LIST:
      return {
        ...state,
        sitesLoading: true,
      }
    case GET_SITES_LIST_SUCCESS:
      return {
        ...state,
        sitesData: action.payload,
        sitesLoading: false,
      }
    case GET_SITE_VIEW:
      return {
        ...state,
        sitesLoading: true,
      }
    case GET_SITE_VIEW_SUCCESS:
      return {
        ...state,
        sitesLoading: false,
        sitesViewData: action.payload.data,
      }
    case MAIN_FILTER_CHANGED:
      return {
        ...state,
        mainFilter: action.payload,
        subSelectedFilters: {},
        selectedSubCategory: {},
        currentSelection: "all",
        isSubFilterChanged: !state.isSubFilterChanged,
      }
    case SUB_FILTER_CHANGED:
      return {
        ...state,
        subSelectedFilters: action.payload,
        selectedSubCategory: {},
        currentSelection: "category",
        isSubFilterChanged: !state.isSubFilterChanged,
      }
    case SUB_CATEGORY_CHANGED:
      return {
        ...state,
        selectedSubCategory: action.payload,
        currentSelection: "subcategory",
        isSubFilterChanged: !state.isSubFilterChanged,
      }
    case TOTAL_VALUE_CHANGED:
      return {
        ...state,
        totalValue: action.payload,
        // currentSelection: "total",
        isSubFilterChanged: !state.isSubFilterChanged,
      }

    case REGION_FILTER_CHANGED:
      return {
        ...state,
        regionSubLoading: true,
        selectedRegion: action.payload,
        areaList: [],
        selectedArea: {},
        currentSelection: "region",
        isSubFilterChanged: !state.isSubFilterChanged,
      }
    case AREA_FILTER_CHANGED:
      return {
        ...state,
        areaSubLoading: true,
        selectedArea: action.payload,
        territoryList: [],
        selectedTerritory: {},
        currentSelection: "area",
        isSubFilterChanged: !state.isSubFilterChanged,
      }
    case TERRITORY_FILTER_CHANGED:
      return {
        ...state,
        territorySubLoading: true,
        selectedTerritory: action.payload,
        currentSelection: "territory",
        isSubFilterChanged: !state.isSubFilterChanged,
      }
    case ROUTE_FILTER_CHANGED:
      return {
        ...state,
        selectedRoutes: action.payload,
        currentSelection: "routes",
        isSubFilterChanged: !state.isSubFilterChanged,
      }

    case CATEGORY_FILTER_CHANGED:
      return {
        ...state,
        subLoading: true,
        selectedCategory: action.payload,
        isSubFilterChanged: !state.isSubFilterChanged,
      }
    case SUB_CATEGORY_FILTER_CHANGED:
      return {
        ...state,
        selectedSubCategory: action.payload,
        isSubFilterChanged: !state.isSubFilterChanged,
      }
    case CLEAR_FILTERS:
      return {
        ...state,
        mainFilter: {},
        selectedRegion: {},
        selectedArea: {},
        selectedTerritory: {},
        selectedRoutes: {},
        selectedCategory: {},
        selectedSubCategory: {},
      }

    case STORE_DASHBOARD_DATA:
      return {
        ...state,
        [action.payload.name]: action.payload.data,
        subLoading: false,
        regionSubLoading: false,
        areaSubLoading: false,
        territorySubLoading: false,
      }
    case ASSIGNING_SITE:
      return {
        ...state,
        assignLoading: true,
      }
    case ASSIGNING_SITE_SUCCESS:
      return {
        ...state,
        assignLoading: false,
      }
    case TOGGLE_DASHBOARD_DATA:
      return {
        ...state,
        [action.payload.name]: !state[action.payload.name],
      }
    case GET_SITE_EXCEL_SUCCESS:
      return {
        ...state,
        siteExcel: action.payload,
        siteExcelLoading: false,
      }
    case GET_SITE_EXCEL_FAIL:
      return {
        ...state,
        error: action.payload,
        siteExcelLoading: false,

        //change here
      }

    //ORG SITE
    case GET_ORG_SITES_LIST:
      return {
        ...state,
        orgSitesLoading: true,
      }
    case GET_ORG_SITES_LIST_SUCCESS:
      return {
        ...state,
        orgSitesData: action.payload,
        orgSitesLoading: false,
      }

    //MANAGING ORG SITE
    case GET_MANAGING_ORG_SITES_LIST:
      return {
        ...state,
        managingOrgSitesLoading: true,
      }
    case GET_MANAGING_ORG_SITES_LIST_SUCCESS:
      return {
        ...state,
        managingOrgSitesData: action.payload,
        managingOrgSitesLoading: false,
      }

    //SITE IN  ORGANIZATION PANEL
    case GET_ORG_SITE:
      return {
        ...state,
        orgSiteLoading: true,
      }
    case GET_ORG_SITE_SUCCESS:
      return {
        ...state,
        orgSiteData: action.payload,
        orgSiteLoading: false,
      }
    //delete org site
    case DELETE_ORG_SITE_SUCCESS:
      return {
        ...state,
        ["managingOrgSitesData"]: {
          ...state.managingOrgSitesData,
          ["orgSite"]: state.managingOrgSitesData?.orgSite?.filter(
            orgSite =>
              orgSite._id.toString() !== action.payload.siteId.toString()
          ),
        },
      }

    case DELETE_ORG_SITE_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    // UPDATE STATUS IN ORGANIZATION PANEL
    case UPDATE_ORG_STATUS_SUCCESS:
      return {
        ...state,
        orgStatus: [...state.orgStatus, action.payload],
        isLoading: false,
      }

    case UPDATE_ORG_STATUS_FAIL:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      }

    default:
      return state
  }
}

export default DashboardReducer
