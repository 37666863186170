import {
  ADD_NEW_USER,
  ADD_USER_FAIL,
  ADD_USER_SUCCESS,
  GET_USER_VALUE,
  GET_USER_VALUE_SUCCESS,
  STORE_USER_DATA,
  UPDATE_USER,
  UPDATE_USER_FAIL,
  UPDATE_USER_SUCCESS,
} from "./actionTypes"

//Users
export const getUserValue = (authtoken, userRole, currentPage, dataRange) => ({
  type: GET_USER_VALUE,
  payload: { authtoken, userRole, currentPage, dataRange },
})

export const getUserValueSuccess = data => ({
  type: GET_USER_VALUE_SUCCESS,
  payload: { data },
})

export const addNewUser = (data, history, authtoken) => ({
  type: ADD_NEW_USER,
  payload: { data, history, authtoken },
})
export const addUserSuccess = data => ({
  type: ADD_USER_SUCCESS,
  payload: data,
})

export const addUserFail = error => ({
  type: ADD_USER_FAIL,
  payload: error,
})
export const storeUserData = (name, data) => ({
  type: STORE_USER_DATA,
  payload: { name, data },
})

export const updateUser = (id, data, token, history) => ({
  type: UPDATE_USER,
  payload: { data, history, token, id },
})

export const updateUserSuccess = (id, data) => ({
  type: UPDATE_USER_SUCCESS,
  payload: { id, data },
})

export const updateUserFail = error => ({
  type: UPDATE_USER_FAIL,
  payload: error,
})
