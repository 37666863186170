import AddCard from "components/Atoms/AddCard"
import HelpCard from "components/Atoms/HelpCard"
import SearchInput from "components/Atoms/SearchInput"
import UserCard from "components/Common/UserCard"
import CardComponent from "components/Layout/CardComponent"
import CustomTable from "components/Layout/CustomTable"
import InnerLayer from "components/Layout/InnerLayer"
import NoTableData from "components/Layout/NoTableData"
import { toCapitalize } from "helpers/Custom/Capitalize"
import PropTypes from "prop-types"
import React, { useState } from "react"
import { Link, withRouter } from "react-router-dom"
import Switch from "react-switch"
import { Badge, Button, Col, Row } from "reactstrap"
import { allGeo, reports } from "utils/fakeGeo"
import { OffSymbol, OnSymbol } from "utils/fakeUser"

const tableHead = [
  "No.",
  "Region",
  "Area",
  "Territories",
  "Total routs",
  "Status",
  "View Details",
  "Action",
]

const GeoInformation = ({ history }) => {
  const [geoList, setGeoList] = useState(allGeo)
  const [currentPage, setCurrentPage] = useState(1)
  console.log(`Users ~ currentPage`, currentPage, allGeo)

  const viewCustomer = index => {
    setGeoList(
      geoList?.map((data, i) => {
        if (i === index) {
          data.toggle = !data.toggle
        } else {
          data.toggle = false
        }
        return data
      })
    )
  }

  const handleGeo = data => {
    // history.push(`/set-user-route`, { data })
  }
  return (
    <React.Fragment>
      <InnerLayer
        title="GEO Information"
        wrapperClass="py-3 users"
        isBreadCrumb={true}
        link={"/dashboard"}
        mainTitle={"Dashboard"}
      >
        {/* first Row */}
        <Row>
          {reports.map((data, idx) => (
            <Col md="3" key={"_col_" + idx}>
              <UserCard report={data} value={data.value} />
            </Col>
          ))}
          <Col md="3">
            <AddCard
              title="Add new GEO"
              buttonText="Add GEO"
              onAddHandler={() => history.push("/add-geo")}
            />
          </Col>
        </Row>
        <Row>
          <CardComponent className="user_table_card">
            <Row className="mb-2">
              <Col md="12">
                <div className="search-box me-2 mb-2 d-flex justify-content-end">
                  <SearchInput
                    className="userSearchBar form-control"
                    onChange={() => {}}
                  />
                </div>
              </Col>
            </Row>
            {/* table */}
            <Row>
              <CustomTable
                paginationClass="paginationContainer text-right"
                data={geoList}
                tableHead={tableHead}
                setCurrentPage={setCurrentPage}
                isPagination
              >
                {geoList.length ? (
                  geoList.map((data, idx) => (
                    <tr key={data.id}>
                      <th scope="row">{data.id}</th>
                      <td>{data.name}</td>
                      <td>{data.area}</td>
                      <td>{data.territories}</td>
                      <td>{data.total}</td>
                      <td>
                        <Badge
                          className={"font-size-12"}
                          color={
                            data.status === "active" ? "success" : "danger"
                          }
                          pill
                        >
                          {toCapitalize(data.status)}
                        </Badge>
                      </td>
                      <td>
                        <Button
                          className="btn button btn-sm"
                          style={{ borderRadius: "20px" }}
                          onClick={() => handleGeo(data)}
                        >
                          Set routs
                        </Button>
                      </td>
                      <td>
                        <Switch
                          uncheckedIcon={<OffSymbol />}
                          checkedIcon={<OnSymbol />}
                          className="me-1"
                          onColor="#4b3768"
                          onChange={() => viewCustomer(idx)}
                          checked={data.toggle}
                        />
                      </td>
                    </tr>
                  ))
                ) : (
                  <NoTableData
                    colSpan="9"
                    className="d-flex justify-content-center align-items-center"
                    style={{ minHeight: "300px", width: `100%` }}
                  >
                    <span>
                      Currently you have no GEO Information.{" "}
                      <Link
                        to="/add-geo"
                        style={{
                          color: "blue",
                          textDecoration: "underline",
                        }}
                      >
                        create GEO Information
                      </Link>
                    </span>
                  </NoTableData>
                )}
              </CustomTable>
              {/* table end */}
            </Row>
          </CardComponent>
          {/* component end */}

          {/*  */}
          <HelpCard link="#" />
        </Row>
      </InnerLayer>
    </React.Fragment>
  )
}
GeoInformation.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
}

export default withRouter(GeoInformation)
