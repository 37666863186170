import CustomInput from "components/Common/CustomInput"
import CardComponent from "components/Layout/CardComponent"
import InnerLayer from "components/Layout/InnerLayer"
import { addProductSchema } from "components/Schemas/AddProduct.schema"
import AddUserUpload from "components/Users/AddUserUpload"
import { useFormik } from "formik"
import PropTypes from "prop-types"
import React, { useRef, useState } from "react"
import { withRouter } from "react-router-dom"
import { Col, Form, Row } from "reactstrap"
const breadcrumbItems = [
  {
    title: "Dashboard",
    link: "/dashboard",
  },
  {
    title: "Product List",
    link: "/products",
  },
  {
    title: "Add User",
    link: "#",
  },
]

const initialValues = {
  productName: "",
  code: "",
  model: "",
  category: "",
  brandName: "",
  discount: "",
  unit: "",
  unitPrice: "",
  file: "",
}

const AddProduct = () => {
  const [selectedFiles, setSelectedFiles] = useState({})
  console.log(`AddUser ~ selectedFiles`, selectedFiles)
  const fileShare_ref = useRef()
  const onSubmit = values => {
    console.log("values", values)
  }
  console.log(`AddUser ~ fileShare_ref`, fileShare_ref)

  const addProduct = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: addProductSchema,
    onSubmit,
  })

  // console.log(`AddUser ~ addUser`, addProduct)
  return (
    <React.Fragment>
      <InnerLayer
        isMultiple
        title={"Create Product"}
        breadcrumbItems={breadcrumbItems}
      >
        <Row className="mt-4">
          <Col md="8" className="mx-auto">
            {/* add User */}
            <CardComponent className="">
              <Form
                className="needs-validation"
                onSubmit={addProduct.handleSubmit}
              >
                <Row>
                  <Col>
                    <CustomInput
                      name={"productName"}
                      type={"text"}
                      label={"Product Name"}
                      placeholder={"Type product name"}
                      validationType={addProduct}
                    />
                  </Col>
                  <Col>
                    <CustomInput
                      name={"code"}
                      type={"number"}
                      label={"Product Code"}
                      placeholder={"Type product code"}
                      validationType={addProduct}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <CustomInput
                      name={"model"}
                      type={"text"}
                      label={"Model No. (It’s set by you)"}
                      placeholder={"Type your model no"}
                      validationType={addProduct}
                    />
                  </Col>
                  <Col>
                    <CustomInput
                      name={"category"}
                      type={"text"}
                      label={"Category"}
                      placeholder={"Type product category"}
                      validationType={addProduct}
                    />
                  </Col>
                </Row>

                {/* select */}
                <Row>
                  <Col>
                    <CustomInput
                      name={"brandName"}
                      type={"text"}
                      label={"Brand Name"}
                      placeholder={"Type brand name"}
                      validationType={addProduct}
                    />
                  </Col>
                  <Col>
                    <CustomInput
                      name={"discount"}
                      type={"text"}
                      label={"Max Discount"}
                      placeholder={"Type discount"}
                      validationType={addProduct}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <CustomInput
                      name={"unit"}
                      type={"number"}
                      label={"Unit type"}
                      placeholder={"Type unit"}
                      validationType={addProduct}
                    />
                  </Col>
                  <Col>
                    <CustomInput
                      name={"unitPrice"}
                      type={"number"}
                      label={"Unit price"}
                      placeholder={"Type unit price"}
                      validationType={addProduct}
                    />
                  </Col>
                </Row>

                {/* file */}
                <Row>
                  <Col md="12">
                    <CustomInput
                      name={"file"}
                      type={"file"}
                      accept="image/png, image/jpg, image/jpeg"
                      label={"Upload user image"}
                      //   placeholder={"Type email address"}
                      validationType={addProduct}
                      innerRef={fileShare_ref}
                    />
                  </Col>
                </Row>
                <div className="d-flex justify-content-end">
                  <input
                    type="submit"
                    value={"Save Product"}
                    className="btn button "
                  />
                </div>
              </Form>
            </CardComponent>

            {/* file Upload */}
            <AddUserUpload setSelectedFiles={setSelectedFiles} />
          </Col>
        </Row>
      </InnerLayer>
    </React.Fragment>
  )
}

AddProduct.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
}

export default withRouter(AddProduct)
