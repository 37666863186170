import CustomInput from "components/Common/CustomInput"
import CardComponent from "components/Layout/CardComponent"
import { addOfferSchema } from "components/Schemas/AddOffer.schema"
import { useFormik } from "formik"
import React from "react"
import { Form } from "reactstrap"
const initialValues = {
  title: "",
  message: "",
}

const AddOffer = () => {
  const onSubmit = values => {
    console.log("values", values)
  }

  const addOffer = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: addOfferSchema,
    onSubmit,
  })

  return (
    <React.Fragment>
      <CardComponent>
        <h5>Add Offer</h5>
        <Form className="needs-validation" onSubmit={addOffer.handleSubmit}>
          <CustomInput
            name={"title"}
            type={"text"}
            //   label={"Product Name"}
            placeholder={"Select offer title"}
            validationType={addOffer}
          />
          <CustomInput
            name={"message"}
            type={"textarea"}
            rows="6"
            //   row
            //   label={"Product Code"}
            placeholder={"Description"}
            validationType={addOffer}
          />
          <div className="d-flex justify-content-start">
            <input type="submit" value={"Add offer"} className="btn button " />
          </div>
        </Form>
      </CardComponent>
    </React.Fragment>
  )
}

export default AddOffer
