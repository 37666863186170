import AttendanceModal from "components/Attendace/AttendanceModal"
import InnerLayer from "components/Layout/InnerLayer"
import React, { useState } from "react"
import { Row } from "reactstrap"
import { attendance } from "utils/fakeAttendance"
import CardContact from "./card-contact"
const breadcrumbItems = [
  {
    title: "Contact",
    link: "/",
  },
  {
    title: "Users Grid",
    link: "#",
  },
]

const Attendance = () => {
  const [open, setOpen] = useState(false)
  const [user, setUser] = useState({})
  const toggle = () => {
    setUser({})
    setOpen(!open)
  }

  const viewAttendance = data => {
    console.log(`viewAttendance ~ data`, data)
    setOpen(true)
    setUser(data)
  }
  return (
    <React.Fragment>
      <InnerLayer
        title="Users"
        wrapperClass="py-3 users"
        isMultiple
        breadcrumbItems={breadcrumbItems}
      >
        <Row>
          {attendance.map((data, idx) => (
            <CardContact
              key={idx}
              user={data}
              viewAttendance={viewAttendance}
            />
          ))}
        </Row>
        {/* row */}

        {/* modal */}
        <AttendanceModal open={open} toggle={toggle} user={user} />

        <div className="d-flex justify-content-center align-items-center">
          <span
            className="text_green pointer_event"
            style={{ fontWeight: "500", fontSize: "16px" }}
          >
            <i
              className="bx bx-hourglass bx-flashing"
              style={{ fontSize: "16px" }}
            ></i>{" "}
            <span>Load more</span>
          </span>
        </div>
      </InnerLayer>
    </React.Fragment>
  )
}

export default Attendance
