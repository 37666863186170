import CustomInput from "components/Common/CustomInput"
import CardComponent from "components/Layout/CardComponent"
import InnerLayer from "components/Layout/InnerLayer"
import { addTerritorySchema } from "components/Schemas/AddTerritory.schema"
import { useFormik } from "formik"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory, useParams } from "react-router-dom"
import { Col, Form, Row } from "reactstrap"
import {
  getAreaByRegionValue,
  getRegionValue,
  getTerritoryByAreaValue,
  updateTerritory,
} from "../../../store/GeoInformation/action"
const breadcrumbItems = [
  {
    title: "Geo Information",
    link: "#",
  },
  {
    title: "Territory",
    link: "/geo/territory",
  },
  {
    title: "Edit Territory",
    link: "#",
  },
]
const initialValues = {
  region: "",
  area: "",
  name: "",
}

const EditTerritory = () => {
  const history = useHistory()
  let dispatch = useDispatch()
  const { id: territoryId } = useParams()
  const [territoryInfo, setTerritoryInfo] = useState(null)
  //get region/area
  const {
    editTerritoryInfo,
    territoryByAreaData,
    areaByRegionData,
    regionData,
    authtoken,
  } = useSelector(state => ({
    authtoken: state.Login.token,
    regionData: state.GeoInformationReducer.regionData,
    areaByRegionData: state.GeoInformationReducer.areaByRegionData,
    territoryByAreaData: state.GeoInformationReducer.territoryByAreaData,
    editTerritoryInfo: state.GeoInformationReducer.editTerritoryInfo,
  }))

  useEffect(() => {
    const oldTerritoryInfo = { ...territoryInfo }
    oldTerritoryInfo.name = editTerritoryInfo?.data?.name
    oldTerritoryInfo.area = editTerritoryInfo?.data?.area?._id
    oldTerritoryInfo.region = editTerritoryInfo?.data?.area?.region?._id
    setTerritoryInfo(oldTerritoryInfo)
  }, [editTerritoryInfo])
  useEffect(() => {
    dispatch(getRegionValue(authtoken))
    dispatch(
      getAreaByRegionValue(
        authtoken,
        editTerritoryInfo?.data?.area?.region?._id,
        0,
        0
      )
    )
    dispatch(
      getTerritoryByAreaValue(authtoken, "61efb637f6591700084755e9", 0, 0)
    )
  }, [])
  //get region/area
  console.log("uuuuuuuu", territoryByAreaData)

  const onSubmit = values => {
    dispatch(updateTerritory(territoryId, values, authtoken, history))
  }

  const TerritoryEdit = useFormik({
    enableReinitialize: true,
    initialValues: territoryInfo || initialValues,
    validationSchema: addTerritorySchema,
    onSubmit,
  })
  return (
    <React.Fragment>
      <InnerLayer
        isMultiple
        title={"Edit Territory"}
        breadcrumbItems={breadcrumbItems}
      >
        <div style={{ minHeight: "100vh" }} className="position-relative">
          <Row className="mt-4">
            <Col md="6" className="mx-auto">
              {/* add User */}
              <CardComponent className="">
                <Form
                  className="needs-validation"
                  onSubmit={TerritoryEdit.handleSubmit}
                >
                  <CustomInput
                    name={"region"}
                    type={"select"}
                    label={"Select region"}
                    validationType={TerritoryEdit}
                  >
                    <option defaultValue>Select region...</option>
                    {regionData?.length > 0 ? (
                      regionData?.region.map((data, idx) => (
                        <option value={data._id} key={idx}>
                          {data.name}
                        </option>
                      ))
                    ) : (
                      <span></span>
                    )}
                  </CustomInput>

                  <CustomInput
                    name={"area"}
                    type={"select"}
                    label={"Select area"}
                    validationType={TerritoryEdit}
                  >
                    <option defaultValue>Select area...</option>
                    {areaByRegionData?.length > 0 ? (
                      areaByRegionData?.area.map((data, idx) => (
                        <option value={data._id} key={idx}>
                          {data.name}
                        </option>
                      ))
                    ) : (
                      <span></span>
                    )}
                  </CustomInput>

                  <CustomInput
                    name={"name"}
                    type={"text"}
                    label={"Type territory"}
                    placeholder={"Territory name"}
                    validationType={TerritoryEdit}
                  />

                  <div className="d-flex justify-content-start">
                    <input
                      type="submit"
                      value={"Update Territory"}
                      className="btn button "
                    />
                  </div>
                </Form>
              </CardComponent>
            </Col>
          </Row>
        </div>
      </InnerLayer>
    </React.Fragment>
  )
}

export default EditTerritory
