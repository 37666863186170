import CardComponent from "components/Layout/CardComponent"
import PropTypes from "prop-types"
import React, { useState } from "react"
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap"

const ProductChart = ({ title, children }) => {
  const [singlebtn, setSinglebtn] = useState(false)
  return (
    <React.Fragment>
      <CardComponent>
        <div className="d-flex justify-content-between">
          <h5>{title}</h5>
          <div className="d-flex">
            <Dropdown
              isOpen={singlebtn}
              className="mx-2"
              toggle={() => setSinglebtn(!singlebtn)}
            >
              <DropdownToggle className="btn btn-secondary">
                Dhanmondi <i className="mdi mdi-chevron-down" />
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem>Action</DropdownItem>
                <DropdownItem>Another action</DropdownItem>
                <DropdownItem>Something else here</DropdownItem>
              </DropdownMenu>
            </Dropdown>
            <Dropdown
              isOpen={singlebtn}
              className="ml-2"
              toggle={() => setSinglebtn(!singlebtn)}
            >
              <DropdownToggle className="btn btn-secondary ml-2">
                2021 <i className="mdi mdi-chevron-down" />
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem>Action</DropdownItem>
                <DropdownItem>Another action</DropdownItem>
                <DropdownItem>Something else here</DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div>
        </div>
        <div>{children}</div>
      </CardComponent>
    </React.Fragment>
  )
}

ProductChart.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.any,
}

export default ProductChart
