import {
  ADD_SEGMENT_FAIL,
  ADD_SEGMENT_SUCCESS,
  GET_SEGMENT_VALUE,
  GET_SEGMENT_VALUE_SUCCESS,
  STORE_SEGMENT_DATA,
  UPDATE_SEGMENT_FAIL,
  UPDATE_SEGMENT_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
  isLoading: false,
  segmentData: [],
  editSegmentInfo: [],
}

const SegmentReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_SEGMENT_VALUE:
      return {
        ...state,
        isLoading: true,
      }
      break

    case GET_SEGMENT_VALUE_SUCCESS:
      return {
        ...state,
        segmentData: action.payload.data,
        isLoading: false,
      }

    case ADD_SEGMENT_SUCCESS:
      return {
        ...state,
        segment: [...state.segment, action.payload],
        isLoading: false,
      }

    case ADD_SEGMENT_FAIL:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      }

    case STORE_SEGMENT_DATA:
      return {
        ...state,
        editSegmentInfo: action.payload,
      }
    case UPDATE_SEGMENT_SUCCESS:
      return {
        ...state,
        segmentData: state.segment.map(segment =>
          segment.id.toString() === action.payload.id.toString()
            ? { segment, ...action.payload }
            : segment
        ),
        loading: false,
      }

    case UPDATE_SEGMENT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default SegmentReducer
