import CardComponent from "components/Layout/CardComponent"
import { makeQuery } from "helpers/Custom/makeQuery"
import React, { useEffect, useState } from "react"
import ReactApexChart from "react-apexcharts"
import { Col, Form, Row } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { Spinner } from "reactstrap"
import { getDashboardIndex } from "store/actions"
import Cold from "../../assets/images/xelerator/icon/dashboard/cold.png"
import Hot from "../../assets/images/xelerator/icon/dashboard/hot.png"
import Warm from "../../assets/images/xelerator/icon/dashboard/warm.png"

const Hwc = () => {
  const dispatch = useDispatch()
  const [decoration, setDecoration] = useState("WPCC")
  const [chartValue, setChartValue] = useState("monthly")
  const [year, setYear] = useState("all")
  const [index, setIndex] = useState("value")
  const {
    mainFilter,
    totalValue,
    subSelectedFilters,
    isSubFilterChanged,
    selectedSubCategory,
    currentSelection,
    dashboardIndex,
    dashboardIndexLoading,
    authToken,
    role
  } = useSelector(state => ({
    mainFilter: state.DashboardReducer.mainFilter,
    subSelectedFilters: state.DashboardReducer.subSelectedFilters,
    totalValue: state.DashboardReducer.totalValue,
    isSubFilterChanged: state.DashboardReducer.isSubFilterChanged,
    selectedSubCategory: state.DashboardReducer.selectedSubCategory,
    currentSelection: state.DashboardReducer.currentSelection,
    dashboardIndex: state?.AdminReducer?.dashboardIndex,
    dashboardIndexLoading: state?.AdminReducer?.dashboardIndexLoading,
    authToken : state.Login.token,
    role: state.Login.data?.role,
  }))
  const [wpccButtonStyle, setWpccButtonStyle] = useState(
    "button btn-blue btn-sm"
  )
  const [decoderButtonStyle, setDecoderButtonStyle] = useState(
    "btn btn-light btn-sm"
  )

  const handleIndex = e => {
    setIndex(e.target.value)
  }

  const handleButtonStyle = e => {
    setDecoration(e.target.id)
    if (e.target.id === "WPCC") {
      setWpccButtonStyle("button btn-blue btn-sm")
      setDecoderButtonStyle("btn btn-light btn-sm")
    } else {
      setDecoderButtonStyle("button btn-blue btn-sm")
      setWpccButtonStyle("btn btn-light btn-sm")
    }
  }

  useEffect(() => {
    const chartQuery = makeQuery(
      mainFilter,
      subSelectedFilters,
      selectedSubCategory,
      currentSelection,
      totalValue,
      chartValue,
      year,
      index,
      decoration,
      "decoration"
    )
    // console.log(
    //   `chartQuery`,
    //   chartQuery
    // )
    dispatch(getDashboardIndex(authToken, chartQuery, role))
  }, [isSubFilterChanged, decoration, index])
  const handleChat = e => {
    setChartValue(e.target.value)
  }
  const handleYear = e => {
    setYear(e.target.value)
  }
  // console.log('dashboardIndex',dashboardIndex);
  let coldData, hotData, warmData, monthData
  if (!dashboardIndexLoading) {
    coldData = dashboardIndex?.data?.colds
    hotData = dashboardIndex?.data?.hots
    warmData = dashboardIndex?.data?.warms
    monthData = dashboardIndex?.data?.month
  }
  let valueColdArray = []
  let valueWarmArray = []
  let valueHotArray = []
  let volumeColdArray = []
  let volumeWarmArray = []
  let volumeHotArray = []
  if (!dashboardIndexLoading) {
    {
      ;(coldData || []).map((data, key) => {
        if (index === "value") {
          let valueColdData = Math.round(data / 10000000)
          valueColdArray.push(valueColdData)
        }
        if (index === "volume") {
          let volumeColdData = Math.round(data)
          volumeColdArray.push(volumeColdData)
        }
      })
    }
    {
      ;(warmData || []).map((data, key) => {
        if (index === "value") {
          let valueWarmData = Math.round(data / 10000000)
          valueWarmArray.push(valueWarmData)
        }
        if (index === "volume") {
          let volumeWarmData = Math.round(data)
          volumeWarmArray.push(volumeWarmData)
        }
      })
    }
    {
      ;(hotData || []).map((data, key) => {
        if (index === "value") {
          let valueHotData = Math.round(data / 10000000)
          valueHotArray.push(valueHotData)
        }
        if (index === "volume") {
          let volumeHotData = Math.round(data)
          volumeHotArray.push(volumeHotData)
        }
      })
    }
  }
  // console.log("dashboardIndex", dashboardIndex)

  // console.log("valueColdArray", valueColdArray)
  // const series = [
  //   {
  //     name: "Hot",
  //     data: coldData || [],
  //   },
  //   {
  //     name: "warm",
  //     data: hotData || [],
  //   },
  //   {
  //     name: "Cold",
  //     data: warmData || [],
  //   },
  // ]

  // const options = {
  //   chart: {
  //     height: 350,
  //     type: "area",
  //     toolbar: {
  //       show: false,
  //     },
  //   },
  //   colors: ["#EF4D39", "#EDA01C", "#556EE6"],
  //   dataLabels: {
  //     enabled: false,
  //   },
  //   stroke: {
  //     curve: "smooth",
  //     width: 2,
  //   },
  //   fill: {
  //     type: "gradient",
  //     gradient: {
  //       shadeIntensity: 1,
  //       inverseColors: false,
  //       opacityFrom: 0.45,
  //       opacityTo: 0.05,
  //       stops: [20, 100, 100, 100],
  //     },
  //   },
  //   xaxis: {
  //     categories: monthData || []
  //   },

  //   markers: {
  //     size: 3,
  //     strokeWidth: 3,

  //     hover: {
  //       size: 4,
  //       sizeOffset: 2,
  //     },
  //   },
  //   legend: {
  //     position: "bottom",
  //     horizontalAlign: "center",
  //   },
  // }

  // var options = {
  //   series: [{
  //   name: 'Hot',
  //   type: 'column',
  //   data:  hotData || []
  // }, {
  //   name: 'Warm',
  //   type: 'column',
  //   data:  warmData || [],

  // }, {
  //   name: 'Cold',
  //   type: 'column',
  //   data:  coldData || []
  // }],
  //   chart: {
  //   height: 350,
  //   width: '100%',
  //   type: 'line',
  //   stacked: false
  // },
  // plotOptions: {
  //   bar: {
  //     columnWidth: "15%",
  //   },
  // },
  // dataLabels: {
  //   enabled: false
  // },
  // fill: {
  //   colors: undefined,
  //   opacity: 1,
  //   type: "solid",
  // },
  // stroke: {
  //   width: [4, 4, 4]
  // },

  // xaxis: {
  //   categories: monthData || [] ,
  // },
  // yaxis: [
  //   {
  //     axisTicks: {
  //       show: true,

  //     },
  //     axisBorder: {
  //       show: true,
  //       color: '#008FFB',
  //       offsetX: -8,

  //     },
  //     labels: {
  //       style: {
  //         colors: '#008FFB',
  //       }
  //     },
  //     title: {
  //       text: "Hot",
  //       style: {
  //         color: '#008FFB',
  //       }
  //     },
  //     tooltip: {
  //       enabled: true
  //     }
  //   },
  //   {
  //     seriesName: 'Hot',
  //     opposite: true,
  //     axisTicks: {
  //       show: false,
  //     },
  //     axisBorder: {
  //       show: true,
  //       color: '#00E396',
  //       offsetX: -8,
  //     },
  //     labels: {
  //       style: {
  //         colors: '#00E396',
  //       }
  //     },
  //     title: {
  //       text: "Warm",
  //       style: {
  //         color: '#00E396',
  //       }
  //     },
  //   },
  //   {
  //     seriesName: 'Cold',
  //     opposite: true,
  //     axisTicks: {
  //       show: true,
  //     },
  //     axisBorder: {
  //       show: true,
  //       color: '#FEB019',
  //       // padding:10
  //     },
  //     labels: {
  //       style: {
  //         colors: '#FEB019',
  //       },
  //     },
  //     title: {
  //       text: "Cold",
  //       style: {
  //         color: '#FEB019',
  //       }
  //     }
  //   },
  // ],
  // tooltip: {
  //   fixed: {
  //     enabled: true,
  //     position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
  //     offsetY: 30,
  //     offsetX: 60
  //   },
  // },
  // legend: {
  //   horizontalAlign: 'center',
  //   offsetX: 40
  // }
  // };
  const state = {
    series: [
      {
        name: "Hot",
        data: volumeHotArray || [],
      },
      {
        name: "Warm",
        data: volumeWarmArray || [],
      },
      {
        name: "Cold",
        data: volumeColdArray || [],
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 280,
        stacked: true,
      },
      plotOptions: {
        bar: {
          horizontal: true,

          barHeight: "30%",
        },
      },
      stroke: {
        width: 1,
        colors: ["#fff"],
      },
      title: {
        // text: 'Fiction Books Sales'
      },
      xaxis: {
        categories: monthData,
        // labels: {
        //   formatter: function (val) {
        //     return val + "K"
        //   }
        // }
        labels: {
          show: false,
          style: {
            // colors: "#556EE6",
            // offsetX: 10,
          },
        },
      },
      yaxis: {
        title: {
          text: undefined,
        },
      },
      colors: ["#FA2222", "#FFAA3D", "#556ee6"],

      tooltip: {
        // y: {
        //   formatter: function (val) {
        //     return val + "K"
        //   }
        // }
      },
      fill: {
        opacity: 1,
      },
      legend: {
        position: "bottom",
        horizontalAlign: "center",
        offsetX: 40,
      },
    },
  }
  const valueOption = {
    series: [
      {
        name: "Hot",
        data: valueHotArray || [],
        formatter: function (name) {
          return name + "cr"
        },
      },
      {
        name: "Warm",
        data: valueWarmArray || [],
      },
      {
        name: "Cold",
        data: valueColdArray || [],
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 280,
        stacked: true,
      },
      plotOptions: {
        bar: {
          horizontal: true,

          barHeight: "30%",
        },
      },
      stroke: {
        width: 1,
        colors: ["#fff"],
      },
      title: {
        // text: 'Fiction Books Sales'
      },
      xaxis: {
        categories: monthData,
        // labels: {
        //   formatter: function (val) {
        //     return val + "K"
        //   }
        // }
        labels: {
          show: false,
          style: {
            // colors: "#556EE6",
            // offsetX: 10,
          },
        },
      },
      yaxis: {
        title: {
          text: undefined,
        },
      },
      colors: ["#FA2222", "#FFAA3D", "#556ee6"],

      tooltip: {
        // y: {
        //   formatter: function (val) {
        //     return val + "K"
        //   }
        // }
      },
      fill: {
        opacity: 1,
      },
      legend: {
        // customLegendItems : ["Hot","Warm","Cold"],
        // categories: ['Hot', , 2010, 2011, 2012, 2013, 2014],

        //   formatter: function (val) {
        //     return val + "&nbsp;"

        // },

        position: "bottom",
        horizontalAlign: "center",
        offsetX: 40,
      },
    },
  }
  return (
    <CardComponent>
      {dashboardIndexLoading ? (
        <div className="text-center mt-5 pt-5">
          <Spinner />
        </div>
      ) : (
        <div className="hwc-section">
          <div className="d-flex flex-wrap justify-content-between align-items-center">
            <h6 className="card-title me-2">Index</h6>
            <div className="d-flex">
              {/* <div className="select-area me-2">
            <Form.Select aria-label="Default select" onChange={handleChat}>
              <option value="monthly">Monthly</option>
              <option value="weekly">Weekly</option>
              <option value="daily">Daily</option>
            </Form.Select>
          </div> */}
              <div className="select-area">
                {/* <Form.Select aria-label="Default select" onChange={handleYear}>
                  <option value="all">All Year</option>
                  <option value="2021">2021</option>
                  <option value="2022">2022</option>

                </Form.Select> */}
                <Form.Select
                  aria-label="Default select"
                  value={index}
                  onChange={handleIndex}
                >
                  {/* <option value="">Select</option> */}
                  <option value="value">Value</option>
                  <option value="volume">Volume</option>
                </Form.Select>
              </div>
            </div>
            <div className="ms-auto">
              <div className="toolbar d-flex flex-wrap gap-2 text-end">
                <button
                  type="button"
                  className={wpccButtonStyle}
                  id="WPCC"
                  onClick={handleButtonStyle}
                >
                  WPCC
                </button>
                <button
                  type="button"
                  className={decoderButtonStyle}
                  id="DECO"
                  onClick={handleButtonStyle}
                >
                  Decor
                </button>
              </div>
            </div>
          </div>

          <Row className="mt-4">
            <Col lg={4}>
              <div className="card hot">
                <div className="card-body">
                  <div className="d-flex justify-content-center">
                    <div className="me-2">
                      <img src={Hot} alt="Icon" />
                    </div>
                    <p className="">Hot</p>
                  </div>
                  {index === "value" ? (
                    <h5 className="text-center ms-4">
                      {Math.round(dashboardIndex?.data?.hot / 10000000)} cr.
                    </h5>
                  ) : (
                    <h5 className="text-center ms-4">
                      {Math.round(dashboardIndex?.data?.hot)}
                    </h5>
                  )}
                </div>
              </div>
            </Col>

            <Col lg={4}>
              <div className="card warm">
                <div className="card-body">
                  <div className="d-flex justify-content-center">
                    <div className="me-2">
                      <img src={Warm} alt="Icon" />
                    </div>
                    <p className="">Warm</p>
                  </div>

                  {index === "value" ? (
                    <h5 className="text-center ms-4">
                      {Math.round(dashboardIndex?.data?.warm / 10000000)} cr.
                    </h5>
                  ) : (
                    <h5 className="text-center ms-4">
                      {Math.round(dashboardIndex?.data?.warm)}
                    </h5>
                  )}
                </div>
              </div>
            </Col>
            <Col lg={4}>
              <div className="card cold">
                <div className="card-body">
                  <div className="d-flex justify-content-center">
                    <div className="me-2">
                      <img src={Cold} alt="Icon" />
                    </div>
                    <p className="">Cold</p>
                  </div>

                  {index === "value" ? (
                    <h5 className="text-center ms-4">
                      {Math.round(dashboardIndex?.data?.cold / 10000000)} cr.
                    </h5>
                  ) : (
                    <h5 className="text-center ms-4">
                      {Math.round(dashboardIndex?.data?.cold)}
                    </h5>
                  )}
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <CardComponent>
                <h6 className="ms-3 font-size-16">Index</h6>
                <div id="chart">
                  {index !== "value" ? (
                    <ReactApexChart
                      options={state.options}
                      series={state.series}
                      type="bar"
                      height={350}
                    />
                  ) : (
                    <ReactApexChart
                      options={valueOption.options}
                      series={valueOption.series}
                      type="bar"
                      height={350}
                    />
                  )}
                </div>
              </CardComponent>
            </Col>
          </Row>
        </div>
      )}
    </CardComponent>
  )
}

export default Hwc
