//ADD TARGET
export const ADD_NEW_TARGET = "ADD_NEW_TARGET"
export const ADD_TARGET_SUCCESS = "ADD_TARGET_SUCCESS"
export const ADD_TARGET_FAIL = "ADD_TARGET_FAIL"

//Edit TARGET
export const GET_TARGET_VALUE = "GET_TARGET_VALUE"
export const GET_TARGET_VALUE_SUCCESS = "GET_TARGET_VALUE_SUCCESS"
export const GET_TARGET_VALUE_FAIL = "GET_TARGET_VALUE_FAIL"

//Update TARGET
export const UPDATE_TARGET = "UPDATE_TARGET"
export const UPDATE_TARGET_SUCCESS = "UPDATE_TARGET_SUCCESS"
export const UPDATE_TARGET_FAIL = "UPDATE_TARGET_FAIL"

//export const STORE_ROUTE_PLAN_DATA = "STORE_ROUTE_PLAN_DATA"
