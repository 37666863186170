import TableHeader from "components/Common/TableHeader"
import CardComponent from "components/Layout/CardComponent"
import CustomTable from "components/Layout/CustomTable"
import InnerLayer from "components/Layout/InnerLayer"
import NoTableData from "components/Layout/NoTableData"
import { getSiteExcelData } from "helpers/backend_helper"
import moment from "moment"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import {
  Container,
  Form,
  OverlayTrigger,
  Spinner,
  Tooltip,
} from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import Modal from "react-responsive-modal"
import "react-responsive-modal/styles.css"
import { useHistory, withRouter } from "react-router-dom"
import Select from "react-select"
import { Col, Row } from "reactstrap"
import {
  clearFilters,
  deleteOrgSite,
  getAreaByRegion,
  getCategoryFilter,
  getLAUser,
  getManagingOrgSitesData,
  getRegion,
  getRouteByTerritory,
  getSegmentValue,
  getSitesViewData,
  getSubCategoryById,
  getTerritoryByArea,
  getUserValue,
  onAreaFilterChange,
  onCategoryFilterChange,
  onRegionFilterChange,
  onRoutesFilterChange,
  onSubCategoryFilterChange,
  onTerritoryFilterChange,
} from "store/actions"
import XLSX from "xlsx"
import "../Project/project.scss"

const tableHead = [
  "No.",
  "Visit To",
  "Site Name",
  "Information Availability",
  "Category",
  "Sub Category",
  "Visit Date",
  "Type of Structure",
  "Paint Pot",
  "Details",
  "Action",
]

export const products = [
  {
    id: "1",
    name: "Asian Tractor Emulsion Paint",
    code: 1234,
    category: "Exterior",
    unit: "LTR",
    price: "43k",
    status: "active",
    toggle: false,
  },
  {
    id: "2",
    name: "Jane Cooper",
    code: 1234,
    category: "WPCC",
    unit: "LTR",
    price: "43k",
    status: "active",
    toggle: false,
  },
  {
    id: "3",
    name: "Marvin McKinney",
    code: 1234,
    category: "Exterior",
    unit: "LTR",
    price: "43k",
    status: "inactive",
    toggle: false,
  },
]
const visitTo = [
  { name: "Construction Site", value: "Construction Site" },
  { name: "Office Site", value: "Office Site" },
]
const statusData = [
  { name: "Ongoing", value: "Ongoing" },
  { name: "Yes", value: "Yes" },
  { name: "No", value: "No" },
]
const Structure = [
  { name: "Old Structure", value: "OB" },
  { name: "New Structure", value: "NB" },
]
const Information = [
  { name: "Full Set available", value: "Full Set available" },
  { name: "Partial Available", value: "Partial Available" },
]
const Construction = [
  { name: "BOQ Stage", value: "BOQ Stage" },
  { name: "Paint Assign", value: "Paint work is assigned" },
  { name: "Sub Structure ongoing", value: "Sub Structure ongoing" },
  { name: "Super Structure ongoing", value: "Super Structure ongoing" },
]
const moneyData = [
  {
    _id: 1,
    name: "Total",
    value: "all",
  },
  {
    _id: 2,
    name: "Less than 7 lakh",
    value: "lt",
  },
  {
    _id: 3,
    name: "Greater than 7 lakh",
    value: "gt",
  },
]
const renderTooltipDelete = props => (
  <Tooltip id="button-tooltip" {...props}>
    Delete
  </Tooltip>
)
const renderTooltipView = props => (
  <Tooltip id="button-tooltip" {...props}>
    View
  </Tooltip>
)

const Projects = () => {
  const history = useHistory()
  const [currentPage, setCurrentPage] = useState(1)
  const [downloadExcel, setDownloadExcel] = useState(false)
  const [dataRange, setDataRange] = useState(100)
  const [reRender, setReRender] = useState(false)
  const [reRenderTo, setReRenderTo] = useState(false)
  const [loadArea, setLoadArea] = useState(false)
  const [loadTerritory, setLoadTerritory] = useState(false)
  const [loadRoutes, setLoadRoutes] = useState(false)
  const [loadSubCategory, setLoadSubCategory] = useState(false)
  const [selectedLa, setSelectedLa] = useState({})
  const [selectedOrg, setSelectedOrg] = useState({})
  const [selectedSegment, setSelectedSegment] = useState({})
  const [visit, setVisit] = useState({})
  const [status, setStatus] = useState({})
  const [structure, setStructure] = useState({})
  const [info, setInfo] = useState({})
  const [construction, setConstruction] = useState({})
  const [total, setTotal] = useState("all")
  const [toDate, setToDate] = useState("")
  const [fromDate, setFromDate] = useState("")
  const [noData, setNoData] = useState(false)
  const {
    region,
    authToken,
    areaList,
    territoryList,
    selectedArea,
    selectedTerritory,
    selectedRegion,
    selectedCategory,
    selectedRoutes,
    selectedSubCategory,
    categoryList,
    subCategoryList,
    routesList,
    laUser,
    orgSitesLoading,
    sitesData,
    managingOrgSitesData,
    sitesLength,
    sitesViewData,
    segmentData,
    userData,
    subLoading,
    regionSubLoading,
    areaSubLoading,
    territorySubLoading,
  } = useSelector(state => ({
    authToken: state.Login?.token,
    region: state.GeoInformationReducer?.region?.region,
    areaList: state.DashboardReducer.areaList,
    territoryList: state.DashboardReducer.territoryList,
    mainFilter: state.DashboardReducer.mainFilter,
    subLoading: state.DashboardReducer.subLoading,
    regionSubLoading: state.DashboardReducer.regionSubLoading,
    areaSubLoading: state.DashboardReducer.areaSubLoading,
    territorySubLoading: state.DashboardReducer.territorySubLoading,
    selectedRegion: state.DashboardReducer.selectedRegion,
    selectedArea: state.DashboardReducer.selectedArea,
    selectedTerritory: state.DashboardReducer.selectedTerritory,
    subCategoryList: state.DashboardReducer.subCategoryList,
    selectedSubCategory: state.DashboardReducer.selectedSubCategory,
    selectedCategory: state.DashboardReducer.selectedCategory,
    categoryList: state.DashboardReducer.categoryList,
    routesList: state.DashboardReducer.routesList,
    selectedRoutes: state.DashboardReducer.selectedRoutes,
    laUser: state.DashboardReducer.laUser,
    sitesData: state.DashboardReducer.sitesData?.sites,
    managingOrgSitesData: state.DashboardReducer.managingOrgSitesData?.orgSite,
    sitesViewData: state.DashboardReducer.sitesViewData,
    sitesLength: state.DashboardReducer.managingOrgSitesData,
    orgSitesLoading: state.DashboardReducer.orgSitesLoading,
    segmentData: state.SegmentReducer?.segmentData?.customerSegment,
    userData: state.UserReducer?.userData?.users,
  }))
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(clearFilters())
  }, [])

  useEffect(async () => {
    await dispatch(getRegion(authToken))
  }, [])

  useEffect(async () => {
    await dispatch(getCategoryFilter(authToken))
  }, [])

  useEffect(async () => {
    await dispatch(getLAUser(authToken))
  }, [])

  useEffect(async () => {
    await dispatch(getSegmentValue(authToken))
  }, [])

  useEffect(() => {
    dispatch(getUserValue(authToken, "ORG"))
  }, [])

  const handleMainFilter = value => {
    if (!!value) {
      setVisit(value)
    } else {
      setVisit({})
    }
    if (reRenderTo == true) {
      setReRender(!reRender)
    }
  }

  const handleTotalValue = data => {
    if (!data) {
      setTotal(data?.value)
    } else {
      setTotal("all")
    }
  }
  const handleSubCategory = value => {
    if (!!value) {
      setStructure(value)
    } else {
      setStructure({})
    }
    if (reRenderTo == true) {
      setReRender(!reRender)
    }
  }

  const handleSubCategory1 = value => {
    if (!!value) {
      setInfo(value)
    } else {
      setInfo({})
    }
    if (reRenderTo == true) {
      setReRender(!reRender)
    }
  }
  const handleSubCategory2 = value => {
    if (!!value) {
      setConstruction(value)
    } else {
      setConstruction({})
    }
    if (reRenderTo == true) {
      setReRender(!reRender)
    }
  }
  const handleStatus = value => {
    if (!!value) {
      setStatus(value)
    } else {
      setStatus({})
    }
    if (reRenderTo == true) {
      setReRender(!reRender)
    }
  }

  const data = {
    visitTo: visit?.value || null,
    typeOfStructureShort: structure?.value || null,
    categoryId: selectedCategory?._id || null,
    subCategoryId: selectedSubCategory?._id || null,
    regionId: selectedRegion?._id || null,
    areaId: selectedArea?._id || null,
    territoryId: selectedTerritory?._id || null,
    routeId: selectedRoutes?._id || null,
    orgId: selectedOrg?._id || null,
    segmentId: selectedSegment?._id || null,
    limit: dataRange,
    pageNo: currentPage,
    status: status?.value || null,
    fromDate: fromDate,
    toDate: toDate,
  }
  const checkObjectKeys = obj => {
    return Object.keys(obj).length === 0
  }
  /// table API
  useEffect(async () => {
    // const isChangedFilter = checkObjectKeys()
    await dispatch(getManagingOrgSitesData(authToken, data))
  }, [reRender, reRenderTo, dataRange, currentPage])

  useEffect(async () => {
    if (Object?.keys(selectedRegion)?.length) {
      await dispatch(getAreaByRegion(authToken, selectedRegion?._id))
    }
  }, [loadArea])

  useEffect(async () => {
    if (Object?.keys(selectedArea)?.length) {
      await dispatch(getTerritoryByArea(authToken, selectedArea?._id))
    }
  }, [loadTerritory])
  useEffect(async () => {
    if (Object?.keys(selectedTerritory)?.length) {
      await dispatch(getRouteByTerritory(authToken, selectedTerritory?._id))
    }
  }, [loadRoutes])

  //
  useEffect(async () => {
    if (Object?.keys(selectedCategory)?.length) {
      await dispatch(getSubCategoryById(authToken, selectedCategory?._id))
    }
  }, [loadSubCategory])
  // region
  const handleRegion = data => {
    if (!!data) {
      dispatch(onRegionFilterChange(data))
    } else {
      dispatch(onRegionFilterChange({}))
    }
    setLoadArea(!loadArea)
    if (reRenderTo == true) {
      setReRender(!reRender)
    }
  }

  // area
  const handleArea = data => {
    if (!!data) {
      dispatch(onAreaFilterChange(data))
    } else {
      dispatch(onAreaFilterChange({}))
    }
    setLoadTerritory(!loadTerritory)
    if (reRenderTo == true) {
      setReRender(!reRender)
    }
  }

  // territory
  const handleTerritory = data => {
    if (!!data) {
      dispatch(onTerritoryFilterChange(data))
    } else {
      dispatch(onTerritoryFilterChange({}))
    }
    setLoadRoutes(!loadRoutes)
    if (reRenderTo == true) {
      setReRender(!reRender)
    }
  }

  // territory
  const handleRoutes = data => {
    if (!!data) {
      dispatch(onRoutesFilterChange(data))
    } else {
      dispatch(onRoutesFilterChange({}))
    }
    if (reRenderTo == true) {
      setReRender(!reRender)
    }
  }

  // category
  const handleCategory = data => {
    if (!!data) {
      dispatch(onCategoryFilterChange(data))
    } else {
      dispatch(onCategoryFilterChange({}))
    }
    setLoadSubCategory(!loadSubCategory)
    if (reRenderTo == true) {
      setReRender(!reRender)
    }
  }
  const handleSubCategoryFilter = data => {
    if (!!data) {
      dispatch(onSubCategoryFilterChange(data))
    } else {
      dispatch(onSubCategoryFilterChange({}))
    }
    if (reRenderTo == true) {
      setReRender(!reRender)
    }
  }
  const handleOrganizationUser = data => {
    if (!!data) {
      setSelectedOrg(data)
    } else {
      setSelectedOrg({})
    }
    setReRenderTo(!reRenderTo)
  }
  const handleSegment = data => {
    if (!!data) {
      setSelectedSegment(data)
    } else {
      setSelectedSegment({})
    }
    if (reRenderTo == true) {
      setReRender(!reRender)
    }
  }

  const handleLaUser = data => {
    if (!!data) {
      setSelectedLa(data)
    } else {
      setSelectedLa({})
    }
    setReRender(!reRender)
  }
  const handleExcel = async () => {
    try {
      setDownloadExcel(true)
      const url = `/site/siteListDownloadExcel`
      const response = await getSiteExcelData(url, data, authToken)
      let newArray = []
      response?.sites?.forEach(sites => {
        let data = {}
        data.VisitDate = sites?.VisitDate
        data.VisitTo = sites?.VisitTo
        data.TimeOfVisit = sites?.TimeOfVisit
        data.InformationAvailability = sites?.InformationAvailability
        data.SiteName = sites?.SiteName
        data.SiteCategoryName = sites?.CategoryId?.name
        data.SubCategoryName = sites?.SubCategoryId?.name
        data.TypeOfStructure = sites?.TypeOfStructure
        data.OpportunityBlock = sites?.OpportunityBlock
        data.ConstructionStage = sites?.ConstructionStage
        data.CurrentStageOfWork = sites?.CurrentStageOfWork
        data.NoOfFloorAboveGround = sites?.NoOfFloorAboveGround
        data.NoOfFloorInBasement = sites?.NoOfFloorInBasement
        data.StructureFloorArea = sites?.StructureFloorArea
        data.HeightOfTheStructureWall = sites?.HeightOfTheStructureWall
        data.RoofArea = sites?.RoofArea
        data.wetSurfaceArea = sites?.wetSurfaceArea
        data.BasementFloorArea = sites?.BasementFloorArea
        data.ExteriorArea = sites?.ExteriorArea
        data.InteriorArea = sites?.InteriorArea
        data.PaintPotentialArea = sites?.PaintPotentialArea
        data.PaintPot = sites?.PaintPot
        data.ActualPaintPot = sites?.ActualPaintPot
        data.PaintStages = sites?.PaintStages
        data.WPCC = sites.WPCC
        data.DECO = sites?.DECO
        data.ConstructionPOT = sites?.ConstructionPOT
        data.RegionName = sites?.RegionId?.name
        data.AreaName = sites?.AreaId?.name
        data.TerritoryName = sites?.TerritoryId?.name
        data.RouteName = sites?.RouteId?.name
        data.CustomerSegment = sites?.CustomerSegment
        data.PersonNameC1 = sites?.client[0]?.name
        data.DesignationC1 = sites?.client[0]?.designation
        data.EmailAddressC1 = sites?.client[0]?.email
        data.ContactNoC1 = sites?.client[0]?.contactNo
        data.OrganizationNameC1 = sites?.client[0]?.organizationName
        data.OrganizationTypeC1 = sites?.client[0]?.organizationType
        data.RespondentTypeC1 = sites?.client[0]?.respondentType

        data.PersonNameC2 = sites?.client[1]?.name
        data.DesignationC2 = sites?.client[1]?.designation
        data.EmailAddressC2 = sites?.client[1]?.email
        data.ContactNoC2 = sites?.client[1]?.contactNo
        data.OrganizationNameC2 = sites?.client[1]?.organizationName
        data.OrganizationTypeC2 = sites?.client[1]?.organizationType
        data.RespondentTypeC2 = sites?.client[1]?.respondentType

        data.PersonNameC3 = sites?.client[2]?.name
        data.DesignationC3 = sites?.client[2]?.designation
        data.EmailAddressC3 = sites?.client[2]?.email
        data.ContactNoC3 = sites?.client[2]?.contactNo
        data.OrganizationNameC3 = sites?.client[2]?.organizationName
        data.OrganizationTypeC3 = sites?.client[2]?.organizationType
        data.RespondentTypeC3 = sites?.client[2]?.respondentType

        data.PersonNameC4 = sites?.client[3]?.name
        data.DesignationC4 = sites?.client[3]?.designation
        data.EmailAddressC4 = sites?.client[3]?.email
        data.ContactNoC4 = sites?.client[3]?.contactNo
        data.OrganizationNameC4 = sites?.client[3]?.organizationName
        data.OrganizationTypeC4 = sites?.client[3]?.organizationType
        data.RespondentTypeC4 = sites?.client[3]?.respondentType

        data.PersonNameC5 = sites?.client[4]?.name
        data.DesignationC5 = sites?.client[4]?.designation
        data.EmailAddressC5 = sites?.client[4]?.email
        data.ContactNoC5 = sites?.client[4]?.contactNo
        data.OrganizationNameC5 = sites?.client[4]?.organizationName
        data.OrganizationTypeC5 = sites?.client[4]?.organizationType
        data.RespondentTypeC5 = sites?.client[4]?.respondentType
        newArray.push(data)
      })
      downloadxls(newArray)
    } catch (err) {
      setDownloadExcel(false)
    }
  }
  const downloadxls = data => {
    const ws = XLSX.utils.json_to_sheet(data)
    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, "SheetJS")
    XLSX.writeFile(wb, "excel.xlsx")
    setDownloadExcel(false)
  }
  const [open, setOpen] = useState(false)
  const [siteData, setSiteData] = useState()
  const onOpenModal = data => {
    dispatch(getSitesViewData(authToken, data?.site?._id))
    setSiteData(data)
    setOpen(true)
  }
  const handleDelete = (e, data) => {
    window.confirm("Are you sure, you want to remove this site?") &&
      dispatch(deleteOrgSite(history, authToken, data?._id))
    // history.push("/managing-org-site")
  }
  let totalPageNumber = Math.ceil(sitesLength?.length / dataRange)
  const onCloseModal = () => setOpen(false)
  const breadcrumbItems = [
    {
      title: "Dashboard",
      link: "/dashboard",
    },
    {
      title: "Managing Org Sites",
    },
  ]

  return (
    <React.Fragment>
      <InnerLayer
        isMultiple
        title={"Managing Org Sites"}
        breadcrumbItems={breadcrumbItems}
      >
        {/* filter */}
        <Row>
          <CardComponent>
            <div className="row">
              <div className="col-xl-3 col-lg-4 col-md-4 mb-3">
                <Select
                  name="mainFilter"
                  classNamePrefix="select2-selection"
                  placeholder="Visit To"
                  cacheOptions
                  getOptionLabel={e => e.name}
                  getOptionValue={e => e.value}
                  isDisabled={checkObjectKeys(selectedOrg) === true}
                  // defaultValue={visitTo?.filter(
                  //   data => data.value === status
                  // )}
                  isClearable
                  options={visitTo}
                  onChange={handleMainFilter}
                />
              </div>
              <div className="col-xl-3 col-lg-4 col-md-4 mb-3">
                <Select
                  name="subFilter"
                  classNamePrefix="select2-selection"
                  style={{ minWidth: "100px" }}
                  placeholder="Structure Type"
                  getOptionLabel={e => e.name}
                  getOptionValue={e => e.value}
                  isClearable
                  isDisabled={checkObjectKeys(selectedOrg) === true}
                  // value={
                  //   subCategory.filter(
                  //     data => data?._id === selectedSubCategory?._id
                  //   ) || [{ name: "", value: "" }]
                  // }
                  options={Structure}
                  onChange={handleSubCategory}
                />
              </div>
              {/* ) : null} */}
              <div className="col-xl-3 col-lg-4 col-md-4 mb-3">
                <Select
                  name="mainFilter"
                  classNamePrefix="select2-selection"
                  isClearable
                  // disabled={loadingCondition}
                  style={{ minWidth: "100px" }}
                  //   cacheOptions
                  getOptionLabel={e => e.name}
                  getOptionValue={e => e._id}
                  // defaultInputValue={}
                  placeholder={"Category"}
                  isDisabled={checkObjectKeys(selectedOrg) === true}
                  options={categoryList}
                  onChange={handleCategory}
                />
              </div>
              {/* {Object.keys(selectedCategory)?.length &&
              subCategoryList.length ? ( */}
              <div className="col-xl-3 col-lg-4 col-md-4 mb-3">
                {!subLoading ? (
                  <Select
                    name="mainFilter"
                    classNamePrefix="select2-selection"
                    getOptionLabel={e => e.name}
                    getOptionValue={e => e._id}
                    placeholder={"Sub Category"}
                    isClearable
                    options={subCategoryList}
                    onChange={handleSubCategoryFilter}
                    isDisabled={checkObjectKeys(selectedOrg) === true}
                  />
                ) : (
                  <div
                    className="p-2"
                    style={{ border: "1px solid #ced4da", borderRadius: "4px" }}
                  >
                    <Spinner animation="border" size="sm" />
                  </div>
                )}
              </div>
            </div>
            {/* <hr /> */}
            <div className="row">
              <div className="col-xl-3 col-lg-4 col-md-4 mb-3">
                <Select
                  name="mainFilter"
                  classNamePrefix="select2-selection"
                  // disabled={loadingCondition}
                  style={{ minWidth: "100px" }}
                  isClearable
                  //   cacheOptions
                  getOptionLabel={e => e.name}
                  getOptionValue={e => e._id}
                  // defaultInputValue={}
                  placeholder={"Select Region"}
                  options={region}
                  onChange={handleRegion}
                  isDisabled={checkObjectKeys(selectedOrg) === true}
                />
              </div>
              {/* {Object.keys(selectedRegion)?.length && areaList?.length ? ( */}
              <div className="col-xl-3 col-lg-4 col-md-4 mb-3">
                {!regionSubLoading ? (
                  <Select
                    name="mainFilter"
                    classNamePrefix="select2-selection"
                    getOptionLabel={e => e.name}
                    isClearable
                    getOptionValue={e => e._id}
                    placeholder={"Select Area"}
                    options={areaList}
                    onChange={handleArea}
                    isDisabled={checkObjectKeys(selectedOrg) === true}
                  />
                ) : (
                  <div
                    className="p-2"
                    style={{ border: "1px solid #ced4da", borderRadius: "4px" }}
                  >
                    <Spinner animation="border" size="sm" />
                  </div>
                )}
              </div>
              {/* // ) : null} */}
              {/* {Object.keys(selectedArea)?.length && territoryList.length ? ( */}
              <div className="col-xl-3 col-lg-4 col-md-4 mb-3">
                {!areaSubLoading ? (
                  <Select
                    name="mainFilter"
                    classNamePrefix="select2-selection"
                    getOptionLabel={e => e.name}
                    isClearable
                    getOptionValue={e => e._id}
                    placeholder={"Select Territory"}
                    options={territoryList}
                    onChange={handleTerritory}
                    isDisabled={checkObjectKeys(selectedOrg) === true}
                  />
                ) : (
                  <div
                    className="p-2"
                    style={{ border: "1px solid #ced4da", borderRadius: "4px" }}
                  >
                    <Spinner animation="border" size="sm" />
                  </div>
                )}
              </div>
              {/* ) : null} */}
              {/* {Object.keys(selectedTerritory)?.length && routesList.length ? ( */}
              <div className="col-xl-3 col-lg-4 col-md-4 mb-3">
                {!territorySubLoading ? (
                  <Select
                    name="mainFilter"
                    classNamePrefix="select2-selection"
                    getOptionLabel={e => e.name}
                    isClearable
                    getOptionValue={e => e._id}
                    placeholder={"Select Routes"}
                    options={routesList}
                    onChange={handleRoutes}
                    isDisabled={checkObjectKeys(selectedOrg) === true}
                  />
                ) : (
                  <div
                    className="p-2"
                    style={{ border: "1px solid #ced4da", borderRadius: "4px" }}
                  >
                    <Spinner animation="border" size="sm" />
                  </div>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-xl col-lg-4 col-md-4 mb-3">
                <Select
                  name="subFilter"
                  classNamePrefix="select2-selection"
                  placeholder="Customer Segment"
                  getOptionLabel={e => e.name}
                  getOptionValue={e => e.value}
                  isClearable
                  options={segmentData}
                  onChange={handleSegment}
                  isDisabled={checkObjectKeys(selectedOrg) === true}
                />
              </div>
              <div className="col-xl col-lg-4 col-md-4 mb-3">
                <Select
                  name="subFilter"
                  classNamePrefix="select2-selection"
                  placeholder="Select status"
                  getOptionLabel={e => e.name}
                  getOptionValue={e => e.value}
                  isClearable
                  options={statusData}
                  onChange={handleStatus}
                  isDisabled={checkObjectKeys(selectedOrg) === true}
                />
              </div>
              <div className="col-xl col-lg-4 col-md-4 mb-3">
                <Form.Group controlId="date">
                  <Form.Control
                    type="date"
                    name="date"
                    placeholder="Date"
                    onChange={e => setFromDate(e.target.value)}
                    disabled={checkObjectKeys(selectedOrg) === true}
                  />
                </Form.Group>
              </div>
              <div className="col-xl col-lg-4 col-md-4 mb-3">
                <Form.Group controlId="date">
                  <Form.Control
                    type="date"
                    name="date"
                    placeholder="Date"
                    onChange={e => setToDate(e.target.value)}
                    disabled={checkObjectKeys(selectedOrg) === true}
                  />
                </Form.Group>
              </div>

              <div className="col-xl col-lg-4 col-md-4 mb-3">
                <Select
                  name="subFilter"
                  classNamePrefix="select2-selection"
                  placeholder="Select Organization"
                  getOptionLabel={e => e.name}
                  getOptionValue={e => e.value}
                  isClearable
                  // value={
                  //   subCategory.filter(
                  //     data => data?._id === selectedSubCategory?._id
                  //   ) || [{ name: "", value: "" }]
                  // }
                  options={userData}
                  onChange={handleOrganizationUser}
                />
              </div>
            </div>
            {/* // ) : null} */}

            <div className="d-flex mt-2"></div>
          </CardComponent>
        </Row>
        {/* row */}

        {/* table */}
        <Row>
          <CardComponent className="user_table_card">
            {/* <Row className="mb-2">
              <Col md="12">
                <div className="search-box me-2 mb-2 d-flex justify-content-end">
                  <SearchInput
                    className="userSearchBar form-control"
                    onChange={() => {}}
                  />
                </div>
              </Col>
            </Row> */}

            {/* table */}
            <div>
              {/* <button onClick={onOpenModal}>Open modal</button> */}
              {orgSitesLoading ? (
                <></>
              ) : (
                <Modal
                  open={open}
                  onClose={onCloseModal}
                  center
                  classNames={{
                    overlay: "customOverlay",
                    modal: "modal-main-wrap-fgf",
                  }}
                >
                  <Container>
                    <div className="popup-main-area-data-site-information">
                      <div className="site-info-popup-title">
                        <h3>Site Information</h3>
                      </div>
                      <Row>
                        <Col lg={6}>
                          <div className="x-site-info-single-data-wrap">
                            <div className="single-data-x-item">
                              <div className="x-data-title">
                                <p>LA User</p>
                              </div>
                              <div className="x-data-middle">:</div>
                              <div className="x-data-dec">
                                <p>{sitesViewData?.site?.UserId?.name}</p>
                              </div>
                            </div>{" "}
                            <div className="single-data-x-item">
                              <div className="x-data-title">
                                <p>Visit to</p>
                              </div>
                              <div className="x-data-middle">:</div>
                              <div className="x-data-dec">
                                <p>{sitesViewData?.site?.VisitTo}</p>
                              </div>
                            </div>{" "}
                            <div className="single-data-x-item">
                              <div className="x-data-title">
                                <p>Site Name</p>
                              </div>
                              <div className="x-data-middle">:</div>
                              <div className="x-data-dec">
                                <p>{sitesViewData?.site?.SiteName}</p>
                              </div>
                            </div>{" "}
                            <div className="single-data-x-item">
                              <div className="x-data-title">
                                <p>Site Address</p>
                              </div>
                              <div className="x-data-middle">:</div>
                              <div className="x-data-dec">
                                <p>{sitesViewData?.site?.SiteAddress}</p>
                              </div>
                            </div>{" "}
                            <div className="single-data-x-item">
                              <div className="x-data-title">
                                <p>Visit Date</p>
                              </div>
                              <div className="x-data-middle">:</div>
                              <div className="x-data-dec">
                                <p>{sitesViewData?.site?.VisitDate}</p>
                              </div>
                            </div>{" "}
                            <div className="single-data-x-item">
                              <div className="x-data-title">
                                <p>Check In Time</p>
                              </div>
                              <div className="x-data-middle">:</div>
                              <div className="x-data-dec">
                                <p>{sitesViewData?.site?.CheckInTime}</p>
                              </div>
                            </div>{" "}
                            <div className="single-data-x-item">
                              <div className="x-data-title">
                                <p>Type of Structure </p>
                              </div>
                              <div className="x-data-middle">:</div>
                              <div className="x-data-dec">
                                <p>{sitesViewData?.site?.TypeOfStructure}</p>
                              </div>
                            </div>{" "}
                            <div className="single-data-x-item">
                              <div className="x-data-title">
                                <p>Time of Visit </p>
                              </div>
                              <div className="x-data-middle">:</div>
                              <div className="x-data-dec">
                                <p>{sitesViewData?.site?.TimeOfVisit}</p>
                              </div>
                            </div>{" "}
                            <div className="single-data-x-item">
                              <div className="x-data-title">
                                <p>Information Availability </p>
                              </div>
                              <div className="x-data-middle">:</div>
                              <div className="x-data-dec">
                                <p>
                                  {sitesViewData?.site?.InformationAvailability}
                                </p>
                              </div>
                            </div>{" "}
                            <div className="single-data-x-item">
                              <div className="x-data-title">
                                <p>Site Category</p>
                              </div>
                              <div className="x-data-middle">:</div>
                              <div className="x-data-dec">
                                <p>{sitesViewData?.site?.CategoryId?.name}</p>
                              </div>
                            </div>{" "}
                            <div className="single-data-x-item">
                              <div className="x-data-title">
                                <p> Sub Category</p>
                              </div>
                              <div className="x-data-middle">:</div>
                              <div className="x-data-dec">
                                <p>
                                  {sitesViewData?.site?.SubCategoryId?.name}
                                </p>
                              </div>
                            </div>{" "}
                            <div className="single-data-x-item">
                              <div className="x-data-title">
                                <p>Opprtunity Block</p>
                              </div>
                              <div className="x-data-middle">:</div>
                              <div className="x-data-dec">
                                <p>{sitesViewData?.site?.OpportunityBlock}</p>
                              </div>
                            </div>{" "}
                            <div className="single-data-x-item">
                              <div className="x-data-title">
                                <p>Construction Stage</p>
                              </div>
                              <div className="x-data-middle">:</div>
                              <div className="x-data-dec">
                                <p>{sitesViewData?.site?.ConstructionStage}</p>
                              </div>
                            </div>{" "}
                            <div className="single-data-x-item">
                              <div className="x-data-title">
                                <p>Current Stage of Work</p>
                              </div>
                              <div className="x-data-middle">:</div>
                              <div className="x-data-dec">
                                <p>{sitesViewData?.site?.CurrentStageOfWork}</p>
                              </div>
                            </div>{" "}
                          </div>
                        </Col>
                        <Col lg={6}>
                          <div className="x-site-info-single-data-wrap">
                            <div className="single-data-x-item">
                              <div className="x-data-title">
                                <p>No of Floor Above Ground</p>
                              </div>
                              <div className="x-data-middle">:</div>
                              <div className="x-data-dec">
                                <p>
                                  {sitesViewData?.site?.NoOfFloorAboveGround}
                                </p>
                              </div>
                            </div>{" "}
                            <div className="single-data-x-item">
                              <div className="x-data-title">
                                <p>No of Floor in Basement</p>
                              </div>
                              <div className="x-data-middle">:</div>
                              <div className="x-data-dec">
                                <p>
                                  {sitesViewData?.site?.NoOfFloorInBasement}
                                </p>
                              </div>
                            </div>{" "}
                            <div className="single-data-x-item">
                              <div className="x-data-title">
                                <p>Structure Floor Area</p>
                              </div>
                              <div className="x-data-middle">:</div>
                              <div className="x-data-dec">
                                <p>{sitesViewData?.site?.StructureFloorArea}</p>
                              </div>
                            </div>{" "}
                            <div className="single-data-x-item">
                              <div className="x-data-title">
                                <p>Height Of the Structure Wall</p>
                              </div>
                              <div className="x-data-middle">:</div>
                              <div className="x-data-dec">
                                <p>
                                  {
                                    sitesViewData?.site
                                      ?.HeightOfTheStructureWall
                                  }
                                </p>
                              </div>
                            </div>{" "}
                            <div className="single-data-x-item">
                              <div className="x-data-title">
                                <p>Roof Area</p>
                              </div>
                              <div className="x-data-middle">:</div>
                              <div className="x-data-dec">
                                <p>{sitesViewData?.site?.RoofArea}</p>
                              </div>
                            </div>{" "}
                            <div className="single-data-x-item">
                              <div className="x-data-title">
                                <p>Wet Surface Area</p>
                              </div>
                              <div className="x-data-middle">:</div>
                              <div className="x-data-dec">
                                <p>{sitesViewData?.site?.wetSurfaceArea}</p>
                              </div>
                            </div>{" "}
                            <div className="single-data-x-item">
                              <div className="x-data-title">
                                <p>Basement Floor Area</p>
                              </div>
                              <div className="x-data-middle">:</div>
                              <div className="x-data-dec">
                                <p>{sitesViewData?.site?.BasementFloorArea}</p>
                              </div>
                            </div>{" "}
                            <div className="single-data-x-item">
                              <div className="x-data-title">
                                <p>Exterior Area</p>
                              </div>
                              <div className="x-data-middle">:</div>
                              <div className="x-data-dec">
                                <p>{sitesViewData?.site?.ExteriorArea}</p>
                              </div>
                            </div>{" "}
                            <div className="single-data-x-item">
                              <div className="x-data-title">
                                <p>Interior Area</p>
                              </div>
                              <div className="x-data-middle">:</div>
                              <div className="x-data-dec">
                                <p>{sitesViewData?.site?.InteriorArea}</p>
                              </div>
                            </div>{" "}
                            <div className="single-data-x-item">
                              <div className="x-data-title">
                                <p>Paint Potetial Area</p>
                              </div>
                              <div className="x-data-middle">:</div>
                              <div className="x-data-dec">
                                <p>{sitesViewData?.site?.PaintPotentialArea}</p>
                              </div>
                            </div>{" "}
                            <div className="single-data-x-item">
                              <div className="x-data-title">
                                <p>Paint Pot</p>
                              </div>
                              <div className="x-data-middle">:</div>
                              <div className="x-data-dec">
                                <p>{sitesViewData?.site?.PaintPot}</p>
                              </div>
                            </div>{" "}
                            <div className="single-data-x-item">
                              <div className="x-data-title">
                                <p>Paint Stage</p>
                              </div>
                              <div className="x-data-middle">:</div>
                              <div className="x-data-dec">
                                <p>{sitesViewData?.site?.PaintStages}</p>
                              </div>
                            </div>{" "}
                            <div className="single-data-x-item">
                              <div className="x-data-title">
                                <p>WPCC</p>
                              </div>
                              <div className="x-data-middle">:</div>
                              <div className="x-data-dec">
                                <p>{sitesViewData?.site?.WPCC}</p>
                              </div>
                            </div>{" "}
                            <div className="single-data-x-item">
                              <div className="x-data-title">
                                <p>DECOR</p>
                              </div>
                              <div className="x-data-middle">:</div>
                              <div className="x-data-dec">
                                <p>{sitesViewData?.site?.DECO}</p>
                              </div>
                            </div>{" "}
                            <div className="single-data-x-item">
                              <div className="x-data-title">
                                <p>Construction Pot</p>
                              </div>
                              <div className="x-data-middle">:</div>
                              <div className="x-data-dec">
                                <p>{sitesViewData?.site?.ConstructionPOT}</p>
                              </div>
                            </div>{" "}
                            <div className="single-data-x-item">
                              <div className="x-data-title">
                                <p>Region</p>
                              </div>
                              <div className="x-data-middle">:</div>
                              <div className="x-data-dec">
                                <p>{sitesViewData?.site?.RegionId?.name}</p>
                              </div>
                            </div>{" "}
                            <div className="single-data-x-item">
                              <div className="x-data-title">
                                <p>Area</p>
                              </div>
                              <div className="x-data-middle">:</div>
                              <div className="x-data-dec">
                                <p>{sitesViewData?.site?.AreaId?.name}</p>
                              </div>
                            </div>{" "}
                            <div className="single-data-x-item">
                              <div className="x-data-title">
                                <p>Territory</p>
                              </div>
                              <div className="x-data-middle">:</div>
                              <div className="x-data-dec">
                                <p>{sitesViewData?.site?.TerritoryId?.name}</p>
                              </div>
                            </div>{" "}
                            <div className="single-data-x-item">
                              <div className="x-data-title">
                                <p>Route</p>
                              </div>
                              <div className="x-data-middle">:</div>
                              <div className="x-data-dec">
                                <p>{sitesViewData?.site?.RouteId?.name}</p>
                              </div>
                            </div>{" "}
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Container>
                </Modal>
              )}
            </div>
            <Row>
              <CardComponent className="user_table_card">
                <TableHeader
                  // handleSearchFilter={() => {}}
                  handleSelectFilter={e => {
                    setDataRange(e.target.value)
                  }}
                  data={[100, 200, 500, 1000]}
                  handleSelectFilter2={() => {}}
                  data2={[]}
                  // title="Total Data : {sitesLength?.length}"
                  selectValue={dataRange}
                  handleExcel={handleExcel}
                  isLoading={downloadExcel}
                  headerSearchOption="yes"
                />
                <CustomTable
                  paginationClass="paginationContainer text-right"
                  data={managingOrgSitesData}
                  tableHead={tableHead}
                  setCurrentPage={setCurrentPage}
                  pageNo={totalPageNumber}
                  isPagination
                >
                  {orgSitesLoading ? (
                    <NoTableData
                      colSpan={tableHead.length}
                      className="d-flex justify-content-center align-items-center"
                      style={{ minHeight: "300px", width: `100%` }}
                    >
                      <p className="text-center">
                        <i className="bx bx-loader bx-spin"></i> Loading...
                      </p>
                    </NoTableData>
                  ) : managingOrgSitesData?.length ? (
                    managingOrgSitesData?.map((data, idx) => (
                      <tr key={idx}>
                        <th scope="row">{idx + 1}</th>
                        <td>{data?.site?.VisitTo}</td>
                        <td>{data?.site?.name}</td>
                        <td>{data?.site?.InformationAvailability}</td>
                        <td>{data?.site?.SubCategoryId?.categoryId?.name}</td>
                        <td>{data?.site?.SubCategoryId?.name}</td>
                        <td>
                          {moment(data?.site?.VisitDate).format("DD-MM-YYYY")}
                        </td>
                        <td>{data?.site?.TypeOfStructureShort}</td>
                        <td>{data?.site?.PaintPot}</td>
                        <td>
                          {/* <div
                            className="btn button btn-sm jfkgn"
                            style={{ borderRadius: "10px" }}
                            onClick={() => onOpenModal(data)}
                          >
                            <i className="bx bx-show mt-1"></i>
                          </div> */}
                          <OverlayTrigger
                            placement="bottom"
                            delay={{ show: 250, hide: 400 }}
                            overlay={renderTooltipView}
                          >
                            <button
                              className="btn button btn-sm"
                              style={{ borderRadius: "10px" }}
                              onClick={() => onOpenModal(data)}
                            >
                              <i className="bx bx-show mt-1"></i>
                            </button>
                          </OverlayTrigger>
                        </td>
                        <td>
                          {/* <div
                            className="btn button btn-sm jfkgn"
                            style={{ borderRadius: "10px" }}
                            onClick={e => handleDelete(e, data)}
                          >
                            <i className="bx bx-trash mt-1"></i>
                          </div> */}
                          <OverlayTrigger
                            placement="bottom"
                            delay={{ show: 250, hide: 400 }}
                            overlay={renderTooltipDelete}
                          >
                            <button
                              className="btn btn-outline-primary btn-sm"
                              style={{ borderRadius: "10px" }}
                              onClick={e => handleDelete(e, data)}
                            >
                              <i className="bx bx-trash mt-1"></i>
                            </button>
                          </OverlayTrigger>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <NoTableData
                      colSpan={tableHead.length}
                      className="d-flex justify-content-center align-items-center"
                      style={{ minHeight: "300px", width: `100%` }}
                    >
                      <span>Currently you have no Sites.</span>
                    </NoTableData>
                  )}
                </CustomTable>
                {/* table end */}
              </CardComponent>
            </Row>
          </CardComponent>
        </Row>
      </InnerLayer>
    </React.Fragment>
  )
}

Projects.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
}

export default withRouter(Projects)
