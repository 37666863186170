import Button from "components/Common/Button"
import CustomInput from "components/Common/CustomInput"
import TableHeader from "components/Common/TableHeader"
import CardComponent from "components/Layout/CardComponent"
import CustomTable from "components/Layout/CustomTable"
import { default as InnerLayer } from "components/Layout/InnerLayer"
import NoTableData from "components/Layout/NoTableData"
import { addCategorySchema } from "components/Schemas/AddCategory.schema"
import { useFormik } from "formik"
import moment from "moment"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { Container, Form } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import Modal from "react-responsive-modal"
import "react-responsive-modal/styles.css"
import { useHistory, withRouter } from "react-router-dom"
import Select from "react-select"
import { Col, Row } from "reactstrap"
import { users } from "utils/fakeUser"
import {
  getRoutePlanValue,
  getUserValue,
  storeUserData,
} from "../../store/actions"
import { getRouteValue } from "../../store/GeoInformation/action"
import { addNewRoutePlan, updateRoutePlan } from "../../store/RoutePlan/action"
const tableHead = [
  "No.",
  "User Name",
  "User Role",
  "Email",
  "Phone No.",
  "Action",
  // "View Details",
  // "View Details",
  // "Action",
]
const initialValues = {
  name: "",
}

const Users = () => {
  let { userData, loading, routeData, authtoken, routePlanData } = useSelector(
    state => ({
      loading: state.UserReducer.isLoading,
      userData: state.UserReducer.userData,
      routeData: state.GeoInformationReducer.routeData,
      routePlanData: state.RoutePlanReducer.routePlanData,
      authtoken: state.Login.token,
    })
  )

  const [listUser, setListUser] = useState(users)
  const [userRole, setUserRole] = useState("LA")
  const [currentPage, setCurrentPage] = useState(1)
  const [dataRange, setDataRange] = useState(10)
  let [routePlan, setRoutePlan] = useState({})
  const [userId, setUserId] = useState("")
  let [getUserId, setGetUserId] = useState("")
  let [updateFromDate, setUpateFromDate] = useState({})
  let [days, setDays] = useState(0)
  // let [updateRouteId, setUpdateRouteId] = useState({
  //   obj : [lable : ]
  // })
  let dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    dispatch(getUserValue(authtoken, userRole, currentPage, dataRange))
  }, [currentPage, dataRange, userRole])
  useEffect(() => {
    dispatch(getRouteValue(authtoken, 0, 0))
  }, [])

  let totalPageNumber = Math.ceil(userData?.users?.length / dataRange)

  const handleEditUser = data => {
    dispatch(storeUserData("singleUser", data))
    history.push("/edit-user/" + data._id)
  }
  const viewCustomer = index => {
    setListUser(
      listUser?.map((data, i) => {
        if (i === index) {
          data.toggle = !data.toggle
        } else {
          data.toggle = false
        }
        return data
      })
    )
  }

  const handleRoute = data => {
    history.push(`/set-user-route`, { data })
  }
  const breadcrumbItems = [
    {
      title: "Route Plan",
      link: "#",
    },
    {
      title: "User List",
      link: "#",
    },
  ]
  //modal
  const [open, setOpen] = useState(false)
  const [modalSelect, setModalSelect] = useState(true)

  const onOpenAddModal = data => {
    //dispatch(getSitesViewData(authToken, data._id))
    setModalSelect(true)
    setOpen(true)
  }
  const onOpenEditModal = data => {
    // dispatch(getRoutePlanValue())
    setGetUserId(data._id)
    setModalSelect(false)
    setOpen(true)
  }
  const onCloseModal = () => setOpen(false)
  //formik
  const onSubmit = values => {
    const data = { ...values, arr }
    // dispatch(addNewRoutePlan(obj, history, authtoken))
    let obj = {}
    // obj.name = values.name
    // dispatch(addNewCategory(obj, history, authtoken))
  }
  const RouteAdd = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: addCategorySchema,
    onSubmit,
  })
  //date
  const [fromDate, setFromDate] = useState(
    moment(new Date(Date.now())).format("YYYY-MM-DD")
  )
  const [toDate, setToDate] = useState("")
  //const [days, setDays] = useState("")
  const onFromDate = e => {
    setFromDate(e.target.value)
  }
  const onToDate = e => {
    setToDate(e.target.value)
    let now = moment(fromDate)
    let end = moment(e.target.value)
    setDays(end.diff(now, "days"))
    // call here
    dispatch(getRoutePlanValue(authtoken, getUserId, fromDate, e.target.value))
  }

  const arr = []
  const calculateDate = dt => {
    let newDt = moment(fromDate)
    let dta = moment(
      moment(new Date(newDt), "DD-MM-YYYY").add(dt, "days")
    ).format("YYYY-MM-DD")
    arr.push(dta)
    return dta
  }

  const handleSetRoute = (e, name, routeId, serial) => {
    let RouteDate = calculateDate(serial)
    setRoutePlan({
      ...routePlan,
      [serial]: { userId: userId, routeDate: RouteDate, id: routeId },
    })
  }

  const handleSubmit = e => {
    e.preventDefault()
    let arr = []
    Object.keys(routePlan).map((v, i) => {
      arr.push({
        user: routePlan[v].userId,
        route: routePlan[v].id,
        date: routePlan[v].routeDate,
      })
    })
    setOpen(false)
    dispatch(addNewRoutePlan(arr, history, authtoken))
  }
  const handleUpdate = e => {
    e.preventDefault()

    if (updateFromDate) {
      Object.keys(updateFromDate).map((v, i) => {
        routePlanData[v] = {
          ...routePlanData[v],
          date: updateFromDate?.[v] ? updateFromDate?.[v]?.["setDt"] : date,
        }
      })
    }
    let arr = []
    routePlanData?.map((v, i) => {
      arr.push({
        _id: v._id,
        user: v.user._id,
        route: v.route._id,
        date: v.date,
      })
    })
    setOpen(false)
    dispatch(updateRoutePlan(arr, history, authtoken))
  }

  const handleUpdateRoute = (index, name, _id, date, data) => {
    routePlanData[index] = {
      ...routePlanData[index],
      date: updateFromDate?.[index] ? updateFromDate?.[index]?.["setDt"] : date,
      route: {
        _id: _id,
        name: name,
      },
    }
  }
  return (
    <React.Fragment>
      <InnerLayer
        isMultiple
        title={"Route Plan"}
        breadcrumbItems={breadcrumbItems}
      >
        {/* first Row */}

        <Row>
          <CardComponent className="user_table_card">
            <TableHeader
              handleSearchFilter={() => {}}
              handleSelectFilter={e => {
                setDataRange(e.target.value)
              }}
              data={[10, 20, 50, 100]}
              selectValue={dataRange}
              title="Route Plan"
              headerSearchOption="yes"
            />
            {/* table */}
            <CustomTable
              paginationClass="paginationContainer text-right"
              data={userData?.users}
              tableHead={tableHead}
              pageNo={totalPageNumber}
              setCurrentPage={setCurrentPage}
              isPagination
            >
              {userData?.length > 0 ? (
                userData?.users.map((data, idx) => (
                  <tr key={idx}>
                    <th scope="row">{idx + 1}</th>
                    <td>{data.name}</td>
                    <td>{data.role}</td>
                    <td>{data.email}</td>
                    <td>{data.mobileNo}</td>
                    <td>
                      <div className="d-flex justify-content-start align-items-center">
                        <Button
                          className="btn button btn-sm me-2"
                          style={{ borderRadius: "10px" }}
                          onClick={() => {
                            setUserId(data._id)
                            onOpenAddModal(data)
                            setDays(0)
                          }}
                        >
                          <i
                            className="bx bx-plus
"
                          ></i>
                        </Button>
                        <Button
                          className="btn button btn-sm"
                          style={{ borderRadius: "10px" }}
                          onClick={() => onOpenEditModal(data)}
                        >
                          <i className="bx bx-edit"></i>
                        </Button>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <NoTableData
                  colSpan="9"
                  className="d-flex justify-content-center align-items-center"
                  style={{ minHeight: "300px", width: `100%` }}
                >
                  <span>
                    No data here
                    {/* <Link
                        to="/add-user"
                        style={{
                          color: "blue",
                          textDecoration: "underline",
                        }}
                      >
                        create User
                      </Link> */}
                  </span>
                </NoTableData>
              )}
            </CustomTable>
            {/* table end */}
          </CardComponent>
          {/* component end */}
        </Row>
        <div>
          {/* <button onClick={onOpenModal}>Open modal</button> */}
          {loading ? (
            <></>
          ) : modalSelect ? (
            <Modal
              open={open}
              onClose={onCloseModal}
              center
              classNames={{
                overlay: "customOverlay",
                modal: "modal-main-wrap-fgf",
              }}
            >
              <Container>
                <div className="popup-main-area-data-site-information">
                  <div className="site-info-popup-title">
                    <h3>Add Route Plan</h3>
                  </div>
                  <Row>
                    <Form
                      className="needs-validation"
                      // onSubmit={RouteAdd.handleSubmit}
                      onSubmit={handleSubmit}
                    >
                      <Container>
                        <Row>
                          <Col lg={6}>
                            <CustomInput
                              name={"fromDate"}
                              type={"date"}
                              label={"From Date"}
                              placeholder={"From Date"}
                              validationType={RouteAdd}
                              //onChange={(e) => setFromDate(e.target.value)}
                              value={fromDate}
                              onChange={e => onFromDate(e)}
                            />
                          </Col>
                          <Col lg={6}>
                            <CustomInput
                              name={"toDate"}
                              type={"date"}
                              label={"To Date"}
                              placeholder={"To Date"}
                              validationType={RouteAdd}
                              onChange={e => onToDate(e)}
                            />
                          </Col>

                          {days ? (
                            [...Array(parseInt(days)).keys()].map((v, i) => (
                              <>
                                <Col lg={6}>
                                  <Form.Group controlId="date">
                                    <Form.Label>Select Date</Form.Label>
                                    <Form.Control
                                      type="date"
                                      name="date"
                                      placeholder="Date"
                                      value={calculateDate(v)}
                                    />
                                  </Form.Group>
                                </Col>
                                <Col lg={6}>
                                  <label>Select route</label>
                                  <Select
                                    name="mainFilter"
                                    classNamePrefix="select2-selection"
                                    cacheOptions
                                    getOptionLabel={e => e.name}
                                    getOptionValue={e => e._id}
                                    //defaultValue={mainFilter?.filter(data => data.value === status)}
                                    options={routeData?.route}
                                    validationType={RouteAdd}
                                    onChange={e =>
                                      handleSetRoute(e, e.name, e._id, v)
                                    }
                                  />
                                </Col>
                              </>
                            ))
                          ) : (
                            <></>
                          )}
                        </Row>

                        <div className="d-flex justify-content-center">
                          <input
                            type="submit"
                            value={"Submit"}
                            className="btn button mt-4"
                          />
                        </div>
                      </Container>
                    </Form>
                    {/* <Col lg={6}>
                      <div>ADD</div>
                    </Col> */}
                  </Row>
                </div>
              </Container>
            </Modal>
          ) : (
            <Modal
              open={open}
              onClose={onCloseModal}
              center
              classNames={{
                overlay: "customOverlay",
                modal: "modal-main-wrap-fgf",
              }}
            >
              <Container>
                <div className="popup-main-area-data-site-information">
                  <div className="site-info-popup-title">
                    <h3>Edit Route Plan</h3>
                  </div>
                  <Row>
                    <Form
                      className="needs-validation"
                      // onSubmit={RouteAdd.handleSubmit}
                      onSubmit={handleUpdate}
                    >
                      <Container>
                        <Row>
                          <Col lg={6}>
                            <CustomInput
                              name={"fromDate"}
                              type={"date"}
                              label={"From Date"}
                              placeholder={"From Date"}
                              validationType={RouteAdd}
                              //onChange={(e) => setFromDate(e.target.value)}
                              value={fromDate}
                              onChange={e => onFromDate(e)}
                            />
                          </Col>
                          <Col lg={6}>
                            <CustomInput
                              name={"toDate"}
                              type={"date"}
                              label={"To Date"}
                              placeholder={"To Date"}
                              validationType={RouteAdd}
                              onChange={e => onToDate(e)}
                            />
                          </Col>
                          {routePlanData?.length > 0 ? (
                            routePlanData?.map((data, idx) => (
                              <>
                                <Col lg={6}>
                                  <Form.Group controlId="date">
                                    <Form.Label>Select Date</Form.Label>
                                    <Form.Control
                                      type="date"
                                      name="date"
                                      placeholder="Date"
                                      defaultValue={moment(
                                        new Date(data.date)
                                      ).format("YYYY-MM-DD")}
                                      onChange={e =>
                                        setUpateFromDate({
                                          ...updateFromDate,
                                          [idx]: { setDt: e.target.value },
                                        })
                                      }
                                      // value={moment(new Date(data.date)).format(
                                      //   "YYYY-MM-DD"
                                      // )}
                                    />
                                  </Form.Group>
                                </Col>
                                <Col lg={6}>
                                  <label>Select route</label>
                                  <Select
                                    name="mainFilter"
                                    classNamePrefix="select2-selection"
                                    cacheOptions
                                    getOptionLabel={e => e.name}
                                    getOptionValue={e => e._id}
                                    defaultValue={routeData?.route?.filter(
                                      dta => dta.name === data?.route?.name
                                    )}
                                    options={routeData?.route}
                                    onChange={e =>
                                      handleUpdateRoute(
                                        idx,
                                        e.name,
                                        e._id,
                                        data.date,
                                        data
                                      )
                                    }
                                  />
                                </Col>
                              </>
                            ))
                          ) : (
                            <></>
                          )}
                        </Row>

                        <div className="d-flex justify-content-center">
                          <input
                            type="submit"
                            value={"Update"}
                            className="btn button mt-4"
                          />
                        </div>
                      </Container>
                    </Form>
                  </Row>
                </div>
              </Container>
            </Modal>
          )}
        </div>
      </InnerLayer>
    </React.Fragment>
  )
}

Users.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
}

export default withRouter(Users)
