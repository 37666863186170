/* eslint-disable react/react-in-jsx-scope */
export const users = [
  {
    id: "1",
    name: "Cameron Williamson",
    role: "SR",
    email: "Cameron Williamson@gmal.com",
    status: "active",
    toggle: false,
  },
  {
    id: "2",
    name: "Jane Cooper",
    role: "DP",
    email: "Jane Cooper@gmal.com",
    status: "active",
    toggle: false,
  },
  {
    id: "3",
    name: "Marvin McKinney",
    role: "SR",
    email: "Cameron Williamson@gmal.com",
    status: "inactive",
    toggle: false,
  },
]

export const reports = [
  {
    title: "You have total",
    iconClass: "bx-copy-alt",
    value: `0 User`,
  },
  { title: "Top user as SR", iconClass: "bx-archive-in", value: "SR name" },
  {
    title: "Top user as DP",
    iconClass: "bx-archive-in",
    value: "DP name",
  },
]

export const OffSymbol = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2,
      }}
    ></div>
  )
}
export const OnSymbol = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2,
      }}
    ></div>
  )
}
