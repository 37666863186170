import { getData } from "helpers/backend_helper"
import { call, put, takeEvery } from "redux-saga/effects"
//Include Both Helper File with needed methods
import { api } from "../../helpers/fakebackend_helper"
// Calender Redux States
import {
  getConstructionStageFail,
  getConstructionStageSuccess,
  getDashboardIndexFail,
  getDashboardIndexSuccess,
  getDashboardOverviewFail,
  getDashboardOverviewSuccess,
  getPaintAreaFail,
  getPaintAreaSuccess,
  getPotValueFail,
  getPotValueSuccess,
  getStructureTypeFail,
  getStructureTypeSuccess,
} from "./action"
import {
  GET_CONSTRUCTION_STAGE,
  GET_DASHBOARD_INDEX,
  GET_DASHBOARD_OVERVIEW,
  GET_PAINT_AREA,
  GET_POT_VALUE,
  GET_STRUCTURE_TYPE,
} from "./actionTypes"

function* fetchPotValue({ payload: { authtoken, query , role} }) {
  try {
    // console.log(role)
    const newQuery = `?${query}`
    const url = role === 'ORG' ? `/org/organizationDashboard/dashboardbannerone${newQuery ? newQuery : ""}`  : `/dashboard/dashboardbannerone${newQuery ? newQuery : ""}`
    const response = yield call(getData, url, authtoken)

    yield put(getPotValueSuccess(response))
  } catch (error) {
    yield put(getPotValueFail(error))
  }
}

function* fetchDashboardOverView({ payload: { authtoken, query, role } }) {
  try {
    // console.log('fetPot',query);
    const newQuery = `?${query}`
    const url = role === 'ORG' ? `/org/organizationDashboard/topbanner${newQuery ? newQuery : ""}`  : `/dashboard/topbanner${newQuery ? newQuery : ""}`
    const response = yield call(getData, url, authtoken)
    yield put(getDashboardOverviewSuccess(response))
  } catch (error) {
    yield put(getDashboardOverviewFail(error))
  }
}
function* fetchDashboardIndex({ payload: { authtoken, query ,role} }) {
  try {
    const newQuery = `?${query}`
    const url = role === 'ORG' ? `/org/organizationDashboard/dashboardbannertwo${newQuery ? newQuery : ""}`  : `/dashboard/dashboardbannertwo${newQuery ? newQuery : ""}`

    const response = yield call(getData, url, authtoken)
    yield put(getDashboardIndexSuccess(response))
  } catch (error) {
    yield put(getDashboardIndexFail(error))
  }
}
function* fetchPaintArea({ payload: { authtoken, query, role } }) {
  // console.log('fetchPaintArea',query);
  try {
    const newQuery = `?${query}`
    const url = role === 'ORG' ? `/org/organizationDashboard/paintarea${newQuery ? newQuery : ""}`  : `/dashboard/paintarea${newQuery ? newQuery : ""}`
    const response = yield call(getData, url, authtoken)
    yield put(getPaintAreaSuccess(response))
  } catch (error) {
    yield put(getPaintAreaFail(error))
  }
}
function* fetchStructureType({ payload: { authtoken, query, role } }) {
  const newQuery = `?${query}`
  const url = role === 'ORG' ? `/org/organizationDashboard/structuretype${newQuery ? newQuery : ""}`  : `/dashboard/structuretype${newQuery ? newQuery : ""}`
  const response = yield call(getData, url, authtoken)
  try {
    yield put(getStructureTypeSuccess(response))
  } catch (error) {
    yield put(getStructureTypeFail(error))
  }
}
function* fetchConstructionStage({ payload: { authtoken, query, role } }) {
  const newQuery = `?${query}`
  const url = role === 'ORG' ? `/org/organizationDashboard/constructurestage${newQuery ? newQuery : ""}`  : `/dashboard/constructurestage${newQuery ? newQuery : ""}`
  const response = yield call(getData, url, authtoken)
  try {
    yield put(getConstructionStageSuccess(response))
  } catch (error) {
    yield put(getConstructionStageFail(error))
  }
}

function* AdminSaga() {
  yield takeEvery(GET_POT_VALUE, fetchPotValue)
  yield takeEvery(GET_DASHBOARD_OVERVIEW, fetchDashboardOverView)
  yield takeEvery(GET_DASHBOARD_INDEX, fetchDashboardIndex)
  yield takeEvery(GET_PAINT_AREA, fetchPaintArea)
  yield takeEvery(GET_STRUCTURE_TYPE, fetchStructureType)
  yield takeEvery(GET_CONSTRUCTION_STAGE, fetchConstructionStage)
}

export default AdminSaga
