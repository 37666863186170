import { Paper, Tab, Tabs } from "@material-ui/core"
import CustomModal from "components/Layout/CustomModal"
import Overview from "pages/Attendence/overview"
import PropTypes from "prop-types"
import React, { useState } from "react"
import image from "../../assets/images/users/avatar-1.jpg"
import AttendanceList from "./AttendanceList"

const AttendanceModal = ({ open, toggle, user }) => {
  const [value, setValue] = useState(0)

  const handleChangeTab = (event, newValue) => {
    setValue(newValue)
  }

  const getTabContent = step => {
    switch (step) {
      case 0:
        return <AttendanceList />
      case 1:
        return <Overview />
      default:
        return "No Data"
    }
  }

  return (
    <React.Fragment>
      <CustomModal open={open} toggle={toggle} size={"lg"}>
        <div className="attendanceModal">
          <div className="attendanceModal__profile">
            <div className="img">
              <img src={image} alt="profile" />
            </div>
            <div className="attendanceModal__des">
              <h5 className="font-size-15 mb-1 text-dark">{user.name}</h5>
              <p className="text-muted">{user?.role}</p>
            </div>
          </div>

          {/* tab */}
          <div className="attendanceTab mt-3">
            <Paper
              className={"shadow-none d-flex justify-content-center w-100"}
            >
              <Tabs
                value={value}
                onChange={handleChangeTab}
                indicatorColor="primary"
                className="tabHeader"
              >
                <Tab label="Attendance" className="text_violet" />
                <Tab label="Attendance History" className="text_violet" />
              </Tabs>
            </Paper>
            {getTabContent(value)}
          </div>
        </div>
      </CustomModal>
    </React.Fragment>
  )
}

AttendanceModal.propTypes = {
  open: PropTypes.bool,
  toggle: PropTypes.func,
  user: PropTypes.object,
}

export default AttendanceModal
