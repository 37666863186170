import { getData } from "helpers/backend_helper"
import { toast } from "react-toastify"
import { call, put, takeEvery } from "redux-saga/effects"
import {
  axiosGetArea,
  axiosGetAreaByRegion,
  axiosGetRegion,
  axiosGetRoute,
  axiosGetTerritory,
  axiosGetTerritoryByArea,
  postAreaData,
  postRegiondata,
  postRouteData,
  postTerritoryData,
  updateAreaData,
  updateRegionData,
  updateRouteData,
  updateTerritoryData,
} from "../../helpers/api_helper"
import {
  addAreaFail,
  addRegionFail,
  addRouteFail,
  addTerritoryFail,
  getAreaByRegionValueSuccess,
  getAreaValueSuccess,
  getRegionFail,
  getRegionSuccess,
  getRegionValueSuccess,
  getRouteValueSuccess,
  getTerritoryByAreaValueSuccess,
  getTerritoryValueSuccess,
  updateAreaFail,
  updateRegionFail,
  updateRouteFail,
  updateTerritoryFail,
} from "./action"
// Calender Redux States
import {
  ADD_NEW_AREA,
  ADD_NEW_REGION,
  ADD_NEW_ROUTE,
  ADD_NEW_TERRITORY,
  GET_AREA_BY_REGION_VALUE,
  GET_AREA_VALUE,
  GET_REGIONS,
  GET_REGION_VALUE,
  GET_ROUTE_VALUE,
  GET_TERRITORY_BY_AREA_VALUE,
  GET_TERRITORY_VALUE,
  UPDATE_AREA,
  UPDATE_REGION,
  UPDATE_ROUTE,
  UPDATE_TERRITORY,
} from "./actionTypes"

//REGION
const asyncGetRegion = async (authtoken, currentPage, pageRange) => {
  try {
    console.log("hello call here")
    const response = await axiosGetRegion(authtoken, currentPage, pageRange)
    return response.data
  } catch (error) {
    return error.response.data
  }
}
function* workerGetRegion({ payload: { authtoken, currentPage, pageRange } }) {
  try {
    const response = yield call(
      asyncGetRegion,
      authtoken,
      currentPage,
      pageRange
    )
    yield put(getRegionValueSuccess(response))
    console.log("hello response 118", response)
  } catch (error) {}
}

//async
const asyncPostRegionData = async (data, authtoken) => {
  try {
    const response = await postRegiondata(data, authtoken)
    return response.data
  } catch (error) {
    return error.response.data
  }
}

function* onAddRegion({ payload: { data, history, authtoken } }) {
  try {
    const response = yield call(asyncPostRegionData, data, authtoken)
    console.log("response here", response)
    if (response.status == "success") {
      toast("🦄 Region added successfully!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
      history.push("geo/region")
    } else {
      toast.error(response.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
  } catch (error) {
    console.log("error", error)
    yield put(addRegionFail(error.message))
    toast.error(error.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    })
  }
}

function* onUpdateRegion({ payload: { id, data, token, history } }) {
  try {
    const response = yield call(updateRegionData, id, data, token)
    console.log("response", response)
    console.log("history", history)
    // yield put(updateTerritorySuccess(response))
    toast("🦄 Region updated successfully!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    })
    if (response.status === 200) {
      history.push("/geo/region")
    }
  } catch (error) {
    console.log(error.response)
    yield put(updateRegionFail(error))
  }
}
//AREA

const asyncGetArea = async (authtoken, dataRange, currentPage) => {
  try {
    const response = await axiosGetArea(authtoken, dataRange, currentPage)
    return response.data
  } catch (error) {
    return error.response.data
  }
}
function* workerGetArea({ payload: { authtoken, dataRange, currentPage } }) {
  try {
    const response = yield call(asyncGetArea, authtoken, dataRange, currentPage)
    yield put(getAreaValueSuccess(response))
  } catch (error) {}
}

const asyncGetAreaByRegion = async (
  authtoken,
  regionId,
  dataRange,
  currentPage
) => {
  try {
    const response = await axiosGetAreaByRegion(
      authtoken,
      regionId,
      dataRange,
      currentPage
    )
    return response.data
  } catch (error) {
    return error.response.data
  }
}
function* workerGetAreaByRegion({
  payload: { authtoken, regionId, dataRange, currentPage },
}) {
  try {
    const response = yield call(
      asyncGetAreaByRegion,
      authtoken,
      regionId,
      dataRange,
      currentPage
    )
    yield put(getAreaByRegionValueSuccess(response))
  } catch (error) {}
}

//async
const asyncPostAreaData = async (data, authtoken) => {
  try {
    const response = await postAreaData(data, authtoken)
    return response.data
  } catch (error) {
    return error.response.data
  }
}

function* onAddArea({ payload: { data, history, authtoken } }) {
  try {
    const response = yield call(asyncPostAreaData, data, authtoken)
    console.log("response here", response)
    if (response.status == "success") {
      toast("🦄 Area added successfully!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
      history.push("geo/area")
    } else {
      toast.error(response.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
  } catch (error) {
    yield put(addAreaFail(error.message))
    toast.error(error.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    })
  }
}

function* onUpdateArea({ payload: { id, data, token, history } }) {
  try {
    const response = yield call(updateAreaData, id, data, token)
    console.log("response", response)
    console.log("history", history)
    // yield put(updateTerritorySuccess(response))
    toast("🦄 Area updated successfully!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    })
    if (response.status === 200) {
      history.push("/geo/area")
    }
  } catch (error) {
    console.log(error.response)
    yield put(updateAreaFail(error))
  }
}

//TERRITORY
const asyncGetTerritory = async (authtoken, dataRange, currentPage) => {
  try {
    const response = await axiosGetTerritory(authtoken, dataRange, currentPage)
    return response.data
  } catch (error) {
    return error.response.data
  }
}
function* workerGetTerritory({
  payload: { authtoken, dataRange, currentPage },
}) {
  try {
    const response = yield call(
      asyncGetTerritory,
      authtoken,
      dataRange,
      currentPage
    )
    yield put(getTerritoryValueSuccess(response))
  } catch (error) {}
}

const asyncGetTerritoryByArea = async (
  authtoken,
  regionId,
  dataRange,
  currentPage
) => {
  try {
    const response = await axiosGetTerritoryByArea(
      authtoken,
      regionId,
      dataRange,
      currentPage
    )
    return response.data
  } catch (error) {
    return error.response.data
  }
}
function* workerGetTerritoryByArea({
  payload: { authtoken, areaId, dataRange, currentPage },
}) {
  try {
    const response = yield call(
      asyncGetTerritoryByArea,
      authtoken,
      areaId,
      dataRange,
      currentPage
    )
    yield put(getTerritoryByAreaValueSuccess(response))
  } catch (error) {}
}

//async
const asyncPostTerritoryData = async (data, authtoken) => {
  try {
    const response = await postTerritoryData(data, authtoken)
    return response.data
  } catch (error) {
    return error.response.data
  }
}

function* onAddTerritory({ payload: { data, history, authtoken } }) {
  try {
    const response = yield call(asyncPostTerritoryData, data, authtoken)

    if (response.status == "success") {
      toast("🦄 Territory added successfully!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
      history.push("geo/territory")
    } else {
      toast.error(response.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
  } catch (error) {
    yield put(addTerritoryFail(error.message))
    toast.error(error.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    })
  }
}

function* onUpdateTerritory({ payload: { id, data, token, history } }) {
  try {
    const response = yield call(updateTerritoryData, id, data, token)
    console.log("response", response)
    console.log("history", history)
    // yield put(updateTerritorySuccess(response))
    toast("🦄 Territory updated successfully!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    })
    if (response.status === 200) {
      console.log("push push push")
      history.push("/geo/territory")
    }
  } catch (error) {
    console.log(error.response)
    yield put(updateTerritoryFail(error))
  }
}

//ROUTE
const asyncGetRoute = async (authtoken, dataRange, currentPage) => {
  try {
    const response = await axiosGetRoute(authtoken, dataRange, currentPage)
    return response.data
  } catch (error) {
    return error.response.data
  }
}
function* workerGetRoute({ payload: { authtoken, dataRange, currentPage } }) {
  try {
    const response = yield call(
      asyncGetRoute,
      authtoken,
      dataRange,
      currentPage
    )
    yield put(getRouteValueSuccess(response))
  } catch (error) {}
}

//async
const asyncPostRouteData = async (data, authtoken) => {
  try {
    const response = await postRouteData(data, authtoken)
    return response.data
  } catch (error) {
    return error.response.data
  }
}

function* onAddRoute({ payload: { data, history, authtoken } }) {
  try {
    const response = yield call(asyncPostRouteData, data, authtoken)

    if (response.status == "success") {
      toast("🦄 Route added successfully!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
      history.push("geo/route")
    } else {
      toast.error(response.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
  } catch (error) {
    yield put(addRouteFail(error.message))
    toast.error(error.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    })
  }
}

function* onUpdateRoute({ payload: { id, data, token, history } }) {
  try {
    const response = yield call(updateRouteData, id, data, token)
    console.log("response", response)
    console.log("history", history)
    // yield put(updateTerritorySuccess(response))
    toast("🦄 Route updated successfully!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    })
    if (response.status === 200) {
      history.push("/geo/route")
    }
  } catch (error) {
    console.log(error.response)
    yield put(updateRouteFail(error))
  }
}

function* fetchRegion({ payload: { authtoken } }) {
  try {
    const url = "/region/0/0"
    const response = yield call(getData, url, authtoken)
    yield put(getRegionSuccess(response))
  } catch (error) {
    const message = error?.response?.data?.message || "Invalid Operation"
    yield put(getRegionFail(message))
  }
}

function* GeoInformationSaga() {
  yield takeEvery(GET_REGION_VALUE, workerGetRegion)
  yield takeEvery(ADD_NEW_REGION, onAddRegion)
  yield takeEvery(GET_AREA_VALUE, workerGetArea)
  yield takeEvery(GET_REGIONS, fetchRegion)
  yield takeEvery(ADD_NEW_AREA, onAddArea)
  yield takeEvery(GET_ROUTE_VALUE, workerGetRoute)
  yield takeEvery(ADD_NEW_ROUTE, onAddRoute)
  yield takeEvery(GET_TERRITORY_VALUE, workerGetTerritory)
  yield takeEvery(GET_AREA_BY_REGION_VALUE, workerGetAreaByRegion)
  yield takeEvery(GET_TERRITORY_BY_AREA_VALUE, workerGetTerritoryByArea)
  yield takeEvery(ADD_NEW_TERRITORY, onAddTerritory)
  yield takeEvery(UPDATE_REGION, onUpdateRegion)
  yield takeEvery(UPDATE_AREA, onUpdateArea)
  yield takeEvery(UPDATE_TERRITORY, onUpdateTerritory)
  yield takeEvery(UPDATE_ROUTE, onUpdateRoute)
}

export default GeoInformationSaga
