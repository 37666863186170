import CustomInput from "components/Common/CustomInput"
import CardComponent from "components/Layout/CardComponent"
import InnerLayer from "components/Layout/InnerLayer"
import { createPJPSchema } from "components/Schemas/CreatePJP.schema"
import { useFormik } from "formik"
import PropTypes from "prop-types"
import React from "react"
import { withRouter } from "react-router-dom"
import { Col, Form, Row } from "reactstrap"
const breadcrumbItems = [
  {
    title: "Dashboard",
    link: "/dashboard",
  },
  {
    title: "Set PJP",
    link: "/set-pjp",
  },
  {
    title: "Create PJP",
    link: "#",
  },
]

const role = ["SR", "DP"]
const office = [
  "27 no road, Dhanmond",
  "27 no road, Badda",
  "27 no road, Banani",
]

const initialValues = {
  sr: "",
  route: "",
  month: "",
  day: "",
}

const AddPJP = () => {
  const onSubmit = values => {
    console.log("values", values)
  }

  const createPJP = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: createPJPSchema,
    onSubmit,
  })

  return (
    <React.Fragment>
      <InnerLayer
        isMultiple
        title={"Create PJP"}
        breadcrumbItems={breadcrumbItems}
      >
        <Row className="mt-4">
          <Col md="8" className="mx-auto">
            <CardComponent className="">
              <Form
                className="needs-validation"
                onSubmit={createPJP.handleSubmit}
              >
                <Row>
                  <CustomInput
                    name={"sr"}
                    type={"select"}
                    label={"Select SR"}
                    validationType={createPJP}
                  >
                    <option defaultValue>Select SR...</option>
                    {office.map((data, idx) => (
                      <option key={idx} value={data}>
                        {data}
                      </option>
                    ))}
                  </CustomInput>

                  <CustomInput
                    name={"route"}
                    type={"select"}
                    label={"Select route"}
                    validationType={createPJP}
                  >
                    <option defaultValue>Select route...</option>
                    {role.map((data, idx) => (
                      <option key={idx} value={data}>
                        {data}
                      </option>
                    ))}
                  </CustomInput>

                  <CustomInput
                    name={"month"}
                    type={"select"}
                    label={"Select month"}
                    // multiple
                    validationType={createPJP}
                  >
                    <option defaultValue>Select month...</option>
                    {role.map((data, idx) => (
                      <option key={idx} value={data}>
                        {data}
                      </option>
                    ))}
                  </CustomInput>

                  <CustomInput
                    name={"day"}
                    type={"select"}
                    label={"Select day"}
                    validationType={createPJP}
                  >
                    <option defaultValue>Select day...</option>
                    {role.map((data, idx) => (
                      <option key={idx} value={data}>
                        {data}
                      </option>
                    ))}
                  </CustomInput>
                </Row>

                <div className="d-flex justify-content-end">
                  <input type="submit" value={"Done"} className="btn button" />
                </div>
              </Form>
            </CardComponent>
          </Col>
        </Row>
      </InnerLayer>
    </React.Fragment>
  )
}

AddPJP.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
}

export default withRouter(AddPJP)
