import AddCard from "components/Atoms/AddCard"
import TableHeader from "components/Common/TableHeader"
import CardComponent from "components/Layout/CardComponent"
import CustomTable from "components/Layout/CustomTable"
import { default as InnerLayer } from "components/Layout/InnerLayer"
import NoTableData from "components/Layout/NoTableData"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { withRouter } from "react-router-dom"
import { Badge, Card, CardBody, Col, Row } from "reactstrap"
import { users } from "utils/fakeUser"
import { toCapitalize } from "../../helpers/Custom/Capitalize"
import { getUserValue, storeUserData } from "../../store/Users/action"
const tableHead = [
  "No.",
  "User Name",
  "User Role",
  "Email",
  "Phone No.",
  "Status",
  // "View Details",
  // "View Details",
  "Action",
]

const Users = ({ history }) => {
  const [listUser, setListUser] = useState(users)
  const [userRole, setUserRole] = useState("All")
  const [currentPage, setCurrentPage] = useState(1)
  const [dataRange, setDataRange] = useState(10)
  let dispatch = useDispatch()
  const { userData, loading, authtoken } = useSelector(state => ({
    loading: state.UserReducer.isLoading,
    userData: state.UserReducer.userData,
    authtoken: state.Login.token,
  }))
  useEffect(() => {
    dispatch(getUserValue(authtoken, userRole, currentPage, dataRange))
  }, [currentPage, dataRange, userRole])
  let totalPageNumber = Math.ceil(userData?.users?.length / dataRange)

  const handleEditUser = data => {
    dispatch(storeUserData("singleUser", data))
    history.push("/edit-user/" + data._id)
  }
  const viewCustomer = index => {
    setListUser(
      listUser?.map((data, i) => {
        if (i === index) {
          data.toggle = !data.toggle
        } else {
          data.toggle = false
        }
        return data
      })
    )
  }

  const handleRoute = data => {
    history.push(`/set-user-route`, { data })
  }

  const breadcrumbItems = [
    {
      title: "Dashboard",
      link: "/dashboard",
    },
    {
      title: "Users",
      link: "/users",
    },
  ]

  return (
    <React.Fragment>
      <InnerLayer
        isMultiple
        title={"User List"}
        breadcrumbItems={breadcrumbItems}
      >
        {/* first Row */}
        <Row>
          <Col md="3">
            <Card className="mini-stats-wid">
              <CardBody>
                <div className="d-flex">
                  <div className="flex-grow-1">
                    <p className="text-muted fw-medium">Admin</p>
                    <h4 className="mb-0">{userData?.totalUser?.admin}</h4>
                  </div>
                  <div className="avatar-sm rounded-circle bg_violet align-self-center mini-stat-icon">
                    <span className="avatar-title rounded-circle bg_violet">
                      <i className={"bx bx-user font-size-24"}></i>
                    </span>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col md="3">
            <Card className="mini-stats-wid">
              <CardBody>
                <div className="d-flex">
                  <div className="flex-grow-1">
                    <p className="text-muted fw-medium">Lead Agent</p>
                    <h4 className="mb-0">{userData?.totalUser?.la}</h4>
                  </div>
                  <div className="avatar-sm rounded-circle bg_violet align-self-center mini-stat-icon">
                    <span className="avatar-title rounded-circle bg_violet">
                      <i className={"bx bx-user font-size-24"}></i>
                    </span>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col md="3">
            <Card className="mini-stats-wid">
              <CardBody>
                <div className="d-flex">
                  <div className="flex-grow-1">
                    <p className="text-muted fw-medium">Organization</p>
                    <h4 className="mb-0">{userData?.totalUser?.org}</h4>
                  </div>
                  <div className="avatar-sm rounded-circle bg_violet align-self-center mini-stat-icon">
                    <span className="avatar-title rounded-circle bg_violet">
                      <i className={"bx bx-user font-size-24"}></i>
                    </span>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col md="3">
            <AddCard
              title="Add new User"
              buttonText="Add User"
              onAddHandler={() => history.push("/add-user")}
            />
          </Col>
        </Row>

        <Row>
          <CardComponent className="user_table_card">
            <TableHeader
              handleSearchFilter={() => {}}
              handleSelectFilter={e => {
                setDataRange(e.target.value)
              }}
              data={[10, 20, 50, 100]}
              handleSelectFilter2={e => {
                setUserRole(e.target.value)
              }}
              data2={["All", "ADMIN", "LA", "ORG"]}
              selectValue={dataRange}
              title="User List"
              headerSearchOption="yes"
              isMultiple
            />
            {/* table */}
            <CustomTable
              paginationClass="paginationContainer text-right"
              data={userData?.users}
              tableHead={tableHead}
              pageNo={totalPageNumber}
              setCurrentPage={setCurrentPage}
              isPagination
            >
              {userData?.length > 0 ? (
                userData?.users.map((data, idx) => (
                  <tr key={idx}>
                    <th scope="row">{idx + 1}</th>
                    <td>{data.name}</td>
                    <td>{data.role}</td>
                    <td>{data.email}</td>
                    <td>{data.mobileNo}</td>
                    <td>
                      <Badge
                        className={"font-size-12"}
                        color={data.active ? "danger" : "success"}
                        pill
                      >
                        {data.active
                          ? toCapitalize("Inactive")
                          : toCapitalize("Active")}
                      </Badge>
                    </td>
                    <td>
                      <div className="d-flex gap-3">
                        <span
                          className="text-success pointer_event"
                          onClick={() => handleEditUser(data)}
                        >
                          <i
                            className="mdi mdi-pencil font-size-18"
                            id="edittooltip"
                          ></i>
                        </span>
                      </div>
                    </td>
                    {/* <td>
                        <Button
                          className="btn button btn-sm"
                          style={{ borderRadius: "20px" }}
                          onClick={() => handleRoute(data)}
                        >
                          Set routs
                        </Button>
                      </td>
                      <td>
                        <Switch
                          uncheckedIcon={<OffSymbol />}
                          checkedIcon={<OnSymbol />}
                          className="me-1"
                          onColor="#4b3768"
                          onChange={() => viewCustomer(idx)}
                          checked={data.toggle}
                        />
                      </td> */}
                  </tr>
                ))
              ) : (
                <NoTableData
                  colSpan="9"
                  className="d-flex justify-content-center align-items-center"
                  style={{ minHeight: "300px", width: `100%` }}
                >
                  <span>
                    No data here
                    {/* <Link
                        to="/add-user"
                        style={{
                          color: "blue",
                          textDecoration: "underline",
                        }}
                      >
                        create User
                      </Link> */}
                  </span>
                </NoTableData>
              )}
            </CustomTable>
            {/* table end */}
          </CardComponent>
          {/* component end */}
        </Row>
      </InnerLayer>
    </React.Fragment>
  )
}

Users.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
}

export default withRouter(Users)
