import {
  ADD_ROUTE_PLAN_FAIL,
  ADD_ROUTE_PLAN_SUCCESS,
  GET_ROUTE_PLAN_VALUE,
  GET_ROUTE_PLAN_VALUE_SUCCESS,
  UPDATE_ROUTE_PLAN_FAIL,
  UPDATE_ROUTE_PLAN_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
  isLoading: false,
  routePlanData: [],
  editRoutePlanInfo: [],
}

const RoutePlanReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ROUTE_PLAN_VALUE:
      return {
        ...state,
        isLoading: true,
      }
      break

    case GET_ROUTE_PLAN_VALUE_SUCCESS:
      return {
        ...state,
        routePlanData: action.payload.data,
        isLoading: false,
      }

    case ADD_ROUTE_PLAN_SUCCESS:
      return {
        ...state,
        routePlan: [...state.routePlan, action.payload],
        isLoading: false,
      }

    case ADD_ROUTE_PLAN_FAIL:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      }

    // case STORE_ROUTE_PLAN_DATA:
    //   return {
    //     ...state,
    //     editRoutePlanInfo: action.payload,
    //   }
    case UPDATE_ROUTE_PLAN_SUCCESS:
      return {
        ...state,
        routePlan: [...state.routePlan, action.payload],
        isLoading: false,
      }

    case UPDATE_ROUTE_PLAN_FAIL:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      }

    default:
      return state
  }
}

export default RoutePlanReducer
