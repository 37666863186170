import { combineReducers } from "redux"
import AdminReducer from "./AdminDashboard/reducers"
import ForgetPassword from "./auth/forgetpwd/reducer"
// Authentication
import Login from "./auth/login/reducer"
import Profile from "./auth/profile/reducer"
import Account from "./auth/register/reducer"
//Calendar
import calendar from "./calendar/reducer"
//Category
import CategoryReducer from "./Category/reducers"
//chat
import chat from "./chat/reducer"
//contacts
// import contacts from "./contacts/reducer"
//crypto
import crypto from "./crypto/reducer"
//Dasboard saas
import DashboardSaas from "./dashboard-saas/reducer"
//Dashboard
import Dashboard from "./dashboard/reducer"
//E-commerce
import ecommerce from "./e-commerce/reducer"
import GeoInformationReducer from "./GeoInformation/reducers"
//invoices
import invoices from "./invoices/reducer"
// Front
import Layout from "./layout/reducer"
//mails
import mails from "./mails/reducer"
import DashboardReducer from "./OrgDashboard/reducers"
//projects
import projects from "./projects/reducer"
//Route Plan
import RoutePlanReducer from "./RoutePlan/reducers"
//Segment
import SegmentReducer from "./Segment/reducers"
//projects
import SiteReducer from "./Site/reducers"
import TargetReducer from "./Target/reducers"
//tasks
import tasks from "./tasks/reducer"
import UserReducer from "./Users/reducers"

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  ForgetPassword,
  Profile,
  ecommerce,
  calendar,
  chat,
  mails,
  crypto,
  invoices,
  projects,
  tasks,
  // contacts,
  Dashboard,
  DashboardSaas,
  AdminReducer,
  DashboardReducer,
  GeoInformationReducer,
  CategoryReducer,
  SegmentReducer,
  UserReducer,
  RoutePlanReducer,
  TargetReducer,
  SiteReducer,
})

export default rootReducer
