import {
  ADD_NEW_ROUTE_PLAN,
  ADD_ROUTE_PLAN_FAIL,
  ADD_ROUTE_PLAN_SUCCESS,
  GET_ROUTE_PLAN_VALUE,
  GET_ROUTE_PLAN_VALUE_SUCCESS,
  UPDATE_ROUTE_PLAN,
  UPDATE_ROUTE_PLAN_FAIL,
  UPDATE_ROUTE_PLAN_SUCCESS,
} from "./actionTypes"

//ROUTE PLAN
export const getRoutePlanValue = (authtoken, userId, fromDate, toDate) => ({
  type: GET_ROUTE_PLAN_VALUE,
  payload: { authtoken, userId, fromDate, toDate },
})

export const getRoutePlanValueSuccess = data => ({
  type: GET_ROUTE_PLAN_VALUE_SUCCESS,
  payload: { data },
})

export const addNewRoutePlan = (data, history, authtoken) => ({
  type: ADD_NEW_ROUTE_PLAN,
  payload: { data, history, authtoken },
})
export const addRoutePlanSuccess = data => ({
  type: ADD_ROUTE_PLAN_SUCCESS,
  payload: data,
})

export const addRoutePlanFail = error => ({
  type: ADD_ROUTE_PLAN_FAIL,
  payload: error,
})
// export const storeRoutePlanData = (name, data) => ({
//   type: STORE_ROUTE_PLAN_DATA,
//   payload: { name, data },
// })

export const updateRoutePlan = (data, history, authtoken) => ({
  type: UPDATE_ROUTE_PLAN,
  payload: { data, history, authtoken },
})

export const updateRoutePlanSuccess = data => ({
  type: UPDATE_ROUTE_PLAN_SUCCESS,
  payload: { data },
})

export const updateRoutePlanFail = error => ({
  type: UPDATE_ROUTE_PLAN_FAIL,
  payload: error,
})
