import {
  ADD_AREA_FAIL,
  ADD_AREA_SUCCESS,
  ADD_NEW_AREA,
  ADD_NEW_REGION,
  ADD_NEW_ROUTE,
  ADD_NEW_TERRITORY,
  ADD_REGION_FAIL,
  ADD_REGION_SUCCESS,
  ADD_ROUTE_FAIL,
  ADD_ROUTE_SUCCESS,
  ADD_TERRITORY_FAIL,
  ADD_TERRITORY_SUCCESS,
  GET_AREA_BY_REGION_VALUE,
  GET_AREA_BY_REGION_VALUE_SUCCESS,
  GET_AREA_VALUE,
  GET_AREA_VALUE_SUCCESS,
  GET_REGIONS,
  GET_REGIONS_FAIL,
  GET_REGIONS_SUCCESS,
  GET_REGION_VALUE,
  GET_REGION_VALUE_SUCCESS,
  GET_ROUTE_VALUE,
  GET_ROUTE_VALUE_SUCCESS,
  GET_TERRITORY_BY_AREA_VALUE,
  GET_TERRITORY_BY_AREA_VALUE_SUCCESS,
  GET_TERRITORY_VALUE,
  GET_TERRITORY_VALUE_SUCCESS,
  STORE_AREA_DATA,
  STORE_REGION_DATA,
  STORE_ROUTE_DATA,
  STORE_TERRITORY_DATA,
  UPDATE_AREA,
  UPDATE_AREA_FAIL,
  UPDATE_AREA_SUCCESS,
  UPDATE_REGION,
  UPDATE_REGION_FAIL,
  UPDATE_REGION_SUCCESS,
  UPDATE_ROUTE,
  UPDATE_ROUTE_FAIL,
  UPDATE_ROUTE_SUCCESS,
  UPDATE_TERRITORY,
  UPDATE_TERRITORY_FAIL,
  UPDATE_TERRITORY_SUCCESS,
} from "./actionTypes"

//REGION
export const getRegionValue = (authtoken, currentPage, pageRange) => ({
  type: GET_REGION_VALUE,
  payload: { authtoken, currentPage, pageRange },
})

export const getRegionValueSuccess = data => ({
  type: GET_REGION_VALUE_SUCCESS,
  payload: { data },
})

export const addNewRegion = (data, history, authtoken) => ({
  type: ADD_NEW_REGION,
  payload: { data, history, authtoken },
})
export const addRegionSuccess = data => ({
  type: ADD_REGION_SUCCESS,
  payload: data,
})

export const addRegionFail = error => ({
  type: ADD_REGION_FAIL,
  payload: error,
})
export const storeRegionData = (name, data) => ({
  type: STORE_REGION_DATA,
  payload: { name, data },
})

export const updateRegion = (id, data, token, history) => ({
  type: UPDATE_REGION,
  payload: { data, history, token, id },
})

export const updateRegionSuccess = (id, data) => ({
  type: UPDATE_REGION_SUCCESS,
  payload: { id, data },
})

export const updateRegionFail = error => ({
  type: UPDATE_REGION_FAIL,
  payload: error,
})

//AREA
export const getAreaValue = (authtoken, dataRange, currentPage) => ({
  type: GET_AREA_VALUE,
  payload: { authtoken, dataRange, currentPage },
})

export const getAreaValueSuccess = data => ({
  type: GET_AREA_VALUE_SUCCESS,
  payload: { data },
})

export const getAreaByRegionValue = (
  authtoken,
  regionId,
  dataRange,
  currentPage
) => ({
  type: GET_AREA_BY_REGION_VALUE,
  payload: { authtoken, regionId, dataRange, currentPage },
})

export const getAreaByRegionValueSuccess = data => ({
  type: GET_AREA_BY_REGION_VALUE_SUCCESS,
  payload: { data },
})

export const addNewArea = (data, history, authtoken) => ({
  type: ADD_NEW_AREA,
  payload: { data, history, authtoken },
})
export const addAreaSuccess = data => ({
  type: ADD_AREA_SUCCESS,
  payload: data,
})

export const addAreaFail = error => ({
  type: ADD_AREA_FAIL,
  payload: error,
})
export const storeAreaData = (name, data) => ({
  type: STORE_AREA_DATA,
  payload: { name, data },
})

export const updateArea = (id, data, token, history) => ({
  type: UPDATE_AREA,
  payload: { data, history, token, id },
})

export const updateAreaSuccess = (id, data) => ({
  type: UPDATE_AREA_SUCCESS,
  payload: { id, data },
})

export const updateAreaFail = error => ({
  type: UPDATE_AREA_FAIL,
  payload: error,
})
//TERRITORY
export const getTerritoryValue = (authtoken, dataRange, currentPage) => ({
  type: GET_TERRITORY_VALUE,
  payload: { authtoken, dataRange, currentPage },
})

export const getTerritoryValueSuccess = data => ({
  type: GET_TERRITORY_VALUE_SUCCESS,
  payload: { data },
})

export const getTerritoryByAreaValue = (
  authtoken,
  areaId,
  dataRange,
  currentPage
) => ({
  type: GET_TERRITORY_BY_AREA_VALUE,
  payload: { authtoken, areaId, dataRange, currentPage },
})

export const getTerritoryByAreaValueSuccess = data => ({
  type: GET_TERRITORY_BY_AREA_VALUE_SUCCESS,
  payload: { data },
})

export const addNewTerritory = (data, history, authtoken) => ({
  type: ADD_NEW_TERRITORY,
  payload: { data, history, authtoken },
})
export const addTerritorySuccess = data => ({
  type: ADD_TERRITORY_SUCCESS,
  payload: data,
})

export const addTerritoryFail = error => ({
  type: ADD_TERRITORY_FAIL,
  payload: error,
})

export const storeTerritoryData = (name, data) => ({
  type: STORE_TERRITORY_DATA,
  payload: { name, data },
})

export const updateTerritory = (id, data, token, history) => ({
  type: UPDATE_TERRITORY,
  payload: { id, data, token, history },
})

export const updateTerritorySuccess = (id, data) => ({
  type: UPDATE_TERRITORY_SUCCESS,
  payload: { id, data },
})

export const updateTerritoryFail = error => ({
  type: UPDATE_TERRITORY_FAIL,
  payload: error,
})

//ROUTE
export const getRouteValue = (authtoken, dataRange, currentPage) => ({
  type: GET_ROUTE_VALUE,
  payload: { authtoken, dataRange, currentPage },
})

export const getRouteValueSuccess = data => ({
  type: GET_ROUTE_VALUE_SUCCESS,
  payload: { data },
})

export const addNewRoute = (data, history, authtoken) => ({
  type: ADD_NEW_ROUTE,
  payload: { data, history, authtoken },
})
export const addRouteSuccess = data => ({
  type: ADD_ROUTE_SUCCESS,
  payload: data,
})

export const addRouteFail = error => ({
  type: ADD_ROUTE_FAIL,
  payload: error,
})
export const storeRouteData = (name, data) => ({
  type: STORE_ROUTE_DATA,
  payload: { name, data },
})

export const updateRoute = (id, data, token, history) => ({
  type: UPDATE_ROUTE,
  payload: { id, data, token, history },
})

export const updateRouteSuccess = (id, data) => ({
  type: UPDATE_ROUTE_SUCCESS,
  payload: { id, data },
})

export const updateRouteFail = error => ({
  type: UPDATE_ROUTE_FAIL,
  payload: error,
})

export const getRegion = authtoken => ({
  type: GET_REGIONS,
  payload: { authtoken },
})

export const getRegionSuccess = data => ({
  type: GET_REGIONS_SUCCESS,
  payload: data,
})

export const getRegionFail = error => ({
  type: GET_REGIONS_FAIL,
  payload: error,
})
