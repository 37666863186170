import CustomInput from "components/Common/CustomInput"
import CardComponent from "components/Layout/CardComponent"
import InnerLayer from "components/Layout/InnerLayer"
import { addSegmentSchema } from "components/Schemas/AddSegment.schema"
import { useFormik } from "formik"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory, useParams } from "react-router-dom"
import { Col, Form, Row } from "reactstrap"
import { updateSegment } from "../../store/Segment/action"

const breadcrumbItems = [
  {
    title: "Customer Segment",
    link: "/customer-segments",
  },
  {
    title: "Edit Customer Segment",
    link: "#",
  },
]
const initialValues = {
  name: "",
}

const EditSegment = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [segment, setSegment] = useState(null)
  const { id: segmentID } = useParams()
  const onSubmit = values => {
    dispatch(updateSegment(segmentID, values, authtoken, history))
  }
  const { editSegmentInfo, loading, authtoken } = useSelector(state => ({
    loading: state.SegmentReducer.isLoading,
    editSegmentInfo: state.SegmentReducer.editSegmentInfo,
    authtoken: state.Login.token,
  }))

  useEffect(() => {
    const oldSegment = { ...segment }
    oldSegment.name = editSegmentInfo?.data?.name
    setSegment(oldSegment)
  }, [editSegmentInfo])

  const segmentEdit = useFormik({
    enableReinitialize: true,
    initialValues: segment || initialValues,
    validationSchema: addSegmentSchema,
    onSubmit,
  })
  return (
    <React.Fragment>
      <InnerLayer
        isMultiple
        title={"Edit Customer Segment"}
        breadcrumbItems={breadcrumbItems}
      >
        <div style={{ minHeight: "100vh" }} className="position-relative">
          <Row className="mt-4">
            <Col md="6" className="mx-auto">
              {/* add User */}
              <CardComponent className="">
                <Form
                  className="needs-validation"
                  onSubmit={segmentEdit.handleSubmit}
                >
                  <CustomInput
                    name={"name"}
                    type={"text"}
                    label={"Type Customer Segment"}
                    placeholder={"Customer Segment Name"}
                    validationType={segmentEdit}
                  />

                  <div className="d-flex justify-content-start">
                    <input
                      type="submit"
                      value={"Update Customer Segment"}
                      className="btn button "
                    />
                  </div>
                </Form>
              </CardComponent>
            </Col>
          </Row>
        </div>
      </InnerLayer>
    </React.Fragment>
  )
}

export default EditSegment
