export const reports = [
  {
    title: "You have total",
    iconClass: "bx-copy-alt",
    value: `0 Region`,
  },
  { title: "Top user as SR", iconClass: "bx-archive-in", value: "SR name" },
  {
    title: "Top user as DP",
    iconClass: "bx-archive-in",
    value: "DP name",
  },
]

export const allGeo = [
  {
    id: "1",
    name: "Dhaka",
    area: "Jamalpur",
    territories: "Jhenaigati Upazila",
    total: "17",
    status: "active",
    toggle: true,
  },
  {
    id: "2",
    name: "Khulna",
    area: "Sherpur",
    territories: "Sherpur Sadar Upazila",
    total: "20",
    status: "inactive",
    toggle: false,
  },
  {
    id: "3",
    name: "Khulna",
    area: "Jamalpur",
    territories: "Nalitabari Upazila",
    total: "15",
    status: "active",
    status: "inactive",
    toggle: true,
  },
]
