// TOP DP

// GET AREA BY REGION
export const GET_AREA_BY_REGION = "GET_AREA_BY_REGION"
export const GET_AREA_BY_REGION_SUCCESS = "GET_AREA_BY_REGION_SUCCESS"
export const GET_AREA_BY_REGION_FAIL = "GET_AREA_BY_REGION_FAIL"

// GET TERRITORY BY AREA
export const GET_TERRITORY_BY_AREA = "GET_TERRITORY_BY_AREA"
export const GET_TERRITORY_BY_AREA_SUCCESS = "GET_TERRITORY_BY_AREA_SUCCESS"
export const GET_TERRITORY_BY_AREA_FAIL = "GET_TERRITORY_BY_AREA_FAIL"

// filter action
// GET AREA BY REGION
export const GET_ROUTE_BY_TERRITORY = "GET_ROUTE_BY_TERRITORY"
export const GET_ROUTE_BY_TERRITORY_SUCCESS = "GET_ROUTE_BY_TERRITORY_SUCCESS"
export const GET_ROUTE_BY_TERRITORY_FAIL = "GET_ROUTE_BY_TERRITORY_FAIL"
export const GET_CATEGORY_BY_ID = "GET_CATEGORY_BY_ID"

export const MAIN_FILTER_CHANGED = "MAIN_FILTER_CHANGED"
export const SUB_FILTER_CHANGED = "SUB_FILTER_CHANGED"
export const SUB_CATEGORY_CHANGED = "SUB_CATEGORY_CHANGED"
export const TOTAL_VALUE_CHANGED = "TOTAL_VALUE_CHANGED"

export const CLEAR_FILTERS = "CLEAR_FILTERS"

export const STORE_DASHBOARD_DATA = "STORE_DASHBOARD_DATA"
export const TOGGLE_DASHBOARD_DATA = "TOGGLE_DASHBOARD_DATA"
export const GET_CATEGORY = "GET_CATEGORY"
export const GET_SUB_SELECTED = "GET_SUB_SELECTED"
export const REGION_FILTER_CHANGED = "REGION_FILTER_CHANGED"
export const AREA_FILTER_CHANGED = "AREA_FILTER_CHANGED"
export const TERRITORY_FILTER_CHANGED = "TERRITORY_FILTER_CHANGED"
export const ROUTE_FILTER_CHANGED = "ROUTE_FILTER_CHANGED"
export const CATEGORY_FILTER_CHANGED = "CATEGORY_FILTER_CHANGED"
export const SUB_CATEGORY_FILTER_CHANGED = "SUB_CATEGORY_FILTER_CHANGED"
export const CATEGORY_FILTER = "CATEGORY_FILTER"
export const GET_LA_USER = "GET_LA_USER"
export const GET_SITES_LIST = "GET_SITES_LIST"
export const GET_SITES_LIST_SUCCESS = "GET_SITES_LIST_SUCCESS"

export const GET_SITE_VIEW = "GET_SITE_VIEW"
export const GET_SITE_VIEW_SUCCESS = "GET_SITE_VIEW_SUCCESS"
// DOWNLOAD
export const GET_SITE_EXCEL = "GET_SITE_EXCEL"
export const GET_SITE_EXCEL_SUCCESS = "GET_SITE_EXCEL_SUCCESS"
export const GET_SITE_EXCEL_FAIL = "GET_SITE_EXCEL_FAIL"

//ORGANIZATION SITE
export const GET_ORG_SITES_LIST = "GET_ORG_SITES_LIST"
export const GET_ORG_SITES_LIST_SUCCESS = "GET_ORG_SITES_LIST_SUCCESS"
export const ASSIGNING_SITE = "ASSIGNING_SITE"
export const ASSIGNING_SITE_SUCCESS = "ASSIGNING_SITE_SUCCESS"
export const ASSIGNING_SITE_FAIL = "ASSIGNING_SITE_FAIL"

//Managing ORGANIZATION SITE
export const GET_MANAGING_ORG_SITES_LIST = "GET_MANAGING_ORG_SITES_LIST"
export const GET_MANAGING_ORG_SITES_LIST_SUCCESS =
  "GET_MANAGING_ORG_SITES_LIST_SUCCESS"

/**
 * Delete user
 */
export const DELETE_ORG_SITE = "DELETE_ORG_SITE"
export const DELETE_ORG_SITE_SUCCESS = "DELETE_ORG_SITE_SUCCESS"
export const DELETE_ORG_SITE_FAIL = "DELETE_ORG_SITE_FAIL"

// SITE IN ORGANIZATION PANEL
export const GET_ORG_SITE = "GET_ORG_SITE"
export const GET_ORG_SITE_SUCCESS = "GET_ORG_SITE_SUCCESS"

// UPDATE STATUS IN ORGANIZATION PANEL
export const UPDATE_ORG_STATUS = "UPDATE_ORG_STATUS"
export const UPDATE_ORG_STATUS_SUCCESS = "UPDATE_ORG_STATUS_SUCCESS"
export const UPDATE_ORG_STATUS_FAIL = "UPDATE_ORG_STATUS_FAIL"
