import {
  ADD_NEW_SITE,
  ADD_SITE_FAIL,
  ADD_SITE_SUCCESS,
  STORE_SITE_DATA,
  UPDATE_SITE,
  UPDATE_SITE_FAIL,
  UPDATE_SITE_SUCCESS,
} from "./actionTypes"

//SITE
export const addNewSite = (data, history, authtoken) => ({
  type: ADD_NEW_SITE,
  payload: { data, history, authtoken },
})
export const addSiteSuccess = data => ({
  type: ADD_SITE_SUCCESS,
  payload: data,
})

export const addSiteFail = error => ({
  type: ADD_SITE_FAIL,
  payload: error,
})
export const storeSiteData = (name, data) => ({
  type: STORE_SITE_DATA,
  payload: { name, data },
})

export const updateSite = (id, data, history, token) => ({
  type: UPDATE_SITE,
  payload: { id, data, history, token },
})

export const updateSiteSuccess = (id, data) => ({
  type: UPDATE_SITE_SUCCESS,
  payload: { id, data },
})

export const updateSiteFail = error => ({
  type: UPDATE_SITE_FAIL,
  payload: error,
})
