import CustomInput from "components/Common/CustomInput"
import CardComponent from "components/Layout/CardComponent"
import InnerLayer from "components/Layout/InnerLayer"
import { addUserSchema } from "components/Schemas/AddUser.schemal"
import { useFormik } from "formik"
import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { Col, Form, Row } from "reactstrap"
import { addNewUser } from "store/actions"
const breadcrumbItems = [
  {
    title: "Dashboard",
    link: "/dashboard",
  },
  {
    title: "Users",
    link: "/users",
  },
  {
    title: "Add User",
    link: "#",
  },
]
const role = [
  {
    title: "Admin",
    value: "ADMIN",
  },
  {
    title: "Lead Agent",
    value: "LA",
  },
  {
    title: "Organization",
    value: "ORG",
  },
]

const initialValues = {
  fullName: "",
  password: "",
  confirmPassword: "",
  role: "",
  // office: "",
  email: "",
  phone: "",
  file: "",
  paint: "",
  cement: "",
  sand: "",
  steel: "",
  aggregates: "",
  brick: "",
  windows: "",
  doors: "",
  plumbing: "",
  electrical: "",
  sanitary: "",
  tiles: "",
}

const AddUser = () => {
  const [selectedFiles, setSelectedFiles] = useState({})
  const [selectStatus, setSelectStatus] = useState(false)
  console.log(`AddUser ~ selectedFiles`, selectedFiles)
  //const fileShare_ref = useRef()

  const history = useHistory()
  let dispatch = useDispatch()
  const { authtoken } = useSelector(state => ({
    authtoken: state.Login.token,
  }))
  // useEffect(() => {
  //   dispatch(getUserValue(authtoken))
  // }, [])
  const onSubmit = values => {
    console.log("valuessss", values)
    let obj = {}
    obj.name = values.fullName
    obj.password = values.password
    obj.role = values.role
    obj.email = values.email
    obj.mobileNo = values.phone
    obj.passwordConfirm = values.confirmPassword
    obj.paint = values.paint ? values.paint : false
    obj.cement = values.cement ? values.cement : false
    obj.sand = values.sand ? values.sand : false
    obj.steel = values.steel ? values.steel : false
    obj.aggregates = values.aggregates ? values.aggregates : false
    obj.brick = values.brick ? values.brick : false
    obj.windows = values.windows ? values.windows : false
    obj.doors = values.doors ? values.doors : false
    obj.plumbing = values.plumbing ? values.plumbing : false
    obj.electrical = values.electrical ? values.electrical : false
    obj.sanitary = values.sanitary ? values.sanitary : false
    obj.tiles = values.tiles ? values.tiles : false
    console.log("values", obj)
    dispatch(addNewUser(obj, history, authtoken))
  }
  //console.log(`AddUser ~ fileShare_ref`, fileShare_ref)

  const addUser = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: addUserSchema,
    onSubmit,
  })

  // console.log(`AddUser ~ addUser`, addUser)
  const handleSelectAll = e => {
    setSelectStatus(e.target.checked)
  }

  return (
    <React.Fragment>
      <InnerLayer
        isMultiple
        title={"Add new User"}
        breadcrumbItems={breadcrumbItems}
      >
        <Row className="mt-4">
          <Col md="8" className="mx-auto">
            {/* add User */}
            <CardComponent className="">
              <Form
                className="needs-validation"
                onSubmit={addUser.handleSubmit}
              >
                <Row>
                  <Col>
                    <CustomInput
                      name={"fullName"}
                      type={"text"}
                      label={"Full name"}
                      placeholder={"Type user name"}
                      validationType={addUser}
                    />
                  </Col>
                  <Col>
                    <CustomInput
                      name={"phone"}
                      type={"text"}
                      label={"Phone name"}
                      placeholder={"Type phone number"}
                      validationType={addUser}
                    />
                  </Col>
                </Row>
                {/* file */}
                <Row>
                  <Col md="12">
                    <CustomInput
                      name={"file"}
                      type={"file"}
                      accept="image/png, image/jpg, image/jpeg"
                      label={"Upload user image"}
                      //   placeholder={"Type email address"}
                      validationType={addUser}
                      //innerRef={fileShare_ref}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <CustomInput
                      name={"email"}
                      type={"email"}
                      label={"Email"}
                      placeholder={"Type email address"}
                      validationType={addUser}
                    />
                  </Col>
                  <Col>
                    <CustomInput
                      name={"role"}
                      type={"select"}
                      label={"Select role"}
                      validationType={addUser}
                    >
                      <option defaultValue>Select role...</option>
                      {role?.map((data, idx) => (
                        <option key={idx} value={data.value}>
                          {data.title}
                        </option>
                      ))}
                    </CustomInput>
                  </Col>
                </Row>
                {/* select */}
                {/* <Row>
                  <Col md="12">
                    <CustomInput
                      name={"office"}
                      type={"select"}
                      label={"Office select"}
                      validationType={addUser}
                    >
                      <option defaultValue>Select office...</option>
                      {office.map((data, idx) => (
                        <option key={idx} value={data}>
                          {data}
                        </option>
                      ))}
                    </CustomInput>
                  </Col>
                </Row> */}
                <Row>
                  <Col>
                    <CustomInput
                      name={"password"}
                      type={"password"}
                      label={"Password"}
                      placeholder={"Type password"}
                      validationType={addUser}
                    />
                  </Col>
                  <Col>
                    <CustomInput
                      name={"confirmPassword"}
                      type={"password"}
                      label={"Confirm Password"}
                      placeholder={"Type confirm password"}
                      validationType={addUser}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col className="col-6 mt-3">
                    <CustomInput
                      name={"check"}
                      type={"checkbox"}
                      label={"All"}
                      validationType={addUser}
                      onChange={handleSelectAll}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col className="col-6">
                    <CustomInput
                      name={"paint"}
                      type={"checkbox"}
                      label={"Paint"}
                      validationType={addUser}
                    />
                  </Col>

                  <Col className="col-6">
                    <CustomInput
                      name={"cement"}
                      type={"checkbox"}
                      label={"Cement"}
                      validationType={addUser}
                    />
                  </Col>
                  <Col className="col-6">
                    <CustomInput
                      name={"sand"}
                      type={"checkbox"}
                      label={"Sand"}
                      validationType={addUser}
                    />
                  </Col>

                  <Col className="col-6">
                    <CustomInput
                      name={"steel"}
                      type={"checkbox"}
                      label={"Steel"}
                      validationType={addUser}
                    />
                  </Col>
                  <Col className="col-6">
                    <CustomInput
                      name={"aggregates"}
                      type={"checkbox"}
                      label={"Aggregates"}
                      validationType={addUser}
                    />
                  </Col>

                  <Col className="col-6">
                    <CustomInput
                      name={"brick"}
                      type={"checkbox"}
                      label={"Brick"}
                      validationType={addUser}
                    />
                  </Col>
                  <Col className="col-6">
                    <CustomInput
                      name={"windows"}
                      type={"checkbox"}
                      label={"Windows"}
                      validationType={addUser}
                    />
                  </Col>

                  <Col className="col-6">
                    <CustomInput
                      name={"doors"}
                      type={"checkbox"}
                      label={"Doors"}
                      validationType={addUser}
                    />
                  </Col>
                  <Col className="col-6">
                    <CustomInput
                      name={"plumbing"}
                      type={"checkbox"}
                      label={"Plumbing"}
                      validationType={addUser}
                    />
                  </Col>

                  <Col className="col-6">
                    <CustomInput
                      name={"electrical"}
                      type={"checkbox"}
                      label={"Electrical"}
                      validationType={addUser}
                    />
                  </Col>
                  <Col className="col-6">
                    <CustomInput
                      name={"sanitary"}
                      type={"checkbox"}
                      label={"Sanitary"}
                      validationType={addUser}
                    />
                  </Col>
                  <Col className="col-6">
                    <CustomInput
                      name={"tiles"}
                      type={"checkbox"}
                      label={"Tiles"}
                      validationType={addUser}
                    />
                  </Col>
                </Row>
                <div className="d-flex justify-content-end">
                  <input
                    type="submit"
                    value={"Save User"}
                    className="btn button "
                  />
                </div>
              </Form>
            </CardComponent>

            {/* file Upload */}
            {/* <AddUserUpload setSelectedFiles={setSelectedFiles} /> */}
          </Col>
        </Row>
      </InnerLayer>
    </React.Fragment>
  )
}

export default AddUser
