import {
  ADD_AREA_FAIL,
  ADD_AREA_SUCCESS,
  ADD_REGION_FAIL,
  ADD_REGION_SUCCESS,
  ADD_ROUTE_FAIL,
  ADD_ROUTE_SUCCESS,
  ADD_TERRITORY_FAIL,
  ADD_TERRITORY_SUCCESS,
  GET_AREA_BY_REGION_VALUE,
  GET_AREA_BY_REGION_VALUE_SUCCESS,
  GET_AREA_VALUE,
  GET_AREA_VALUE_SUCCESS,
  GET_REGIONS,
  GET_REGIONS_FAIL,
  GET_REGIONS_SUCCESS,
  GET_REGION_VALUE,
  GET_REGION_VALUE_SUCCESS,
  GET_ROUTE_VALUE,
  GET_ROUTE_VALUE_SUCCESS,
  GET_TERRITORY_BY_AREA_VALUE,
  GET_TERRITORY_BY_AREA_VALUE_SUCCESS,
  GET_TERRITORY_VALUE,
  GET_TERRITORY_VALUE_SUCCESS,
  STORE_AREA_DATA,
  STORE_REGION_DATA,
  STORE_ROUTE_DATA,
  STORE_TERRITORY_DATA,
  UPDATE_AREA_FAIL,
  UPDATE_AREA_SUCCESS,
  UPDATE_REGION_FAIL,
  UPDATE_REGION_SUCCESS,
  UPDATE_ROUTE_FAIL,
  UPDATE_ROUTE_SUCCESS,
  UPDATE_TERRITORY_FAIL,
  UPDATE_TERRITORY_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
  isLoading: false,
  potValue: [],
  regionData: [],
  areaData: [],
  routeData: [],
  territoryData: [],
  editRegionInfo: [],
  editAreaInfo: [],
  editTerritoryInfo: [],
  editRouteInfo: [],
  areaByRegionData: [],
  territoryByAreaData: [],
  region: [],
  regionloading: false,
}

const GeoInformationReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_REGION_VALUE:
      return {
        ...state,
        isLoading: true,
      }
      break

    case GET_REGION_VALUE_SUCCESS:
      return {
        ...state,
        regionData: action.payload.data,
        isLoading: false,
      }

    case ADD_REGION_SUCCESS:
      return {
        ...state,
        region: [...state.region, action.payload],
        isLoading: false,
      }

    case ADD_REGION_FAIL:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      }

    case STORE_REGION_DATA:
      return {
        ...state,
        editRegionInfo: action.payload,
      }
    case UPDATE_REGION_SUCCESS:
      return {
        ...state,
        regionData: state.region.map(region =>
          region.id.toString() === action.payload.id.toString()
            ? { region, ...action.payload }
            : region
        ),
        loading: false,
      }

    case UPDATE_REGION_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    //area

    case GET_AREA_VALUE:
      return {
        ...state,
        isLoading: true,
      }
      break

    case GET_AREA_VALUE_SUCCESS:
      return {
        ...state,
        areaData: action.payload.data,
        isLoading: false,
      }
    case GET_AREA_BY_REGION_VALUE:
      return {
        ...state,
        isLoading: true,
      }
      break
    case GET_REGIONS_SUCCESS:
      return {
        ...state,
        region: action.payload,
        regionloading: false,
        isLoading: false,
      }
    case GET_REGIONS:
      return {
        ...state,
        regionloading: true,
      }
    case GET_REGIONS_FAIL:
      return {
        ...state,
        regionloading: false,
        error: action.payload,
        //change here
      }

    case GET_AREA_BY_REGION_VALUE_SUCCESS:
      return {
        ...state,
        areaByRegionData: action.payload.data,
        isLoading: false,
      }

    case ADD_AREA_SUCCESS:
      return {
        ...state,
        area: [...state.area, action.payload],
        isLoading: false,
      }

    case ADD_AREA_FAIL:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      }

    case STORE_AREA_DATA:
      return {
        ...state,
        editAreaInfo: action.payload,
      }
    case UPDATE_AREA_SUCCESS:
      return {
        ...state,
        areaData: state.area.map(area =>
          area.id.toString() === action.payload.id.toString()
            ? { area, ...action.payload }
            : area
        ),
        loading: false,
      }

    case UPDATE_AREA_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    //territory
    case GET_TERRITORY_VALUE:
      return {
        ...state,
        isLoading: true,
      }
      break

    case GET_TERRITORY_VALUE_SUCCESS:
      return {
        ...state,
        territoryData: action.payload.data,
        isLoading: false,
      }

    case GET_TERRITORY_BY_AREA_VALUE:
      return {
        ...state,
        isLoading: true,
      }
      break

    case GET_TERRITORY_BY_AREA_VALUE_SUCCESS:
      return {
        ...state,
        territoryByAreaData: action.payload.data,
        isLoading: false,
      }

    case ADD_TERRITORY_SUCCESS:
      return {
        ...state,
        route: [...state.territory, action.payload],
        isLoading: false,
      }

    case ADD_TERRITORY_FAIL:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      }

    case STORE_TERRITORY_DATA:
      return {
        ...state,
        editTerritoryInfo: action.payload,
      }
    case UPDATE_TERRITORY_SUCCESS:
      return {
        ...state,
        territoryData: state.territory.map(area =>
          territory.id.toString() === action.payload.id.toString()
            ? { territory, ...action.payload }
            : territory
        ),
        loading: false,
      }

    case UPDATE_TERRITORY_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    //route
    case GET_ROUTE_VALUE:
      return {
        ...state,
        isLoading: true,
      }
      break

    case GET_ROUTE_VALUE_SUCCESS:
      return {
        ...state,
        routeData: action.payload.data,
        isLoading: false,
      }

    case ADD_ROUTE_SUCCESS:
      return {
        ...state,
        route: [...state.route, action.payload],
        isLoading: false,
      }

    case ADD_ROUTE_FAIL:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      }

    case STORE_ROUTE_DATA:
      return {
        ...state,
        editRouteInfo: action.payload,
      }
    case UPDATE_ROUTE_SUCCESS:
      return {
        ...state,
        routeData: state.route.map(route =>
          route.id.toString() === action.payload.id.toString()
            ? { route, ...action.payload }
            : route
        ),
        loading: false,
      }

    case UPDATE_ROUTE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default GeoInformationReducer
