import {
  AREA_FILTER_CHANGED,
  ASSIGNING_SITE,
  ASSIGNING_SITE_FAIL,
  ASSIGNING_SITE_SUCCESS,
  CATEGORY_FILTER,
  CATEGORY_FILTER_CHANGED,
  CLEAR_FILTERS,
  DELETE_ORG_SITE,
  DELETE_ORG_SITE_FAIL,
  DELETE_ORG_SITE_SUCCESS,
  GET_AREA_BY_REGION,
  GET_CATEGORY,
  GET_CATEGORY_BY_ID,
  GET_LA_USER,
  GET_MANAGING_ORG_SITES_LIST,
  GET_MANAGING_ORG_SITES_LIST_SUCCESS,
  GET_ORG_SITE,
  GET_ORG_SITES_LIST,
  GET_ORG_SITES_LIST_SUCCESS,
  GET_ORG_SITE_SUCCESS,
  GET_ROUTE_BY_TERRITORY,
  GET_SITES_LIST,
  GET_SITES_LIST_SUCCESS,
  GET_SITE_EXCEL,
  GET_SITE_EXCEL_FAIL,
  GET_SITE_EXCEL_SUCCESS,
  GET_SITE_VIEW,
  GET_SITE_VIEW_SUCCESS,
  GET_SUB_SELECTED,
  GET_TERRITORY_BY_AREA,
  MAIN_FILTER_CHANGED,
  REGION_FILTER_CHANGED,
  ROUTE_FILTER_CHANGED,
  STORE_DASHBOARD_DATA,
  SUB_CATEGORY_CHANGED,
  SUB_CATEGORY_FILTER_CHANGED,
  SUB_FILTER_CHANGED,
  TERRITORY_FILTER_CHANGED,
  TOGGLE_DASHBOARD_DATA,
  TOTAL_VALUE_CHANGED,
  UPDATE_ORG_STATUS,
  UPDATE_ORG_STATUS_FAIL,
  UPDATE_ORG_STATUS_SUCCESS,
} from "./actionTypes"

export const storeDashboardReducerData = (name, data) => ({
  type: STORE_DASHBOARD_DATA,
  payload: { name, data },
})

export const toggleDashboardData = name => ({
  type: TOGGLE_DASHBOARD_DATA,
  payload: { name },
})

export const getCategory = authToken => ({
  type: GET_CATEGORY,
  payload: authToken,
})

export const getSubCategory = id => ({
  type: GET_SUB_SELECTED,
  payload: { id },
})
// GET AREA BY REGION

export const getSubfilterChanged = (name, data) => ({
  type: SUB_FILTER_CHANGED,
  payload: { name, data },
})

// export const getAreaByRegionSuccess = () => ({type : GET_AREA_BY_REGION, })
// export const getAreaByRegion = () => ({type : GET_AREA_BY_REGION})

export const clearFilters = () => ({ type: CLEAR_FILTERS })
export const onMainFilterChange = data => ({
  type: MAIN_FILTER_CHANGED,
  payload: data,
})

export const onSubFilterChange = data => ({
  type: SUB_FILTER_CHANGED,
  payload: data,
})

export const onSubCategoryChange = data => ({
  type: SUB_CATEGORY_CHANGED,
  payload: data,
})
export const onTotalValueChange = data => ({
  type: TOTAL_VALUE_CHANGED,
  payload: data,
})

export const onRegionFilterChange = data => ({
  type: REGION_FILTER_CHANGED,
  payload: data,
})

export const onAreaFilterChange = data => ({
  type: AREA_FILTER_CHANGED,
  payload: data,
})

export const onTerritoryFilterChange = data => ({
  type: TERRITORY_FILTER_CHANGED,
  payload: data,
})

export const onRoutesFilterChange = data => ({
  type: ROUTE_FILTER_CHANGED,
  payload: data,
})
export const onCategoryFilterChange = data => ({
  type: CATEGORY_FILTER_CHANGED,
  payload: data,
})
export const onSubCategoryFilterChange = data => ({
  type: SUB_CATEGORY_FILTER_CHANGED,
  payload: data,
})

// GET AREA BY REGION

export const getAreaByRegion = (token, id) => ({
  type: GET_AREA_BY_REGION,
  payload: { id, token },
})

export const getTerritoryByArea = (token, id) => ({
  type: GET_TERRITORY_BY_AREA,
  payload: { id, token },
})

export const getRouteByTerritory = (token, id) => ({
  type: GET_ROUTE_BY_TERRITORY,
  payload: { id, token },
})

export const getCategoryFilter = token => ({
  type: CATEGORY_FILTER,
  payload: { token },
})

export const getSubCategoryById = (token, id) => ({
  type: GET_CATEGORY_BY_ID,
  payload: { token, id },
})

export const getLAUser = token => ({
  type: GET_LA_USER,
  payload: { token },
})

export const getSitesData = (token, data) => ({
  type: GET_SITES_LIST,
  payload: { token, data },
})

export const getSitesDataSuccess = data => ({
  type: GET_SITES_LIST_SUCCESS,
  payload: { data },
})

export const getSitesViewData = (token, siteId) => ({
  type: GET_SITE_VIEW,
  payload: { token, siteId },
})

export const getSitesViewDataSuccess = data => ({
  type: GET_SITE_VIEW_SUCCESS,
  payload: { data },
})

export const getSiteExcel = (token, data) => ({
  type: GET_SITE_EXCEL,
  payload: { token, data },
})

export const getSiteExcelSuccess = data => ({
  type: GET_SITE_EXCEL_SUCCESS,
  payload: { data },
})

export const getSiteExcelFail = error => ({
  type: GET_SITE_EXCEL_FAIL,
  payload: error,
})
//ORG SITE
export const getOrgSitesData = (token, data) => ({
  type: GET_ORG_SITES_LIST,
  payload: { token, data },
})

export const getOrgSitesDataSuccess = data => ({
  type: GET_ORG_SITES_LIST_SUCCESS,
  payload: { data },
})
//MANAGING ORG SITE
export const getManagingOrgSitesData = (token, data) => ({
  type: GET_MANAGING_ORG_SITES_LIST,
  payload: { token, data },
})

export const getManagingOrgSitesDataSuccess = data => ({
  type: GET_MANAGING_ORG_SITES_LIST_SUCCESS,
  payload: { data },
})
//SITE IN  ORGANIZATION PANEL
export const getOrgSiteData = (token, data) => ({
  type: GET_ORG_SITE,
  payload: { token, data },
})

export const getOrgSiteDataSuccess = data => ({
  type: GET_ORG_SITE_SUCCESS,
  payload: { data },
})
//
export const assigningSite = (data, token, loadData) => ({
  type: ASSIGNING_SITE,
  payload: { data, token, loadData },
})

export const assigningSiteSuccessful = data => ({
  type: ASSIGNING_SITE_SUCCESS,
  payload: { data },
})

export const assigningSiteFail = data => ({
  type: ASSIGNING_SITE_FAIL,
  payload: { data },
})

//DELETE ORG SITE
export const deleteOrgSite = (history, authtoken, siteId) => ({
  type: DELETE_ORG_SITE,
  payload: { history, authtoken, siteId },
})

export const deleteOrgSiteSuccess = siteId => ({
  type: DELETE_ORG_SITE_SUCCESS,
  payload: { siteId },
})

export const deleteOrgSiteFail = error => ({
  type: DELETE_ORG_SITE_FAIL,
  payload: error,
})

// UPDATE STATUS IN ORGANIZATION PANEL
export const updateOrgStatus = (data, history, authtoken) => ({
  type: UPDATE_ORG_STATUS,
  payload: { data, history, authtoken },
})

export const updateOrgStatusSuccess = data => ({
  type: UPDATE_ORG_STATUS_SUCCESS,
  payload: { data },
})

export const updateOrgStatusFail = error => ({
  type: UPDATE_ORG_STATUS_FAIL,
  payload: error,
})
