import CustomInput from "components/Common/CustomInput"
import CardComponent from "components/Layout/CardComponent"
import InnerLayer from "components/Layout/InnerLayer"
import { addRegionSchema } from "components/Schemas/AddRegion.schema"
import { useFormik } from "formik"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory, useParams } from "react-router-dom"
import { Col, Form, Row } from "reactstrap"
import { updateRegion } from "../../../store/GeoInformation/action"

const breadcrumbItems = [
  {
    title: "Geo Information",
    link: "#",
  },
  {
    title: "Region",
    link: "/geo/region",
  },
  {
    title: "Edit Region",
    link: "#",
  },
]
const initialValues = {
  name: "",
}

const EditRegion = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [region, setRegion] = useState(null)
  const { id: regionID } = useParams()
  const onSubmit = values => {
    dispatch(updateRegion(regionID, values, authtoken, history))
  }
  const { editRegionInfo, loading, authtoken } = useSelector(state => ({
    loading: state.GeoInformationReducer.isLoading,
    editRegionInfo: state.GeoInformationReducer.editRegionInfo,
    authtoken: state.Login.token,
  }))

  useEffect(() => {
    const oldRegion = { ...region }
    oldRegion.name = editRegionInfo?.data?.name
    setRegion(oldRegion)
  }, [editRegionInfo])

  const regionEdit = useFormik({
    enableReinitialize: true,
    initialValues: region || initialValues,
    validationSchema: addRegionSchema,
    onSubmit,
  })
  return (
    <React.Fragment>
      <InnerLayer
        isMultiple
        title={"Edit Region"}
        breadcrumbItems={breadcrumbItems}
      >
        <div style={{ minHeight: "100vh" }} className="position-relative">
          <Row className="mt-4">
            <Col md="6" className="mx-auto">
              {/* add User */}
              <CardComponent className="">
                <Form
                  className="needs-validation"
                  onSubmit={regionEdit.handleSubmit}
                >
                  <CustomInput
                    name={"name"}
                    type={"text"}
                    label={"Type region"}
                    placeholder={"Region name"}
                    validationType={regionEdit}
                  />

                  <div className="d-flex justify-content-start">
                    <input
                      type="submit"
                      value={"Update Region"}
                      className="btn button "
                    />
                  </div>
                </Form>
              </CardComponent>
            </Col>
          </Row>
        </div>
      </InnerLayer>
    </React.Fragment>
  )
}

export default EditRegion
