import {
  ADD_CATEGORY_FAIL,
  ADD_CATEGORY_SUCCESS,
  ADD_NEW_CATEGORY,
  ADD_NEW_SUB_CATEGORY,
  ADD_SUB_CATEGORY_FAIL,
  ADD_SUB_CATEGORY_SUCCESS,
  GET_CATEGORY_VALUE,
  GET_CATEGORY_VALUE_SUCCESS,
  GET_SUB_CATEGORY_BY_CATEGORY_VALUE,
  GET_SUB_CATEGORY_BY_CATEGORY_VALUE_SUCCESS,
  GET_SUB_CATEGORY_VALUE,
  GET_SUB_CATEGORY_VALUE_SUCCESS,
  STORE_CATEGORY_DATA,
  STORE_SUB_CATEGORY_DATA,
  UPDATE_CATEGORY,
  UPDATE_CATEGORY_FAIL,
  UPDATE_CATEGORY_SUCCESS,
  UPDATE_SUB_CATEGORY,
  UPDATE_SUB_CATEGORY_FAIL,
  UPDATE_SUB_CATEGORY_SUCCESS,
} from "./actionTypes"

//Category
export const getCategoryValue = (authtoken, currentPage, dataRange) => ({
  type: GET_CATEGORY_VALUE,
  payload: { authtoken, currentPage, dataRange },
})

export const getCategoryValueSuccess = data => ({
  type: GET_CATEGORY_VALUE_SUCCESS,
  payload: { data },
})

export const addNewCategory = (data, history, authtoken) => ({
  type: ADD_NEW_CATEGORY,
  payload: { data, history, authtoken },
})
export const addCategorySuccess = data => ({
  type: ADD_CATEGORY_SUCCESS,
  payload: data,
})

export const addCategoryFail = error => ({
  type: ADD_CATEGORY_FAIL,
  payload: error,
})
export const storeCategoryData = (name, data) => ({
  type: STORE_CATEGORY_DATA,
  payload: { name, data },
})

export const updateCategory = (id, data, token, history) => ({
  type: UPDATE_CATEGORY,
  payload: { data, history, token, id },
})

export const updateCategorySuccess = (id, data) => ({
  type: UPDATE_CATEGORY_SUCCESS,
  payload: { id, data },
})

export const updateCategoryFail = error => ({
  type: UPDATE_CATEGORY_FAIL,
  payload: error,
})

//sUB Category
export const getSubCategoryValue = (
  authtoken,
  categoryId,
  dataRange,
  currentPage
) => ({
  type: GET_SUB_CATEGORY_VALUE,
  payload: { authtoken, categoryId, dataRange, currentPage },
})

export const getSubCategoryValueSuccess = data => ({
  type: GET_SUB_CATEGORY_VALUE_SUCCESS,
  payload: { data },
})

export const getSubCategoryByCategoryValue = (
  authtoken,
  categoryId,
  dataRange,
  currentPage
) => ({
  type: GET_SUB_CATEGORY_BY_CATEGORY_VALUE,
  payload: { authtoken, categoryId, dataRange, currentPage },
})

export const getSubCategoryByCategoryValueSuccess = data => ({
  type: GET_SUB_CATEGORY_BY_CATEGORY_VALUE_SUCCESS,
  payload: { data },
})

export const addNewSubCategory = (data, history, authtoken) => ({
  type: ADD_NEW_SUB_CATEGORY,
  payload: { data, history, authtoken },
})
export const addSubCategorySuccess = data => ({
  type: ADD_SUB_CATEGORY_SUCCESS,
  payload: data,
})

export const addSubCategoryFail = error => ({
  type: ADD_SUB_CATEGORY_FAIL,
  payload: error,
})
export const storeSubCategoryData = (name, data) => ({
  type: STORE_SUB_CATEGORY_DATA,
  payload: { name, data },
})

export const updateSubCategory = (id, data, token, history) => ({
  type: UPDATE_SUB_CATEGORY,
  payload: { data, history, token, id },
})

export const updateSubCategorySuccess = (id, data) => ({
  type: UPDATE_SUB_CATEGORY_SUCCESS,
  payload: { id, data },
})

export const updateSubCategoryFail = error => ({
  type: UPDATE_SUB_CATEGORY_FAIL,
  payload: error,
})
