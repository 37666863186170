import { toast } from "react-toastify"
import { call, put, takeEvery } from "redux-saga/effects"
import {
  axiosGetTarget,
  postTargetData,
  updateTargetData,
} from "../../helpers/api_helper"
import {
  addTargetFail,
  getTargetValueSuccess,
  updateTargetFail,
} from "./action"
// Calender Redux States
import { ADD_NEW_TARGET, GET_TARGET_VALUE, UPDATE_TARGET } from "./actionTypes"

//target
const asyncGetTarget = async (authtoken, userId, fromDate, toDate) => {
  try {
    const response = await axiosGetTarget(authtoken, userId, fromDate, toDate)

    return response.data
  } catch (error) {
    return error.response.data
  }
}
function* workerGetTarget({
  payload: { authtoken, userId, fromDate, toDate },
}) {
  try {
    const response = yield call(
      asyncGetTarget,
      authtoken,
      userId,
      fromDate,
      toDate
    )
    yield put(getTargetValueSuccess(response.target))
  } catch (error) {}
}

//async
const asyncPostTargetData = async (data, authtoken) => {
  try {
    const response = await postTargetData(data, authtoken)
    return response.data
  } catch (error) {
    return error.response.data
  }
}

function* onAddTarget({ payload: { data, history, authtoken } }) {
  try {
    console.log("oooooo", data)
    const response = yield call(asyncPostTargetData, data, authtoken)
    if (response.status == "success") {
      toast("🦄 Target added successfully!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
      history.push("target-set")
    } else {
      toast.error(response.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
  } catch (error) {
    yield put(addTargetFail(error.message))
    toast.error(error.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    })
  }
}

function* onUpdateTarget({ payload: { data, history, authtoken } }) {
  try {
    const response = yield call(updateTargetData, data, authtoken)
    toast("Target updated successfully!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    })
    if (response.status === 200) {
      history.push("/target-set")
    }
  } catch (error) {
    console.log(error.response)
    yield put(updateTargetFail(error))
  }
}

function* TargetSaga() {
  yield takeEvery(GET_TARGET_VALUE, workerGetTarget)
  yield takeEvery(ADD_NEW_TARGET, onAddTarget)
  yield takeEvery(UPDATE_TARGET, onUpdateTarget)
}

export default TargetSaga
