import TableHeader from "components/Common/TableHeader"
import CardComponent from "components/Layout/CardComponent"
import CustomTable from "components/Layout/CustomTable"
import InnerLayer from "components/Layout/InnerLayer"
import NoTableData from "components/Layout/NoTableData"
import { getSiteExcelData } from "helpers/backend_helper"
import moment from "moment"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { Container, OverlayTrigger, Spinner, Tooltip } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import Modal from "react-responsive-modal"
import "react-responsive-modal/styles.css"
import { withRouter } from "react-router-dom"
import Select from "react-select"
import { Col, Row } from "reactstrap"
import {
  clearFilters,
  getAreaByRegion,
  getCategoryFilter,
  getLAUser,
  getRegion,
  getRouteByTerritory,
  getSitesData,
  getSitesViewData,
  getSubCategoryById,
  getTerritoryByArea,
  onAreaFilterChange,
  onCategoryFilterChange,
  onRegionFilterChange,
  onRoutesFilterChange,
  onSubCategoryFilterChange,
  onTerritoryFilterChange,
  storeSiteData,
} from "store/actions"
import XLSX from "xlsx"
import "./project.scss"

const tableHead = [
  "No.",
  "Visit To",
  "Site Name",
  "Information Availability",
  "Category",
  "Sub Category",
  "Visit Date",
  "Type of Structure",
  "Paint Pot",
  "Details",
  "Action",
]

export const products = [
  {
    id: "1",
    name: "Asian Tractor Emulsion Paint",
    code: 1234,
    category: "Exterior",
    unit: "LTR",
    price: "43k",
    status: "active",
    toggle: false,
  },
  {
    id: "2",
    name: "Jane Cooper",
    code: 1234,
    category: "WPCC",
    unit: "LTR",
    price: "43k",
    status: "active",
    toggle: false,
  },
  {
    id: "3",
    name: "Marvin McKinney",
    code: 1234,
    category: "Exterior",
    unit: "LTR",
    price: "43k",
    status: "inactive",
    toggle: false,
  },
]
const visitTo = [
  { name: "Construction Site", value: "Construction Site" },
  { name: "Office Site", value: "Office Site" },
]
const Structure = [
  { name: "Old Structure", value: "OB" },
  { name: "New Structure", value: "NB" },
]
const Information = [
  { name: "Full Set available", value: "Full Set available" },
  { name: "Partial Available", value: "Partial Available" },
]
const Construction = [
  { name: "BOQ Stage", value: "BOQ Stage" },
  { name: "Paint Assign", value: "Paint work is assigned" },
  { name: "Sub Structure ongoing", value: "Sub Structure ongoing" },
  { name: "Super Structure ongoing", value: "Super Structure ongoing" },
]
const moneyData = [
  {
    _id: 1,
    name: "Total",
    value: "all",
  },
  {
    _id: 2,
    name: "Less than 7 lakh",
    value: "lt",
  },
  {
    _id: 3,
    name: "Greater than 7 lakh",
    value: "gt",
  },
]

const renderTooltipEdit = props => (
  <Tooltip id="button-tooltip" {...props}>
    Edit
  </Tooltip>
)
const renderTooltipView = props => (
  <Tooltip id="button-tooltip" {...props}>
    View
  </Tooltip>
)

const Projects = ({ history }) => {
  const [currentPage, setCurrentPage] = useState(1)
  const [downloadExcel, setDownloadExcel] = useState(false)
  const [dataRange, setDataRange] = useState(100)
  const [reRender, setReRender] = useState(false)
  const [loadArea, setLoadArea] = useState(false)
  const [loadTerritory, setLoadTerritory] = useState(false)
  const [loadRoutes, setLoadRoutes] = useState(false)
  const [loadSubCategory, setLoadSubCategory] = useState(false)
  const [selectedLa, setSelectedLa] = useState({})
  const [visit, setVisit] = useState({})
  const [structure, setStructure] = useState({})
  const [info, setInfo] = useState({})
  const [construction, setConstruction] = useState({})
  const [total, setTotal] = useState("all")

  // const [toDate, setToDate] = useState("2019-12-12")
  // const [fromDate, setFromDate] = useState("2019-12-12")
  const {
    region,
    authToken,
    areaList,
    territoryList,
    selectedArea,
    selectedTerritory,
    selectedRegion,
    selectedCategory,
    selectedRoutes,
    selectedSubCategory,
    categoryList,
    subCategoryList,
    routesList,
    laUser,
    sitesLoading,
    sitesData,
    sitesLength,
    sitesViewData,
    subLoading,
    regionSubLoading,
    areaSubLoading,
    territorySubLoading,
  } = useSelector(state => ({
    authToken: state.Login?.token,
    region: state.GeoInformationReducer?.region?.region,
    areaList: state.DashboardReducer.areaList,
    territoryList: state.DashboardReducer.territoryList,
    mainFilter: state.DashboardReducer.mainFilter,
    subLoading: state.DashboardReducer.subLoading,
    regionSubLoading: state.DashboardReducer.regionSubLoading,
    areaSubLoading: state.DashboardReducer.areaSubLoading,
    territorySubLoading: state.DashboardReducer.territorySubLoading,
    selectedRegion: state.DashboardReducer.selectedRegion,
    selectedArea: state.DashboardReducer.selectedArea,
    selectedTerritory: state.DashboardReducer.selectedTerritory,
    subCategoryList: state.DashboardReducer.subCategoryList,
    selectedSubCategory: state.DashboardReducer.selectedSubCategory,
    selectedCategory: state.DashboardReducer.selectedCategory,
    categoryList: state.DashboardReducer.categoryList,
    routesList: state.DashboardReducer.routesList,
    selectedRoutes: state.DashboardReducer.selectedRoutes,
    laUser: state.DashboardReducer.laUser,
    sitesData: state.DashboardReducer.sitesData?.sites,
    sitesViewData: state.DashboardReducer.sitesViewData,
    sitesLength: state.DashboardReducer.sitesData,
    sitesLoading: state.DashboardReducer.sitesLoading,
  }))
  // const data = useSelector(state => state.DashboardReducer)
  // console.log(`🦄 ~ file: Projects.js ~ line 86 ~ Projects ~ region`, data)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(clearFilters())
  }, [])

  useEffect(async () => {
    await dispatch(getRegion(authToken))
  }, [])

  useEffect(async () => {
    await dispatch(getCategoryFilter(authToken))
  }, [])

  useEffect(async () => {
    await dispatch(getLAUser(authToken))
  }, [])

  const handleMainFilter = value => {
    if (!!value) {
      setVisit(value)
    } else {
      setVisit({})
    }
    setReRender(!reRender)
  }

  const handleTotalValue = data => {
    if (!data) {
      setTotal(data?.value)
    } else {
      setTotal("all")
    }
  }
  const handleSubCategory = value => {
    if (!!value) {
      setStructure(value)
    } else {
      setStructure({})
    }
    setReRender(!reRender)
  }

  const handleSubCategory1 = value => {
    if (!!value) {
      setInfo(value)
    } else {
      setInfo({})
    }
    setReRender(!reRender)
  }
  const handleSubCategory2 = value => {
    if (!!value) {
      setConstruction(value)
    } else {
      setConstruction({})
    }
    setReRender(!reRender)
  }

  const data = {
    visitTo: visit?.value || null,
    typeOfStructureShort: structure?.value || null,
    constructionStage: construction?.value || null,
    informationAvailability: info?.value || null,
    categoryId: selectedCategory?._id || null,
    subCategoryId: selectedSubCategory?._id || null,
    regionId: selectedRegion?._id || null,
    areaId: selectedArea?._id || null,
    territoryId: selectedTerritory?._id || null,
    routeId: selectedRoutes?._id || null,
    laUserId: selectedLa?._id || null,
    siteValue: total,
    fromDate: "",
    toDate: "",
    limit: dataRange,
    pageNo: currentPage,
  }
  /// table API
  useEffect(async () => {
    // console.log("Render", data)
    await dispatch(getSitesData(authToken, data))
  }, [reRender, dataRange, currentPage])

  useEffect(async () => {
    if (Object?.keys(selectedRegion)?.length) {
      await dispatch(getAreaByRegion(authToken, selectedRegion?._id))
    }
  }, [loadArea])

  useEffect(async () => {
    if (Object?.keys(selectedArea)?.length) {
      await dispatch(getTerritoryByArea(authToken, selectedArea?._id))
    }
  }, [loadTerritory])
  useEffect(async () => {
    if (Object?.keys(selectedTerritory)?.length) {
      await dispatch(getRouteByTerritory(authToken, selectedTerritory?._id))
    }
  }, [loadRoutes])

  //
  useEffect(async () => {
    if (Object?.keys(selectedCategory)?.length) {
      await dispatch(getSubCategoryById(authToken, selectedCategory?._id))
    }
  }, [loadSubCategory])
  // region
  const handleRegion = data => {
    if (!!data) {
      dispatch(onRegionFilterChange(data))
    } else {
      dispatch(onRegionFilterChange({}))
    }
    setLoadArea(!loadArea)
    setReRender(!reRender)
  }

  // area
  const handleArea = data => {
    if (!!data) {
      dispatch(onAreaFilterChange(data))
    } else {
      dispatch(onAreaFilterChange({}))
    }
    setLoadTerritory(!loadTerritory)
    setReRender(!reRender)
  }

  // territory
  const handleTerritory = data => {
    if (!!data) {
      dispatch(onTerritoryFilterChange(data))
    } else {
      dispatch(onTerritoryFilterChange({}))
    }
    setLoadRoutes(!loadRoutes)
    setReRender(!reRender)
  }

  // route
  const handleRoutes = data => {
    if (!!data) {
      dispatch(onRoutesFilterChange(data))
    } else {
      dispatch(onRoutesFilterChange({}))
    }
    setReRender(!reRender)
  }

  // category
  const handleCategory = data => {
    if (!!data) {
      dispatch(onCategoryFilterChange(data))
    } else {
      dispatch(onCategoryFilterChange({}))
    }
    setLoadSubCategory(!loadSubCategory)
    setReRender(!reRender)
  }
  const handleSubCategoryFilter = data => {
    if (!!data) {
      dispatch(onSubCategoryFilterChange(data))
    } else {
      dispatch(onSubCategoryFilterChange({}))
    }
    setReRender(!reRender)
  }

  const handleLaUser = data => {
    if (!!data) {
      setSelectedLa(data)
    } else {
      setSelectedLa({})
    }
    setReRender(!reRender)
  }
  const handleExcel = async () => {
    try {
      setDownloadExcel(true)
      const url = `/site/siteListDownloadExcel`
      const response = await getSiteExcelData(url, data, authToken)
      let newArray = []
      response?.sites?.forEach(sites => {
        let data = {}
        data.SiteId = sites?._id
        data.VisitDate = sites?.VisitDate
        data.VisitTo = sites?.VisitTo
        data.TimeOfVisit = sites?.TimeOfVisit
        data.InformationAvailability = sites?.InformationAvailability
        data.SiteName = sites?.SiteName
        data.SiteAddress = sites?.SiteAddress
        data.SiteCategoryName = sites?.CategoryId?.name
        data.SubCategoryName = sites?.SubCategoryId?.name
        data.TypeOfStructure = sites?.TypeOfStructure
        data.OpportunityBlock = sites?.OpportunityBlock
        data.ConstructionStage = sites?.ConstructionStage
        data.CurrentStageOfWork = sites?.CurrentStageOfWork
        data.NoOfFloorAboveGround = sites?.NoOfFloorAboveGround
        data.NoOfFloorInBasement = sites?.NoOfFloorInBasement
        data.StructureFloorArea = sites?.StructureFloorArea
        data.HeightOfTheStructureWall = sites?.HeightOfTheStructureWall
        data.RoofArea = sites?.RoofArea
        data.wetSurfaceArea = sites?.wetSurfaceArea
        data.BasementFloorArea = sites?.BasementFloorArea
        data.ExteriorArea = sites?.ExteriorArea
        data.InteriorArea = sites?.InteriorArea
        data.PaintPotentialArea = sites?.PaintPotentialArea
        data.PaintPot = sites?.PaintPot
        data.ActualPaintPot = sites?.ActualPaintPot
        data.PaintStages = sites?.PaintStages
        data.WPCC = sites.WPCC
        data.DECO = sites?.DECO
        data.ConstructionPOT = sites?.ConstructionPOT
        data.RegionName = sites?.RegionId?.name
        data.AreaName = sites?.AreaId?.name
        data.TerritoryName = sites?.TerritoryId?.name
        data.RouteName = sites?.RouteId?.name
        data.CustomerSegment = sites?.CustomerSegment
        data.PersonNameC1 = sites?.client[0]?.name
        data.DesignationC1 = sites?.client[0]?.designation
        data.EmailAddressC1 = sites?.client[0]?.email
        data.ContactNoC1 = sites?.client[0]?.contactNo
        data.OrganizationNameC1 = sites?.client[0]?.organizationName
        data.OrganizationTypeC1 = sites?.client[0]?.organizationType
        data.RespondentTypeC1 = sites?.client[0]?.respondentType

        data.PersonNameC2 = sites?.client[1]?.name
        data.DesignationC2 = sites?.client[1]?.designation
        data.EmailAddressC2 = sites?.client[1]?.email
        data.ContactNoC2 = sites?.client[1]?.contactNo
        data.OrganizationNameC2 = sites?.client[1]?.organizationName
        data.OrganizationTypeC2 = sites?.client[1]?.organizationType
        data.RespondentTypeC2 = sites?.client[1]?.respondentType

        data.PersonNameC3 = sites?.client[2]?.name
        data.DesignationC3 = sites?.client[2]?.designation
        data.EmailAddressC3 = sites?.client[2]?.email
        data.ContactNoC3 = sites?.client[2]?.contactNo
        data.OrganizationNameC3 = sites?.client[2]?.organizationName
        data.OrganizationTypeC3 = sites?.client[2]?.organizationType
        data.RespondentTypeC3 = sites?.client[2]?.respondentType

        data.PersonNameC4 = sites?.client[3]?.name
        data.DesignationC4 = sites?.client[3]?.designation
        data.EmailAddressC4 = sites?.client[3]?.email
        data.ContactNoC4 = sites?.client[3]?.contactNo
        data.OrganizationNameC4 = sites?.client[3]?.organizationName
        data.OrganizationTypeC4 = sites?.client[3]?.organizationType
        data.RespondentTypeC4 = sites?.client[3]?.respondentType

        data.PersonNameC5 = sites?.client[4]?.name
        data.DesignationC5 = sites?.client[4]?.designation
        data.EmailAddressC5 = sites?.client[4]?.email
        data.ContactNoC5 = sites?.client[4]?.contactNo
        data.OrganizationNameC5 = sites?.client[4]?.organizationName
        data.OrganizationTypeC5 = sites?.client[4]?.organizationType
        data.RespondentTypeC5 = sites?.client[4]?.respondentType
        newArray.push(data)
      })
      downloadxls(newArray)
    } catch (err) {
      console.log("Error Excel", error?.response)
      setDownloadExcel(false)
    }
  }
  const downloadxls = data => {
    const ws = XLSX.utils.json_to_sheet(data)
    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, "SheetJS")
    XLSX.writeFile(wb, "excel.xlsx")
    setDownloadExcel(false)
  }
  const [open, setOpen] = useState(false)
  const [siteData, setSiteData] = useState()
  // useEffect(async () => {
  //   // console.log("Render", data)
  //   await dispatch(getSitesViewData(authToken, data))
  // }, [])
  const onOpenModal = data => {
    dispatch(getSitesViewData(authToken, data._id))
    setSiteData(data)
    setOpen(true)
  }
  let totalPageNumber = Math.ceil(sitesLength?.length / dataRange)
  // console.log("currentPage", totalPageNumber, sitesData, dataRange)
  const onCloseModal = () => setOpen(false)
  console.log("subLoading", subLoading)
  //Edit site
  const handleEditSite = data => {
    dispatch(storeSiteData("singleSite", data))
    history.push("/edit-site/" + data._id)
  }
  const breadcrumbItems = [
    {
      title: "Dashboard",
      link: "/dashboard",
    },
    {
      title: "Sites",
    },
  ]
  return (
    <React.Fragment>
      <InnerLayer isMultiple title={"Sites"} breadcrumbItems={breadcrumbItems}>
        {/* filter */}
        <Row>
          <CardComponent>
            <div className="row">
              <div className="col-xl col-lg-4 col-md-4 mb-3">
                <Select
                  name="mainFilter"
                  classNamePrefix="select2-selection"
                  placeholder="Visit To"
                  cacheOptions
                  getOptionLabel={e => e.name}
                  getOptionValue={e => e.value}
                  // defaultValue={visitTo?.filter(
                  //   data => data.value === status
                  // )}
                  isClearable
                  options={visitTo}
                  onChange={handleMainFilter}
                />
              </div>
              <div className="col-xl col-lg-4 col-md-4 mb-3">
                <Select
                  name="subFilter"
                  classNamePrefix="select2-selection"
                  style={{ minWidth: "100px" }}
                  placeholder="Structure Type"
                  getOptionLabel={e => e.name}
                  getOptionValue={e => e.value}
                  isClearable
                  // value={
                  //   subCategory.filter(
                  //     data => data?._id === selectedSubCategory?._id
                  //   ) || [{ name: "", value: "" }]
                  // }
                  options={Structure}
                  onChange={handleSubCategory}
                />
              </div>
              <div className="col-xl col-lg-4 col-md-4 mb-3">
                <Select
                  name="subFilter"
                  classNamePrefix="select2-selection"
                  placeholder="Information Availability"
                  isClearable
                  style={{ minWidth: "100px" }}
                  getOptionLabel={e => e.name}
                  getOptionValue={e => e.value}
                  options={Information}
                  onChange={handleSubCategory1}
                />
              </div>
              <div className="col-xl col-lg-4 col-md-4 mb-3">
                <Select
                  name="subFilter"
                  classNamePrefix="select2-selection"
                  style={{ minWidth: "100px" }}
                  getOptionLabel={e => e.name}
                  placeholder="Construction Stage"
                  getOptionValue={e => e.value}
                  isClearable
                  // value={
                  //   subCategory.filter(
                  //     data => data?._id === selectedSubCategory?._id
                  //   ) || [{ name: "", value: "" }]
                  // }
                  options={Construction}
                  onChange={handleSubCategory2}
                />
              </div>
              {/* {laUser.length > 0 ? ( */}

              {/* ) : null} */}
              <div className="col-xl col-lg-4 col-md-4 mb-3">
                <Select
                  name="mainFilter"
                  classNamePrefix="select2-selection"
                  isClearable
                  // disabled={loadingCondition}
                  style={{ minWidth: "100px" }}
                  //   cacheOptions
                  getOptionLabel={e => e.name}
                  getOptionValue={e => e._id}
                  // defaultInputValue={}
                  placeholder={"Category"}
                  options={categoryList}
                  onChange={handleCategory}
                />
              </div>
              {/* {Object.keys(selectedCategory)?.length &&
              subCategoryList.length ? ( */}
              <div className="col-xl col-lg-4 col-md-4 mb-3">
                {!subLoading ? (
                  <Select
                    name="mainFilter"
                    classNamePrefix="select2-selection"
                    getOptionLabel={e => e.name}
                    getOptionValue={e => e._id}
                    placeholder={"Sub Category"}
                    isClearable
                    options={subCategoryList}
                    onChange={handleSubCategoryFilter}
                  />
                ) : (
                  <div
                    className="p-2"
                    style={{ border: "1px solid #ced4da", borderRadius: "4px" }}
                  >
                    <Spinner animation="border" size="sm" />
                    {/* <span class="select2-selection__indicator-separator css-1okebmr-indicatorSeparator"></span>
                    <span>
                      <svg
                        height="20"
                        width="20"
                        viewBox="0 0 20 20"
                        aria-hidden="true"
                        focusable="false"
                        className="css-tj5bde-Svg"
                      >
                        <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
                      </svg>
                    </span> */}
                  </div>
                )}
              </div>
            </div>
            {/* <hr /> */}
            <div className="row">
              <div className="col-xl col-lg-4 col-md-4 mb-3">
                <Select
                  name="mainFilter"
                  classNamePrefix="select2-selection"
                  // disabled={loadingCondition}
                  style={{ minWidth: "100px" }}
                  isClearable
                  //   cacheOptions
                  getOptionLabel={e => e.name}
                  getOptionValue={e => e._id}
                  // defaultInputValue={}
                  placeholder={"Select Region"}
                  options={region}
                  onChange={handleRegion}
                />
              </div>
              {/* {Object.keys(selectedRegion)?.length && areaList?.length ? ( */}
              <div className="col-xl col-lg-4 col-md-4 mb-3">
                {!regionSubLoading ? (
                  <Select
                    name="mainFilter"
                    classNamePrefix="select2-selection"
                    getOptionLabel={e => e.name}
                    isClearable
                    getOptionValue={e => e._id}
                    placeholder={"Select Area"}
                    options={areaList}
                    onChange={handleArea}
                  />
                ) : (
                  <div
                    className="p-2"
                    style={{ border: "1px solid #ced4da", borderRadius: "4px" }}
                  >
                    <Spinner animation="border" size="sm" />
                  </div>
                )}
              </div>

              {/* // ) : null} */}
              {/* {Object.keys(selectedArea)?.length && territoryList.length ? ( */}
              <div className="col-xl col-lg-4 col-md-4 mb-3">
                {!areaSubLoading ? (
                  <Select
                    name="mainFilter"
                    classNamePrefix="select2-selection"
                    getOptionLabel={e => e.name}
                    isClearable
                    getOptionValue={e => e._id}
                    placeholder={"Select Territory"}
                    options={territoryList}
                    onChange={handleTerritory}
                  />
                ) : (
                  <div
                    className="p-2"
                    style={{ border: "1px solid #ced4da", borderRadius: "4px" }}
                  >
                    <Spinner animation="border" size="sm" />
                  </div>
                )}
              </div>

              {/* ) : null} */}
              {/* {Object.keys(selectedTerritory)?.length && routesList.length ? ( */}
              <div className="col-xl col-lg-4 col-md-4 mb-3">
                {!territorySubLoading ? (
                  <Select
                    name="mainFilter"
                    classNamePrefix="select2-selection"
                    getOptionLabel={e => e.name}
                    isClearable
                    getOptionValue={e => e._id}
                    placeholder={"Select Routes"}
                    options={routesList}
                    onChange={handleRoutes}
                  />
                ) : (
                  <div
                    className="p-2"
                    style={{ border: "1px solid #ced4da", borderRadius: "4px" }}
                  >
                    <Spinner animation="border" size="sm" />
                  </div>
                )}
              </div>
            </div>

            <div className="d-flex mt-2"></div>
          </CardComponent>
        </Row>
        {/* row */}

        {/* table */}
        <Row>
          <CardComponent className="user_table_card">
            {/* <Row className="mb-2">
              <Col md="12">
                <div className="search-box me-2 mb-2 d-flex justify-content-end">
                  <SearchInput
                    className="userSearchBar form-control"
                    onChange={() => {}}
                  />
                </div>
              </Col>
            </Row> */}

            {/* table */}
            <div>
              {/* <button onClick={onOpenModal}>Open modal</button> */}
              {sitesLoading ? (
                <></>
              ) : (
                <Modal
                  open={open}
                  onClose={onCloseModal}
                  center
                  classNames={{
                    overlay: "customOverlay",
                    modal: "modal-main-wrap-fgf",
                  }}
                >
                  <Container>
                    <div className="popup-main-area-data-site-information">
                      <div className="site-info-popup-title">
                        <h3>Site Information</h3>
                      </div>
                      <Row>
                        <Col lg={6}>
                          <div className="x-site-info-single-data-wrap">
                            <div className="single-data-x-item">
                              <div className="x-data-title">
                                <p>LA User</p>
                              </div>
                              <div className="x-data-middle">:</div>
                              <div className="x-data-dec">
                                <p>{sitesViewData?.site?.UserId?.name}</p>
                              </div>
                            </div>{" "}
                            <div className="single-data-x-item">
                              <div className="x-data-title">
                                <p>Visit to</p>
                              </div>
                              <div className="x-data-middle">:</div>
                              <div className="x-data-dec">
                                <p>{sitesViewData?.site?.VisitTo}</p>
                              </div>
                            </div>{" "}
                            <div className="single-data-x-item">
                              <div className="x-data-title">
                                <p>Site Name</p>
                              </div>
                              <div className="x-data-middle">:</div>
                              <div className="x-data-dec">
                                <p>{sitesViewData?.site?.SiteName}</p>
                              </div>
                            </div>{" "}
                            <div className="single-data-x-item">
                              <div className="x-data-title">
                                <p>Site Address</p>
                              </div>
                              <div className="x-data-middle">:</div>
                              <div className="x-data-dec">
                                <p>{sitesViewData?.site?.SiteAddress}</p>
                              </div>
                            </div>{" "}
                            <div className="single-data-x-item">
                              <div className="x-data-title">
                                <p>Visit Date</p>
                              </div>
                              <div className="x-data-middle">:</div>
                              <div className="x-data-dec">
                                <p>{sitesViewData?.site?.VisitDate}</p>
                              </div>
                            </div>{" "}
                            <div className="single-data-x-item">
                              <div className="x-data-title">
                                <p>Check In Time</p>
                              </div>
                              <div className="x-data-middle">:</div>
                              <div className="x-data-dec">
                                <p>{sitesViewData?.site?.CheckInTime}</p>
                              </div>
                            </div>{" "}
                            <div className="single-data-x-item">
                              <div className="x-data-title">
                                <p>Type of Structure </p>
                              </div>
                              <div className="x-data-middle">:</div>
                              <div className="x-data-dec">
                                <p>{sitesViewData?.site?.TypeOfStructure}</p>
                              </div>
                            </div>{" "}
                            <div className="single-data-x-item">
                              <div className="x-data-title">
                                <p>Time of Visit </p>
                              </div>
                              <div className="x-data-middle">:</div>
                              <div className="x-data-dec">
                                <p>{sitesViewData?.site?.TimeOfVisit}</p>
                              </div>
                            </div>{" "}
                            <div className="single-data-x-item">
                              <div className="x-data-title">
                                <p>Information Availability </p>
                              </div>
                              <div className="x-data-middle">:</div>
                              <div className="x-data-dec">
                                <p>
                                  {sitesViewData?.site?.InformationAvailability}
                                </p>
                              </div>
                            </div>{" "}
                            <div className="single-data-x-item">
                              <div className="x-data-title">
                                <p>Site Category</p>
                              </div>
                              <div className="x-data-middle">:</div>
                              <div className="x-data-dec">
                                <p>{sitesViewData?.site?.CategoryId?.name}</p>
                              </div>
                            </div>{" "}
                            <div className="single-data-x-item">
                              <div className="x-data-title">
                                <p> Sub Category</p>
                              </div>
                              <div className="x-data-middle">:</div>
                              <div className="x-data-dec">
                                <p>
                                  {sitesViewData?.site?.SubCategoryId?.name}
                                </p>
                              </div>
                            </div>{" "}
                            <div className="single-data-x-item">
                              <div className="x-data-title">
                                <p>Opprtunity Block</p>
                              </div>
                              <div className="x-data-middle">:</div>
                              <div className="x-data-dec">
                                <p>{sitesViewData?.site?.OpportunityBlock}</p>
                              </div>
                            </div>{" "}
                            <div className="single-data-x-item">
                              <div className="x-data-title">
                                <p>Construction Stage</p>
                              </div>
                              <div className="x-data-middle">:</div>
                              <div className="x-data-dec">
                                <p>{sitesViewData?.site?.ConstructionStage}</p>
                              </div>
                            </div>{" "}
                            <div className="single-data-x-item">
                              <div className="x-data-title">
                                <p>Current Stage of Work</p>
                              </div>
                              <div className="x-data-middle">:</div>
                              <div className="x-data-dec">
                                <p>{sitesViewData?.site?.CurrentStageOfWork}</p>
                              </div>
                            </div>{" "}
                          </div>
                        </Col>
                        <Col lg={6}>
                          <div className="x-site-info-single-data-wrap">
                            <div className="single-data-x-item">
                              <div className="x-data-title">
                                <p>No of Floor Above Ground</p>
                              </div>
                              <div className="x-data-middle">:</div>
                              <div className="x-data-dec">
                                <p>
                                  {sitesViewData?.site?.NoOfFloorAboveGround}
                                </p>
                              </div>
                            </div>{" "}
                            <div className="single-data-x-item">
                              <div className="x-data-title">
                                <p>No of Floor in Basement</p>
                              </div>
                              <div className="x-data-middle">:</div>
                              <div className="x-data-dec">
                                <p>
                                  {sitesViewData?.site?.NoOfFloorInBasement}
                                </p>
                              </div>
                            </div>{" "}
                            <div className="single-data-x-item">
                              <div className="x-data-title">
                                <p>Structure Floor Area</p>
                              </div>
                              <div className="x-data-middle">:</div>
                              <div className="x-data-dec">
                                <p>{sitesViewData?.site?.StructureFloorArea}</p>
                              </div>
                            </div>{" "}
                            <div className="single-data-x-item">
                              <div className="x-data-title">
                                <p>Height Of the Structure Wall</p>
                              </div>
                              <div className="x-data-middle">:</div>
                              <div className="x-data-dec">
                                <p>
                                  {
                                    sitesViewData?.site
                                      ?.HeightOfTheStructureWall
                                  }
                                </p>
                              </div>
                            </div>{" "}
                            <div className="single-data-x-item">
                              <div className="x-data-title">
                                <p>Roof Area</p>
                              </div>
                              <div className="x-data-middle">:</div>
                              <div className="x-data-dec">
                                <p>{sitesViewData?.site?.RoofArea}</p>
                              </div>
                            </div>{" "}
                            <div className="single-data-x-item">
                              <div className="x-data-title">
                                <p>Wet Surface Area</p>
                              </div>
                              <div className="x-data-middle">:</div>
                              <div className="x-data-dec">
                                <p>{sitesViewData?.site?.wetSurfaceArea}</p>
                              </div>
                            </div>{" "}
                            <div className="single-data-x-item">
                              <div className="x-data-title">
                                <p>Basement Floor Area</p>
                              </div>
                              <div className="x-data-middle">:</div>
                              <div className="x-data-dec">
                                <p>{sitesViewData?.site?.BasementFloorArea}</p>
                              </div>
                            </div>{" "}
                            <div className="single-data-x-item">
                              <div className="x-data-title">
                                <p>Exterior Area</p>
                              </div>
                              <div className="x-data-middle">:</div>
                              <div className="x-data-dec">
                                <p>{sitesViewData?.site?.ExteriorArea}</p>
                              </div>
                            </div>{" "}
                            <div className="single-data-x-item">
                              <div className="x-data-title">
                                <p>Interior Area</p>
                              </div>
                              <div className="x-data-middle">:</div>
                              <div className="x-data-dec">
                                <p>{sitesViewData?.site?.InteriorArea}</p>
                              </div>
                            </div>{" "}
                            <div className="single-data-x-item">
                              <div className="x-data-title">
                                <p>Paint Potetial Area</p>
                              </div>
                              <div className="x-data-middle">:</div>
                              <div className="x-data-dec">
                                <p>{sitesViewData?.site?.PaintPotentialArea}</p>
                              </div>
                            </div>{" "}
                            <div className="single-data-x-item">
                              <div className="x-data-title">
                                <p>Paint Pot</p>
                              </div>
                              <div className="x-data-middle">:</div>
                              <div className="x-data-dec">
                                <p>{sitesViewData?.site?.PaintPot}</p>
                              </div>
                            </div>{" "}
                            <div className="single-data-x-item">
                              <div className="x-data-title">
                                <p>Paint Stage</p>
                              </div>
                              <div className="x-data-middle">:</div>
                              <div className="x-data-dec">
                                <p>{sitesViewData?.site?.PaintStages}</p>
                              </div>
                            </div>{" "}
                            <div className="single-data-x-item">
                              <div className="x-data-title">
                                <p>WPCC</p>
                              </div>
                              <div className="x-data-middle">:</div>
                              <div className="x-data-dec">
                                <p>{sitesViewData?.site?.WPCC}</p>
                              </div>
                            </div>{" "}
                            <div className="single-data-x-item">
                              <div className="x-data-title">
                                <p>DECOR</p>
                              </div>
                              <div className="x-data-middle">:</div>
                              <div className="x-data-dec">
                                <p>{sitesViewData?.site?.DECO}</p>
                              </div>
                            </div>{" "}
                            <div className="single-data-x-item">
                              <div className="x-data-title">
                                <p>Construction Pot</p>
                              </div>
                              <div className="x-data-middle">:</div>
                              <div className="x-data-dec">
                                <p>{sitesViewData?.site?.ConstructionPOT}</p>
                              </div>
                            </div>{" "}
                            <div className="single-data-x-item">
                              <div className="x-data-title">
                                <p>Region</p>
                              </div>
                              <div className="x-data-middle">:</div>
                              <div className="x-data-dec">
                                <p>{sitesViewData?.site?.RegionId?.name}</p>
                              </div>
                            </div>{" "}
                            <div className="single-data-x-item">
                              <div className="x-data-title">
                                <p>Area</p>
                              </div>
                              <div className="x-data-middle">:</div>
                              <div className="x-data-dec">
                                <p>{sitesViewData?.site?.AreaId?.name}</p>
                              </div>
                            </div>{" "}
                            <div className="single-data-x-item">
                              <div className="x-data-title">
                                <p>Territory</p>
                              </div>
                              <div className="x-data-middle">:</div>
                              <div className="x-data-dec">
                                <p>{sitesViewData?.site?.TerritoryId?.name}</p>
                              </div>
                            </div>{" "}
                            <div className="single-data-x-item">
                              <div className="x-data-title">
                                <p>Route</p>
                              </div>
                              <div className="x-data-middle">:</div>
                              <div className="x-data-dec">
                                <p>{sitesViewData?.site?.RouteId?.name}</p>
                              </div>
                            </div>{" "}
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Container>
                </Modal>
              )}
            </div>
            <Row>
              <CardComponent className="user_table_card">
                <TableHeader
                  // handleSearchFilter={() => {}}
                  handleSelectFilter={e => {
                    setDataRange(e.target.value)
                  }}
                  data={[100, 200, 500, 1000]}
                  handleSelectFilter2={() => {}}
                  data2={[]}
                  // title="Total Data : {sitesLength?.length}"
                  selectValue={dataRange}
                  handleExcel={handleExcel}
                  isLoading={downloadExcel}
                  headerSearchOption="yes"
                  headerButtonOption="yes"
                />
                <CustomTable
                  paginationClass="paginationContainer text-right"
                  data={sitesData}
                  tableHead={tableHead}
                  setCurrentPage={setCurrentPage}
                  pageNo={totalPageNumber}
                  isPagination
                >
                  {sitesLoading ? (
                    <NoTableData
                      colSpan={tableHead.length}
                      className="d-flex justify-content-center align-items-center"
                      style={{ minHeight: "300px", width: `100%` }}
                    >
                      <p className="text-center">
                        <i className="bx bx-loader bx-spin"></i> Loading...
                      </p>
                    </NoTableData>
                  ) : sitesData?.length ? (
                    sitesData?.map((data, idx) => (
                      <tr key={idx}>
                        <th scope="row">{idx + 1}</th>
                        <td>{data?.VisitTo}</td>
                        <td>{data?.SiteName}</td>
                        <td>{data?.InformationAvailability}</td>
                        <td>{data?.CategoryId?.name}</td>
                        <td>{data?.SubCategoryId?.name}</td>
                        <td>{moment(data?.VisitDate).format("DD-MM-YYYY")}</td>
                        <td>{data?.TypeOfStructureShort}</td>
                        <td>{data?.PaintPot}</td>
                        <td>
                          <OverlayTrigger
                            placement="bottom"
                            delay={{ show: 250, hide: 400 }}
                            overlay={renderTooltipView}
                          >
                            <button
                              className="btn button btn-sm"
                              style={{ borderRadius: "10px" }}
                              onClick={() => onOpenModal(data)}
                            >
                              <i className="bx bx-show mt-1"></i>
                            </button>
                          </OverlayTrigger>
                        </td>
                        <td>
                          <OverlayTrigger
                            placement="bottom"
                            delay={{ show: 250, hide: 400 }}
                            overlay={renderTooltipEdit}
                          >
                            <button
                              className="btn btn-outline-primary btn-sm"
                              style={{ borderRadius: "10px" }}
                              onClick={() => handleEditSite(data)}
                            >
                              <i className="bx bx-edit mt-1"></i>
                            </button>
                          </OverlayTrigger>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <NoTableData
                      colSpan={tableHead.length}
                      className="d-flex justify-content-center align-items-center"
                      style={{ minHeight: "300px", width: `100%` }}
                    >
                      <span>Currently you have no Sites.</span>
                    </NoTableData>
                  )}
                </CustomTable>
                {/* table end */}
              </CardComponent>
            </Row>
          </CardComponent>
        </Row>
        {/* <div className="route-excel">
          <div>
            <p className="mb-0">Download Excelsheet</p>
          </div>
          <div>
            <button className="route-excel-download" onClick={handleExcel}>
              {" "}
              <img></img>Download
            </button>
          </div>
        </div> */}
      </InnerLayer>
    </React.Fragment>
  )
}

Projects.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
}

export default withRouter(Projects)
