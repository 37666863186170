import CustomInput from "components/Common/CustomInput"
import CardComponent from "components/Layout/CardComponent"
import InnerLayer from "components/Layout/InnerLayer"
import { addGeoSchema } from "components/Schemas/AddGeo.schema"
import AddUserUpload from "components/Users/AddUserUpload"
import { useFormik } from "formik"
import React, { useState } from "react"
import { Col, Form, Row } from "reactstrap"
const breadcrumbItems = [
  {
    title: "Dashboard",
    link: "/dashboard",
  },
  {
    title: "Geo Information",
    link: "/geo-information",
  },
  {
    title: "Add GEO",
    link: "#",
  },
]
const initialValues = {
  region: "",
  area: "",
  territories: "",
}

const AddGeo = () => {
  const [selectedFiles, setSelectedFiles] = useState({})
  console.log(`addGeo ~ selectedFiles`, selectedFiles)
  const onSubmit = values => {
    console.log("values", values)
  }

  const addGeo = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: addGeoSchema,
    onSubmit,
  })

  return (
    <React.Fragment>
      <InnerLayer
        isMultiple
        title={"Add new GEO"}
        breadcrumbItems={breadcrumbItems}
      >
        <Row className="mt-4">
          <Col md="8" className="mx-auto">
            {/* add User */}
            <CardComponent className="">
              <Form className="needs-validation" onSubmit={addGeo.handleSubmit}>
                <CustomInput
                  name={"region"}
                  type={"text"}
                  label={"Your Region"}
                  placeholder={"Type region"}
                  validationType={addGeo}
                />
                <CustomInput
                  name={"area"}
                  type={"text"}
                  label={"Your Area"}
                  placeholder={"Type area"}
                  validationType={addGeo}
                />
                <CustomInput
                  name={"territories"}
                  type={"text"}
                  label={"Your Territories"}
                  placeholder={"Type territories"}
                  validationType={addGeo}
                />
                <div className="d-flex justify-content-start">
                  <input
                    type="submit"
                    value={"Add GEO"}
                    className="btn button "
                  />
                </div>
              </Form>
            </CardComponent>

            {/* file Upload */}
            <AddUserUpload setSelectedFiles={setSelectedFiles} />
          </Col>
        </Row>
      </InnerLayer>
    </React.Fragment>
  )
}

export default AddGeo
