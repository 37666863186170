import CardComponent from "components/Layout/CardComponent"
import React, { useEffect, useState } from "react"
import ReactApexChart from "react-apexcharts"
import { Col, Form, Row } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { Spinner } from "reactstrap"
import { getConstructionStage } from "store/actions"
import { makeQuery } from "../../helpers/Custom/makeQuery"

const Construction = () => {
  const dispatch = useDispatch()
  // useEffect(() => {
  //  dispatch(getPotValue)
  // });
  const [chartValue, setChartValue] = useState("monthly")
  const [year, setYear] = useState("all")
  const [index, setIndex] = useState("value")
  const {
    constructionStage,
    mainFilter,
    subSelectedFilters,
    isSubFilterChanged,
    selectedSubCategory,
    currentSelection,
    constructionStageLoading,
    totalValue,
    authToken,
    role
  } = useSelector(state => ({
    mainFilter: state.DashboardReducer.mainFilter,
    subSelectedFilters: state.DashboardReducer.subSelectedFilters,
    isSubFilterChanged: state.DashboardReducer.isSubFilterChanged,
    selectedSubCategory: state.DashboardReducer.selectedSubCategory,
    currentSelection: state.DashboardReducer.currentSelection,
    constructionStage: state?.AdminReducer?.constructionStage,
    constructionStageLoading: state?.AdminReducer?.constructionStageLoading,
    totalValue: state.DashboardReducer.totalValue,
    authToken : state.Login.token,
    role: state.Login.data?.role,
  }))
  // const query = makeQuery(
  //   mainFilter,
  //   subSelectedFilters,
  //   selectedSubCategory,
  //   currentSelection
  // )
  // console.log("mainfilterChanged", query)
  useEffect(() => {
    const chartQuery = makeQuery(
      mainFilter,
      subSelectedFilters,
      selectedSubCategory,
      currentSelection,
      totalValue,
      chartValue,
      year,
      index
    )
    // console.log(
    //   `file: Construction.js ~ line 111 ~ useEffect ~ chartQuery`,
    //   chartQuery
    // )
    dispatch(getConstructionStage(authToken, chartQuery, 'ADMIN'))
  }, [isSubFilterChanged, chartValue, index])
  const handleChat = e => {
    setChartValue(e.target.value)
  }
  const handleYear = e => {
    setYear(e.target.value)
  }
  // console.log("constructionStage", constructionStage)
  // console.log('potValue',potValue);
  let boqData, paintworkData, subStructureData, superstructureData, monthData
  if (!constructionStageLoading) {
    boqData = constructionStage?.data?.boq
    paintworkData = constructionStage?.data?.paintwork
    subStructureData = constructionStage?.data?.substructure
    superstructureData = constructionStage?.data?.superstructure
    monthData = constructionStage?.data?.month
  }
  let constructBoq,
    constructPaint,
    constructSubStructure,
    constructSuperStructure
  if (!constructionStageLoading) {
    if (constructionStage?.data?.boqs > 10000) {
      constructBoq = Math.round(constructionStage?.data?.boqs / 10000000)
    }
    if (constructionStage?.data?.boqs < 10000) {
      constructBoq = Math.round(constructionStage?.data?.boqs)
    }
    if (constructionStage?.data?.paintworks > 10000) {
      constructPaint = Math.round(
        constructionStage?.data?.paintworks / 10000000
      )
    }
    if (constructionStage?.data?.paintworks < 10000) {
      constructPaint = Math.round(constructionStage?.data?.paintworks)
    }
    if (constructionStage?.data?.substructures > 10000) {
      constructSubStructure = Math.round(
        constructionStage?.data?.substructures / 10000000
      )
    }
    if (constructionStage?.data?.substructures < 10000) {
      constructSubStructure = Math.round(constructionStage?.data?.substructures)
    }
    if (constructionStage?.data?.superstructures > 10000) {
      constructSuperStructure = Math.round(
        constructionStage?.data?.superstructures / 10000000
      )
    }
    if (constructionStage?.data?.superstructures < 10000) {
      constructSuperStructure = Math.round(
        constructionStage?.data?.superstructures
      )
    }
  }
  console.log('constructBoq',constructBoq,
  constructPaint,
  constructSubStructure,
  constructSuperStructure);
  console.log('jjj',constructionStage?.data);

  // const series = [
  //   {
  //     name: "BOQ",
  //     data:boqData || [],
  //   },
  //   {
  //     name: "Sub Structure",
  //     data: subStructureData || [],
  //   },
  //   {
  //     name: "Super Structure",
  //     data: superstructureData ||[],
  //   },
  //   {
  //     name: "Paint Assign",
  //     data:  paintworkData || [],

  //   },
  // ]

  // const options = {
  //   chart: {
  //     height: 350,
  //     type: "area",
  //     toolbar: {
  //       show: false,
  //     },
  //   },
  //   colors: ["#7E57C2", "#F9A825", "#2879FE", "#00BCD5"],
  //   dataLabels: {
  //     enabled: false,
  //   },
  //   stroke: {
  //     curve: "smooth",
  //     width: 2,
  //   },
  //   fill: {
  //     type: "gradient",
  //     gradient: {
  //       shadeIntensity: 1,
  //       inverseColors: false,
  //       opacityFrom: 0.45,
  //       opacityTo: 0.05,
  //       stops: [20, 100, 100, 100],
  //     },
  //   },
  //   xaxis: {
  //     categories: monthData || []
  //   },

  //   markers: {
  //     size: 3,
  //     strokeWidth: 3,

  //     hover: {
  //       size: 4,
  //       sizeOffset: 2,
  //     },
  //   },
  //   legend: {
  //     position: "bottom",
  //     horizontalAlign: "center",
  //   },
  // }
  //   var options = {
  //     series: [{
  //     name: 'BOQ',
  //     type: 'column',
  //     data:  boqData || []
  //   }, {
  //     name: 'Paint Assign',
  //     type: 'column',
  //     data:  paintworkData || []
  //   }, {
  //     name: 'Sub Structure',
  //     type: 'line',
  //     data:  subStructureData  || []
  //   },
  //   {
  //     name: 'Super Structure',
  //     type: 'line',
  //     data:  superstructureData  || []
  //   }
  // ],
  //     chart: {
  //     height: 350,
  //     width: '100%',
  //     type: 'line',
  //     stacked: false
  //   },
  //   plotOptions: {
  //     bar: {
  //       columnWidth: "15%",
  //     },
  //   },
  //   dataLabels: {
  //     enabled: false
  //   },
  //   fill: {
  //     colors: undefined,
  //     opacity: 1,
  //     type: "solid",
  //   },
  //   stroke: {
  //     width: [4, 4, 4, 4]
  //   },

  //   xaxis: {
  //     categories: monthData || [] ,
  //   },
  //   yaxis: [
  //     {
  //       axisTicks: {
  //         show: true,
  //       },
  //       axisBorder: {
  //         show: true,
  //         color: '#008FFB',
  //         offsetX: -10,
  //       },
  //       labels: {
  //         style: {
  //           colors: '#008FFB',
  //         }
  //       },
  //       title: {
  //         text: "BOQ",
  //         style: {
  //           color: '#008FFB',
  //         }
  //       },
  //       tooltip: {
  //         enabled: true
  //       }
  //     },
  //     {
  //       seriesName: 'BOQ',
  //       opposite: true,
  //       axisTicks: {
  //         // show: true,
  //       },
  //       axisBorder: {
  //         show: true,
  //         color: '#00E396',
  //         offsetX: -10,
  //       },
  //       labels: {
  //         style: {
  //           colors: '#00E396',

  //         }
  //       },
  //       title: {
  //         text: "Paint Assign",
  //         style: {
  //           color: '#00E396',
  //         }
  //       },
  //     },
  //     {
  //       seriesName: 'Sub Structure',
  //       opposite: true,
  //       axisTicks: {
  //         show: true,
  //       },
  //       axisBorder: {
  //         show: true,
  //         color: '#FEB019',
  //         offsetX: 10,
  //         // padding:10
  //       },
  //       labels: {
  //         style: {
  //           colors: '#FEB019',
  //         },
  //       },
  //       title: {
  //         text: "Sub Structure",
  //         style: {
  //           color: '#FEB019',
  //         }
  //       }
  //     },
  //     {
  //       seriesName: 'Sub Structure',
  //       opposite: true,
  //       axisTicks: {
  //         show: true,
  //       },
  //       axisBorder: {
  //         show: true,
  //         color: '#FF4560',
  //         offsetX: 10,
  //         // padding:10
  //       },
  //       labels: {
  //         style: {
  //           colors: '#FF4560',
  //         },
  //       },
  //       title: {
  //         text: "Super Structure",
  //         style: {
  //           color: '#FF4560',
  //         }
  //       }
  //     },
  //   ],
  //   tooltip: {
  //     fixed: {
  //       enabled: true,
  //       position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
  //       offsetY: 30,
  //       offsetX: 60
  //     },
  //   },
  //   legend: {
  //     horizontalAlign: 'center',
  //     offsetX: 40
  //   }
  //   };

  var options = {
    series: [
      {
        data: [
          {
            x: "BOQ",
            y: Math.round(constructionStage?.data?.boqs),
          },

          {
            x: "Paint Assign",
            y: Math.round(constructionStage?.data?.paintworks),
          },
          {
            x: "Sub Structure",
            y: Math.round(constructionStage?.data?.substructures),
          },
          {
            x: "Super Structure",
            y: Math.round(constructionStage?.data?.superstructures),
          },
        ],
      },
    ],
    // legend: {
    //   show: false,
    // },
    chart: {
      height: 350,
      type: "treemap",
      foreColor: "#fff",
    },
    title: {
      // text: 'Basic Treemap'
    },
    fill: {
      colors: undefined,
      opacity: 1,
      type: "solid",
    },
    dataLabels: {
      enabled: true,
      style: {
        fontSize: "20px",
        //  colors: ["#FFFFFF","#FFFFFF","#FFFFFF","#FFFFFF"]
      },
    },
    colors: ["#421243", "#C1F666", "#EF6537", "#1E5D8C"],
    plotOptions: {
      treemap: {
        distributed: true,
        enableShades: false,
      },
    },
  }
  var valueOption = {
    series: [
      {
        data: [
          {
            x: "BOQ (cr)",
            y: Math.round(constructionStage?.data?.boqs / 10000000) || [],
          },

          {
            x: "Paint Assign (cr)",
            y: Math.ceil(constructionStage?.data?.paintworks / 10000000) || [],
          },
          {
            x: "Sub Structure (cr)",
            y: Math.round(constructionStage?.data?.substructures / 10000000),
          },
          {
            x: "Super Structure (cr)",
            y: Math.round(constructionStage?.data?.superstructures / 10000000),
          },
        ],
      },
    ],
    // legend: {
    //   show: false
    // },
    chart: {
      height: 350,
      type: "treemap",
      foreColor: "#fff",
    },
    title: {
      // text: 'Basic Treemap'
    },
    fill: {
      colors: undefined,
      opacity: 1,
      type: "solid",
    },
    dataLabels: {
      enabled: true,
      style: {
        //fontSize: "50px",
        colors: ["#ffffff", "#ffffff", "#ffffff", "#ffffff"],
      },

      formatter: function (val, opt) {
        const removeAll = val.replaceAll("(cr)", "")
        return removeAll
      },
    },
    colors: ["#421243", "#C1F666", "#EF6537", "#1E5D8C"],
    plotOptions: {
      treemap: {
        distributed: true,
        enableShades: false,
      },
    },
    legend: {
      horizontalAlign: "center",
      offsetX: 40,
    },
  }

  const handleIndex = e => {
    setIndex(e.target.value)
  }
  return (
    <CardComponent>
      {constructionStageLoading ? (
        <div className="text-center mt-5 pt-5">
          <Spinner />
        </div>
      ) : (
        <div className="construction-section">
          <div className="d-flex flex-wrap justify-content-between align-items-center">
            <h6 className="card-title me-2">Construction Stage</h6>
            {/* <div className="extra-large-select-area">
            <Form.Select aria-label="Default select" onChange={handleChat}>
            <option value="monthly">Monthly</option>
              <option value="weekly">Weekly</option>
              <option value="daily">Daily</option>
            </Form.Select>
          </div> */}
            <div className="d-flex">
              {/* <div className="select-area me-2">
            <Form.Select aria-label="Default select" onChange={handleChat}>
              <option value="monthly">Monthly</option>
              <option value="weekly">Weekly</option>
              <option value="daily">Daily</option>
            </Form.Select>
          </div> */}
              <div className="select-area">
                {/* <Form.Select aria-label="Default select" onChange={handleYear}>
            <option value="all">All Year</option>
              <option value="2021">2021</option>
              <option value="2022">2022</option>
             
            </Form.Select> */}
                <Form.Select
                  aria-label="Default select"
                  value={index}
                  onChange={handleIndex}
                >
                  {/* <option value="">Select</option> */}
                  <option value="value">Value</option>
                  <option value="volume">Volume</option>
                </Form.Select>
              </div>
            </div>
          </div>

          <Row className="mt-4">
            <Col lg={3}>
              <div className="card">
                <span className="boq-structure-border-left-area"></span>
                {index === "value" ? (
                  <div className="card-body">
                    <div className="d-flex justify-content-center">
                      <p className="">BOQ </p>
                    </div>
                    <h4 className="text-center">
                      {Math.round(constructionStage?.data?.boqs / 10000000)} cr
                    </h4>
                  </div>
                ) : (
                  <div className="card-body">
                    <div className="d-flex justify-content-center">
                      <p className="">BOQ</p>
                    </div>
                    <h4 className="text-center">
                      {Math.round(constructionStage?.data?.boqs)}
                    </h4>
                  </div>
                )}
              </div>
            </Col>
            <Col lg={3}>
              <div className="card">
                <span className="paint-assigned-border-left-area"></span>
                {index === "value" ? (
                  <div className="card-body">
                    <div className="d-flex justify-content-center">
                      <p className="">Paint Assigned</p>
                    </div>
                    <h4 className="text-center">
                      {Math.ceil(
                        constructionStage?.data?.paintworks / 10000000
                      )}{" "}
                      cr
                    </h4>
                  </div>
                ) : (
                  <div className="card-body">
                    <div className="d-flex justify-content-center">
                      <p className="">Paint Assigned</p>
                    </div>
                    <h4 className="text-center">
                      {Math.round(constructionStage?.data?.paintworks)}
                    </h4>
                  </div>
                )}
              </div>
            </Col>
            <Col lg={3}>
              <div className="card">
                <span className="sub-structure-border-left-area"></span>
                {index === "value" ? (
                  <div className="card-body">
                    <div className="d-flex justify-content-center">
                      <p className="">Sub Structure</p>
                    </div>
                    <h4 className="text-center">
                      {Math.round(
                        constructionStage?.data?.substructures / 10000000
                      )}{" "}
                      cr
                    </h4>
                  </div>
                ) : (
                  <div className="card-body">
                    <div className="d-flex justify-content-center">
                      <p className="">Sub Structure</p>
                    </div>
                    <h4 className="text-center">
                      {Math.round(constructionStage?.data?.substructures)}
                    </h4>
                  </div>
                )}
              </div>
            </Col>
            <Col lg={3}>
              <div className="card">
                <span className="super-structure-border-left-area"></span>
                {index === "value" ? (
                  <div className="card-body">
                    <div className="d-flex justify-content-center">
                      <p className="">Super Structure</p>
                    </div>
                    <h4 className="text-center">
                      {Math.round(
                        constructionStage?.data?.superstructures / 10000000
                      )}{" "}
                      cr
                    </h4>
                  </div>
                ) : (
                  <div className="card-body">
                    <div className="d-flex justify-content-center">
                      <p className="">Super Structure</p>
                    </div>
                    <h4 className="text-center">
                      {Math.round(constructionStage?.data?.superstructures)}
                    </h4>
                  </div>
                )}
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <CardComponent>
                <h6 className="ms-3 font-size-16">Construction Stage</h6>
                <div id="chart" dir="ltr">
                  {index === "value" ? (
                    <ReactApexChart
                      options={valueOption}
                      series={valueOption?.series || []}
                      type="treemap"
                      height={350}
                      className="apex-charts construction-chart"
                    />
                  ) : (
                    <ReactApexChart
                      options={options}
                      series={options?.series || []}
                      type="treemap"
                      height={350}
                      className="apex-charts construction-chart"
                    />
                  )}
                </div>
              </CardComponent>
            </Col>
          </Row>
        </div>
      )}
    </CardComponent>
  )
}

export default Construction
