import CustomInput from "components/Common/CustomInput"
import CardComponent from "components/Layout/CardComponent"
import InnerLayer from "components/Layout/InnerLayer"
import { AddSubCategorySchema } from "components/Schemas/AddSubCategory.schema"
import { useFormik } from "formik"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory, useParams } from "react-router-dom"
import { Col, Form, Row } from "reactstrap"
import {
  getCategoryValue,
  updateSubCategory,
} from "../../../store/Category/action"
const breadcrumbItems = [
  {
    title: "Sub Category",
    link: "/sub-categories",
  },
  {
    title: "Edit Sub Category",
    link: "#",
  },
]
const initialValues = {
  categoryId: "",
  name: "",
}

const EditSubCategory = () => {
  const history = useHistory()
  let dispatch = useDispatch()
  const { id: subCategoryId } = useParams()
  const [subCategoryInfo, setSubCategoryInfo] = useState(null)
  //get region
  const { editSubCategoryInfo, categoryData, authtoken } = useSelector(
    state => ({
      loading: state.CategoryReducer.isLoading,
      authtoken: state.Login.token,
      categoryData: state.CategoryReducer.categoryData,
      editSubCategoryInfo: state.CategoryReducer.editSubCategoryInfo,
    })
  )

  useEffect(() => {
    dispatch(getCategoryValue(authtoken))
  }, [])
  useEffect(() => {
    const oldSubCategoryInfo = { ...subCategoryInfo }
    oldSubCategoryInfo.name = editSubCategoryInfo?.data?.name
    oldSubCategoryInfo.categoryId = editSubCategoryInfo?.data?.categoryId?._id
    setSubCategoryInfo(oldSubCategoryInfo)
  }, [editSubCategoryInfo])

  //get region
  const onSubmit = values => {
    console.log("category data here", subCategoryInfo)
    dispatch(updateSubCategory(subCategoryId, values, authtoken, history))
  }

  const UpdateSubCategory = useFormik({
    enableReinitialize: true,
    initialValues: subCategoryInfo || initialValues,
    validationSchema: AddSubCategorySchema,
    onSubmit,
  })
  console.log("hhhh", editSubCategoryInfo?.data?.categoryId?._id)
  return (
    <React.Fragment>
      <InnerLayer
        isMultiple
        title={"Edit Sub Category"}
        breadcrumbItems={breadcrumbItems}
      >
        <div style={{ minHeight: "100vh" }} className="position-relative">
          <Row className="mt-4">
            <Col md="6" className="mx-auto">
              {/* add User */}
              <CardComponent className="">
                <Form
                  className="needs-validation"
                  onSubmit={UpdateSubCategory.handleSubmit}
                >
                  <CustomInput
                    name={"categoryId"}
                    type={"select"}
                    label={"Select Category"}
                    validationType={UpdateSubCategory}
                  >
                    <option defaultValue>Select....</option>
                    {categoryData?.length > 0 ? (
                      categoryData?.category.map((data, idx) => (
                        <option value={data._id} key={idx}>
                          {data.name}
                        </option>
                      ))
                    ) : (
                      <span></span>
                    )}
                  </CustomInput>

                  <CustomInput
                    name={"name"}
                    type={"text"}
                    label={"Type Sub Category"}
                    placeholder={"Sub Category name"}
                    validationType={UpdateSubCategory}
                  />
                  <div className="d-flex justify-content-start">
                    <input
                      type="submit"
                      value={"Update Sub Category"}
                      className="btn button"
                    />
                  </div>
                </Form>
              </CardComponent>
            </Col>
          </Row>
        </div>
      </InnerLayer>
    </React.Fragment>
  )
}

export default EditSubCategory
