//ADD SEGMRNT
export const ADD_NEW_SEGMENT = "ADD_NEW_SEGMENT"
export const ADD_SEGMENT_SUCCESS = "ADD_SEGMENT_SUCCESS"
export const ADD_SEGMENT_FAIL = "ADD_SEGMENT_FAIL"

//GET SEGMRNT
export const GET_SEGMENT_VALUE = "GET_SEGMENT_VALUE"
export const GET_SEGMENT_VALUE_SUCCESS = "GET_SEGMENT_VALUE_SUCCESS"
export const GET_SEGMENT_VALUE_FAIL = "GET_SEGMENT_VALUE_FAIL"

//EDIT SEGMRNT
export const UPDATE_SEGMENT = "UPDATE_SEGMENT"
export const UPDATE_SEGMENT_SUCCESS = "UPDATE_SEGMENT_SUCCESS"
export const UPDATE_SEGMENT_FAIL = "UPDATE_SEGMENT_FAIL"

export const STORE_SEGMENT_DATA = "STORE_SEGMENT_DATA"
