import CustomInput from "components/Common/CustomInput"
import CardComponent from "components/Layout/CardComponent"
import InnerLayer from "components/Layout/InnerLayer"
import { addRouteSchema } from "components/Schemas/AddRoute.schema"
import { useFormik } from "formik"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory, useParams } from "react-router-dom"
import { Col, Form, Row } from "reactstrap"
import {
  getAreaByRegionValue,
  getRegionValue,
  getTerritoryValue,
  updateRoute,
} from "../../../store/GeoInformation/action"
const breadcrumbItems = [
  {
    title: "Geo Information",
    link: "#",
  },
  {
    title: "Route",
    link: "/geo/route",
  },
  {
    title: "Edit Route",
    link: "#",
  },
]
const initialValues = {
  region: "",
  area: "",
  territory: "",
}

const EditRoute = () => {
  const history = useHistory()
  let dispatch = useDispatch()
  const { id: routeId } = useParams()
  const [routeInfo, setRouteInfo] = useState(null)
  //get region, area,territory
  const {
    regionData,
    areaByRegionData,
    territoryData,
    authtoken,
    editRouteInfo,
  } = useSelector(state => ({
    authtoken: state.Login.token,
    regionData: state.GeoInformationReducer.regionData,
    areaByRegionData: state.GeoInformationReducer.areaByRegionData,
    territoryData: state.GeoInformationReducer.territoryData,
    editRouteInfo: state.GeoInformationReducer.editRouteInfo,
  }))
  useEffect(() => {
    const oldRouteInfo = { ...routeInfo }
    oldRouteInfo.name = editRouteInfo?.data?.name
    oldRouteInfo.territory = editRouteInfo?.data?.territory?._id
    oldRouteInfo.area = editRouteInfo?.data?.territory?.area?._id
    oldRouteInfo.region = editRouteInfo?.data?.territory?.area?.region?._id
    setRouteInfo(oldRouteInfo)
    setRouteInfo(oldRouteInfo)
  }, [editRouteInfo])

  useEffect(() => {
    dispatch(getRegionValue(authtoken))
    dispatch(
      getAreaByRegionValue(
        authtoken,
        editRouteInfo?.data?.territory?.area?.region?._id,
        0,
        0
      )
    )
    dispatch(getTerritoryValue(authtoken))
  }, [])
  //end get region, area,territory
  const onSubmit = values => {
    dispatch(updateRoute(routeId, values, authtoken, history))
  }

  const RouteEdit = useFormik({
    enableReinitialize: true,
    initialValues: routeInfo || initialValues,
    validationSchema: addRouteSchema,
    onSubmit,
  })
  return (
    <React.Fragment>
      <InnerLayer
        isMultiple
        title={"Edit Route"}
        breadcrumbItems={breadcrumbItems}
      >
        <div style={{ minHeight: "100vh" }} className="position-relative">
          <Row className="mt-4">
            <Col md="6" className="mx-auto">
              {/* add User */}
              <CardComponent className="">
                <Form
                  className="needs-validation"
                  onSubmit={RouteEdit.handleSubmit}
                >
                  <Col className="mx-auto" sm="12">
                    <CustomInput
                      name={"region"}
                      type={"select"}
                      label={"Select region"}
                      validationType={RouteEdit}
                    >
                      <option defaultValue>Select region...</option>
                      {regionData?.length > 0 ? (
                        regionData?.region.map((data, idx) => (
                          <option value={data._id} key={idx}>
                            {data.name}
                          </option>
                        ))
                      ) : (
                        <></>
                      )}
                    </CustomInput>
                  </Col>
                  <Col className="mx-auto" sm="12">
                    <CustomInput
                      name={"area"}
                      type={"select"}
                      label={"Select area"}
                      validationType={RouteEdit}
                    >
                      <option defaultValue>Select area...</option>
                      {areaByRegionData?.length > 0 ? (
                        areaByRegionData?.area.map((data, idx) => (
                          <option value={data._id} key={idx}>
                            {data.name}
                          </option>
                        ))
                      ) : (
                        <></>
                      )}
                    </CustomInput>
                  </Col>
                  <Col className="mx-auto" sm="12">
                    <CustomInput
                      name={"territory"}
                      type={"select"}
                      label={"Select territory"}
                      validationType={RouteEdit}
                    >
                      <option defaultValue>Select territory...</option>
                      {territoryData?.length > 0 ? (
                        territoryData?.territory.map((data, idx) => (
                          <option value={data._id} key={idx}>
                            {data.name}
                          </option>
                        ))
                      ) : (
                        <></>
                      )}
                    </CustomInput>
                  </Col>

                  <CustomInput
                    name={"name"}
                    type={"text"}
                    label={"Type route"}
                    placeholder={"Route name"}
                    validationType={RouteEdit}
                  />
                  <div className="d-flex justify-content-start">
                    <input
                      type="submit"
                      value={"Update Route"}
                      className="btn button "
                    />
                  </div>
                </Form>
              </CardComponent>
            </Col>
          </Row>
        </div>
      </InnerLayer>
    </React.Fragment>
  )
}

export default EditRoute
