import {
  ADD_USER_FAIL,
  ADD_USER_SUCCESS,
  GET_USER_VALUE,
  GET_USER_VALUE_SUCCESS,
  STORE_USER_DATA,
  UPDATE_USER_FAIL,
  UPDATE_USER_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
  isLoading: false,
  userData: [],
  editUserInfo: [],
}

const UserReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_USER_VALUE:
      return {
        ...state,
        isLoading: true,
      }
      break

    case GET_USER_VALUE_SUCCESS:
      return {
        ...state,
        userData: action.payload.data,
        isLoading: false,
      }

    case ADD_USER_SUCCESS:
      return {
        ...state,
        user: [...state.user, action.payload],
        isLoading: false,
      }

    case ADD_USER_FAIL:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      }

    case STORE_USER_DATA:
      return {
        ...state,
        editUserInfo: action.payload,
      }
    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        userData: state.user.map(user =>
          user.id.toString() === action.payload.id.toString()
            ? { user, ...action.payload }
            : user
        ),
        loading: false,
      }

    case UPDATE_USER_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default UserReducer
