import CardComponent from "components/Layout/CardComponent"
import React, { useEffect, useState } from "react"
import ReactApexChart from "react-apexcharts"
import { Col, Row } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { Spinner } from "reactstrap"
import { getStructureType } from "store/actions"
import NewStructure from "../../assets/images/xelerator/icon/dashboard/new-structure.svg"
import OldStructure from "../../assets/images/xelerator/icon/dashboard/old-structure.svg"
import { makeQuery } from "../../helpers/Custom/makeQuery"

const Structure = () => {
  const dispatch = useDispatch()
  // useEffect(() => {
  //  dispatch(getPotValue)
  // });
  const [chartValue, setChartValue] = useState("monthly")
  const [year, setYear] = useState("all")
  const {
    structureType,
    mainFilter,
    subSelectedFilters,
    isSubFilterChanged,
    selectedSubCategory,
    currentSelection,
    structureLoading,
    totalValue,
    authToken,
    role
  } = useSelector(state => ({
    mainFilter: state.DashboardReducer.mainFilter,
    subSelectedFilters: state.DashboardReducer.subSelectedFilters,
    isSubFilterChanged: state.DashboardReducer.isSubFilterChanged,
    selectedSubCategory: state.DashboardReducer.selectedSubCategory,
    currentSelection: state.DashboardReducer.currentSelection,
    structureType: state?.AdminReducer?.structureType,
    structureLoading: state?.AdminReducer?.structureLoading,
    totalValue: state.DashboardReducer.totalValue,
    authToken : state.Login.token,
    role: state.Login.data?.role,
  }))
  //  console.log('structureType',structureType);
  useEffect(() => {
    const chartQuery = makeQuery(
      mainFilter,
      subSelectedFilters,
      selectedSubCategory,
      currentSelection,
      totalValue,
      chartValue,
      year
    )
    // console.log(
    //   `file: Structure.js ~ line 111 ~ useEffect ~ chartQuery`,
    //   chartQuery
    // )

    dispatch(getStructureType(authToken, chartQuery, 'ADMIN'))
  }, [isSubFilterChanged, chartValue, year])
  const handleChat = e => {
    setChartValue(e.target.value)
  }
  const handleYear = e => {
    setYear(e.target.value)
  }
  // console.log("structureType", structureType)
  let newStructureData, oldStructureData, monthData
  if (!structureLoading) {
    newStructureData = structureType?.data?.newstructure
    oldStructureData = structureType?.data?.oldstructure
    monthData = structureType?.data?.month
  }
  // const series = [
  //   {
  //     name: "New Structure",
  //     data: newStructureData || [],
  //     type: "column",
  //   },
  //   {
  //     name: "Old Structure",
  //     data: oldStructureData || [] ,
  //     type: "column"
  //   },
  // ]

  // const options = {
  //   chart: {
  //     height: 350,
  //     type: "area",
  //     toolbar: {
  //       show: false,
  //     },
  //   },
  //   plotOptions: {
  //     bar: {
  //       columnWidth: "20%",
  //     },
  //   },
  //   colors: ["#D500FA", "#6A1B9A"],
  //   dataLabels: {
  //     enabled: false,
  //   },
  //   stroke: {
  //     curve: "smooth",
  //     width: 2,
  //   },
  //   fill: {
  //     colors: undefined,
  //     opacity: 1,
  //     type: "solid",
  //   },
  //   stroke: {
  //     width: [4, 1],
  //   },
  //    colors: ["#34C38F", "#FFA701"],
  //   xaxis: {
  //     categories: monthData || []
  //   },
  //   yaxis: {

  //     logBase: 10,
  //       axisTicks: {
  //         show: false,
  //       },
  //       axisBorder: {
  //         show: false,
  //         color: "#34C38F",
  //         offsetX: -10,
  //       },

  //     labels: {
  //       show: false,
  //       style: {
  //         colors: "#34C38F",
  //       },
  //     },
  //   },

  //   // markers: {
  //   //   size: 3,
  //   //   strokeWidth: 3,

  //   //   hover: {
  //   //     size: 4,
  //   //     sizeOffset: 2,
  //   //   },
  //   // },
  //   legend: {
  //     position: "bottom",
  //     horizontalAlign: "center",
  //   },
  // }
  // var options = {
  //   series: [
  //     {
  //       name: "New Structure",
  //       type: "column",
  //       data: newStructureData || [],
  //     },
  //     {
  //       name: "Old Structure",
  //       type: "column",
  //       data: oldStructureData || [],
  //     },
  //   ],
  //   plotOptions: {
  //     bar: {
  //       columnWidth: "15%",
  //     },
  //   },
  //   colors: ["#34C38F", "#FFA701"],
  //   chart: {
  //     height: 350,
  //     type: "line",
  //     stacked: false,
  //   },
  //   dataLabels: {
  //     enabled: false,
  //   },
  //   colors: ["#34C38F", "#FFA701"],
  //   fill: {
  //     colors: undefined,
  //     opacity: 1,
  //     type: "solid",
  //   },
  //   stroke: {
  //     width: [4, 0],
  //   },
  //   title: {
  //     // text: 'XYZ - Stock Analysis (2009 - 2016)',
  //     align: "left",
  //     offsetX: 110,
  //   },
  //   xaxis: {
  //     categories: monthData || [],
  //   },
  //   yaxis: [
  //     {
  //       axisTicks: {
  //         show: false,
  //       },
  //       axisBorder: {
  //         show: true,
  //         color: "#34C38F",
  //         offsetX: -10,

  //       },
  //       labels: {
  //         show: false,
  //         style: {
  //           colors: "#34C38F",
  //         },
  //       },
  //       title: {
  //         text: "New Structure",
  //         style: {
  //           color: "#34C38F",
  //         },
  //       },
  //       tooltip: {
  //         enabled: true,
  //       },
  //     },
  //     {
  //       seriesName: "paint",
  //       opposite: true,
  //       axisTicks: {
  //         show: false,
  //       },
  //       axisBorder: {
  //         show: true,
  //         color: "#FFA701",
  //         offsetX: -15,
  //       },
  //       labels: {
  //         show: false,
  //         style: {
  //           colors: "#FFA701",
  //         },
  //       },
  //       title: {
  //         text: "Old Structure",
  //         style: {
  //           color: "#FFA701",
  //         },
  //       },
  //     },

  //   ],
  //   tooltip: {
  //     fixed: {
  //       enabled: true,
  //       position: "topLeft", // topRight, topLeft, bottomRight, bottomLeft
  //       offsetY: 30,
  //       offsetX: 60,
  //     },
  //   },
  //   legend: {
  //     horizontalAlign: "center",
  //     offsetX: 40,
  //   },
  // }

  const state = {
    series: [
      {
        name: "New Structure",
        data: newStructureData || [],
      },
      {
        name: "Old Structure",
        data: oldStructureData || [],
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 280,
        stacked: true,
      },
      plotOptions: {
        bar: {
          horizontal: true,

          barHeight: "30%",
        },
      },
      stroke: {
        width: 1,
        colors: ["#fff"],
      },
      title: {
        // text: 'Fiction Books Sales'
      },
      xaxis: {
        categories: monthData,
        // labels: {
        //   formatter: function (val) {
        //     return val + "K"
        //   }
        // }
        labels: {
          show: false,
          style: {
            // colors: "#556EE6",
            // offsetX: 10,
          },
        },
      },
      yaxis: {
        title: {
          text: undefined,
        },
      },
      colors: ["#34C38F", "#FFA701"],

      tooltip: {
        // y: {
        //   formatter: function (val) {
        //     return val + "K"
        //   }
        // }
      },
      fill: {
        opacity: 1,
      },
      legend: {
        position: "bottom",
        horizontalAlign: "center",
        offsetX: 40,
      },
    },
  }
  return (
    <CardComponent>
      {structureLoading ? (
        <div className="text-center mt-5 pt-5">
          <Spinner />
        </div>
      ) : (
        <div className="structure-section">
          <div className="d-flex flex-wrap justify-content-between align-items-center">
            <h6 className="card-title me-2">Structure Type</h6>
            <div className="d-flex">
              {/* <div className="select-area me-2">
            <Form.Select aria-label="Default select" onChange={handleChat}>
              <option value="monthly">Monthly</option>
              <option value="monthly">Weekly</option>
              <option value="monthly">Daily</option>
            </Form.Select>
          </div> */}
              <div className="select-area">
                {/* <Form.Select aria-label="Default select" onChange={handleYear}>
            <option value="all">All Year</option>
              <option value="2021">2021</option>
              <option value="2022">2022</option>
             
            </Form.Select> */}
              </div>
            </div>
            {/* <div className="select-area">
            <Form.Select aria-label="Default select" onChange={handleChat}>
            <option value="monthly">Monthly</option>
              <option value="weekly">Weekly</option>
              <option value="daily">Daily</option>
            </Form.Select>
          </div> */}
          </div>

          <Row className="mt-4">
            <Col lg={6}>
              <div className="card">
                <span className="new-structure-border-left-area"></span>
                <div className="card-body">
                  <div className="d-flex justify-content-center">
                    <div className="me-2">
                      <img src={NewStructure} alt="Icon" />
                    </div>
                    <p className="">New Structure</p>
                  </div>
                  <h5 className="text-center">
                    {structureType?.data?.newstructures}
                  </h5>
                </div>
              </div>
            </Col>
            <Col lg={6}>
              <div className="card">
                <span className="old-structure-border-left-area"></span>
                <div className="card-body">
                  <div className="d-flex justify-content-center">
                    <div className="me-2">
                      <img src={OldStructure} alt="Icon" />
                    </div>
                    <p className="">Old Structure</p>
                  </div>
                  <h5 className="text-center ms-4">
                    {structureType?.data?.oldstructures}
                  </h5>
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <CardComponent>
                <h6 className="ms-3 font-size-16">Structure Type</h6>
                <div id="area-chart" dir="ltr">
                  <ReactApexChart
                    options={state.options}
                    series={state.series}
                    type="bar"
                    height={350}
                  />
                </div>
              </CardComponent>
            </Col>
          </Row>
        </div>
      )}
    </CardComponent>
  )
}

export default Structure
