import CardComponent from "components/Layout/CardComponent"
import InnerLayer from "components/Layout/InnerLayer"
import React from "react"
import { Col, Row } from "reactstrap"
import BangladeshSection from "./BangladeshSection"
import Construction from "./Construction"
import DashboardTopArea from "./DashboardTopArea"
import Hwc from "./Hwc"
import Paint from "./Paint"
import PotValue from "./PotValue"
import Structure from "./Structure"

const Dashboard = () => {
  return (
    <React.Fragment>
      <InnerLayer>
        <div className="dashboard">
          <Row>
            <Col md="12 mb-4">
              <CardComponent>
                <div className="row">
                  <BangladeshSection />
                </div>
              </CardComponent>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <CardComponent>
                <div className="d-flex justify-content-between text-center dashboard-top-area flex-wrap">
                  <DashboardTopArea />
                </div>
              </CardComponent>
            </Col>
          </Row>
          <Row>
            <Col md="5">
              <PotValue />
            </Col>
            <Col md="7">
              <Hwc />
            </Col>
          </Row>
          <Row>
            <Col md="7">
              <Paint />
            </Col>
            <Col md="5">
              <Structure />
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <Construction />
            </Col>
          </Row>
        </div>
      </InnerLayer>
    </React.Fragment>
  )
}

export default Dashboard
