export const survey = [
  {
    name: "Which company got the project?",
    value: "Maac",
  },
  {
    name: "Site Specification Status",
    value: "New Stracture",
  },
  {
    name: "New Stracture",
    value: "BOQ",
  },
  {
    name: "Project completion timeline",
    value: "1 Month",
  },
]

export const generalInfo = [
  {
    name: "Name",
    value: "Samrat Khan",
  },
  {
    name: "Role",
    value: "SR",
  },
  {
    name: "Email Address",
    value: "samratkhan@gmail.com",
  },
  {
    name: "Phone Number",
    value: "+880 16831 53819",
  },
  {
    name: "Region",
    value: "Dhaka",
  },
  {
    name: "Location",
    value: "3517 W. Gray St. Utica, Pennsylvania 57867",
  },
]
