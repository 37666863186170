import CardComponent from "components/Layout/CardComponent"
import React, { useEffect, useState } from "react"
import ReactApexChart from "react-apexcharts"
import { Col, Row } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { Spinner } from "reactstrap"
import { getPotValue } from "store/actions"
import Construction from "../../assets/images/xelerator/icon/dashboard/construction.svg"
import Paint from "../../assets/images/xelerator/icon/dashboard/paint.svg"
import { makeQuery } from "../../helpers/Custom/makeQuery"

const PotValue = () => {
  const dispatch = useDispatch()
  // useEffect(() => {
  //  dispatch(getPotValue)
  // });
  const [chartValue, setChartValue] = useState("monthly")
  const [year, setYear] = useState("all")

  const {
    potValue,
    mainFilter,
    subSelectedFilters,
    isSubFilterChanged,
    selectedSubCategory,
    currentSelection,
    potValueLoading,
    totalValue,
    authToken,
    role
  } = useSelector(state => ({
    mainFilter: state.DashboardReducer.mainFilter,
    totalValue: state.DashboardReducer.totalValue,
    subSelectedFilters: state.DashboardReducer.subSelectedFilters,
    isSubFilterChanged: state.DashboardReducer.isSubFilterChanged,
    selectedSubCategory: state.DashboardReducer.selectedSubCategory,
    currentSelection: state.DashboardReducer.currentSelection,
    potValue: state?.AdminReducer?.potValue,
    potValueLoading: state?.AdminReducer?.potValueLoading,
    authToken : state.Login.token,
    role: state.Login.data?.role,
  }))

  // const query = makeQuery(
  //   mainFilter,
  //   subSelectedFilters,
  //   selectedSubCategory,
  //   currentSelection
  // )
  // console.log("mainfilterChanged", query)
  useEffect(() => {
    const chartQuery = makeQuery(
      mainFilter,
      subSelectedFilters,
      selectedSubCategory,
      currentSelection,
      totalValue,
      chartValue,
      // "chart",
      year
    )

    dispatch(getPotValue(authToken, chartQuery, 'ADMIN'))
  }, [isSubFilterChanged, chartValue, year])
  const handleChat = e => {
    setChartValue(e.target.value)
  }
  const handleYear = e => {
    setYear(e.target.value)
  }
  // console.log('potValue------',potValue);
  let potValueData = Math.ceil(potValue?.data?.paintpot / 10000000)
  let construction, paint, monthData
  if (!potValueLoading) {
    construction = potValue?.data?.constructionpots
    paint = potValue?.data?.paintpots
    monthData = potValue?.data?.month
  }

  // console.log('construction',construction,'paint',paint);
  // const series = [
  //   {
  //     name: "Construction Pot",
  //     data: construction || [],
  //   },
  //   {
  //     name: "Paint Pot",
  //     data: paint || [],
  //   },
  // ]

  // const options = {
  //   chart: {
  //     height: 350,
  //     type: "area",
  //     toolbar: {
  //       show: false,
  //     },
  //   },
  //   colors: ["#00BA88", "#FF9900"],
  //   dataLabels: {
  //     enabled: false,
  //   },
  //   stroke: {
  //     curve: "smooth",
  //     width: 2,
  //   },
  //   fill: {
  //     type: "gradient",
  //     gradient: {
  //       shadeIntensity: 1,
  //       inverseColors: false,
  //       opacityFrom: 0.45,
  //       opacityTo: 0.05,
  //       stops: [20, 100, 100, 100],
  //     },
  //   },
  //   xaxis: {
  //     categories: [
  //       "Jan",
  //       "Feb",
  //       "Mar",
  //       "Apr",
  //       "May",
  //       "Jun",
  //       "Jul",
  //       "Aug",
  //       "Sep",
  //       "Oct",
  //       "Nov",
  //       "Dec",
  //     ],
  //   },

  //   markers: {
  //     size: 3,
  //     strokeWidth: 3,

  //     hover: {
  //       size: 4,
  //       sizeOffset: 2,
  //     },
  //   },
  //   legend: {
  //     position: "bottom",
  //     horizontalAlign: "center",
  //   },
  // }

  let convertedPaintArray = []
  let convertedConstructionArray = []
  const newArr = paint?.map(paintFunction)

  function paintFunction(num) {
    const convertToCr = Math.ceil(num / 10000000).toLocaleString()
    const addCrsign = convertToCr + "cr"
    convertedPaintArray.push(addCrsign)
  }

  const constructionArr = construction?.map(constructionFunction)
  function constructionFunction(num) {
    const convertToCr = Math.ceil(num).toLocaleString()
    const addCrsign = convertToCr + "cr"
    convertedConstructionArray.push(addCrsign)
  }

  var options = {
    series: [
      {
        name: "Paint",
        type: "line",
        data: convertedPaintArray || [],
      },
      {
        name: "Construction",
        type: "column",
        data: construction || [],
      },
    ],
    plotOptions: {
      bar: {
        columnWidth: "10%",
      },
    },
    colors: ["#34C38F", "#556EE6"],
    chart: {
      height: 350,
      type: "line",
      stacked: false,
    },
    dataLabels: {
      enabled: false,
    },
    colors: ["#34C38F", "#556EE6"],
    fill: {
      colors: undefined,
      opacity: 1,
      type: "solid",
    },
    stroke: {
      width: [4, 1],
    },
    title: {
      // text: 'XYZ - Stock Analysis (2009 - 2016)',
      align: "left",
      offsetX: 110,
    },
    xaxis: {
      categories: monthData || [],
    },
    yaxis: [
      {
        axisTicks: {
          show: false,
        },
        axisBorder: {
          show: false,
          color: "#34C38F",
          offsetX: -10,
        },
        labels: {
          show: false,
          style: {
            colors: "#34C38F",
          },
        },
        title: {
          // text: "Paint",
          style: {
            color: "#34C38F",
          },
        },
        tooltip: {
          enabled: true,
        },
      },
      {
        seriesName: "Construction",
        opposite: true,
        axisTicks: {
          show: false,
          offsetX: 5,
        },
        axisBorder: {
          show: false,
          color: "#556EE6",
          offsetX: -10,
        },
        labels: {
          show: false,
          style: {
            // colors: "#556EE6",
            offsetX: 10,
          },
        },
        title: {
          // text: "Construction",
          style: {
            color: "#556EE6",
          },
        },
      },
      // {
      //   seriesName: 'Revenue',
      //   opposite: true,
      //   axisTicks: {
      //     show: true,
      //   },
      //   axisBorder: {
      //     show: true,
      //     color: '#FEB019'
      //   },
      //   labels: {
      //     style: {
      //       colors: '#FEB019',
      //     },
      //   },
      //   title: {
      //     text: "Revenue (thousand crores)",
      //     style: {
      //       color: '#FEB019',
      //     }
      //   }
      // },
    ],
    tooltip: {
      fixed: {
        enabled: true,
        position: "topLeft", // topRight, topLeft, bottomRight, bottomLeft
        offsetY: 30,
        offsetX: 60,
      },
    },
    legend: {
      horizontalAlign: "center",
      offsetX: 40,
    },
  }

  return (
    <CardComponent>
      {potValueLoading ? (
        <div className="text-center mt-5 pt-5">
          <Spinner />
        </div>
      ) : (
        <div className="pot-value-section">
          <div className="d-flex flex-wrap justify-content-between align-items-center">
            <h6 className="card-title me-2">Pot Value (BDT cr)</h6>
            <div className="d-flex">
              {/* <div className="select-area me-2">
            <Form.Select aria-label="Default select" onChange={handleChat}>
              <option value="monthly">Monthly</option>
              <option value="weekly">Weekly</option>
              <option value="daily">Daily</option>
            </Form.Select>
          </div> */}
              {/* <div className="select-area">
            <Form.Select aria-label="Default select" onChange={handleYear}>
            <option value="all">All Year</option>
              <option value="2021">2021</option>
              <option value="2022">2022</option>
             
            </Form.Select>
          </div> */}
            </div>
          </div>

          <Row className="mt-4">
            <Col lg={6}>
              <div className="card">
                <span className="construction-border-left-area"></span>
                <div className="card-body">
                  <div className="d-flex justify-content-center">
                    <div className="me-2">
                      <img src={Construction} alt="Icon" />
                    </div>
                    <p className="">Construction Pot</p>
                  </div>
                  <h5 className="text-center">
                    {Math.ceil(
                      potValue?.data?.constructionpot
                    ).toLocaleString()}
                  </h5>
                </div>
              </div>
            </Col>
            <Col lg={6}>
              <div className="card">
                <span className="paint-border-left-area"></span>
                <div className="card-body">
                  <div className="d-flex justify-content-center">
                    <div className="me-2">
                      <img src={Paint} alt="Icon" />
                    </div>
                    <p className="">Paint Pot</p>
                  </div>
                  <h5 className="text-center ms-4">
                    {potValueData?.toLocaleString()}
                  </h5>
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <CardComponent>
                <h6 className="ms-3 font-size-16">Pot Value</h6>
                <div id="area-chart" dir="ltr">
                  <ReactApexChart
                    options={options}
                    series={options?.series || []}
                    type="area"
                    height={375}
                    className="apex-charts"
                  />
                </div>
              </CardComponent>
            </Col>
          </Row>
        </div>
      )}
    </CardComponent>
  )
}

export default PotValue
