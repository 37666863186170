// MetisMenu
import MetisMenu from "metismenujs"
import PropTypes from "prop-types"
import React, { useEffect, useRef } from "react"
//i18n
import { withTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { Link, withRouter } from "react-router-dom"
// //Import Scrollbar
import SimpleBar from "simplebar-react"
import Dashboard from "../../assets/images/xelerator/icon/element-4.svg"
import Projects from "../../assets/images/xelerator/icon/folder-2.svg"
import PJP from "../../assets/images/xelerator/icon/gps.svg"
import GeoInformation from "../../assets/images/xelerator/icon/location.svg"
import Users from "../../assets/images/xelerator/icon/profile-circle.svg"
import {
  default as Attendence,
  default as Category,
} from "../../assets/images/xelerator/icon/tick-square.svg"

const SidebarContent = props => {
  const { data } = useSelector(state => ({
    data: state.Login.data,
  }))
  const ref = useRef()
  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props.location.pathname

    const initMenu = () => {
      new MetisMenu("#side-menu")
      let matchingMenuItem = null
      const ul = document.getElementById("side-menu")
      const items = ul.getElementsByTagName("a")
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i]
          break
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem)
      }
    }
    initMenu()
  }, [props.location.pathname])

  useEffect(() => {
    ref.current.recalculate()
  })

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item)
      return false
    }
    scrollElement(item)
    return false
  }
  const navStyleHelper = path => {
    const location = props.location.pathname
    const [, name] = location.split("/")
    if (location === path || `/${name}` === path) {
      return true
    }
    return false
  }

  const items = [
    {
      name: "Dashboard",
      icon: Dashboard,
      link: "/dashboard",
      inactiveIcon: "element-4.svg",
      roles: ["ADMIN", "ORG"],
    },
    {
      name: "Users",
      icon: Users,
      link: "/users",
      inactiveIcon: "profile-circle.svg",
      roles: ["ADMIN"],
    },
    {
      name: "Route Plan",
      icon: PJP,
      link: "/route-plan",
      inactiveIcon: "gps.svg",
      roles: ["ADMIN"],
    },
    {
      name: "Site",
      icon: Projects,
      link: "/sites",
      inactiveIcon: "folder-2.svg",
      roles: ["ADMIN"],
    },
    {
      name: "Providing Sites to Org",
      icon: Category,
      link: "/site-providing",
      inactiveIcon: "box.svg",
      roles: ["ADMIN"],
    },
    {
      name: "Geo Information",
      icon: GeoInformation,
      link: "/geo-information",
      inactiveIcon: "location.svg",
      roles: ["ADMIN"],
    },
    {
      name: "Customer Segments",
      icon: PJP,
      link: "/customer-segments",
      inactiveIcon: "gps.svg",
      roles: ["ADMIN"],
    },

    {
      name: "Category",
      icon: Category,
      link: "/category",
      inactiveIcon: "tick-square.svg",
      roles: ["ADMIN"],
    },
    {
      name: "Target",
      icon: GeoInformation,
      link: "/target-achievement",
      inactiveIcon: "location.svg",
      roles: ["ADMIN"],
    },

    // { name: "Route", icon: Route, link: "/route", inactiveIcon: "routing.svg" },
    // {
    //   name: "Target Set",
    //   icon: TargetSet,
    //   link: "/target-set",
    //   inactiveIcon: "cd.svg",
    // },
    {
      name: "PJP",
      icon: PJP,
      link: "/set-pjp",
      inactiveIcon: "gps.svg",
      roles: ["ADMIN"],
    },
    {
      name: "Attendance",
      icon: Attendence,
      link: "/attendance",
      inactiveIcon: "tick-square.svg",
      roles: ["ADMIN"],
    },
    //organization panel
    {
      name: "Sites",
      icon: Projects,
      link: "/org-sites",
      inactiveIcon: "folder-2.svg",
      roles: [""],
    },
  ]

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">{props.t("Menu")} </li>
            {items
              ?.filter(item => item.roles.includes(data.role) === true)
              ?.map((data, idx) =>
                data.name === "Geo Information" ? (
                  <li
                    key={idx}
                    style={{
                      backgroundColor: navStyleHelper(data.link)
                        ? "#F8F7FA"
                        : " ",
                    }}
                  >
                    <Link to={data.link} className="has-arrow">
                      <i>
                        {navStyleHelper(data.link) ? (
                          <img src={data.icon} alt="icon" />
                        ) : (
                          <img
                            src={require(`../../assets/images/xelerator/icon/inactive/${data.inactiveIcon}`)}
                            alt="icon"
                          />
                        )}
                      </i>
                      <span>{props.t(`${data.name}`)}</span>
                    </Link>
                    <ul className="sub-menu" aria-expanded="true">
                      <li>
                        <Link to="/geo/region">{props.t("Region")}</Link>
                      </li>
                      <li>
                        <Link to="/geo/area">{props.t("Area")}</Link>
                      </li>
                      <li>
                        <Link to="/geo/territory">{props.t("Territory")}</Link>
                      </li>
                      <li>
                        <Link to="/geo/route">{props.t("Route")}</Link>
                      </li>
                    </ul>
                  </li>
                ) : data.name === "Target" ? (
                  <li
                    key={idx}
                    style={{
                      backgroundColor: navStyleHelper(data.link)
                        ? "#F8F7FA"
                        : " ",
                    }}
                  >
                    <Link to={data.link} className="has-arrow">
                      <i>
                        {navStyleHelper(data.link) ? (
                          <img src={data.icon} alt="icon" />
                        ) : (
                          <img
                            src={require(`../../assets/images/xelerator/icon/inactive/${data.inactiveIcon}`)}
                            alt="icon"
                          />
                        )}
                      </i>
                      <span>{props.t(`${data.name}`)}</span>
                    </Link>
                    <ul className="sub-menu" aria-expanded="true">
                      <li>
                        <Link to="/target-set">{props.t("Target Set")}</Link>
                      </li>
                      <li>
                        <Link to="/target-achievement">
                          {props.t("Target Achievement")}
                        </Link>
                      </li>
                    </ul>
                  </li>
                ) : data.name === "Category" ? (
                  <li
                    key={idx}
                    style={{
                      backgroundColor: navStyleHelper(data.link)
                        ? "#F8F7FA"
                        : " ",
                    }}
                  >
                    <Link to={data.link} className="has-arrow">
                      <i>
                        {navStyleHelper(data.link) ? (
                          <img src={data.icon} alt="icon" />
                        ) : (
                          <img
                            src={require(`../../assets/images/xelerator/icon/inactive/${data.inactiveIcon}`)}
                            alt="icon"
                          />
                        )}
                      </i>
                      <span>{props.t(`${data.name}`)}</span>
                    </Link>
                    <ul className="sub-menu" aria-expanded="true">
                      <li>
                        <Link to="/categories">{props.t("Category")}</Link>
                      </li>
                      <li>
                        <Link to="/sub-categories">
                          {props.t("Sub Category")}
                        </Link>
                      </li>
                    </ul>
                  </li>
                ) : data.name === "Site" ? (
                  <li
                    key={idx}
                    style={{
                      backgroundColor: navStyleHelper(data.link)
                        ? "#F8F7FA"
                        : " ",
                    }}
                  >
                    <Link to={data.link} className="has-arrow">
                      <i>
                        {navStyleHelper(data.link) ? (
                          <img src={data.icon} alt="icon" />
                        ) : (
                          <img
                            src={require(`../../assets/images/xelerator/icon/inactive/${data.inactiveIcon}`)}
                            alt="icon"
                          />
                        )}
                      </i>
                      <span>{props.t(`${data.name}`)}</span>
                    </Link>
                    <ul className="sub-menu" aria-expanded="true">
                      <li>
                        <Link to="/sites">{props.t("Sites")}</Link>
                      </li>
                      <li>
                        <Link to="/add-site">{props.t("Create Site")}</Link>
                      </li>
                    </ul>
                  </li>
                ) : data.name === "Providing Sites to Org" ? (
                  <li
                    key={idx}
                    style={{
                      backgroundColor: navStyleHelper(data.link)
                        ? "#F8F7FA"
                        : " ",
                    }}
                  >
                    <Link to={data.link} className="has-arrow">
                      <i>
                        {navStyleHelper(data.link) ? (
                          <img src={data.icon} alt="icon" />
                        ) : (
                          <img
                            src={require(`../../assets/images/xelerator/icon/inactive/${data.inactiveIcon}`)}
                            alt="icon"
                          />
                        )}
                      </i>
                      <span>{props.t(`${data.name}`)}</span>
                    </Link>
                    <ul className="sub-menu" aria-expanded="true">
                      <li>
                        <Link to="/site-providing">
                          {props.t("Providing Sites")}
                        </Link>
                      </li>
                      <li>
                        <Link to="/managing-org-site">
                          {props.t("Managing Org Sites")}
                        </Link>
                      </li>
                    </ul>
                  </li>
                ) : (
                  <li
                    key={idx}
                    style={{
                      backgroundColor: navStyleHelper(data.link)
                        ? "#F8F7FA"
                        : " ",
                    }}
                  >
                    <Link to={data.link} className="">
                      <i>
                        {navStyleHelper(data.link) ? (
                          <img src={data.icon} alt="icon" />
                        ) : (
                          <img
                            src={require(`../../assets/images/xelerator/icon/inactive/${data.inactiveIcon}`)}
                            alt="icon"
                          />
                        )}
                      </i>
                      <span>{props.t(`${data.name}`)}</span>
                    </Link>
                  </li>
                )
              )}

            {/* <li>
              <Link to="/#" className="has-arrow ">
                <i className="bx bx-share-alt"></i>
                <span>{props.t("Multi Level")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="true">
                <li>
                  <Link to="/#">{props.t("Level 1.1")}</Link>
                </li>
                <li>
                  <Link to="/#" className="has-arrow">
                    {props.t("Level 1.2")}
                  </Link>
                  <ul className="sub-menu" aria-expanded="true">
                    <li>
                      <Link to="/attendence2">{props.t("Level 2.1")}</Link>
                    </li>
                    <li>
                      <Link to="/pjp2">{props.t("Level 2.2")}</Link>
                    </li>
                  </ul>
                </li>
              </ul>
            </li> */}
            {/*  */}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SidebarContent))
