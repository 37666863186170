import AddCard from "components/Atoms/AddCard"
import TableHeader from "components/Common/TableHeader"
import CardComponent from "components/Layout/CardComponent"
import CustomTable from "components/Layout/CustomTable"
import InnerLayer from "components/Layout/InnerLayer"
import NoTableData from "components/Layout/NoTableData"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { withRouter } from "react-router-dom"
import { Col, Row } from "reactstrap"
import {
  getRegionValue,
  storeRegionData,
} from "../../../store/GeoInformation/action"
const tableHead = ["No.", "Region Name", "Action"]

const Region = ({ history }) => {
  const [currentPage, setCurrentPage] = useState(1)
  const [pageRange, setPageRange] = useState(10)

  let dispatch = useDispatch()
  const { regionData, loading, authtoken } = useSelector(state => ({
    loading: state.GeoInformationReducer.isLoading,
    regionData: state.GeoInformationReducer.regionData,
    authtoken: state.Login.token,
  }))
  console.log("regionData", regionData)
  useEffect(() => {
    dispatch(getRegionValue(authtoken, currentPage, pageRange))
  }, [currentPage, pageRange])
  let totalPageNumber = Math.ceil(regionData?.length / pageRange)
  console.log("totalPageNumber", totalPageNumber)

  const handleEditRegion = data => {
    dispatch(storeRegionData("singleRegion", data))
    history.push("/edit-region/" + data._id)
  }

  return (
    <React.Fragment>
      <InnerLayer
        title="Region List"
        wrapperClass="py-3 users"
        isBreadCrumb={true}
        link={"#"}
        mainTitle={"Geo Information"}
        subTitle={"Region List"}
      >
        <Row>
          <Col md="3" className="">
            <AddCard
              title="Create New Region"
              buttonText="Create Region"
              onAddHandler={() => history.push("/add-region")}
            />
          </Col>
        </Row>

        {/* table */}

        <Row>
          <CardComponent className="user_table_card">
            <TableHeader
              handleSearchFilter={() => {}}
              handleSelectFilter={e => {
                setPageRange(e.target.value)
              }}
              data={[10, 20, 50, 100]}
              handleSelectFilter2={() => {}}
              data2={[]}
              selectValue={pageRange}
              title="Region List"
              headerSearchOption="yes"
            />

            {/* table */}
            <Row>
              <CustomTable
                paginationClass="paginationContainer text-right"
                data={regionData?.region}
                pageNo={totalPageNumber}
                tableHead={tableHead}
                setCurrentPage={setCurrentPage}
                isPagination
              >
                {regionData?.length > 0 ? (
                  regionData?.region.map((data, idx) => (
                    <tr key={idx}>
                      <th scope="row">{idx + 1}</th>
                      <td>{data.name}</td>
                      <td>
                        <div className="d-flex gap-3">
                          <span
                            className="text-success pointer_event"
                            onClick={() => handleEditRegion(data)}
                          >
                            <i
                              className="mdi mdi-pencil font-size-18"
                              id="edittooltip"
                            ></i>
                          </span>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <NoTableData
                    colSpan=""
                    className="d-flex justify-content-center align-items-center"
                    style={{ minHeight: "300px", width: `100%` }}
                  >
                    <span>
                      No data here
                      {/* <Link
                        to="#"
                        style={{
                          color: "blue",
                          textDecoration: "underline",
                        }}
                      >
                        Add Product
                      </Link> */}
                    </span>
                  </NoTableData>
                )}
              </CustomTable>
              {/* table end */}
            </Row>
          </CardComponent>
        </Row>
      </InnerLayer>
    </React.Fragment>
  )
}

Region.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
}

export default withRouter(Region)
