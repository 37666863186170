import AddCard from "components/Atoms/AddCard"
import TableHeader from "components/Common/TableHeader"
import CardComponent from "components/Layout/CardComponent"
import CustomTable from "components/Layout/CustomTable"
import InnerLayer from "components/Layout/InnerLayer"
import NoTableData from "components/Layout/NoTableData"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { withRouter } from "react-router-dom"
import { Col, Row } from "reactstrap"
import {
  getCategoryValue,
  storeCategoryData,
} from "../../store/Category/action"

const tableHead = ["No.", "Category Name", "Action"]

const Category = ({ history }) => {
  const [currentPage, setCurrentPage] = useState(1)
  const [dataRange, setDataRange] = useState(10)

  let dispatch = useDispatch()
  const { categoryData, loading, authtoken } = useSelector(state => ({
    loading: state.CategoryReducer.isLoading,
    categoryData: state.CategoryReducer.categoryData,
    authtoken: state.Login.token,
  }))
  useEffect(() => {
    dispatch(getCategoryValue(authtoken, currentPage, dataRange))
  }, [currentPage, dataRange])
  let totalPageNumber = Math.ceil(categoryData?.length / dataRange)

  const handleEditCategory = data => {
    dispatch(storeCategoryData("singleCategory", data))
    history.push("/edit-category/" + data._id)
  }
  return (
    <React.Fragment>
      <InnerLayer
        title="Category List"
        wrapperClass="py-3 users"
        isBreadCrumb={true}
        link={"#"}
        mainTitle={"Category"}
        subTitle={"Category List"}
      >
        <Row>
          <Col md="3" className="">
            <AddCard
              title="Create New Category"
              buttonText="Create Category"
              onAddHandler={() => history.push("/add-category")}
            />
          </Col>
        </Row>

        {/* table */}

        <Row>
          <CardComponent className="user_table_card">
            <TableHeader
              handleSearchFilter={() => {}}
              handleSelectFilter={e => {
                setDataRange(e.target.value)
              }}
              data={[10, 20, 50, 100]}
              handleSelectFilter2={() => {}}
              data2={[]}
              selectValue={dataRange}
              title="Category List"
              headerSearchOption="yes"
            />

            {/* table */}
            <Row>
              <CustomTable
                paginationClass="paginationContainer text-right"
                data={categoryData?.category}
                pageNo={totalPageNumber}
                tableHead={tableHead}
                setCurrentPage={setCurrentPage}
                isPagination
              >
                {categoryData?.length > 0 ? (
                  categoryData?.category.map((data, idx) => (
                    <tr key={idx}>
                      <th scope="row">{idx + 1}</th>
                      <td>{data.name}</td>
                      <td>
                        <div className="d-flex gap-3">
                          <span
                            className="text-success pointer_event"
                            onClick={() => handleEditCategory(data)}
                          >
                            <i
                              className="mdi mdi-pencil font-size-18"
                              id="edittooltip"
                            ></i>
                          </span>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <NoTableData
                    colSpan=""
                    className="d-flex justify-content-center align-items-center"
                    style={{ minHeight: "300px", width: `100%` }}
                  >
                    <span>No data here</span>
                  </NoTableData>
                )}
              </CustomTable>
              {/* table end */}
            </Row>
          </CardComponent>
        </Row>
      </InnerLayer>
    </React.Fragment>
  )
}

Category.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
}

export default withRouter(Category)
