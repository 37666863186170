import {
  GET_CONSTRUCTION_STAGE,
  GET_CONSTRUCTION_STAGE_FAIL,
  GET_CONSTRUCTION_STAGE_SUCCESS,
  GET_DASHBOARD_INDEX,
  GET_DASHBOARD_INDEX_FAIL,
  GET_DASHBOARD_INDEX_SUCCESS,
  GET_DASHBOARD_OVERVIEW,
  GET_DASHBOARD_OVERVIEW_FAIL,
  GET_DASHBOARD_OVERVIEW_SUCCESS,
  GET_PAINT_AREA,
  GET_PAINT_AREA_FAIL,
  GET_PAINT_AREA_SUCCESS,
  GET_POT_VALUE,
  GET_POT_VALUE_FAIL,
  GET_POT_VALUE_SUCCESS,
  GET_STRUCTURE_TYPE,
  GET_STRUCTURE_TYPE_FAIL,
  GET_STRUCTURE_TYPE_SUCCESS,
} from "./actionTypes"

export const getPotValue = (authtoken, query, role) => ({
  type: GET_POT_VALUE,
  payload: { authtoken, query, role },
})

export const getPotValueSuccess = (data, authtoken) => ({
  type: GET_POT_VALUE_SUCCESS,
  payload: { data, authtoken },
})

export const getPotValueFail = error => ({
  type: GET_POT_VALUE_FAIL,
  payload: error,
})
export const getDashboardOverview = (authtoken, query, role) => ({
  type: GET_DASHBOARD_OVERVIEW,
  payload: { authtoken, query, role },
})

export const getDashboardOverviewSuccess = (data, authtoken) => ({
  type: GET_DASHBOARD_OVERVIEW_SUCCESS,
  payload: { data, authtoken },
})

export const getDashboardOverviewFail = error => ({
  type: GET_DASHBOARD_OVERVIEW_FAIL,
  payload: error,
})
export const getDashboardIndex = (authtoken, query, role) => ({
  type: GET_DASHBOARD_INDEX,
  payload: { authtoken, query, role },
})

export const getDashboardIndexSuccess = (data, authtoken) => ({
  type: GET_DASHBOARD_INDEX_SUCCESS,
  payload: { data, authtoken },
})

export const getDashboardIndexFail = error => ({
  type: GET_DASHBOARD_INDEX_FAIL,
  payload: error,
})
export const getPaintArea = (authtoken, query, role) => ({
  type: GET_PAINT_AREA,
  payload: { authtoken, query , role},
})
export const getPaintAreaSuccess = (data, authtoken) => ({
  type: GET_PAINT_AREA_SUCCESS,
  payload: { data, authtoken },
})
export const getPaintAreaFail = error => ({
  type: GET_PAINT_AREA_FAIL,
  payload: error,
})

export const getStructureType = (authtoken, query, role) => ({
  type: GET_STRUCTURE_TYPE,
  payload: { authtoken, query , role},
})
export const getStructureTypeSuccess = (data, authtoken) => ({
  type: GET_STRUCTURE_TYPE_SUCCESS,
  payload: { data, authtoken },
})
export const getStructureTypeFail = error => ({
  type: GET_STRUCTURE_TYPE_FAIL,
  payload: error,
})
export const getConstructionStage = (authtoken, query, role) => ({
  type: GET_CONSTRUCTION_STAGE,
  payload: { authtoken, query, role },
})
export const getConstructionStageSuccess = (data, authtoken) => ({
  type: GET_CONSTRUCTION_STAGE_SUCCESS,
  payload: { data, authtoken },
})
export const getConstructionStageFail = error => ({
  type: GET_CONSTRUCTION_STAGE_FAIL,
  payload: error,
})
