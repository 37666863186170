import CustomInput from "components/Common/CustomInput"
import CardComponent from "components/Layout/CardComponent"
import InnerLayer from "components/Layout/InnerLayer"
import { addAreaSchema } from "components/Schemas/AddArea.schema"
import { useFormik } from "formik"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory, useParams } from "react-router-dom"
import { Col, Form, Row } from "reactstrap"
import {
  getRegionValue,
  updateArea,
} from "../../../store/GeoInformation/action"
const breadcrumbItems = [
  {
    title: "Geo Information",
    link: "#",
  },
  {
    title: "Area",
    link: "/geo/area",
  },
  {
    title: "Edit Area",
    link: "#",
  },
]
const initialValues = {
  region: "",
  name: "",
}

const EditArea = () => {
  const history = useHistory()
  let dispatch = useDispatch()
  const { id: areaId } = useParams()
  const [areaInfo, setAreaInfo] = useState(null)
  //get region
  const { editAreaInfo, regionData, authtoken } = useSelector(state => ({
    loading: state.GeoInformationReducer.isLoading,
    authtoken: state.Login.token,
    regionData: state.GeoInformationReducer.regionData,
    editAreaInfo: state.GeoInformationReducer.editAreaInfo,
  }))

  useEffect(() => {
    dispatch(getRegionValue(authtoken))
    const oldAreaInfo = { ...areaInfo }
    oldAreaInfo.name = editAreaInfo?.data?.name
    oldAreaInfo.region = editAreaInfo?.data?.region?._id
    setAreaInfo(oldAreaInfo)
  }, [editAreaInfo])
  //get region
  const onSubmit = values => {
    dispatch(updateArea(areaId, values, authtoken, history))
  }

  const AreaUpdate = useFormik({
    enableReinitialize: true,
    initialValues: areaInfo || initialValues,
    validationSchema: addAreaSchema,
    onSubmit,
  })
  return (
    <React.Fragment>
      <InnerLayer
        isMultiple
        title={"Edit Area"}
        breadcrumbItems={breadcrumbItems}
      >
        <div style={{ minHeight: "100vh" }} className="position-relative">
          <Row className="mt-4">
            <Col md="6" className="mx-auto">
              {/* add User */}
              <CardComponent className="">
                <Form
                  className="needs-validation"
                  onSubmit={AreaUpdate.handleSubmit}
                >
                  <CustomInput
                    name={"region"}
                    type={"select"}
                    label={"Select region"}
                    validationType={AreaUpdate}
                  >
                    <option defaultValue>Select region...</option>
                    {regionData?.length > 0 ? (
                      regionData?.region.map((data, idx) => (
                        <option value={data._id} key={idx}>
                          {data.name}
                        </option>
                      ))
                    ) : (
                      <span></span>
                    )}
                  </CustomInput>

                  <CustomInput
                    name={"name"}
                    type={"text"}
                    label={"Type area"}
                    placeholder={"Area name"}
                    validationType={AreaUpdate}
                  />
                  <div className="d-flex justify-content-start">
                    <input
                      type="submit"
                      value={"Update Area"}
                      className="btn button"
                    />
                  </div>
                </Form>
              </CardComponent>
            </Col>
          </Row>
        </div>
      </InnerLayer>
    </React.Fragment>
  )
}

export default EditArea
