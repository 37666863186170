import { toast } from "react-toastify"
import { call, put, takeEvery } from "redux-saga/effects"
import {
  axiosGetUser,
  postUserData,
  updateUserData,
} from "../../helpers/api_helper"
import { addUserFail, getUserValueSuccess, updateUserFail } from "./action"
// Calender Redux States
import { ADD_NEW_USER, GET_USER_VALUE, UPDATE_USER } from "./actionTypes"

//USER
const asyncGetUser = async (authtoken, userRole, dataRange, currentPage) => {
  try {
    const response = await axiosGetUser(
      authtoken,
      userRole,
      dataRange,
      currentPage
    )
    return response.data
  } catch (error) {
    return error.response.data
  }
}
function* workerGetUser({
  payload: { authtoken, userRole, currentPage, dataRange },
}) {
  try {
    const response = yield call(
      asyncGetUser,
      authtoken,
      userRole,
      dataRange,
      currentPage
    )
    yield put(getUserValueSuccess(response))
  } catch (error) {}
}

//async
const asyncPostUserData = async (data, authtoken) => {
  try {
    const response = await postUserData(data, authtoken)
    return response.data
  } catch (error) {
    return error.response.data
  }
}

function* onAddUser({ payload: { data, history, authtoken } }) {
  try {
    const response = yield call(asyncPostUserData, data, authtoken)
    if (response.status == "success") {
      toast("🦄 User added successfully!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
      history.push("users")
    } else {
      toast.error(response.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
  } catch (error) {
    yield put(addUserFail(error.message))
    toast.error(error.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    })
  }
}

function* onUpdateUser({ payload: { id, data, token, history } }) {
  try {
    const response = yield call(updateUserData, id, data, token)
    // yield put(updateTerritorySuccess(response))
    toast("🦄 User updated successfully!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    })
    if (response.status === 200) {
      history.push("/users")
    }
  } catch (error) {
    console.log(error.response)
    yield put(updateUserFail(error))
  }
}

function* UserSaga() {
  yield takeEvery(GET_USER_VALUE, workerGetUser)
  yield takeEvery(ADD_NEW_USER, onAddUser)
  yield takeEvery(UPDATE_USER, onUpdateUser)
}

export default UserSaga
