import {
  GET_CONSTRUCTION_STAGE_FAIL,
  GET_CONSTRUCTION_STAGE_SUCCESS,
  GET_DASHBOARD_INDEX_FAIL,
  GET_DASHBOARD_INDEX_SUCCESS,
  GET_DASHBOARD_OVERVIEW_FAIL,
  GET_DASHBOARD_OVERVIEW_SUCCESS,
  GET_PAINT_AREA_FAIL,
  GET_PAINT_AREA_SUCCESS,
  GET_POT_VALUE_FAIL,
  GET_POT_VALUE_SUCCESS,
  GET_STRUCTURE_TYPE_FAIL,
  GET_STRUCTURE_TYPE_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
  loading: false,
  potValue: [],
  overview: [],
  paintArea: [],
  structureType: [],
  dashboardIndex: [],
  constructionStage: [],
  potValueLoading: true,
  overviewLoading: true,
  paintAreaLoading: true,
  structureLoading: true,
  dashboardIndexLoading: true,
  constructionStageLoading: true,
}

const AdminReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_POT_VALUE_SUCCESS:
      return {
        ...state,
        potValue: action.payload,
        potValueLoading: false,
      }

    case GET_POT_VALUE_FAIL:
      return {
        ...state,
        error: action.payload,
        potValueLoading: true,
      }
    case GET_DASHBOARD_OVERVIEW_SUCCESS:
      return {
        ...state,
        overview: action.payload,
        overviewLoading: false,
      }

    case GET_DASHBOARD_OVERVIEW_FAIL:
      return {
        ...state,
        error: action.payload,
        overviewLoading: true,
      }
    case GET_PAINT_AREA_SUCCESS:
      return {
        ...state,
        paintArea: action.payload,
        paintAreaLoading: false,
      }

    case GET_PAINT_AREA_FAIL:
      return {
        ...state,
        error: action.payload,
        paintAreaLoading: true,
      }
    case GET_STRUCTURE_TYPE_SUCCESS:
      return {
        ...state,
        structureType: action.payload,
        structureLoading: false,
      }

    case GET_STRUCTURE_TYPE_FAIL:
      return {
        ...state,
        error: action.payload,
        structureLoading: true,
      }
    case GET_DASHBOARD_INDEX_SUCCESS:
      return {
        ...state,
        dashboardIndex: action.payload,
        dashboardIndexLoading: false,
      }

    case GET_DASHBOARD_INDEX_FAIL:
      return {
        ...state,
        error: action.payload,
        dashboardIndexLoading: true,
      }
    case GET_CONSTRUCTION_STAGE_SUCCESS:
      return {
        ...state,
        constructionStage: action.payload,
        constructionStageLoading: false,
      }

    case GET_CONSTRUCTION_STAGE_FAIL:
      return {
        ...state,
        error: action.payload,
        constructionStageLoading: true,
      }

    default:
      return state
  }
}

export default AdminReducer
