//ADD REGION
export const ADD_NEW_REGION = "ADD_NEW_REGION"
export const ADD_REGION_SUCCESS = "ADD_REGION_SUCCESS"
export const ADD_REGION_FAIL = "ADD_REGION_FAIL"

//GET REGION
export const GET_REGION_VALUE = "GET_REGION_VALUE"
export const GET_REGION_VALUE_SUCCESS = "GET_REGION_VALUE_SUCCESS"
export const GET_REGION_VALUE_FAIL = "GET_REGION_VALUE_FAIL"

//EDIT REGION
export const UPDATE_REGION = "UPDATE_REGION"
export const UPDATE_REGION_SUCCESS = "UPDATE_REGION_SUCCESS"
export const UPDATE_REGION_FAIL = "UPDATE_REGION_FAIL"

export const STORE_REGION_DATA = "STORE_REGION_DATA"

//ADD AREA
export const ADD_NEW_AREA = "ADD_NEW_AREA"
export const ADD_AREA_SUCCESS = "ADD_AREA_SUCCESS"
export const ADD_AREA_FAIL = "ADD_AREA_FAIL"

//GET AREA
export const GET_AREA_VALUE = "GET_AREA_VALUE"
export const GET_AREA_VALUE_SUCCESS = "GET_AREA_VALUE_SUCCESS"
export const GET_AREA_VALUE_FAIL = "GET_AREA_VALUE_FAIL"

//GET AREA BY REGION
export const GET_AREA_BY_REGION_VALUE = "GET_AREA_BY_REGION_VALUE"
export const GET_AREA_BY_REGION_VALUE_SUCCESS =
  "GET_AREA_BY_REGION_VALUE_SUCCESS"

//EDIT AREA
export const UPDATE_AREA = "UPDATE_AREA"
export const UPDATE_AREA_SUCCESS = "UPDATE_AREA_SUCCESS"
export const UPDATE_AREA_FAIL = "UPDATE_AREA_FAIL"

export const STORE_AREA_DATA = "STORE_AREA_DATA"

//ADD ROUTE
export const ADD_NEW_ROUTE = "ADD_NEW_ROUTE"
export const ADD_ROUTE_SUCCESS = "ADD_ROUTE_SUCCESS"
export const ADD_ROUTE_FAIL = "ADD_ROUTE_FAIL"

//GET ROUTE
export const GET_ROUTE_VALUE = "GET_ROUTE_VALUE"
export const GET_ROUTE_VALUE_SUCCESS = "GET_ROUTE_VALUE_SUCCESS"
export const GET_ROUTE_VALUE_FAIL = "GET_ROUTE_VALUE_FAIL"

//EDIT ROUTE
export const UPDATE_ROUTE = "UPDATE_ROUTE"
export const UPDATE_ROUTE_SUCCESS = "UPDATE_ROUTE_SUCCESS"
export const UPDATE_ROUTE_FAIL = "UPDATE_ROUTE_FAIL"

export const STORE_ROUTE_DATA = "STORE_ROUTE_DATA"

//ADD TERRITORY
export const ADD_NEW_TERRITORY = "ADD_NEW_TERRITORY"
export const ADD_TERRITORY_SUCCESS = "ADD_TERRITORY_SUCCESS"
export const ADD_TERRITORY_FAIL = "ADD_TERRITORY_FAIL"

//GET TERRITORY
export const GET_TERRITORY_VALUE = "GET_TERRITORY_VALUE"
export const GET_TERRITORY_VALUE_SUCCESS = "GET_TERRITORY_VALUE_SUCCESS"
export const GET_TERRITORY_VALUE_FAIL = "GET_TERRITORY_VALUE_FAIL"

//GET TERRITORY BY AREA
export const GET_TERRITORY_BY_AREA_VALUE = "GET_TERRITORY_BY_AREA_VALUE"
export const GET_TERRITORY_BY_AREA_VALUE_SUCCESS =
  "GET_TERRITORY_BY_AREA_VALUE_SUCCESS"

//EDIT TERRITORY
export const UPDATE_TERRITORY = "UPDATE_TERRITORY"
export const UPDATE_TERRITORY_SUCCESS = "UPDATE_TERRITORY_SUCCESS"
export const UPDATE_TERRITORY_FAIL = "UPDATE_TERRITORY_FAIL"
export const GET_REGIONS = "GET_REGIONS"
export const GET_REGIONS_SUCCESS = "GET_REGIONS_SUCCESS"
export const GET_REGIONS_FAIL = "GET_REGIONS_FAIL"
export const STORE_TERRITORY_DATA = "STORE_TERRITORY_DATA"
