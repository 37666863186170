import ProjectCard from "components/Atoms/ProjectCard"
import InnerLayer from "components/Layout/InnerLayer"
import React from "react"
import { Col, Row } from "reactstrap"
import { generalInfo, survey } from "utils/fakeSurvey"
const breadcrumbItems = [
  {
    title: "Dashboard",
    link: "/dashboard",
  },
  {
    title: "Set PJP",
    link: "/projects",
  },
  {
    title: "Survey",
    link: "#",
  },
]

const ViewProjects = () => {
  return (
    <React.Fragment>
      <InnerLayer isMultiple title={"Survey"} breadcrumbItems={breadcrumbItems}>
        <Row>
          <Col md="6">
            <ProjectCard title="General Info" data={generalInfo || []} />
          </Col>
          <Col md="6">
            <ProjectCard title="Survey" data={survey || []} />
          </Col>
        </Row>
      </InnerLayer>
    </React.Fragment>
  )
}

export default ViewProjects
