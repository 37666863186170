import CustomInput from "components/Common/CustomInput"
import CardComponent from "components/Layout/CardComponent"
import InnerLayer from "components/Layout/InnerLayer"
//import { addSegmentSchema } from "components/Schemas/AddSegment.schema"
import { useFormik } from "formik"
import moment from "moment"
import React, { useEffect, useState } from "react"
import { Spinner } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { useHistory, useParams } from "react-router-dom"
import Select from "react-select"
import { Col, Form, FormGroup, Input, Row } from "reactstrap"
import {
  clearFilters,
  getAreaByRegion,
  getCategoryFilter,
  getRegion,
  getRouteByTerritory,
  getSegmentValue,
  getSitesViewData,
  getSubCategory,
  getSubCategoryById,
  getTerritoryByArea,
  getUserValue,
  onAreaFilterChange,
  onCategoryFilterChange,
  onRegionFilterChange,
  onRoutesFilterChange,
  onSubCategoryFilterChange,
  onTerritoryFilterChange,
  updateSite,
} from "../../store/actions"

const breadcrumbItems = [
  {
    title: "Sites",
    link: "/sites",
  },
  {
    title: "Edit Site",
    link: "#",
  },
]
const initialValues = {
  name: "",
  SiteName: "",
}

const EditSite = () => {
  const { id: siteId } = useParams()
  const visitTo = [
    { name: "Construction Site", value: "Construction Site" },
    { name: "Office Site", value: "Office Site" },
  ]
  const visitTime = [
    { name: "1st Time", value: "1st Time" },
    { name: "Follow up visit", value: "Follow up visit" },
  ]
  const Structure = [
    { name: "Old Structure", value: "OB" },
    { name: "New Structure", value: "NB" },
  ]
  const Information = [
    { name: "Full Set available", value: "Full Set available" },
    { name: "Partial Available", value: "Partial Available" },
  ]
  const Construction = [
    { name: "BOQ Stage", value: "BOQ Stage" },
    { name: "Paint Assign", value: "Paint work is assigned" },
    { name: "Sub Structure ongoing", value: "Sub Structure ongoing" },
    { name: "Super Structure ongoing", value: "Super Structure ongoing" },
  ]
  const WPCC = [
    { name: "Hot", value: "HOT" },
    { name: "Warm", value: "WARM" },
    { name: "Cold", value: "COLD" },
  ]
  const [reRender, setReRender] = useState(false)
  const [loadArea, setLoadArea] = useState(false)
  const [loadTerritory, setLoadTerritory] = useState(false)
  const [loadRoutes, setLoadRoutes] = useState(false)
  const [loadSubCategory, setLoadSubCategory] = useState(false)
  const [clients, setClients] = useState([
    {
      value: "",
    },
  ])
  const [site, setSite] = useState(null)
  const history = useHistory()
  const dispatch = useDispatch()
  const {
    authtoken,
    userData,
    region,
    areaList,
    territoryList,
    selectedArea,
    selectedTerritory,
    selectedRegion,
    selectedCategory,
    selectedSubCategory,
    selectedRoutes,
    categoryList,
    subCategoryList,
    subLoading,
    regionSubLoading,
    areaSubLoading,
    territorySubLoading,
    routesList,
    segmentData,
    editSiteInfo,
    sitesViewData,
  } = useSelector(state => ({
    authtoken: state.Login.token,
    userData: state.UserReducer.userData,
    region: state.GeoInformationReducer?.region?.region,
    areaList: state.DashboardReducer.areaList,
    territoryList: state.DashboardReducer.territoryList,
    mainFilter: state.DashboardReducer.mainFilter,
    subLoading: state.DashboardReducer.subLoading,
    regionSubLoading: state.DashboardReducer.regionSubLoading,
    areaSubLoading: state.DashboardReducer.areaSubLoading,
    territorySubLoading: state.DashboardReducer.territorySubLoading,
    selectedRegion: state.DashboardReducer.selectedRegion,
    selectedArea: state.DashboardReducer.selectedArea,
    selectedTerritory: state.DashboardReducer.selectedTerritory,
    subCategoryList: state.DashboardReducer.subCategoryList,
    selectedSubCategory: state.DashboardReducer.selectedSubCategory,
    selectedCategory: state.DashboardReducer.selectedCategory,
    categoryList: state.DashboardReducer.categoryList,
    routesList: state.DashboardReducer.routesList,
    segmentData: state.SegmentReducer?.segmentData?.customerSegment,
    selectedRoutes: state.DashboardReducer.selectedRoutes,
    editSiteInfo: state.SiteReducer.editSiteInfo,
    sitesViewData: state.DashboardReducer.sitesViewData,
  }))

  //edit site
  useEffect(() => {
    dispatch(getSitesViewData(authtoken, siteId))
  }, [editSiteInfo])
  console.log("sitesViewData", sitesViewData)

  const [laUserId, setLaUserId] = useState("")
  const handleLaUser = userId => {
    setLaUserId(userId)
  }

  const [segmentId, setSegmentId] = useState("")
  const handleSegment = segmentId => {
    setSegmentId(segmentId)
  }
  const [clientName, setClientName] = useState({})
  const handleSetClientName = (name, serial) => {
    setClientName({
      ...clientName,
      [serial]: {
        name: name,
      },
    })
  }
  const [designationValue, setDesignationValue] = useState({})
  const handleSetDesignation = (designation, serial) => {
    setDesignationValue({
      ...designationValue,
      [serial]: {
        designation: designation,
      },
    })
  }
  const [contactNoValue, setContactNoValue] = useState({})
  const handleSetContactNo = (contactNo, serial) => {
    setContactNoValue({
      ...contactNoValue,
      [serial]: {
        contactNo: contactNo,
      },
    })
  }
  const [emailValue, setEmailValue] = useState({})
  const handleSetEmail = (email, serial) => {
    setEmailValue({
      ...emailValue,
      [serial]: {
        email: email,
      },
    })
  }
  const [organizationNameValue, setOrganizationNameValue] = useState({})
  const handleSetOrganizationName = (organizationName, serial) => {
    setOrganizationNameValue({
      ...organizationNameValue,
      [serial]: {
        organizationName: organizationName,
      },
    })
  }
  const [respondentTypeValue, setRespondentTypeValue] = useState({})
  const handleSetRespondentType = (respondentType, serial) => {
    setRespondentTypeValue({
      ...respondentType,
      [serial]: {
        respondentType: respondentType,
      },
    })
  }

  const data = {
    categoryId: selectedCategory?._id || null,
    subCategoryId: selectedSubCategory?._id || null,
    regionId: selectedRegion?._id || null,
    areaId: selectedArea?._id || null,
    territoryId: selectedTerritory?._id || null,
    routeId: selectedRoutes?._id || null,
    laUserId: laUserId || null,
    segmentId: segmentId || null,
  }
  const onSubmit = values => {
    let client = []
    Object.keys(clientName).map((v, i) => {
      client.push({
        name: clientName[v]?.name || null,
        designation: designationValue[v]?.designation || null,
        contactNo: contactNoValue[v]?.contactNo || null,
        email: emailValue[v]?.email || null,
        organizationName: organizationNameValue[v]?.organizationName || null,
        respondentType: respondentTypeValue[v]?.respondentType || null,
      })
    })
    const siteInfo = { ...values, data, client }
    // event.preventDefault()
    let obj = {}
    ;(obj.UserId = siteInfo.data.laUserId),
      (obj.RouteId = siteInfo.data.routeId),
      (obj.TerritoryId = siteInfo.data.territoryId),
      (obj.AreaId = siteInfo.data.areaId),
      (obj.RegionId = siteInfo.data.regionId),
      (obj.CategoryId = siteInfo.data.categoryId),
      (obj.SubCategoryId = siteInfo.data.subCategoryId),
      (obj.SegmentId = siteInfo.data.segmentId),
      (obj.VisitTo = siteInfo.VisitTo),
      (obj.SiteName = siteInfo.SiteName),
      (obj.SiteAddress = siteInfo.SiteAddress),
      (obj.VisitDate = moment(new Date(Date.now())).format("YYYY-MM-DD")),
      (obj.CheckInTime = moment().format("HH:mm:ss a")),
      (obj.TimeOfVisit = siteInfo.TimeOfVisit),
      (obj.InformationAvailability = siteInfo.InformationAvailability),
      (obj.TypeOfStructure = siteInfo.TypeOfStructure),
      (obj.OpportunityBlock = siteInfo.OpportunityBlock),
      (obj.ConstructionStage = siteInfo.ConstructionStage),
      (obj.CurrentStageOfWork = siteInfo.CurrentStageOfWork),
      (obj.constructionStartingDate = moment(
        new Date(siteInfo.constructionStartingDate)
      ).format("YYYY-MM-DD")),
      (obj.CompletionDateOfCurrentStage =
        siteInfo.CompletionDateOfCurrentStage),
      (obj.LastPaintDate = moment(new Date(siteInfo.LastPaintDate)).format(
        "YYYY-MM-DD"
      )),
      (obj.BrandNameOfPaint = siteInfo.BrandNameOfPaint),
      (obj.ApplicatorName = siteInfo.ApplicatorName),
      (obj.PaintAssignDate = moment(new Date(siteInfo.PaintAssignDate)).format(
        "YYYY-MM-DD"
      )),
      (obj.ConstructionCompletionDate = moment(
        new Date(siteInfo.ConstructionCompletionDate)
      ).format("YYYY-MM-DD")),
      (obj.NoOfFloorAboveGround = siteInfo.NoOfFloorAboveGround),
      (obj.NoOfFloorInBasement = siteInfo.NoOfFloorInBasement),
      (obj.StructureFloorArea = siteInfo.StructureFloorArea),
      (obj.HeightOfTheStructureWall = siteInfo.HeightOfTheStructureWall),
      (obj.RoofArea = siteInfo.RoofArea),
      (obj.wetSurfaceArea = siteInfo.wetSurfaceArea),
      (obj.PaintStages = siteInfo.PaintStages),
      (obj.PaintPot = siteInfo.PaintPot),
      (obj.ExteriorArea = siteInfo.ExteriorArea),
      (obj.InteriorArea = siteInfo.InteriorArea),
      (obj.BasementFloorArea = siteInfo.BasementFloorArea),
      (obj.WPCC = siteInfo.WPCC),
      (obj.DECO = siteInfo.DECO),
      (obj.ConstructionPOT = siteInfo.ConstructionPOT),
      (obj.PaintPotentialArea = siteInfo.PaintPotentialArea),
      (obj.client = siteInfo.client),
      dispatch(updateSite(siteId, obj, history, authtoken))
  }

  const EditeSite = useFormik({
    enableReinitialize: true,
    initialValues: site || initialValues,
    //validationSchema: addSegmentSchema,
    onSubmit,
  })

  //cliens
  const handleAddClientInput = () => {
    setClients([...clients, { value: "" }])
  }
  const handleRemoveClientInput = index => {
    // setClients([...clients])
    // clients.splice(index, 1)
    // setClients(clients)
    // let newClients = clients.slice()
    // newClients.splice(index, 1)
    // setClients(newClients)
  }

  //La user
  const [userRole, setUserRole] = useState("LA")
  useEffect(() => {
    dispatch(getUserValue(authtoken, userRole, 0, 0))
  }, [])

  useEffect(() => {
    dispatch(clearFilters())
  }, [])

  useEffect(async () => {
    await dispatch(getRegion(authtoken))
  }, [])

  useEffect(async () => {
    await dispatch(getSubCategory(authtoken))
  }, [])

  useEffect(async () => {
    await dispatch(getCategoryFilter(authtoken))
  }, [])

  useEffect(async () => {
    if (Object?.keys(selectedRegion)?.length) {
      await dispatch(getAreaByRegion(authtoken, selectedRegion?._id))
    }
  }, [loadArea])

  useEffect(async () => {
    if (Object?.keys(selectedArea)?.length) {
      await dispatch(getTerritoryByArea(authtoken, selectedArea?._id))
    }
  }, [loadTerritory])
  useEffect(async () => {
    if (Object?.keys(selectedTerritory)?.length) {
      await dispatch(getRouteByTerritory(authtoken, selectedTerritory?._id))
    }
  }, [loadRoutes])

  //
  useEffect(async () => {
    if (Object?.keys(selectedCategory)?.length) {
      await dispatch(getSubCategoryById(authtoken, selectedCategory?._id))
    }
  }, [loadSubCategory])

  useEffect(async () => {
    await dispatch(getSegmentValue(authtoken))
  }, [])
  // region
  const handleRegion = data => {
    if (!!data) {
      dispatch(onRegionFilterChange(data))
    } else {
      dispatch(onRegionFilterChange({}))
    }
    setLoadArea(!loadArea)
    setReRender(!reRender)
  }

  // area
  const handleArea = data => {
    if (!!data) {
      dispatch(onAreaFilterChange(data))
    } else {
      dispatch(onAreaFilterChange({}))
    }
    setLoadTerritory(!loadTerritory)
    setReRender(!reRender)
  }

  // territory
  const handleTerritory = data => {
    if (!!data) {
      dispatch(onTerritoryFilterChange(data))
    } else {
      dispatch(onTerritoryFilterChange({}))
    }
    setLoadRoutes(!loadRoutes)
    setReRender(!reRender)
  }

  // route
  const handleRoutes = data => {
    if (!!data) {
      dispatch(onRoutesFilterChange(data))
    } else {
      dispatch(onRoutesFilterChange({}))
    }
    setReRender(!reRender)
  }

  // category
  const handleCategory = data => {
    if (!!data) {
      dispatch(onCategoryFilterChange(data))
    } else {
      dispatch(onCategoryFilterChange({}))
    }
    setLoadSubCategory(!loadSubCategory)
    setReRender(!reRender)
  }
  const handleSubCategoryFilter = data => {
    if (!!data) {
      dispatch(onSubCategoryFilterChange(data))
    } else {
      dispatch(onSubCategoryFilterChange({}))
    }
    setReRender(!reRender)
  }
  return (
    <React.Fragment>
      <InnerLayer
        isMultiple
        title={"Edit Site"}
        breadcrumbItems={breadcrumbItems}
      >
        <div style={{ minHeight: "100vh" }} className="position-relative">
          <Row className="mt-4">
            <Col md="12" className="mx-auto">
              {/* add User */}
              <CardComponent className="">
                <Form
                  className="needs-validation"
                  onSubmit={EditeSite.handleSubmit}
                >
                  <Row>
                    <Col md="3 mb-3">
                      <label>Select LA User</label>
                      <Select
                        name="mainFilter"
                        classNamePrefix="select2-selection"
                        isClearable
                        //   cacheOptions
                        getOptionLabel={e => e.name}
                        getOptionValue={e => e._id}
                        defaultValue={userData?.users?.filter(
                          dta => dta._id === sitesViewData?.site?.UserId?._id
                        )}
                        placeholder={"Select LA..."}
                        options={userData?.users}
                        onChange={e => handleLaUser(e._id)}
                        required
                      />
                    </Col>
                    <Col md="3 mb-3">
                      <label>Select Region</label>
                      <Select
                        name="mainFilter"
                        classNamePrefix="select2-selection"
                        // disabled={loadingCondition}
                        style={{ minWidth: "100px" }}
                        isClearable
                        //   cacheOptions
                        getOptionLabel={e => e.name}
                        getOptionValue={e => e._id}
                        // defaultInputValue={}
                        placeholder={"Select Region"}
                        options={region}
                        onChange={handleRegion}
                        defaultValue={region?.filter(
                          dta => dta._id === sitesViewData?.site?.RegionId?._id
                        )}
                        required
                      />
                    </Col>
                    <Col md="3 mb-3">
                      <label>Select Area</label>
                      {!regionSubLoading ? (
                        <Select
                          name="mainFilter"
                          classNamePrefix="select2-selection"
                          getOptionLabel={e => e.name}
                          isClearable
                          getOptionValue={e => e._id}
                          placeholder={"Select Area"}
                          options={areaList}
                          onChange={handleArea}
                          defaultValue={areaList?.filter(
                            dta => dta._id === sitesViewData?.site?.AreaId?._id
                          )}
                          required
                        />
                      ) : (
                        <div
                          className="p-2"
                          style={{
                            border: "1px solid #ced4da",
                            borderRadius: "4px",
                          }}
                        >
                          <Spinner animation="border" size="sm" />
                        </div>
                      )}
                    </Col>
                    <Col md="3 mb-3">
                      <label>Select Territory</label>
                      {!areaSubLoading ? (
                        <Select
                          name="mainFilter"
                          classNamePrefix="select2-selection"
                          getOptionLabel={e => e.name}
                          isClearable
                          getOptionValue={e => e._id}
                          placeholder={"Select Territory"}
                          options={territoryList}
                          onChange={handleTerritory}
                          defaultValue={territoryList?.filter(
                            dta =>
                              dta._id === sitesViewData?.site?.TerritoryId?._id
                          )}
                          required
                        />
                      ) : (
                        <div
                          className="p-2"
                          style={{
                            border: "1px solid #ced4da",
                            borderRadius: "4px",
                          }}
                        >
                          <Spinner animation="border" size="sm" />
                        </div>
                      )}
                    </Col>

                    <Col md="3 mb-3">
                      <label>Select Route</label>
                      {!territorySubLoading ? (
                        <Select
                          name="mainFilter"
                          classNamePrefix="select2-selection"
                          getOptionLabel={e => e.name}
                          isClearable
                          getOptionValue={e => e._id}
                          placeholder={"Select Route"}
                          options={routesList}
                          onChange={handleRoutes}
                          defaultValue={routesList?.filter(
                            dta => dta._id === sitesViewData?.site?.RouteId?._id
                          )}
                          required
                        />
                      ) : (
                        <div
                          className="p-2"
                          style={{
                            border: "1px solid #ced4da",
                            borderRadius: "4px",
                          }}
                        >
                          <Spinner animation="border" size="sm" />
                        </div>
                      )}
                    </Col>
                    <Col md="3 mb-3">
                      <label>Select Category</label>
                      <Select
                        name="mainFilter"
                        classNamePrefix="select2-selection"
                        isClearable
                        // disabled={loadingCondition}
                        style={{ minWidth: "100px" }}
                        //   cacheOptions
                        getOptionLabel={e => e.name}
                        getOptionValue={e => e._id}
                        // defaultInputValue={}
                        placeholder={"Category"}
                        defaultValue={categoryList?.filter(
                          dta =>
                            dta._id === sitesViewData?.site?.CategoryId?._id
                        )}
                        options={categoryList}
                        onChange={handleCategory}
                        required
                      />
                    </Col>
                    <Col md="3 mb-3">
                      <label>Select Sub Category</label>
                      {!subLoading ? (
                        <Select
                          name="mainFilter"
                          classNamePrefix="select2-selection"
                          getOptionLabel={e => e.name}
                          getOptionValue={e => e._id}
                          placeholder={"Sub Category"}
                          isClearable
                          options={subCategoryList}
                          defaultValue={categoryList?.filter(
                            dta =>
                              dta._id ===
                              sitesViewData?.site?.SubCategoryId?._id
                          )}
                          required
                        />
                      ) : (
                        <div
                          className="p-2"
                          style={{
                            border: "1px solid #ced4da",
                            borderRadius: "4px",
                          }}
                        >
                          <Spinner animation="border" size="sm" />
                        </div>
                      )}
                    </Col>
                    <Col md="3 mb-3">
                      <label>Select Customer Segment</label>
                      <Select
                        name="subFilter"
                        classNamePrefix="select2-selection"
                        placeholder="Customer Segment"
                        getOptionLabel={e => e.name}
                        getOptionValue={e => e._id}
                        isClearable
                        options={segmentData}
                        onChange={e => handleSegment(e._id)}
                        // defaultValue={segmentData?.filter(
                        //   dta => dta._id === sitesViewData?.site?.SubCategoryId?._id
                        // )}
                        required
                      />
                    </Col>
                    <Col md="3">
                      <CustomInput
                        name={"VisitTo"}
                        type={"select"}
                        label={"Select Visit To"}
                        validationType={EditeSite}
                      >
                        <option defaultValue>Select Visit To</option>
                        {visitTo?.map((data, idx) => (
                          <option key={idx} value={data.value}>
                            {data.name}
                          </option>
                        ))}
                      </CustomInput>
                    </Col>
                    <Col md="3">
                      <CustomInput
                        name={"SiteName"}
                        type={"text"}
                        label={"Site Name"}
                        placeholder={"Type Site Name"}
                        validationType={EditeSite}
                      />
                    </Col>
                    <Col md="3">
                      <CustomInput
                        name={"SiteAddress"}
                        type={"text"}
                        label={"Site Address"}
                        placeholder={"Type Site Address"}
                        validationType={EditeSite}
                        required=""
                      />
                    </Col>
                    <Col md="3">
                      <CustomInput
                        name={"TimeOfVisit"}
                        type={"select"}
                        label={"Select Visit Time"}
                        validationType={EditeSite}
                      >
                        <option defaultValue>Select Visit Time</option>
                        {visitTime?.map((data, idx) => (
                          <option key={idx} value={data.value}>
                            {data.name}
                          </option>
                        ))}
                      </CustomInput>
                    </Col>
                    <Col md="3">
                      <CustomInput
                        name={"TypeOfStructure"}
                        type={"select"}
                        label={"Select Structure type"}
                        validationType={EditeSite}
                      >
                        <option defaultValue>Select Structure type</option>
                        {Structure?.map((data, idx) => (
                          <option key={idx} value={data.value}>
                            {data.name}
                          </option>
                        ))}
                      </CustomInput>
                    </Col>
                    <Col md="3">
                      <CustomInput
                        name={"InformationAvailability"}
                        type={"select"}
                        label={"Select Information Availability"}
                        validationType={EditeSite}
                      >
                        <option defaultValue>
                          Select Information Availability
                        </option>
                        {Information?.map((data, idx) => (
                          <option key={idx} value={data.value}>
                            {data.name}
                          </option>
                        ))}
                      </CustomInput>
                    </Col>
                    <Col md="3">
                      <CustomInput
                        name={"OpportunityBlock"}
                        type={"text"}
                        label={"Opportunity Block"}
                        placeholder={"Type Opportunity Block"}
                        validationType={EditeSite}
                      />
                    </Col>
                    <Col md="3">
                      <CustomInput
                        name={"ConstructionStage"}
                        type={"select"}
                        label={"Select Construction Stage"}
                        validationType={EditeSite}
                      >
                        <option defaultValue>Select Construction Stage</option>
                        {Construction?.map((data, idx) => (
                          <option key={idx} value={data.value}>
                            {data.name}
                          </option>
                        ))}
                      </CustomInput>
                    </Col>
                    <Col md="3">
                      <CustomInput
                        name={"CurrentStageOfWork"}
                        type={"text"}
                        label={"Current Stage Of Work"}
                        placeholder={"Type Current Stage Of Work"}
                        validationType={EditeSite}
                      />
                    </Col>
                    <Col md="3">
                      <CustomInput
                        name={"constructionStartingDate"}
                        type={"date"}
                        label={"Construction Starting Date"}
                        validationType={EditeSite}
                      />
                    </Col>
                    <Col md="3">
                      <CustomInput
                        name={"CompletionDateOfCurrentStage"}
                        type={"date"}
                        label={"Completion Date Of Current Stage"}
                        validationType={EditeSite}
                      />
                    </Col>
                    <Col md="3">
                      <CustomInput
                        name={"LastPaintDate"}
                        type={"date"}
                        label={"Last Paint Date"}
                        validationType={EditeSite}
                      />
                    </Col>
                    <Col md="3">
                      <CustomInput
                        name={"BrandNameOfPaint"}
                        type={"text"}
                        label={"Brand Name Of Paint"}
                        placeholder={"Type Brand Name Of Paint"}
                        validationType={EditeSite}
                      />
                    </Col>
                    <Col md="3">
                      <CustomInput
                        name={"ApplicatorName"}
                        type={"text"}
                        label={"Applicator Name"}
                        placeholder={"Type Applicator Name"}
                        validationType={EditeSite}
                      />
                    </Col>
                    <Col md="3">
                      <CustomInput
                        name={"PaintAssignDate"}
                        type={"date"}
                        label={"Paint Assign Date"}
                        validationType={EditeSite}
                      />
                    </Col>
                    <Col md="3">
                      <CustomInput
                        name={"ConstructionCompletionDate"}
                        type={"date"}
                        label={"Construction Completion Date"}
                        validationType={EditeSite}
                      />
                    </Col>
                    <Col md="3">
                      <CustomInput
                        name={"NoOfFloorAboveGround"}
                        type={"text"}
                        label={"No Of Floor Above Ground"}
                        placeholder={"Type No Of Floor Above Ground"}
                        validationType={EditeSite}
                      />
                    </Col>
                    <Col md="3">
                      <CustomInput
                        name={"NoOfFloorInBasement"}
                        type={"text"}
                        label={"No Of Floor In Basement"}
                        placeholder={"Type Roof Area"}
                        validationType={EditeSite}
                      />
                    </Col>
                    <Col md="3">
                      <CustomInput
                        name={"StructureFloorArea"}
                        type={"text"}
                        label={"Structure Floor Area"}
                        placeholder={"Type Structure Floor Area"}
                        validationType={EditeSite}
                      />
                    </Col>
                    <Col md="3">
                      <CustomInput
                        name={"HeightOfTheStructureWall"}
                        type={"text"}
                        label={"Structure Wall Height"}
                        placeholder={"Type Structure Wall Height"}
                        validationType={EditeSite}
                      />
                    </Col>
                    <Col md="3">
                      <CustomInput
                        name={"RoofArea"}
                        type={"text"}
                        label={"Roof Area"}
                        placeholder={"Type Roof Area"}
                        validationType={EditeSite}
                      />
                    </Col>
                    <Col md="3">
                      <CustomInput
                        name={"wetSurfaceArea"}
                        type={"text"}
                        label={"Wet Surface Area"}
                        placeholder={"Type Wet Surface Area"}
                        validationType={EditeSite}
                      />
                    </Col>
                    <Col md="3">
                      <CustomInput
                        name={"PaintStages"}
                        type={"text"}
                        label={"Paint Stages"}
                        placeholder={"Type Paint Stages"}
                        validationType={EditeSite}
                      />
                    </Col>
                    <Col md="3">
                      <CustomInput
                        name={"ActualPaintPot"}
                        type={"text"}
                        label={"Actual Paint Pot"}
                        placeholder={"Type Actual Paint Pot"}
                        validationType={EditeSite}
                        required
                      />
                    </Col>
                    <Col md="3">
                      <CustomInput
                        name={"ExteriorArea"}
                        type={"text"}
                        label={"Exterior Area"}
                        placeholder={"Type Exterior Area"}
                        validationType={EditeSite}
                        required
                      />
                    </Col>
                    <Col md="3">
                      <CustomInput
                        name={"InteriorArea"}
                        type={"text"}
                        label={"Interior Area"}
                        placeholder={"Type Interior Area"}
                        validationType={EditeSite}
                        required
                      />
                    </Col>
                    <Col md="3">
                      <CustomInput
                        name={"BasementFloorArea"}
                        type={"text"}
                        label={"Basement Floor Area"}
                        placeholder={"Type Basement Floor Area"}
                        validationType={EditeSite}
                      />
                    </Col>
                    <Col md="3">
                      <CustomInput
                        name={"PaintPot"}
                        type={"text"}
                        label={"Paint Pot"}
                        placeholder={"Type Paint Pot"}
                        validationType={EditeSite}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <CustomInput
                        name={"ConstructionPOT"}
                        type={"text"}
                        label={"Construction POT"}
                        placeholder={"Type Construction POT"}
                        validationType={EditeSite}
                        required
                      />
                    </Col>
                    <Col>
                      <CustomInput
                        name={"PaintPotentialArea"}
                        type={"text"}
                        label={"Paint Potential Area"}
                        placeholder={"Type Paint Potential Area"}
                        validationType={EditeSite}
                      />
                    </Col>
                    <Col>
                      <CustomInput
                        name={"WPCC"}
                        type={"select"}
                        label={"Select WPCC"}
                        validationType={EditeSite}
                        required
                      >
                        <option defaultValue>Select WPCC</option>
                        {WPCC?.map((data, idx) => (
                          <option key={idx} value={data.value}>
                            {data.name}
                          </option>
                        ))}
                      </CustomInput>
                    </Col>
                    <Col>
                      <CustomInput
                        name={"DECO"}
                        type={"select"}
                        label={"Select DECO"}
                        validationType={EditeSite}
                        required
                      >
                        <option defaultValue>Select DECO</option>
                        {WPCC?.map((data, idx) => (
                          <option key={idx} value={data.value}>
                            {data.name}
                          </option>
                        ))}
                      </CustomInput>
                    </Col>
                  </Row>

                  {clients.map((client, idx) =>
                    idx <= 3 ? (
                      <Row key={idx}>
                        <label htmlFor="">Client Info:</label>
                        <Col md="2" className="">
                          <FormGroup>
                            <Input
                              name="name"
                              onChange={e =>
                                handleSetClientName(e.target.value, idx)
                              }
                              placeholder="Client Name"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                        <Col md="2" className="">
                          <FormGroup>
                            <Input
                              name="designation"
                              onChange={e =>
                                handleSetDesignation(e.target.value, idx)
                              }
                              placeholder="Designation"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                        <Col md="2" className="">
                          <FormGroup>
                            <Input
                              name="contactNo"
                              onChange={e =>
                                handleSetContactNo(e.target.value, idx)
                              }
                              placeholder="Contact No"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                        <Col md="2" className="">
                          <FormGroup>
                            <Input
                              name="email"
                              onChange={e =>
                                handleSetEmail(e.target.value, idx)
                              }
                              placeholder="Email"
                              type="text"
                              required
                            />
                          </FormGroup>
                        </Col>
                        <Col md="2" className="">
                          <FormGroup>
                            <Input
                              name="organizationName"
                              onChange={e =>
                                handleSetOrganizationName(e.target.value, idx)
                              }
                              placeholder="Organization Name"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                        <Col md="2" className="">
                          <FormGroup>
                            <Input
                              name="respondentType"
                              onChange={e =>
                                handleSetRespondentType(e.target.value, idx)
                              }
                              placeholder="Respondent Type"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                        {idx == 0 ? (
                          <Col md="12" className="text-end">
                            <div
                              className="btn btn-primary btn-sm"
                              onClick={handleAddClientInput}
                            >
                              {" "}
                              +
                            </div>
                          </Col>
                        ) : (
                          <Col md="12" className="text-end">
                            <div
                              className="btn btn-primary btn-sm"
                              onClick={() => this.handleRemoveClientInput(idx)}
                            >
                              -
                            </div>
                          </Col>
                        )}
                      </Row>
                    ) : (
                      <div key={idx}>
                        <></>
                      </div>
                    )
                  )}

                  <div className="text-center">
                    <input
                      type="submit"
                      value={"Update Site"}
                      className="btn button "
                    />
                  </div>
                </Form>
              </CardComponent>
            </Col>
          </Row>
        </div>
      </InnerLayer>
    </React.Fragment>
  )
}

export default EditSite
