import {
  ADD_NEW_SEGMENT,
  ADD_SEGMENT_FAIL,
  ADD_SEGMENT_SUCCESS,
  GET_SEGMENT_VALUE,
  GET_SEGMENT_VALUE_SUCCESS,
  STORE_SEGMENT_DATA,
  UPDATE_SEGMENT,
  UPDATE_SEGMENT_FAIL,
  UPDATE_SEGMENT_SUCCESS,
} from "./actionTypes"

//SEGMENT
export const getSegmentValue = (authtoken, pageRange, currentPage) => ({
  type: GET_SEGMENT_VALUE,
  payload: { authtoken, pageRange, currentPage },
})

export const getSegmentValueSuccess = data => ({
  type: GET_SEGMENT_VALUE_SUCCESS,
  payload: { data },
})

export const addNewSegment = (data, history, authtoken) => ({
  type: ADD_NEW_SEGMENT,
  payload: { data, history, authtoken },
})
export const addSegmentSuccess = data => ({
  type: ADD_SEGMENT_SUCCESS,
  payload: data,
})

export const addSegmentFail = error => ({
  type: ADD_SEGMENT_FAIL,
  payload: error,
})
export const storeSegmentData = (name, data) => ({
  type: STORE_SEGMENT_DATA,
  payload: { name, data },
})

export const updateSegment = (id, data, token, history) => ({
  type: UPDATE_SEGMENT,
  payload: { data, history, token, id },
})

export const updateSegmentSuccess = (id, data) => ({
  type: UPDATE_SEGMENT_SUCCESS,
  payload: { id, data },
})

export const updateSegmentFail = error => ({
  type: UPDATE_SEGMENT_FAIL,
  payload: error,
})
