//ADD SITE
export const ADD_NEW_SITE = "ADD_NEW_SITE"
export const ADD_SITE_SUCCESS = "ADD_SITE_SUCCESS"
export const ADD_SITE_FAIL = "ADD_SITE_FAIL"

//EDIT SITE
export const UPDATE_SITE = "UPDATE_SITE"
export const UPDATE_SITE_SUCCESS = "UPDATE_SITE_SUCCESS"
export const UPDATE_SITE_FAIL = "UPDATE_SITE_FAIL"

export const STORE_SITE_DATA = "STORE_SITE_DATA"
