import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Spinner } from "reactstrap"
import { getDashboardOverview } from "store/actions"
import Brush from "../../assets/images/xelerator/icon/dashboard/brush.svg"
import Building from "../../assets/images/xelerator/icon/dashboard/building.svg"
import Buildings2 from "../../assets/images/xelerator/icon/dashboard/buildings-2.svg"
import Courthouse from "../../assets/images/xelerator/icon/dashboard/courthouse.svg"
import DocumenText2 from "../../assets/images/xelerator/icon/dashboard/document-text.svg"
import Global from "../../assets/images/xelerator/icon/dashboard/global.svg"
import Location from "../../assets/images/xelerator/icon/dashboard/location.svg"
import { makeQuery } from "../../helpers/Custom/makeQuery"

const DashboardTopArea = () => {
  const dispatch = useDispatch()
  // const [overview,setOverview]= useState()

  // useEffect(() => {
  // fetch('https://staging-api.xelerator.org/api/v1/dashboard/topbanner')
  // .then(response => response.json())
  // .then(data => setOverview(data));
  // },[]);

  const {
    overview,
    mainFilter,
    subSelectedFilters,
    isSubFilterChanged,
    selectedSubCategory,
    currentSelection,
    overviewLoading,
    totalValue,
    authToken,
    role
  } = useSelector(state => ({
    mainFilter: state.DashboardReducer.mainFilter,
    totalValue: state.DashboardReducer.totalValue,
    subSelectedFilters: state.DashboardReducer.subSelectedFilters,
    isSubFilterChanged: state.DashboardReducer.isSubFilterChanged,
    selectedSubCategory: state.DashboardReducer.selectedSubCategory,
    currentSelection: state.DashboardReducer.currentSelection,
    overview: state?.AdminReducer?.overview,
    overviewLoading: state?.AdminReducer?.overviewLoading,
    authToken : state.Login.token,
    role: state.Login.data?.role,
  }))
  useEffect(() => {
    const chartQuery = makeQuery(
      mainFilter,
      subSelectedFilters,
      selectedSubCategory,
      currentSelection,
      totalValue
    )
    // console.log(
    //   `file: dashboard.js ~ line 111 ~ useEffect ~ chartQuery`,
    //   chartQuery
    // )
    dispatch(getDashboardOverview(authToken, chartQuery, 'ADMIN'))
  }, [isSubFilterChanged, totalValue])

  //  console.log('overviewn',overview);
  let overViewData = Math.round(overview?.data?.paintpot / 10000000)
  let constructionPotData = Math.round(overview?.data?.constructionpot)

  return (
    <>
      {overviewLoading ? (
        <div className="text-center mt-5 pt-5">
          <Spinner />
        </div>
      ) : (
        <>
          <div className="paint-pot-responsive">
            <div className="d-flex justify-content-center">
              <img src={Brush} alt="Icon" />
              <p className="ms-1 mt-2">Paint Pot</p>
            </div>
            <h6>{overViewData} cr.</h6>
          </div>
          <div className="construction-pot-responsive">
            <div className="d-flex justify-content-center">
              <img src={Buildings2} alt="Icon" />
              <p className="ms-1 mt-2">Construction Pot</p>
            </div>
            <h6>{constructionPotData.toLocaleString()} cr.</h6>
          </div>
          <div className="">
            <div className="d-flex justify-content-center">
              <img src={DocumenText2} alt="Icon" />
              <p className="ms-1 mt-2">Total Project</p>
            </div>
            <h6>{Math.ceil(overview?.data?.totalproject).toLocaleString()}</h6>
          </div>
          <div className="">
            <div className="d-flex justify-content-center">
              <img src={Courthouse} alt="Icon" />
              <p className="ms-1 mt-2">Total Govt. Project</p>
            </div>
            <h6>{Math.ceil(overview?.data?.govtproject).toLocaleString()}</h6>
          </div>
          <div className="">
            <div className="d-flex justify-content-center">
              <img src={Building} alt="Icon" />
              <p className="ms-1 mt-2">Total Private Project</p>
            </div>
            <h6>
              {Math.ceil(overview?.data?.privateproject).toLocaleString()}
            </h6>
          </div>
          <div className="total-areas-responsive">
            <div className="d-flex justify-content-center">
              <img src={Global} alt="Icon" />
              <p className="ms-1 mt-2">Total Areas</p>
            </div>
            <h6>{Math.ceil(overview?.data?.totalareas).toLocaleString()}</h6>
          </div>
          <div className="">
            <div className="d-flex justify-content-center">
              <img src={Location} alt="Icon" />
              <p className="ms-1 mt-2">Total Territories</p>
            </div>
            <h6>
              {Math.ceil(overview?.data?.totalterittory).toLocaleString()}
            </h6>
          </div>
        </>
      )}
    </>
  )
}

export default DashboardTopArea
