import {
  ADD_SITE_FAIL,
  ADD_SITE_SUCCESS,
  STORE_SITE_DATA,
  UPDATE_SITE_FAIL,
  UPDATE_SITE_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
  isLoading: false,
  siteData: [],
  editSiteInfo: [],
  site: [],
}

const SiteReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ADD_SITE_SUCCESS:
      return {
        ...state,
        site: [...state.site, action.payload],
        isLoading: false,
      }

    case ADD_SITE_FAIL:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      }

    case STORE_SITE_DATA:
      return {
        ...state,
        editSiteInfo: action.payload,
      }
    case UPDATE_SITE_SUCCESS:
      return {
        ...state,
        siteData: state.site.map(site =>
          site.id.toString() === action.payload.id.toString()
            ? { site, ...action.payload }
            : site
        ),
        loading: false,
      }

    case UPDATE_SITE_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state
  }
}

export default SiteReducer
