import CustomInput from "components/Common/CustomInput"
import CardComponent from "components/Layout/CardComponent"
import InnerLayer from "components/Layout/InnerLayer"
import { addRouteSchema } from "components/Schemas/AddRoute.schema"
import { useFormik } from "formik"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { Col, Form, FormGroup, Input, Label, Row } from "reactstrap"
import {
  addNewRoute,
  getAreaByRegionValue,
  getRegionValue,
  getTerritoryValue,
} from "../../../store/GeoInformation/action"
const breadcrumbItems = [
  {
    title: "Geo Information",
    link: "#",
  },
  {
    title: "Route",
    link: "/geo/route",
  },
  {
    title: "Add Route",
    link: "#",
  },
]
const initialValues = {
  region: "",
  area: "",
  territory: "",
  name: "",
}

const AddRoute = () => {
  const [routes, setRoutes] = useState([
    {
      value: "",
    },
  ])
  const [regionId, setRegionId] = useState("")
  const handleRoutInput = () => {
    setRoutes([...routes, { value: "" }])
  }

  const handleRoute = (e, idx) => {
    console.log(e, idx)
    const oldValue = [...routes]
    oldValue[idx].value = e.target.value
    setRoutes(oldValue)
  }
  const handleRegionValue = e => {
    setRegionId(e._id)
    dispatch(getAreaByRegionValue(authtoken, regionId, 0, 0))
  }
  const history = useHistory()
  let dispatch = useDispatch()
  //get region, area,territory
  const { regionData, areaByRegionData, territoryData, authtoken } =
    useSelector(state => ({
      authtoken: state.Login.token,
      regionData: state.GeoInformationReducer.regionData,
      areaByRegionData: state.GeoInformationReducer.areaByRegionData,
      territoryData: state.GeoInformationReducer.territoryData,
    }))
  //end get region, area,territory

  useEffect(() => {
    dispatch(getRegionValue(authtoken))
    dispatch(getTerritoryValue(authtoken))
  }, [])
  // useEffect(() => {
  //   dispatch(getAreaByRegionValue(authtoken, regionId, 0, 0))
  // }, [regionId])
  console.log("iiiii", regionId)

  const onSubmit = values => {
    const data = { ...values, routes }
    let obj = {}
    obj.name = data.routes
    obj.territory = data.territory
    console.log("obj data here", obj)
    let arr = []
    obj.name.map((v, i) => {
      // console.log("Hello v", v)
      arr.push({ name: v.value, territory: obj.territory })
    })
    dispatch(addNewRoute(arr, history, authtoken))
  }

  const AddRoute = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: addRouteSchema,
    onSubmit,
  })
  return (
    <React.Fragment>
      <InnerLayer
        isMultiple
        title={"Add new Route"}
        breadcrumbItems={breadcrumbItems}
      >
        <div style={{ minHeight: "100vh" }} className="position-relative">
          <Row className="mt-4">
            <Col md="8" className="mx-auto">
              {/* add User */}
              <CardComponent className="">
                <Form
                  className="needs-validation"
                  onSubmit={AddRoute.handleSubmit}
                >
                  <Row>
                    <Col className="mx-auto" sm="12">
                      <CustomInput
                        name={"region"}
                        type={"select"}
                        label={"Select region"}
                        validationType={AddRoute}
                      >
                        <option defaultValue>Select region...</option>
                        {regionData?.length > 0 ? (
                          regionData?.region.map((data, idx) => (
                            <option value={data._id} key={idx}>
                              {data.name}
                            </option>
                          ))
                        ) : (
                          <></>
                        )}
                      </CustomInput>
                    </Col>
                    <Col className="mx-auto" sm="12">
                      <CustomInput
                        name={"area"}
                        type={"select"}
                        label={"Select area"}
                        validationType={AddRoute}
                      >
                        <option defaultValue>Select area...</option>
                        {areaByRegionData?.length > 0 ? (
                          areaByRegionData?.area.map((data, idx) => (
                            <option value={data._id} key={idx}>
                              {data.name}
                            </option>
                          ))
                        ) : (
                          <></>
                        )}
                      </CustomInput>
                    </Col>
                    <Col className="mx-auto" sm="12">
                      <CustomInput
                        name={"territory"}
                        type={"select"}
                        label={"Select territory"}
                        validationType={AddRoute}
                      >
                        <option defaultValue>Select territory...</option>
                        {territoryData?.length > 0 ? (
                          territoryData?.territory.map((data, idx) => (
                            <option value={data._id} key={idx}>
                              {data.name}
                            </option>
                          ))
                        ) : (
                          <></>
                        )}
                      </CustomInput>
                    </Col>

                    <Col md="11" className="">
                      {routes.map((route, idx) => (
                        <FormGroup key={idx}>
                          <Label for="route">Type route</Label>
                          <Input
                            name="name"
                            onChange={e => handleRoute(e, idx)}
                            placeholder="Route name"
                            type="text"
                            value={route.value}
                          />
                        </FormGroup>
                      ))}
                    </Col>
                    <Col
                      md="1"
                      className="text-end"
                      style={{ marginTop: "30px" }}
                    >
                      <button
                        type="button"
                        className="btn btn-primary btn-sm"
                        onClick={handleRoutInput}
                      >
                        +
                      </button>
                    </Col>
                  </Row>

                  <div className="d-flex justify-content-start">
                    <input
                      type="submit"
                      value={"Add Route"}
                      className="btn button "
                    />
                  </div>
                </Form>
              </CardComponent>
            </Col>
          </Row>
        </div>
      </InnerLayer>
    </React.Fragment>
  )
}

export default AddRoute
