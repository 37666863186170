import { toast } from "react-toastify"
import { call, put, takeEvery, takeLatest } from "redux-saga/effects"
import { axiosLoginUser } from "../../../helpers/api_helper"
import { postSocialLogin } from "../../../helpers/fakebackend_helper"
//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper"
import { apiError, loginSuccess, logoutUserSuccess } from "./actions"
// Login Redux States
import { LOGIN_USER, LOGOUT_USER, SOCIAL_LOGIN } from "./actionTypes"

const fireBaseBackend = getFirebaseBackend()
const asyncLoginUser = async (email, password) => {
  try {
    const response = await axiosLoginUser(email, password)
    return response.data
  } catch (error) {
    return error.response.data
  }
}
function* loginUser({ payload: { user, history } }) {
  try {
    const { email, password } = user
    const response = yield call(asyncLoginUser, email, password)
    // console.log("all response from here", response)
    if (response.status == "success") {
      // localStorage.setItem("authUser", response.token)
      yield put(loginSuccess(response))
      // console.log("pccccccc", user)
      history.push("/dashboard")
      toast.success("You have logged in successfully")
    } else {
      toast.error(response.message)
    }
  } catch (error) {
    const message = error.response.data.message
    toast.error(message)
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem("authUser")

    // if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
    //   const response = yield call(fireBaseBackend.logout)
    // }
    yield put(logoutUserSuccess(""))
    history.push("/login")
  } catch (error) {
    yield put(apiError(error))
  }
}

function* socialLogin({ payload: { data, history, type } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const fireBaseBackend = getFirebaseBackend()
      const response = yield call(fireBaseBackend.socialLoginUser, data, type)
      localStorage.setItem("authUser", JSON.stringify(response))
      yield put(loginSuccess(response))
    } else {
      const response = yield call(postSocialLogin, data)
      localStorage.setItem("authUser", JSON.stringify(response))
      yield put(loginSuccess(response))
    }
    history.push("/dashboard")
  } catch (error) {
    yield put(apiError(error))
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser)
  yield takeLatest(SOCIAL_LOGIN, socialLogin)
  yield takeEvery(LOGOUT_USER, logoutUser)
}

export default authSaga
