//ADD user
export const ADD_NEW_USER = "ADD_NEW_USER"
export const ADD_USER_SUCCESS = "ADD_USER_SUCCESS"
export const ADD_USER_FAIL = "ADD_USER_FAIL"

//GET user
export const GET_USER_VALUE = "GET_USER_VALUE"
export const GET_USER_VALUE_SUCCESS = "GET_USER_VALUE_SUCCESS"

//EDIT user
export const UPDATE_USER = "UPDATE_USER"
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS"
export const UPDATE_USER_FAIL = "UPDATE_USER_FAIL"

export const STORE_USER_DATA = "STORE_USER_DATA"

