import PropTypes from "prop-types"
import React from "react"
import { useSelector } from "react-redux"
import { Redirect, Route } from "react-router-dom"

const AuthMiddleware = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  roles,
  ...rest
}) => {
  const { token, data } = useSelector(state => ({
    token: state.Login.token,
    data: state.Login.data,
  }))

  // if (token !== "") {
  //   var decode = jwtDecode(token)
  // }
  // console.log(`🦄 ~ file: AuthMiddleware.js ~ line 19 ~ decode`, data, roles)

  return (
    <Route
      {...rest}
      render={props => {
        if (isAuthProtected && token && roles.includes(data.role)) {
          return (
            <Layout>
              <Component {...props} />
            </Layout>
          )
        }
        return (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        )
      }}
    />
  )
}

AuthMiddleware.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
  layout: PropTypes.any,
  roles: PropTypes.array,
}

export default AuthMiddleware
