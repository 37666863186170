import SearchInput from "components/Atoms/SearchInput"
import CardComponent from "components/Layout/CardComponent"
import CustomTable from "components/Layout/CustomTable"
import InnerLayer from "components/Layout/InnerLayer"
import PropTypes from "prop-types"
import React from "react"
import { withRouter } from "react-router-dom"
import { Col, Input, Row } from "reactstrap"
const breadcrumbItems = [
  {
    title: "Dashboard",
    link: "/dashboard",
  },
  {
    title: "Users",
    link: "/users",
  },
  {
    title: "Set Route",
    link: "#",
  },
]
const tableData = [
  {
    id: "1",
    name: "27 no road, dhanmond",
  },
  {
    id: "3",
    name: "27 no road, dhanmond",
  },
  {
    id: "3",
    name: "27 no road, dhanmond",
  },
]
const tableHead = ["No.", "Route name", "Action"]

const SetUserRoute = ({ location, history }) => {
  console.log(`SetUserRoute ~ location`, location)
  if (!!location.state) {
    var { data } = location.state
  } else {
    history.push("/users")
  }
  return (
    <React.Fragment>
      <InnerLayer
        isMultiple
        title={`Set Route for "${data?.name || ""}"`}
        breadcrumbItems={breadcrumbItems}
      >
        <Row>
          <Col md="8" className="mx-auto">
            <CardComponent>
              <div>
                <h6>Please Type route (Multi select)</h6>
                <div className="search-box me-2">
                  <SearchInput
                    className="userSearchBar form-control"
                    onChange={() => {}}
                  />
                </div>

                <div className="" style={{ minHeight: "200px" }}>
                  {}
                </div>

                <div className="">
                  <h5>Selected route list</h5>
                  <CustomTable
                    data={tableData}
                    tableHead={tableHead}
                    isCheckbox
                    handleAllSelect={e => console.log(e.target.value)}
                  >
                    {tableData.map((data, idx) => (
                      <tr key={data.id}>
                        <th scope="row">
                          <Input
                            type="checkbox"
                            name={data.id}
                            onChange={e => console.log(e.target.value)}
                          />
                        </th>
                        <th scope="row">{data.id}</th>
                        <td>{data.name}</td>
                        <td>
                          <span
                            className={"pointer_event text-danger"}
                            onClick={() => {}}
                          >
                            <i className="fa fa-times"></i>
                          </span>
                        </td>
                      </tr>
                    ))}
                  </CustomTable>
                </div>
              </div>
            </CardComponent>
          </Col>
        </Row>
      </InnerLayer>
    </React.Fragment>
  )
}

SetUserRoute.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
}

export default withRouter(SetUserRoute)
