import {
  ADD_CATEGORY_FAIL,
  ADD_CATEGORY_SUCCESS,
  ADD_SUB_CATEGORY_FAIL,
  ADD_SUB_CATEGORY_SUCCESS,
  GET_CATEGORY_VALUE,
  GET_CATEGORY_VALUE_SUCCESS,
  GET_SUB_CATEGORY_BY_CATEGORY_VALUE,
  GET_SUB_CATEGORY_BY_CATEGORY_VALUE_SUCCESS,
  GET_SUB_CATEGORY_VALUE,
  GET_SUB_CATEGORY_VALUE_SUCCESS,
  STORE_CATEGORY_DATA,
  STORE_SUB_CATEGORY_DATA,
  UPDATE_CATEGORY_FAIL,
  UPDATE_CATEGORY_SUCCESS,
  UPDATE_SUB_CATEGORY_FAIL,
  UPDATE_SUB_CATEGORY_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
  isLoading: false,
  categoryData: [],
  subCategoryByCategoryData: [],
  editCategoryInfo: [],
  subCategoryData: [],
  editSubCategoryInfo: [],
}

const CategoryReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CATEGORY_VALUE:
      return {
        ...state,
        isLoading: true,
      }
      break

    case GET_CATEGORY_VALUE_SUCCESS:
      return {
        ...state,
        categoryData: action.payload.data,
        isLoading: false,
      }

    case ADD_CATEGORY_SUCCESS:
      return {
        ...state,
        category: [...state.category, action.payload],
        isLoading: false,
      }

    case ADD_CATEGORY_FAIL:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      }

    case STORE_CATEGORY_DATA:
      return {
        ...state,
        editCategoryInfo: action.payload,
      }
    case UPDATE_CATEGORY_SUCCESS:
      return {
        ...state,
        categoryData: state.category.map(category =>
          category.id.toString() === action.payload.id.toString()
            ? { category, ...action.payload }
            : category
        ),
        loading: false,
      }

    case UPDATE_CATEGORY_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    //SUB CATEGORY
    case GET_SUB_CATEGORY_VALUE:
      return {
        ...state,
        isLoading: true,
      }
      break

    case GET_SUB_CATEGORY_VALUE_SUCCESS:
      return {
        ...state,
        subCategoryData: action.payload.data,
        isLoading: false,
      }

    case GET_SUB_CATEGORY_BY_CATEGORY_VALUE:
      return {
        ...state,
        isLoading: true,
      }
      break

    case GET_SUB_CATEGORY_BY_CATEGORY_VALUE_SUCCESS:
      return {
        ...state,
        subCategoryByCategoryData: action.payload.data,
        isLoading: false,
      }

    case ADD_SUB_CATEGORY_SUCCESS:
      return {
        ...state,
        subCategory: [...state.subCategory, action.payload],
        isLoading: false,
      }

    case ADD_SUB_CATEGORY_FAIL:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      }

    case STORE_SUB_CATEGORY_DATA:
      return {
        ...state,
        editSubCategoryInfo: action.payload,
      }
    case UPDATE_SUB_CATEGORY_SUCCESS:
      return {
        ...state,
        subCategoryData: state.subCategory.map(subCategory =>
          subCategory.id.toString() === action.payload.id.toString()
            ? { subCategory, ...action.payload }
            : subCategory
        ),
        loading: false,
      }

    case UPDATE_SUB_CATEGORY_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default CategoryReducer
