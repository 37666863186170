import React from "react"
import { Table } from "reactstrap"

const AttendanceList = () => {
  return (
    <React.Fragment>
      <div className="table-responsive mt-4">
        <Table className="table mb-0">
          <thead className="table-light">
            <tr>
              <th>Date</th>
              <th>Time</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>21/02/2022</td>
              <td>10:00 AM</td>
            </tr>
            <tr>
              <td>22/02/2022</td>
              <td>10:00 AM</td>
            </tr>
            <tr>
              <td>22/02/2022</td>
              <td>10:00 AM</td>
            </tr>
          </tbody>
        </Table>
      </div>
    </React.Fragment>
  )
}

export default AttendanceList
