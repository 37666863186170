import { toast } from "react-toastify"
import { call, put, takeEvery } from "redux-saga/effects"
import {
  axiosGetRoutePlan,
  postRoutePlanData,
  updateRoutePlanData,
} from "../../helpers/api_helper"
import {
  addRoutePlanFail,
  getRoutePlanValueSuccess,
  updateRoutePlanFail,
} from "./action"
// Calender Redux States
import {
  ADD_NEW_ROUTE_PLAN,
  GET_ROUTE_PLAN_VALUE,
  UPDATE_ROUTE_PLAN,
} from "./actionTypes"

//ROUTE PLAN
const asyncGetRoutePlan = async (authtoken, userId, fromDate, toDate) => {
  try {
    const response = await axiosGetRoutePlan(
      authtoken,
      userId,
      fromDate,
      toDate
    )
    console.log("response here 29", response)

    return response.data
  } catch (error) {
    return error.response.data
  }
}
function* workerGetRoutePlan({
  payload: { authtoken, userId, fromDate, toDate },
}) {
  try {
    const response = yield call(
      asyncGetRoutePlan,
      authtoken,
      userId,
      fromDate,
      toDate
    )
    console.log("response here 29", response)
    yield put(getRoutePlanValueSuccess(response.routePlan))
  } catch (error) {}
}

//async
const asyncPostRoutePlanData = async (data, authtoken) => {
  try {
    const response = await postRoutePlanData(data, authtoken)
    return response.data
  } catch (error) {
    return error.response.data
  }
}

function* onAddRoutePlan({ payload: { data, history, authtoken } }) {
  try {
    const response = yield call(asyncPostRoutePlanData, data, authtoken)
    if (response.status == "success") {
      toast("🦄 Route plan added successfully!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
      history.push("route-plan")
    } else {
      toast.error(response.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
  } catch (error) {
    yield put(addRoutePlanFail(error.message))
    toast.error(error.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    })
  }
}

function* onUpdateRoutePlan({ payload: { data, history, authtoken } }) {
  try {
    console.log("arr", data)
    const response = yield call(updateRoutePlanData, data, authtoken)
    console.log("response show", response)
    toast("🦄 Route Plan updated successfully!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    })
    if (response.status === 200) {
      history.push("/route-plan")
    }
  } catch (error) {
    console.log(error.response)
    yield put(updateRoutePlanFail(error))
  }
}

function* RoutePlanSaga() {
  yield takeEvery(GET_ROUTE_PLAN_VALUE, workerGetRoutePlan)
  yield takeEvery(ADD_NEW_ROUTE_PLAN, onAddRoutePlan)
  yield takeEvery(UPDATE_ROUTE_PLAN, onUpdateRoutePlan)
}

export default RoutePlanSaga
