import * as Yup from "yup"
const FILE_SIZE = 160 * 1024
const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/gif", "image/png"]

export const editUserSchema = Yup.object().shape({
  name: Yup.string().required("This value is required"),
  // password: Yup.string().required("Please Enter your password"),
  // .matches(
  //   /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
  //   "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
  // ),
  // confirmPassword: Yup.string()
  //   .oneOf([Yup.ref("password"), null], "Passwords must match")
  //   .required("Please Enter your Confirm password"),
  email: Yup.string()
    .email("Must be a valid Email")
    .max(255)
    .required("Email is required"),
  // url: Yup.string()
  //   .matches(
  //     /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
  //     "Enter correct url!"
  //   )
  //   .required("Please enter correct Url"),
  mobileNo: Yup.number().required("Please Enter Your Phone Number"),
  role: Yup.string()
    .oneOf(["ADMIN", "LA", "ORG"], "Invalid Role Type")
    .required("Select Role is Required"),
  // office: Yup.string()
  //   .oneOf(
  //     ["27 no road, dhanmond", "27 no road, Badda", "27 no road, "],
  //     "Invalid Role Type"
  //   )
  //   .required("Select Office is Required"),
  //file: Yup.mixed().required("A file is required"),
  // .test(
  //   "fileSize",
  //   "File too large",
  //   value => value && value.size <= FILE_SIZE
  // )
  // .test(
  //   "fileFormat",
  //   "Unsupported Format",
  //   value => value && SUPPORTED_FORMATS.includes(value.type)
  // ),
  // alphanumeric: Yup.string()
  //   .matches(/^[a-z0-9]+$/i, "Enter correct Alphanumeric!")
  //   .required("Please Enter Your Alphanumeric"),
  // textarea: Yup.string().required("Please Enter Your Textarea"),
})
