import AddCard from "components/Atoms/AddCard"
import TableHeader from "components/Common/TableHeader"
import CardComponent from "components/Layout/CardComponent"
import CustomTable from "components/Layout/CustomTable"
import InnerLayer from "components/Layout/InnerLayer"
import NoTableData from "components/Layout/NoTableData"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { withRouter } from "react-router-dom"
import { Col, Row } from "reactstrap"
import {
  getTerritoryValue,
  storeTerritoryData,
} from "../../../store/GeoInformation/action"

const tableHead = [
  "No.",
  "Region Name",
  "Area Name",
  "Territory Name",
  "Action",
]

const Territory = ({ history }) => {
  const [currentPage, setCurrentPage] = useState(1)
  const [dataRange, setDataRange] = useState(10)
  let dispatch = useDispatch()
  const { regionData, territoryData, loading, authtoken } = useSelector(
    state => ({
      loading: state.GeoInformationReducer.isLoading,
      territoryData: state.GeoInformationReducer.territoryData,
      authtoken: state.Login.token,
    })
  )
  useEffect(() => {
    dispatch(getTerritoryValue(authtoken, dataRange, currentPage))
  }, [dataRange, currentPage])
  let totalPageNumber = Math.ceil(territoryData?.length / dataRange)

  const handleEditTerritory = data => {
    dispatch(storeTerritoryData("singleTerritory", data))
    history.push("/edit-territory/" + data._id)
  }
  return (
    <React.Fragment>
      <InnerLayer
        title="Territory List"
        wrapperClass="py-3 users"
        isBreadCrumb={true}
        link={"#"}
        mainTitle={"Geo Information"}
        subTitle={"Territory List"}
      >
        <Row>
          <Col md="3" className="">
            <AddCard
              title="Create New Territory"
              buttonText="Create Territory"
              onAddHandler={() => history.push("/add-territory")}
            />
          </Col>
        </Row>

        {/* table */}

        <Row>
          <CardComponent className="user_table_card">
            <TableHeader
              handleSearchFilter={() => {}}
              handleSelectFilter={() => {}}
              data={[10, 20, 50, 100]}
              handleSelectFilter2={() => {}}
              data2={[]}
              headerSearchOption="yes"
              selectValue={dataRange}
              title="Territory List"
            />

            {/* table */}
            <Row>
              <CustomTable
                paginationClass="paginationContainer text-right"
                data={territoryData?.territory}
                tableHead={tableHead}
                setCurrentPage={setCurrentPage}
                isPagination
                pageNo={totalPageNumber}
              >
                {territoryData?.length > 0 ? (
                  territoryData?.territory.map((data, idx) => (
                    <tr key={idx}>
                      <th scope="row">{idx + 1}</th>
                      <td>{data?.area?.region?.name}</td>
                      <td>{data.area.name}</td>
                      <td>{data.name}</td>
                      <td>
                        <div className="d-flex gap-3">
                          <span
                            className="text-success pointer_event"
                            onClick={() => handleEditTerritory(data)}
                          >
                            <i
                              className="mdi mdi-pencil font-size-18"
                              id="edittooltip"
                            ></i>
                          </span>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <NoTableData
                    colSpan=""
                    className="d-flex justify-content-center align-items-center"
                    style={{ minHeight: "300px", width: `100%` }}
                  >
                    <span>No Data Here</span>
                  </NoTableData>
                )}
              </CustomTable>
              {/* table end */}
            </Row>
          </CardComponent>
        </Row>
      </InnerLayer>
    </React.Fragment>
  )
}

Territory.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
}

export default withRouter(Territory)
