import { getData, getSiteExcelData, postData } from "helpers/backend_helper"
import { toaster } from "helpers/Custom/Toaster"
import { api } from "helpers/fakebackend_helper"
import { toast } from "react-toastify"
import { call, put, takeEvery } from "redux-saga/effects"
// import { getFilterData } from "../../helpers/backend_helper"
import {
  axiosGetSitesView,
  deleteOrgSite,
  updateOrgStatusData,
} from "../../helpers/api_helper"
import {
  assigningSiteSuccessful,
  deleteOrgSiteFail,
  deleteOrgSiteSuccess,
  getOrgSitesData,
  getSiteExcelFail,
  getSiteExcelSuccess,
  getSitesViewDataSuccess,
  storeDashboardReducerData,
  updateOrgStatusFail,
} from "./actions"
import {
  ASSIGNING_SITE,
  CATEGORY_FILTER,
  DELETE_ORG_SITE,
  GET_AREA_BY_REGION,
  GET_CATEGORY,
  GET_CATEGORY_BY_ID,
  GET_LA_USER,
  GET_MANAGING_ORG_SITES_LIST,
  GET_ORG_SITE,
  GET_ORG_SITES_LIST,
  GET_ROUTE_BY_TERRITORY,
  GET_SITES_LIST,
  GET_SITE_EXCEL,
  GET_SITE_VIEW,
  GET_SUB_SELECTED,
  GET_TERRITORY_BY_AREA,
  UPDATE_ORG_STATUS,
} from "./actionTypes"

function* fetchAreaByRegion({ payload: { id, token } }) {
  try {
    const url = `/area/getAreaByRegion/${id}/0/0`
    const response = yield call(getData, url, token)
    yield put(storeDashboardReducerData("areaList", response?.area || []))
  } catch (error) {
    console.log(error.response)
  }
}

function* fetchTerritoryByArea({ payload: { id, token } }) {
  try {
    const url = `/territory/getTerritoryByArea/${id}/0/0`
    const response = yield call(getData, url, token)
    yield put(
      storeDashboardReducerData("territoryList", response?.territory || [])
    )
  } catch (error) {
    console.log(error.response)
  }
}

function* fetchRouteByTerritory({ payload: { id, token } }) {
  try {
    const url = `route/getRouteByTerritory/${id}/0/0`
    const response = yield call(getData, url, token)
    yield put(storeDashboardReducerData("routesList", response?.route || []))
  } catch (error) {
    console.log(error.response)
  }
}

function* fetchCategoryFilter({ payload: { token } }) {
  try {
    const url = `category`
    const response = yield call(getData, url, token)
    yield put(
      storeDashboardReducerData("categoryList", response?.category || [])
    )
  } catch (error) {
    console.log(error.response)
  }
}

function* fetchLaUser({ payload: { token } }) {
  try {
    const url = `users/LA/0/0`
    const response = yield call(getData, url, token)
    yield put(storeDashboardReducerData("laUser", response?.users || []))
  } catch (error) {
    console.log(error.response)
  }
}

function* fetchSubCategoryById({ payload: { id, token } }) {
  try {
    const url = `subCategory/getSubCategoryByCategory/${id}/0/0`
    const response = yield call(getData, url, token)
    yield put(
      storeDashboardReducerData("subCategoryList", response?.subCategory || [])
    )
  } catch (error) {
    console.log(error.response)
  }
}

function* fetchCategory({ payload }) {
  try {
    const url = `/category`
    const response = yield call(api, url)
    yield put(storeDashboardReducerData("subFilters", response?.category || []))
  } catch (error) {
    console.log(error)
  }
}

function* fetchSubCategory({ payload }) {
  try {
    const url = `/category/getsubcategory/${payload?.id}`
    const response = yield call(api, url)
    yield put(
      storeDashboardReducerData("subCategory", response?.subcategory || [])
    )
  } catch (error) {
    console.log(error)
  }
}

function* fetchSitesList({ payload: { token, data } }) {
  try {
    const url = `/site/getSiteList`
    const response = yield call(postData, url, data, token)
    yield put(storeDashboardReducerData("sitesData", response))
    yield put(storeDashboardReducerData("sitesLoading", false))
  } catch (error) {
    console.log(error.response)
  }
}

function* fetchOrgSitesList({ payload: { token, data } }) {
  try {
    const url = `/organizationSite/getSiteList`
    const response = yield call(postData, url, data, token)
    response?.sites?.forEach(data => {
      data.select = false
    })
    yield put(storeDashboardReducerData("orgSitesData", response))
    yield put(storeDashboardReducerData("orgSitesLoading", false))
  } catch (error) {
    console.log(error.response)
  }
}

function* fetchManagingOrgSitesList({ payload: { token, data } }) {
  try {
    const url = `/organizationSite/getOrganizationSite`
    const response = yield call(postData, url, data, token)
    console.log("responseeee", response)
    response?.sites?.forEach(data => {
      data.select = false
    })
    yield put(storeDashboardReducerData("managingOrgSitesData", response))
    yield put(storeDashboardReducerData("managingOrgSitesLoading", false))
  } catch (error) {
    console.log(error.response)
  }
}
//SITE IN  ORGANIZATION PANEL
function* fetchOrgSite({ payload: { token, data } }) {
  try {
    const url = `org/organizationSite/getOrganizationSite`
    const response = yield call(postData, url, data, token)
    console.log("responseeee", response)
    response?.sites?.forEach(data => {
      data.select = false
    })
    yield put(storeDashboardReducerData("orgSiteData", response))
    yield put(storeDashboardReducerData("orgSiteLoading", false))
  } catch (error) {
    console.log(error.response)
  }
}
//
function* fetchSitesView({ payload: { token, sitesId } }) {
  try {
    const url = `/site`
    const response = yield call(postData, url, sitesId, token)
    yield put(storeDashboardReducerData("sitesData", response))
    yield put(storeDashboardReducerData("sitesLoading", false))
  } catch (error) {
    console.log(error.response)
  }
}
function* fetchSiteExcel({ payload: { token, data } }) {
  try {
    const url = `/site/siteListDownloadExcel`
    const response = yield call(getSiteExcelData, url, data, token)
    yield put(getSiteExcelSuccess(response))
  } catch (error) {
    yield put(getSiteExcelFail(error))
    console.log("hi")
  }
}

const asyncGetSitesView = async (token, siteId) => {
  try {
    const response = await axiosGetSitesView(token, siteId)
    return response.data
  } catch (error) {
    return error.response.data
  }
}
function* workerGetSitesView({ payload: { token, siteId } }) {
  try {
    const response = yield call(asyncGetSitesView, token, siteId)
    yield put(getSitesViewDataSuccess(response))
  } catch (error) {}
}

function* onAssigningSites({ payload: { data, token, loadData } }) {
  try {
    const url = "/organizationSite"
    const response = yield call(postData, url, data, token)

    if (response) {
      toaster("success", "Site Assigned Successful")
      yield put(getOrgSitesData(token, loadData))
      yield put(assigningSiteSuccessful())
    }
  } catch (error) {
    const message = error?.response?.data?.message || "Site Assigned  fail"
    toaster("error", message)
    yield put(assigningSiteSuccessful())
  }
}

//async delte Org site
const asyncDeleteOrgSite = async (authtoken, siteId) => {
  try {
    const response = await deleteOrgSite(authtoken, siteId)
    console.log("response data", response)
    return response.data
  } catch (error) {
    console.log("error ", error)
    return error.response.data
  }
}
//delete org site
function* onDeleteOrgSite({ payload: { history, authtoken, siteId } }) {
  // try {
  //   const response = yield call(deleteOrgSite, authtoken, siteId)

  //   console.log("response", response)
  //   yield put(deleteOrgSiteSuccess(siteId))
  //   if (response.data.status == "success") {
  //     console.log("response2", response.data.status)
  //     yield put(deleteOrgSiteSuccess(siteId))
  //     toast("🦄 Org Site updated successfully!", {
  //       position: "top-right",
  //       autoClose: 5000,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //     })
  //   } else {
  //     console.log("error", error.response)
  //     yield put(deleteOrgSiteFail(error))
  //   }

  //   // if (response.status === 200) {
  //   //   history.push("/geo/route")
  //   // }
  // } catch (error) {
  //   //console.log("error", error.response)
  //   //yield put(deleteOrgSiteFail(error))
  // }

  try {
    const response = yield call(asyncDeleteOrgSite, authtoken, siteId)

    if (response.status === "success") {
      console.log("site id data", siteId)
      yield put(deleteOrgSiteSuccess(siteId))
      toast("🦄 Site deleted successfully!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
      history.push("/managing-org-site")
    }
  } catch (error) {
    console.log(error)
    yield put(deleteOrgSiteFail(error))
  }
}
// UPDATE STATUS IN ORGANIZATION PANEL
function* onUpdateOrgStatus({ payload: { data, history, authtoken } }) {
  try {
    const response = yield call(updateOrgStatusData, data, authtoken)
    toast("🦄 Org status updated successfully!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    })
    if (response.status === 200) {
      history.push("/org-sites")
    }
  } catch (error) {
    console.log(error.response)
    yield put(updateOrgStatusFail(error))
  }
}

function* DashboardSaga() {
  yield takeEvery(GET_AREA_BY_REGION, fetchAreaByRegion)
  yield takeEvery(GET_TERRITORY_BY_AREA, fetchTerritoryByArea)
  yield takeEvery(GET_ROUTE_BY_TERRITORY, fetchRouteByTerritory)
  yield takeEvery(CATEGORY_FILTER, fetchCategoryFilter)
  yield takeEvery(GET_CATEGORY_BY_ID, fetchSubCategoryById)
  yield takeEvery(GET_LA_USER, fetchLaUser)
  yield takeEvery(GET_CATEGORY, fetchCategory)
  yield takeEvery(GET_SUB_SELECTED, fetchSubCategory)
  yield takeEvery(GET_SITES_LIST, fetchSitesList)
  yield takeEvery(GET_ORG_SITES_LIST, fetchOrgSitesList)
  yield takeEvery(GET_MANAGING_ORG_SITES_LIST, fetchManagingOrgSitesList)
  yield takeEvery(GET_ORG_SITE, fetchOrgSite)
  yield takeEvery(GET_SITE_EXCEL, fetchSiteExcel)
  yield takeEvery(GET_SITE_VIEW, workerGetSitesView)
  yield takeEvery(DELETE_ORG_SITE, onDeleteOrgSite)
  yield takeEvery(ASSIGNING_SITE, onAssigningSites)
  yield takeEvery(UPDATE_ORG_STATUS, onUpdateOrgStatus)
}
export default DashboardSaga
