//ADD ROUTE PLAN
export const ADD_NEW_ROUTE_PLAN = "ADD_NEW_ROUTE_PLAN"
export const ADD_ROUTE_PLAN_SUCCESS = "ADD_ROUTE_PLAN_SUCCESS"
export const ADD_ROUTE_PLAN_FAIL = "ADD_ROUTE_PLAN_FAIL"

//Edit ROUTE PLAN
export const GET_ROUTE_PLAN_VALUE = "GET_ROUTE_PLAN_VALUE"
export const GET_ROUTE_PLAN_VALUE_SUCCESS = "GET_ROUTE_PLAN_VALUE_SUCCESS"
export const GET_ROUTE_PLAN_VALUE_FAIL = "GET_ROUTE_PLAN_VALUE_FAIL"

//Update ROUTE PLAN
export const UPDATE_ROUTE_PLAN = "UPDATE_ROUTE_PLAN"
export const UPDATE_ROUTE_PLAN_SUCCESS = "UPDATE_ROUTE_PLAN_SUCCESS"
export const UPDATE_ROUTE_PLAN_FAIL = "UPDATE_ROUTE_PLAN_FAIL"

//export const STORE_ROUTE_PLAN_DATA = "STORE_ROUTE_PLAN_DATA"
