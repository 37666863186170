import Attendance from "pages/Attendence/Attendance"
import AddCategory from "pages/Category/AddCategory"
import Category from "pages/Category/Category"
import EditCategory from "pages/Category/EditCategory"
import AddSubCategory from "pages/Category/SubCategory/AddSubCategory"
import EditSubCategory from "pages/Category/SubCategory/EditSubCategory"
import SubCategory from "pages/Category/SubCategory/SubCategory"
import AddCustomerSegments from "pages/CustomerSegments/AddCustomerSegments"
import CustomerSegments from "pages/CustomerSegments/CustomerSegments"
import EditCustomerSegments from "pages/CustomerSegments/EditCustomerSegments"
import AddGeo from "pages/GeoInformation/AddGeo"
import AddArea from "pages/GeoInformation/Area/AddArea"
import Area from "pages/GeoInformation/Area/Area"
import EditArea from "pages/GeoInformation/Area/EditArea"
import GeoInformation from "pages/GeoInformation/GeoInformation"
import AddRegion from "pages/GeoInformation/Region/AddRegion"
import EditRegion from "pages/GeoInformation/Region/EditRegion"
import Region from "pages/GeoInformation/Region/Region"
import AddRoute from "pages/GeoInformation/Route/AddRoute"
import EditRoute from "pages/GeoInformation/Route/EditRoute"
import Route from "pages/GeoInformation/Route/Route"
import AddTerritory from "pages/GeoInformation/Territory/AddTerritory"
import EditTerritory from "pages/GeoInformation/Territory/EditTerritory"
import Territory from "pages/GeoInformation/Territory/Territory"
import OrgSites from "pages/Organization/Sites/OrgSites"
import AddProduct from "pages/Products/AddProduct"
import ProductDetails from "pages/Products/ProductDetails"
import Products from "pages/Products/Products"
import AddSite from "pages/Project/AddSite"
import EditSite from "pages/Project/EditSite"
import Projects from "pages/Project/Projects"
import ViewProjects from "pages/Project/ViewProjects"
import RoutePlan from "pages/RoutePlan/RoutePlan"
import AddPJP from "pages/SetPJP/AddPJP"
import ManagingOrgSite from "pages/SiteProviding/ManagingOrgSite"
import SiteProviding from "pages/SiteProviding/SiteProviding"
import TargetAchievement from "pages/Target/TargetAchievement"
import TargetSet from "pages/Target/TargetSet"
import AddUser from "pages/Users/AddUser"
import EditUser from "pages/Users/EditUser"
import SetUserRoute from "pages/Users/SetUserRoute"
import Users from "pages/Users/Users"
import React from "react"
import { Redirect } from "react-router-dom"
import ForgetPwd from "../pages/Authentication/ForgetPassword"
// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
// Profile
import UserProfile from "../pages/Authentication/user-profile"
// Dashboard
import Dashboard from "../pages/Dashboard/index"

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
]

const authProtectedRoutes = [
  //
  // Dashboard
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/dashboard" />,
    roles: ["ADMIN", "ORG"],
  },
  { path: "/dashboard", component: Dashboard, roles: ["ADMIN", "ORG"] },

  // //profile
  { path: "/profile", component: UserProfile, roles: ["ADMIN", "ORG", "LA"] },

  // users
  { path: "/users", component: Users, roles: ["ADMIN", "ORG"] },
  { path: "/add-user", component: AddUser, roles: ["ADMIN", "ORG"] },
  {
    path: "/edit-user/:id",
    component: EditUser,
    roles: ["ADMIN", "ORG"],
  },
  {
    path: "/set-user-route",
    component: SetUserRoute,
    roles: ["ADMIN", "ORG"],
  },

  // Route Plan
  { path: "/route-plan", component: RoutePlan, roles: ["ADMIN", "ORG"] },
  // { path: "/create-pjp", component: AddPJP },

  // project
  { path: "/sites", component: Projects, roles: ["ADMIN", "ORG"] },
  { path: "/add-site", component: AddSite, roles: ["ADMIN", "ORG"] },
  {
    path: "/edit-site/:id",
    component: EditSite,
    roles: ["ADMIN", "ORG"],
  },
  {
    path: "/view-projects",
    component: ViewProjects,
    roles: ["ADMIN", "ORG"],
  },

  // Sites providing
  {
    path: "/site-providing",
    component: SiteProviding,
    roles: ["ADMIN", "ORG"],
  },
  {
    path: "/managing-org-site",
    component: ManagingOrgSite,
    roles: ["ADMIN", "ORG"],
  },

  // Product
  { path: "/product", component: Products, roles: ["ADMIN", "ORG"] },
  {
    path: "/add-product",
    component: AddProduct,
    roles: ["ADMIN", "ORG"],
  },
  {
    path: "/product-details",
    component: ProductDetails,
    roles: ["ADMIN", "ORG"],
  },

  // Geo
  {
    path: "/geo-information",
    component: GeoInformation,
    roles: ["ADMIN", "ORG"],
  },
  { path: "/add-geo", component: AddGeo, roles: ["ADMIN", "ORG"] },

  // Geo sub menu region
  { path: "/geo/region", component: Region, roles: ["ADMIN", "ORG"] },
  { path: "/add-region", component: AddRegion, roles: ["ADMIN", "ORG"] },
  {
    path: "/edit-region/:id",
    component: EditRegion,
    roles: ["ADMIN", "ORG"],
  },
  // Geo sub menu area
  { path: "/geo/area", component: Area, roles: ["ADMIN", "ORG"] },
  { path: "/add-area", component: AddArea, roles: ["ADMIN", "ORG"] },
  {
    path: "/edit-area/:id",
    component: EditArea,
    roles: ["ADMIN", "ORG"],
  },
  // Geo sub menu territory
  {
    path: "/geo/territory",
    component: Territory,
    roles: ["ADMIN", "ORG"],
  },
  {
    path: "/add-territory",
    component: AddTerritory,
    roles: ["ADMIN", "ORG"],
  },
  {
    path: "/edit-territory/:id",
    component: EditTerritory,
    roles: ["ADMIN", "ORG"],
  },
  // Geo sub menu route
  { path: "/geo/route", component: Route, roles: ["ADMIN", "ORG"] },
  { path: "/add-route", component: AddRoute, roles: ["ADMIN", "ORG"] },
  {
    path: "/edit-route/:id",
    component: EditRoute,
    roles: ["ADMIN", "ORG"],
  },
  // Customer Segments
  {
    path: "/customer-segments",
    component: CustomerSegments,
    roles: ["ADMIN", "ORG"],
  },
  {
    path: "/add-customer-segment",
    component: AddCustomerSegments,
    roles: ["ADMIN", "ORG"],
  },
  {
    path: "/edit-customer-segment/:id",
    component: EditCustomerSegments,
    roles: ["ADMIN", "ORG"],
  },
  // { path: "/create-pjp", component: AddPJP },

  // Category
  { path: "/categories", component: Category, roles: ["ADMIN", "ORG"] },
  {
    path: "/add-category",
    component: AddCategory,
    roles: ["ADMIN", "ORG"],
  },
  {
    path: "/edit-category/:id",
    component: EditCategory,
    roles: ["ADMIN", "ORG"],
  },

  {
    path: "/sub-categories",
    component: SubCategory,
    roles: ["ADMIN", "ORG"],
  },
  {
    path: "/add-sub-category",
    component: AddSubCategory,
    roles: ["ADMIN", "ORG"],
  },
  {
    path: "/edit-sub-category/:id",
    component: EditSubCategory,
    roles: ["ADMIN", "ORG"],
  },

  //Target
  { path: "/target-set", component: TargetSet, roles: ["ADMIN", "ORG"] },
  {
    path: "/target-achievement",
    component: TargetAchievement,
    roles: ["ADMIN", "ORG"],
  },
  // // Route
  // { path: "/route", component: Route },
  // { path: "/add-route", component: AddRoute },
  // { path: "/view-route", component: ViewRoute },

  // target
  //{ path: "/target-set", component: SetTarget, roles: ["ADMIN"] },

  // pjp
  { path: "/set-pjp", component: AddGeo, roles: ["ADMIN", "ORG"] },
  { path: "/create-pjp", component: AddPJP, roles: ["ADMIN", "ORG"] },

  // Attendance
  { path: "/attendance", component: Attendance, roles: ["ADMIN", "ORG"] },

  // *organization panel* //
  // org sites
  { path: "/org-sites", component: OrgSites, roles: ["ORG", "ORG"] },
]

// const adminRoutes = [

// ]

// const orgRoutes = [

// ]

export { publicRoutes, authProtectedRoutes }
