//ADD Category
export const ADD_NEW_CATEGORY = "ADD_NEW_CATEGORY"
export const ADD_CATEGORY_SUCCESS = "ADD_CATEGORY_SUCCESS"
export const ADD_CATEGORY_FAIL = "ADD_CATEGORY_FAIL"

//GET Category
export const GET_CATEGORY_VALUE = "GET_CATEGORY_VALUE"
export const GET_CATEGORY_VALUE_SUCCESS = "GET_CATEGORY_VALUE_SUCCESS"
export const GET_CATEGORY_VALUE_FAIL = "GET_CATEGORY_VALUE_FAIL"

//EDIT CATEGORY
export const UPDATE_CATEGORY = "UPDATE_CATEGORY"
export const UPDATE_CATEGORY_SUCCESS = "UPDATE_CATEGORY_SUCCESS"
export const UPDATE_CATEGORY_FAIL = "UPDATE_CATEGORY_FAIL"

export const STORE_CATEGORY_DATA = "STORE_CATEGORY_DATA"

//ADD Sub Category
export const ADD_NEW_SUB_CATEGORY = "ADD_NEW_SUB_CATEGORY"
export const ADD_SUB_CATEGORY_SUCCESS = "ADD_SUB_CATEGORY_SUCCESS"
export const ADD_SUB_CATEGORY_FAIL = "ADD_SUB_CATEGORY_FAIL"

//GET Sub Category
export const GET_SUB_CATEGORY_VALUE = "GET_SUB_CATEGORY_VALUE"
export const GET_SUB_CATEGORY_VALUE_SUCCESS = "GET_SUB_CATEGORY_VALUE_SUCCESS"
export const GET_SUB_CATEGORY_VALUE_FAIL = "GET_SUB_CATEGORY_VALUE_FAIL"

//GET Sub Category by Category
export const GET_SUB_CATEGORY_BY_CATEGORY_VALUE =
  "GET_SUB_CATEGORY_BY_CATEGORY_VALUE"
export const GET_SUB_CATEGORY_BY_CATEGORY_VALUE_SUCCESS =
  "GET_SUB_CATEGORY_BY_CATEGORY_VALUE_SUCCESS"
export const GET_SUB_CATEGORY_BY_CATEGORY_VALUE_FAIL =
  "GET_SUB_CATEGORY_BY_CATEGORY_VALUE_FAIL"

//EDIT Sub CATEGORY
export const UPDATE_SUB_CATEGORY = "UPDATE_SUB_CATEGORY"
export const UPDATE_SUB_CATEGORY_SUCCESS = "UPDATE_SUB_CATEGORY_SUCCESS"
export const UPDATE_SUB_CATEGORY_FAIL = "UPDATE_SUB_CATEGORY_FAIL"

export const STORE_SUB_CATEGORY_DATA = "STORE_SUB_CATEGORY_DATA"
