export const GET_POT_VALUE = "GET_POT_VALUE"
export const GET_POT_VALUE_SUCCESS = "GET_POT_VALUE_SUCCESS"
export const GET_POT_VALUE_FAIL = "GET_POT_VALUE_FAIL"

export const GET_DASHBOARD_OVERVIEW = "GET_DASHBOARD_OVERVIEW"
export const GET_DASHBOARD_OVERVIEW_SUCCESS = "GET_DASHBOARD_OVERVIEW_SUCCESS"
export const GET_DASHBOARD_OVERVIEW_FAIL = "GET_DASHBOARD_OVERVIEW_FAIL"

export const GET_DASHBOARD_INDEX = "GET_DASHBOARD_INDEX"
export const GET_DASHBOARD_INDEX_SUCCESS = "GET_DASHBOARD_INDEX_SUCCESS"
export const GET_DASHBOARD_INDEX_FAIL = "GET_DASHBOARD_INDEX_FAIL"

export const GET_PAINT_AREA = "GET_PAINT_AREA"
export const GET_PAINT_AREA_SUCCESS = "GET_PAINT_AREA_SUCCESS"
export const GET_PAINT_AREA_FAIL = "GET_PAINT_AREA_FAIL"

export const GET_STRUCTURE_TYPE = "GET_STRUCTURE_TYPE"
export const GET_STRUCTURE_TYPE_SUCCESS = "GET_STRUCTURE_TYPE_SUCCESS"
export const GET_STRUCTURE_TYPE_FAIL = "GET_STRUCTURE_TYPE_FAIL"

export const GET_CONSTRUCTION_STAGE = "GET_CONSTRUCTION_STAGE"
export const GET_CONSTRUCTION_STAGE_SUCCESS = "GET_CONSTRUCTION_STAGE_SUCCESS"
export const GET_CONSTRUCTION_STAGE_FAIL = "GET_CONSTRUCTION_STAGE_FAIL"
