import AddCard from "components/Atoms/AddCard"
import TableHeader from "components/Common/TableHeader"
import CardComponent from "components/Layout/CardComponent"
import CustomTable from "components/Layout/CustomTable"
import InnerLayer from "components/Layout/InnerLayer"
import NoTableData from "components/Layout/NoTableData"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { withRouter } from "react-router-dom"
import { Col, Row } from "reactstrap"
import {
  getCategoryValue,
  getSubCategoryValue,
  storeSubCategoryData,
} from "../../../store/Category/action"

const tableHead = ["No.", "Category", "Sub Category", "Action"]

const SubCategory = ({ history }) => {
  const [currentPage, setCurrentPage] = useState(1)
  const [dataRange, setDataRange] = useState(10)
  const [categoryId, setCategoryId] = useState("All")
  let dispatch = useDispatch()
  const { subCategoryData, categoryData, loading, authtoken } = useSelector(
    state => ({
      loading: state.CategoryReducer.isLoading,
      subCategoryData: state.CategoryReducer.subCategoryData,
      categoryData: state.CategoryReducer.categoryData,
      authtoken: state.Login.token,
    })
  )
  useEffect(() => {
    dispatch(getCategoryValue(authtoken))
  }, [])

  useEffect(() => {
    dispatch(getSubCategoryValue(authtoken, categoryId, dataRange, currentPage))
  }, [dataRange, currentPage, categoryId])

  let totalPageNumber = Math.ceil(subCategoryData?.length / dataRange)
  console.log("totalPageNumber", totalPageNumber)

  const handleEditSubCategory = data => {
    dispatch(storeSubCategoryData("singleSubCategory", data))
    history.push("/edit-sub-category/" + data._id)
  }
  console.log("categoryId", categoryId)
  return (
    <React.Fragment>
      <InnerLayer
        title="Sub Category List"
        wrapperClass="py-3 users"
        isBreadCrumb={true}
        link={"#"}
        mainTitle={"Sub Category"}
        subTitle={"Sub Category List"}
      >
        <Row>
          <Col md="3" className="">
            <AddCard
              title="Create New Sub Category"
              buttonText="Create Sub Category"
              onAddHandler={() => history.push("/add-sub-category")}
            />
          </Col>
        </Row>

        {/* table */}

        <Row>
          <CardComponent className="user_table_card">
            {!loading && (
              <TableHeader
                handleSearchFilter={() => {}}
                handleSelectFilter={e => {
                  setDataRange(e.target.value)
                }}
                data={[10, 20, 50, 100]}
                handleSelectFilter3={e => {
                  setCategoryId(e.target.value)
                }}
                data3={
                  categoryData?.category?.length > 0
                    ? categoryData?.category
                    : []
                }
                selectValue={dataRange}
                title="Sub Category List"
                headerSearchOption="yes"
                isMultiple3
              />
            )}

            {/* table */}
            <Row>
              <CustomTable
                paginationClass="paginationContainer text-right"
                data={subCategoryData?.subCategory}
                tableHead={tableHead}
                setCurrentPage={setCurrentPage}
                isPagination
                pageNo={totalPageNumber}
              >
                {subCategoryData?.length > 0 ? (
                  subCategoryData?.subCategory.map((data, idx) => (
                    <tr key={idx}>
                      <th scope="row">{idx + 1}</th>
                      <td>{data.categoryId.name}</td>
                      <td>{data.name}</td>
                      <td>
                        <div className="d-flex gap-3">
                          <span
                            className="text-success pointer_event"
                            onClick={() => handleEditSubCategory(data)}
                          >
                            <i
                              className="mdi mdi-pencil font-size-18"
                              id="edittooltip"
                            ></i>
                          </span>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <NoTableData
                    colSpan=""
                    className="d-flex justify-content-center align-items-center"
                    style={{ minHeight: "300px", width: `100%` }}
                  >
                    <span>No data here</span>
                  </NoTableData>
                )}
              </CustomTable>
              {/* table end */}
            </Row>
          </CardComponent>
        </Row>
      </InnerLayer>
    </React.Fragment>
  )
}

SubCategory.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
}

export default withRouter(SubCategory)
